var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "login-m c-container navbar-distance" },
    [
      _c(
        "div",
        {
          staticClass:
            "c-navbar-m px-3 d-flex justify-content-between cj-bg-white-primary",
        },
        [
          _c("img", {
            staticClass: "c-logo-mobile my-auto",
            staticStyle: { width: "6rem" },
            attrs: {
              src: `${_vm.$env.CDN_CB}/content/cb-img/ic-logo-black.png`,
              alt: "Image Klikjob",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "my-auto cursor-pointer p-3 m-n3",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onBack.apply(null, arguments)
                },
              },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-100" }, [
        _c("p", {
          staticClass:
            "font-roboto cj-color-black-primary font-size-24 font-weight-700",
          domProps: {
            textContent: _vm._s(
              _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.title")
            ),
          },
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "font-catamaran cj-color-grey-secondary font-weight-400 font-size-14 my-3",
          },
          [
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbHome.cbHomeBeforeLogin.login.newToCollarbear"
                  )
                ),
              },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "cj-color-red-primary font-weight-600",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.linkRegister")
                ),
              },
              on: { click: _vm.toRegister },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "cursor-pointer wrap-border-grey-tertiary rounded-pill d-flex w-100 p-2 mb-3",
          attrs: { "data-gtm": "login-with-google-button" },
          on: { click: _vm.loginGoogle },
        },
        [
          _c("div", { staticClass: "d-flex mx-auto" }, [
            _c(
              "div",
              { staticClass: "d-flex my-auto mr-3" },
              [_c("icon", { attrs: { name: "ic_google", width: "1.625rem" } })],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticClass:
                "font-roboto my-auto font-weight-400 font-size-14-m text-nowrap cj-color-black-primary",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.google")
                ),
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "cursor-pointer wrap-border-grey-tertiary rounded-pill d-flex w-100 p-2 mb-3",
          attrs: { "data-gtm": "login-with-facebook-button" },
          on: { click: _vm.loginFacebook },
        },
        [
          _c("div", { staticClass: "d-flex mx-auto" }, [
            _c(
              "div",
              { staticClass: "d-flex my-auto mr-3" },
              [
                _c("icon", {
                  attrs: { name: "ic_facebook", width: "1.625rem" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticClass:
                "font-roboto my-auto font-weight-400 font-size-14-m text-nowrap cj-color-black-primary",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.facebook")
                ),
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "cursor-pointer wrap-border-grey-tertiary rounded-pill d-flex w-100 p-2 mb-3",
          attrs: { "data-gtm": "login-with-linkedin-button" },
          on: { click: _vm.loginLinkedin },
        },
        [
          _c("div", { staticClass: "d-flex mx-auto" }, [
            _c(
              "div",
              { staticClass: "d-flex my-auto mr-3" },
              [
                _c("icon", {
                  attrs: { name: "ic_linkedin", width: "1.625rem" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticClass:
                "font-roboto my-auto font-weight-400 font-size-14-m text-nowrap cj-color-black-primary",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.linkedin")
                ),
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "cursor-pointer wrap-border-grey-tertiary rounded-pill d-flex w-100 p-2",
          attrs: { "data-gtm": "login-with-apple-button" },
          on: { click: _vm.loginApple },
        },
        [
          _c("div", { staticClass: "d-flex mx-auto" }, [
            _c(
              "div",
              { staticClass: "d-flex my-auto mr-3" },
              [_c("icon", { attrs: { name: "ic_apple", width: "1.625rem" } })],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticClass:
                "font-roboto my-auto font-weight-400 font-size-14-m text-nowrap cj-color-black-primary",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.apple")
                ),
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", {}, [
        _c("div", { staticClass: "w-100" }, [
          _c("div", { staticClass: "d-flex w-100 my-3" }, [
            _c("p", { staticClass: "hr-text my-4" }, [
              _c("span", {
                staticClass:
                  "font-catamaran font-weight-light cj-color-grey-secondary font-size-12 cj-bg-white-primary px-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.or")
                  ),
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {}, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.$v.user.login.$model,
              expression: "$v.user.login.$model",
            },
          ],
          ref: "enterlogin",
          staticClass: "font-size-14 cj-field-input",
          class: _vm.isLoginValidCopy(_vm.$v.user.login),
          attrs: {
            "data-cy": "input-email-login-m",
            placeholder: "Email / Phone number",
            type: "text",
            autocomplete: "off",
          },
          domProps: { value: _vm.$v.user.login.$model },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.$v.user.login, "$model", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", {}, [
        _c("div", { staticClass: "d-flex mt-3 w-100 position-relative" }, [
          _vm.passwordInputType === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.user.password.$model,
                    expression: "$v.user.password.$model",
                  },
                ],
                ref: "enterloginPassword",
                staticClass: "font-size-14 w-100",
                class: `${
                  _vm.$v.user.password.$anyDirty
                    ? _vm.$v.user.password.$invalid
                      ? "invalid"
                      : "valid"
                    : ""
                }`,
                attrs: {
                  "data-cy": "input-password-login-m",
                  placeholder: "Password",
                  autocomplete: "off",
                  type: "checkbox",
                },
                domProps: {
                  checked: Array.isArray(_vm.$v.user.password.$model)
                    ? _vm._i(_vm.$v.user.password.$model, null) > -1
                    : _vm.$v.user.password.$model,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.$v.user.password.$model,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$v.user.password,
                            "$model",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$v.user.password,
                            "$model",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.$v.user.password, "$model", $$c)
                    }
                  },
                },
              })
            : _vm.passwordInputType === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.user.password.$model,
                    expression: "$v.user.password.$model",
                  },
                ],
                ref: "enterloginPassword",
                staticClass: "font-size-14 w-100",
                class: `${
                  _vm.$v.user.password.$anyDirty
                    ? _vm.$v.user.password.$invalid
                      ? "invalid"
                      : "valid"
                    : ""
                }`,
                attrs: {
                  "data-cy": "input-password-login-m",
                  placeholder: "Password",
                  autocomplete: "off",
                  type: "radio",
                },
                domProps: {
                  checked: _vm._q(_vm.$v.user.password.$model, null),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.$v.user.password, "$model", null)
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.user.password.$model,
                    expression: "$v.user.password.$model",
                  },
                ],
                ref: "enterloginPassword",
                staticClass: "font-size-14 w-100",
                class: `${
                  _vm.$v.user.password.$anyDirty
                    ? _vm.$v.user.password.$invalid
                      ? "invalid"
                      : "valid"
                    : ""
                }`,
                attrs: {
                  "data-cy": "input-password-login-m",
                  placeholder: "Password",
                  autocomplete: "off",
                  type: _vm.passwordInputType,
                },
                domProps: { value: _vm.$v.user.password.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$v.user.password,
                      "$model",
                      $event.target.value
                    )
                  },
                },
              }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-password position-absolute",
              staticStyle: {
                right: "0",
                top: "50%",
                transform: "translateY(-50%)",
              },
              attrs: { type: "button" },
              on: { click: _vm.togglePassword },
            },
            [
              _vm.passwordInputType === "text"
                ? _c("icon", {
                    staticClass: "cj-stroke-grey-secondary",
                    attrs: {
                      name: "ic_eye_open",
                      "data-cy": "button-hide-password",
                      width: "1rem",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.passwordInputType === "password"
                ? _c("icon", {
                    staticClass: "cj-stroke-grey-secondary",
                    attrs: {
                      name: "ic_eye_close",
                      "data-cy": "button-show-password",
                      width: "1rem",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.authenticationError && !_vm.isLoginInvalid
        ? _c(
            "div",
            {
              staticClass: "d-flex mb-3 mt-3",
              staticStyle: { width: "100%", gap: "4px" },
            },
            [
              _c("p", {
                staticClass:
                  "font-roboto cj-color-status-error font-size-12 font-weight-400",
                attrs: { "data-cy": "system-alert-login" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.login.incorrect")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-roboto cj-color-black-primary font-weight-600 hand font-size-12 font-underline cursor-pointer",
                attrs: { "data-cy": "forgot-password" },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.login.forgotPassword"
                    )
                  ),
                },
                on: { click: _vm.changePassword },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text-danger font-size-12 cj-color-status-error",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(`cbgwApp.cbComponent.login.liaison`)
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.authenticationError && !_vm.isLoginInvalid
        ? _c("p", {
            staticClass:
              "font-roboto cj-color-black-primary font-weight-600 hand font-size-12 font-underline mb-3 mt-3 cursor-pointer",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.login.phoneVerification")
              ),
            },
            on: { click: _vm.openPhoneVerification },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoginInvalid
        ? _c("p", {
            staticClass:
              "font-roboto cj-color-status-error font-size-12 font-weight-400 mb-3 mt-3",
            attrs: { "data-cy": "system-alert-login" },
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.login.incorrect")
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", {}, [
        _c("div", { staticClass: "w-100" }, [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("div", {
              staticClass: "input-checkbox cj-color-red-primary d-flex mt-3",
            }),
            _vm._v(" "),
            _c("p", {
              staticClass:
                "font-catamaran font-weight-400 mt-3 cj-color-grey-secondary font-size-12 flex-1 cursor-pointer global-underline",
              attrs: { "data-cy": "forgot-password" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbHome.cbHomeBeforeLogin.login.forgotPassword"
                  )
                ),
              },
              on: { click: _vm.changePassword },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-auto flex-1" },
            [
              _c(
                "c-button",
                {
                  staticClass: "font-roboto mr-2 text-center my-3",
                  attrs: {
                    "data-cy": "btn-submit-login-m",
                    fullWidth: "",
                    boxShadow: "",
                    disabled:
                      _vm.isRequesting ||
                      _vm.$v.user.login.$invalid ||
                      _vm.$v.user.password.$invalid ||
                      _vm.isLoginInvalid,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onLogin.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.isRequesting
                    ? _c("font-awesome-icon", {
                        attrs: { icon: "sync", spin: _vm.isRequesting },
                      })
                    : _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.login.btnLogin"
                            )
                          ),
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-p-register-m-pop-up",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [_c("p-register-m", { attrs: { closePopUp: _vm.closePopUp } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }