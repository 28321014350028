var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-item-chat-message-paw-order p-2 cj-bg-white-primary",
      attrs: { "data-cy": "item-chat-message-paw-order" },
    },
    [
      _c(
        "div",
        { staticClass: "text-center mb-2" },
        [
          _c(
            "p",
            {
              ref: "pawTitle",
              staticClass: "text-ellipsis oneline c-icm-font-card-title",
              attrs: { "data-cy": "message-paw-title" },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cbVwPawOrder.pawTitle) + "\n      "
              ),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["pawTitle"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.cbVwPawOrder.pawTitle) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              ref: "labelOrId",
              staticClass: "c-icm-font-card-subtitle text-ellipsis oneline",
              attrs: { "data-cy": "message-sub-title-project-delivery" },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cbVwPawOrder.labelOrderId) + "\n      "
              ),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["labelOrId"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [_vm._v(_vm._s(_vm.cbVwPawOrder.labelOrderId))]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.cbVwPawOrder.orderStatus
            ? _c("c-detail-order-status", {
                staticClass:
                  "justify-content-center align-items-center flex-column",
                attrs: {
                  type: _vm.TypeComponent.CHAT_BUBBLE,
                  "order-status": _vm.cbVwPawOrder.orderStatus,
                  "expired-date": _vm.cbVwPawOrder.expiredDate,
                  "book-start-date": _vm.cbVwPawOrder.bookStartDate,
                  "aalt-date": _vm.cbVwPawOrder.aaltDate,
                  "freeze-date": _vm.cbVwPawOrder.freezeDate,
                  "is-already-send-project-delivery": Boolean(
                    _vm.cbVwPawOrder.aaltDate
                  ),
                  "key-name": _vm.cbVwPawOrder.keyName,
                  "workflow-name": _vm.cbVwPawOrder.workflowName,
                  "is-freeze-countdown":
                    _vm.cbVwPawOrder.orderStatus ===
                      _vm.CbCommonStatus.REVISION_REQUESTED ||
                    _vm.cbVwPawOrder.orderStatus ===
                      _vm.CbCommonStatus.DISPUTED,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "cbVwPawOrderCurrency",
          staticClass:
            "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
        },
        [
          _c("p", {
            staticClass: "c-icm-font-pkg-title mb-1 text-ellipsis oneline",
            attrs: { "data-cy": "message-paw-pkg-type" },
            domProps: {
              textContent: _vm._s(
                _vm.getPackageTextEnId(_vm.cbVwPawOrder.pawPkgType)
              ),
            },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "c-icm-font-price-title",
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbDetailOrder.totalPrice")),
            },
          }),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "c-icm-font-price-content mb-1 text-ellipsis oneline",
              attrs: { "data-cy": "message-total-price" },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cbVwPawOrderRequirement.currency) +
                  " " +
                  _vm._s(
                    _vm._f("formatCurrency")(
                      _vm.cbVwPawOrderRequirement.amount,
                      _vm.cbVwPawOrderRequirement.currency
                    )
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.cbVwPawOrder.pawPkgDescription
            ? _c("c-textarea-preview", {
                staticClass: "c-icm-font-description-content mb-1 text-break",
                attrs: {
                  dataCyCustom: "message-paw",
                  text: _vm.cbVwPawOrder.pawPkgDescription,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-around" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                { staticClass: "d-flex my-auto" },
                [
                  _c("icon", {
                    staticClass: "cj-fill-red-primary my-auto mr-1",
                    attrs: {
                      name: "ic_time_outlined",
                      width: "12px",
                      height: "12px",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "c-icm-font-delivery-content my-auto text-ellipsis-oneline",
                  attrs: { "data-cy": "message-paw-delivery" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.getDeliveryTimeType(
                          _vm.cbVwPawOrder.deliveryTimeType,
                          _vm.cbVwPawOrder.deliveryTime
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.cbVwPawOrder.revision && _vm.CbSvcType.INSIDE
              ? _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex my-auto" },
                    [
                      _c("icon", {
                        staticClass: "cj-fill-red-primary my-auto mr-1",
                        attrs: {
                          name: "ic_revision",
                          width: "12px",
                          height: "12px",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "c-icm-font-delivery-content my-auto text-ellipsis oneline",
                      attrs: { "data-cy": "message-paw-revision" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.cbVwPawOrder.revision) +
                          "\n          "
                      ),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              _vm.getRevisionTextEnId(_vm.cbVwPawOrder.revision)
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "b-tooltip",
            {
              staticClass: "custom-tooltip",
              attrs: {
                target: () => _vm.$refs["cbVwPawOrderCurrency"],
                variant: "primary",
              },
            },
            [
              _c(
                "p",
                { staticClass: "font-catamaran font-size-12 font-weight-500" },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("capitalEachSpace")(_vm.cbVwPawOrder.pawPkgType)
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("p", {
                staticClass: "font-catamaran font-size-12 font-weight-500",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbDetailOrder.totalPrice")
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "font-catamaran font-size-12 font-weight-500" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.cbVwPawOrderRequirement.currency) +
                      " " +
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.cbVwPawOrderRequirement.amount,
                          _vm.cbVwPawOrderRequirement.currency
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.cbVwPawOrder.pawPkgDescription
                ? _c("c-textarea-preview", {
                    staticClass: "font-catamaran font-size-12 font-weight-500",
                    staticStyle: {
                      color: "var(--cj-color-white-primary) !important",
                    },
                    attrs: { text: _vm.cbVwPawOrder.pawPkgDescription },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "font-catamaran font-size-12 font-weight-500" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.getDeliveryTimeType(
                          _vm.cbVwPawOrder.deliveryTimeType,
                          _vm.cbVwPawOrder.deliveryTime
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.cbVwPawOrder.revision &&
              _vm.cbVwPawOrder.svcType === _vm.CbSvcType.INSIDE
                ? _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrder.revision) +
                          " " +
                          _vm._s(
                            _vm.$t(
                              _vm.getRevisionTextEnId(_vm.cbVwPawOrder.revision)
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
          staticStyle: { "word-break": "break-all" },
        },
        [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("p", {
              staticClass: "c-icm-font-title my-auto",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbDetailOrder.packageInclude")
                ),
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "d-block" }, [
              _c(
                "button",
                {
                  staticClass:
                    "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                  attrs: {
                    type: "button",
                    "data-cy": "message-btn-drop-pkg",
                    "data-gtm": "message-drop-pkg-button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isExpandPackage = !_vm.isExpandPackage
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass:
                      "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                    class: _vm.isExpandPackage ? "actived" : "",
                    attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              model: {
                value: _vm.isExpandPackage,
                callback: function ($$v) {
                  _vm.isExpandPackage = $$v
                },
                expression: "isExpandPackage",
              },
            },
            [
              !_vm.cbPawOrderIncludes || _vm.cbPawOrderIncludes.length === 0
                ? _c("div", [
                    _c("p", { staticClass: "c-icm-font-content" }, [
                      _vm._v("No Data Includes"),
                    ]),
                  ])
                : _vm._l(_vm.cbPawOrderIncludes, function (include, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "d-flex mb-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex my-auto mr-1" },
                          [
                            _c("icon", {
                              staticClass: "cj-fill-red-primary my-auto",
                              attrs: { name: "ic_check", width: "1.5rem" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "c-icm-font-content my-auto",
                            attrs: { "data-cy": "message-collapse-pkg-value" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("capitalEachSpace")(include.value)
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
          staticStyle: { "word-break": "break-all" },
        },
        [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("p", {
              staticClass: "c-icm-font-title my-auto",
              domProps: {
                textContent: _vm._s(_vm.$t("cbgwApp.cbDetailOrder.summary")),
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "d-block" }, [
              _c(
                "button",
                {
                  staticClass:
                    "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                  attrs: {
                    type: "button",
                    "data-cy": "message-btn-drop-summary",
                    "data-gtm": "message-drop-summary-button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isExpandRequirement = !_vm.isExpandRequirement
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass:
                      "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                    class: _vm.isExpandRequirement ? "actived" : "",
                    attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              model: {
                value: _vm.isExpandRequirement,
                callback: function ($$v) {
                  _vm.isExpandRequirement = $$v
                },
                expression: "isExpandRequirement",
              },
            },
            [
              !_vm.cbTextRestrict || !_vm.cbTextRestrict.content
                ? _c("div", [
                    _c("p", { staticClass: "c-icm-font-content" }, [
                      _vm._v("No Data Summary"),
                    ]),
                  ])
                : _c("c-textarea-preview", {
                    staticClass: "c-icm-font-content",
                    attrs: {
                      dataCyCustom: "message-collapse-summary",
                      text: _vm.cbTextRestrict.content,
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
          staticStyle: { "word-break": "break-all" },
        },
        [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("p", { staticClass: "c-icm-font-title my-auto" }, [
              _vm._v("Extra"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-block" }, [
              _c(
                "button",
                {
                  staticClass:
                    "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                  attrs: {
                    type: "button",
                    "data-cy": "message-btn-drop-extra",
                    "data-gtm": "message-drop-drop-button",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isExpandExtra = !_vm.isExpandExtra
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass:
                      "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                    class: _vm.isExpandExtra ? "actived" : "",
                    attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: _vm.isExpandExtra } },
            [
              _vm.isExpandExtra
                ? [
                    !_vm.cbVwPawOrderExtras ||
                    _vm.cbVwPawOrderExtras.length === 0
                      ? _c("div", [
                          _c("p", { staticClass: "c-icm-font-content" }, [
                            _vm._v("No Data Extra"),
                          ]),
                        ])
                      : _vm._l(_vm.cbVwPawOrderExtras, function (extra, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "d-flex mb-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex my-auto mr-1" },
                                [
                                  _c("icon", {
                                    staticClass: "cj-fill-red-primary",
                                    attrs: {
                                      name: "ic_check",
                                      width: "24",
                                      height: "24",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between align-items-center w-100",
                                  attrs: {
                                    "data-cy": "message-collapse-extra-wrap",
                                  },
                                },
                                [
                                  extra.pawOrderExtraType ===
                                  _vm.CbPawOrderExtraType.URGENT
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "c-icm-font-content cj-color-white-primary cj-bg-status-error py-1 px-3 my-auto rounded t",
                                          attrs: {
                                            "data-cy":
                                              "message-collapse-extra-urgent",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("capitalEachSpace")(
                                                  extra.value
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "p",
                                        {
                                          staticClass:
                                            "c-icm-font-content py-1",
                                          attrs: {
                                            "data-cy":
                                              "message-collapse-extra-more",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("capitalEachSpace")(
                                                  extra.value
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-icm-font-content py-1 text-right",
                                      attrs: {
                                        "data-cy":
                                          "message-collapse-extra-amount",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                +" +
                                          _vm._s(
                                            _vm.cbVwPawOrderRequirement.currency
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              extra.amount,
                                              _vm.cbVwPawOrderRequirement
                                                .currency
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.cbVwPawOrder.svcType == _vm.CbSvcType.INSIDE
        ? _c(
            "div",
            {
              staticClass:
                "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
              staticStyle: { "word-break": "break-all" },
            },
            [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c("p", { staticClass: "c-icm-font-title my-auto" }, [
                  _vm._v("Milestone"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-block" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                      attrs: {
                        type: "button",
                        "data-cy": "message-btn-drop-milestone",
                        "data-gtm": "message-drop-milestone-button",
                      },
                      on: {
                        click: function ($event) {
                          _vm.isExpandMilestone = !_vm.isExpandMilestone
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass:
                          "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                        class: _vm.isExpandMilestone ? "actived" : "",
                        attrs: {
                          name: "ic_down",
                          width: "1rem",
                          height: "1rem",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  model: {
                    value: _vm.isExpandMilestone,
                    callback: function ($$v) {
                      _vm.isExpandMilestone = $$v
                    },
                    expression: "isExpandMilestone",
                  },
                },
                [
                  _vm.cbPawOrderMilestones &&
                  _vm.cbPawOrderMilestones.length > 0 &&
                  _vm.cbPawOrderMilestones[1].name &&
                  _vm.cbPawOrderMilestones[1].completedDate
                    ? [
                        _c("c-progress-milestone", {
                          attrs: {
                            type: _vm.TypeComponent.CHAT_BUBBLE,
                            cbVwPawOrder: _vm.cbVwPawOrder,
                          },
                          model: {
                            value: _vm.cbPawOrderMilestones,
                            callback: function ($$v) {
                              _vm.cbPawOrderMilestones = $$v
                            },
                            expression: "cbPawOrderMilestones",
                          },
                        }),
                      ]
                    : _c("div", [
                        _c("p", { staticClass: "c-icm-font-content" }, [
                          _vm._v("No Data Milestone"),
                        ]),
                      ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.cbVwPawOrder.svcType === _vm.CbSvcType.FIELD
        ? [
            _c(
              "div",
              {
                staticClass:
                  "rounded border-width-1 p-2 mb-2 cj-border-color-red-primary",
                staticStyle: { "word-break": "break-all" },
              },
              [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c("p", {
                    staticClass: "c-icm-font-title my-auto",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbDetailOrder.bookDate")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-block" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                        attrs: {
                          type: "button",
                          "data-cy": "message-btn-drop-book-date",
                          "data-gtm": "message-drop-book-date-button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.isExpandDate = !_vm.isExpandDate
                          },
                        },
                      },
                      [
                        _c("icon", {
                          staticClass:
                            "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                          class: _vm.isExpandDate ? "actived" : "",
                          attrs: {
                            name: "ic_down",
                            width: "1rem",
                            height: "1rem",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "b-collapse",
                  {
                    model: {
                      value: _vm.isExpandDate,
                      callback: function ($$v) {
                        _vm.isExpandDate = $$v
                      },
                      expression: "isExpandDate",
                    },
                  },
                  [
                    _vm.cbVwPawOrderRequirement?.bookStartDate &&
                    _vm.cbVwPawOrderRequirement?.timeZone
                      ? _c(
                          "p",
                          {
                            staticClass: "c-icm-font-content text-left",
                            attrs: { "data-cy": "message-collapse-book-date" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$options.filters.convertDateToAccordanceTimeZone(
                                    _vm.cbVwPawOrderRequirement?.bookStartDate,
                                    _vm.cbVwPawOrderRequirement?.timeZone
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _c("div", [
                          _c("p", { staticClass: "c-icm-font-content" }, [
                            _vm._v("No Data Book Date"),
                          ]),
                        ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "rounded border-width-1 p-2 mb-2 cj-border-color-red-primary",
                staticStyle: { "word-break": "break-all" },
              },
              [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c("p", {
                    staticClass: "c-icm-font-title my-auto",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbDetailOrder.workLocation")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-block" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                        attrs: {
                          type: "button",
                          "data-cy": "message-btn-drop-work-location",
                          "data-gtm": "message-drop-work-location-button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.isExpandLocation = !_vm.isExpandLocation
                          },
                        },
                      },
                      [
                        _c("icon", {
                          staticClass:
                            "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                          class: _vm.isExpandLocation ? "actived" : "",
                          attrs: {
                            name: "ic_down",
                            width: "1rem",
                            height: "1rem",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("b-collapse", { attrs: { visible: _vm.isExpandLocation } }, [
                  _vm.cbLocation?.address
                    ? _c("div", [
                        _c(
                          "p",
                          {
                            staticClass: "c-icm-font-content",
                            attrs: {
                              "data-cy": "message-collapse-work-location",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.cbLocation.address) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("button", {
                          staticClass:
                            "cj-btn-text-primary font-size-10 font-weight-400 p-0 mt-2",
                          attrs: {
                            "data-cy": "message-collapse-view-map",
                            "data-gtm": "message-collapse-map-button",
                            type: "button",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.$t("cbGlobal.viewInMap")),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$root.$emit("opc::actionHandler", {
                                action: "HANDLE_VIEW_MAP_TRACK",
                                cbVwPawOrder: _vm.cbVwPawOrder,
                              })
                            },
                          },
                        }),
                      ])
                    : _c("div", [
                        _c("p", { staticClass: "c-icm-font-content" }, [
                          _vm._v("No Data Work Location"),
                        ]),
                      ]),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.cbVwPawOrder.orderStatus === _vm.CbCommonStatus.WAITING_PAYMENT &&
      _vm.cbVwPawOrder.buyerId === _vm.currentUser.id
        ? _c(
            "c-button",
            {
              staticClass: "c-icm-font-btn",
              attrs: {
                disabled: _vm.isSavingLoaderPaymentOrderOffer,
                dataGTMCustom: "accept-and-pay-message",
                "data-cy": "message-btn-accept-and-pay",
                fullWidth: "",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleButtonOfferCard(
                    "HANDLE_MDL_PAYMENT_ORDER_OFFER"
                  )
                },
              },
            },
            [
              _vm.isSavingLoaderPaymentOrderOffer
                ? _c("font-awesome-icon", {
                    attrs: {
                      icon: "sync",
                      spin: _vm.isSavingLoaderPaymentOrderOffer,
                    },
                  })
                : _vm.cbVwPawOrder.svcType === _vm.CbSvcType.INSIDE &&
                  !_vm.isSavingLoaderPaymentOrderOffer
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbDetailOrder.textButtonOrder.payStartProject"
                        )
                      ),
                    },
                  })
                : _vm.cbVwPawOrder.svcType === _vm.CbSvcType.FIELD &&
                  !_vm.isSavingLoaderPaymentOrderOffer
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbDetailOrder.textButtonOrder.payNBookService"
                        )
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.cbVwPawOrder.orderStatus === _vm.CbCommonStatus.OVERDUE &&
      _vm.cbVwPawOrder.buyerId === _vm.currentUser.id
        ? _c(
            "div",
            { staticClass: "d-flex cb-gap-1 mb-3 flex-column" },
            [
              _c(
                "c-button",
                {
                  staticClass: "font-size-14 p-1 w-button-web w-100",
                  attrs: {
                    dataGTMCustom: "cancel-order-message",
                    "data-cy": "message-btn-cancel-order",
                    "type-style": "secondary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleButtonOfferCard(
                        "HANDLE_BUYER_CANCEL_ORDER_OPEN"
                      )
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbDetailOrder.textButtonOrder.cancelOrder"
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "c-button",
                {
                  staticClass: "font-size-14 py-custom w-button-web w-100",
                  attrs: {
                    "data-cy": "message-btn-extend-time",
                    dataGTMCustom: "extend-order",
                    "type-style": "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleButtonOfferCard(
                        "HANDLE_BUYER_EXTEND_ORDER_OPEN"
                      )
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbDetailOrder.textButtonOrder.extendTime"
                        )
                      ),
                    },
                  }),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.cbVwPawOrder.orderStatus === _vm.CbCommonStatus.LATE &&
        _vm.wfHistoriesKeyName.includes("LATE_1") &&
        _vm.cbVwPawOrder.buyerId === _vm.currentUser.id) ||
      (_vm.cbVwPawOrder.orderStatus === _vm.CbCommonStatus.LATE &&
        _vm.wfHistoriesKeyName.includes("LATE_ON_TRACK_1") &&
        _vm.cbVwPawOrder.buyerId === _vm.currentUser.id)
        ? _c(
            "div",
            { staticClass: "d-flex cb-gap-1 mb-3 flex-column" },
            [
              _c(
                "c-button",
                {
                  staticClass: "font-size-14 p-1 w-button-web w-100",
                  attrs: {
                    "data-cy": "message-btn-cancel-order",
                    dataGTMCustom: "extend-order",
                    "type-style": "secondary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleButtonOfferCard(
                        "HANDLE_BUYER_CANCEL_ORDER_OPEN"
                      )
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbDetailOrder.textButtonOrder.cancelOrder"
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.wfHistoriesKeyName.includes("LATE_ON_TRACK_1")
                ? _c(
                    "c-button",
                    {
                      staticClass: "font-size-14 py-custom w-button-web w-100",
                      attrs: {
                        "data-cy": "message-btn-extend-time",
                        dataGTMCustom: "track-order",
                        "type-style": "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleButtonOfferCard(
                            "HANDLE_BUYER_TRACK_ORDER"
                          )
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbDetailOrder.textButtonOrder.trackFreelancer"
                            )
                          ),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }