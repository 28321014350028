var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showRoomsList,
          expression: "showRoomsList",
        },
      ],
      staticClass: "vac-rooms-container",
      class: { "vac-rooms-container-full show-footer-tab": _vm.isMobile },
    },
    [
      _vm._t("rooms-header"),
      _vm._v(" "),
      _vm._t("rooms-list-search", function () {
        return [
          _c("rooms-search", {
            ref: "focusSearcListMain",
            attrs: {
              rooms: _vm.rooms,
              "loading-rooms": _vm.loadingRooms,
              "text-messages": _vm.textMessages,
              "show-search": _vm.showSearch,
              "show-add-room": _vm.showAddRoom,
              isShowSearch: _vm.isShowSearch,
            },
            on: {
              "search-room": _vm.searchRoom,
              "add-room": function ($event) {
                return _vm.$emit("add-room")
              },
              "handle-show-search": _vm.handleShowSearch,
              "handle-show-setting-user": function ($event) {
                return _vm.$emit("handleShowSettingUser")
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (i, name) {
                  return {
                    key: name,
                    fn: function (data) {
                      return [_vm._t(name, null, null, data)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
        ]
      }),
      _vm._v(" "),
      _c("loader", {
        attrs: { show: _vm.loadingRooms },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (idx, name) {
              return {
                key: name,
                fn: function (data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vac-room-list",
          class: { "vac-room-list-bottom": !_vm.isMobile },
          attrs: { id: "rooms-list", "data-cy": "vac-room-list" },
        },
        [
          _vm.filteredRooms &&
          _vm.filteredRooms.length == 0 &&
          !_vm.loadingRooms
            ? _c(
                "p",
                {
                  staticClass:
                    "text-center p-4 font-roboto cj-color-grey-primary font-size-12 font-weight-500",
                  domProps: { textContent: _vm._s(_vm.$t("chat.noUserFound")) },
                },
                [_vm._v("\n      No Found\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.filteredRooms && _vm.filteredRooms.length > 0
            ? [
                _vm._l(_vm.filteredRooms, function (fRoom, index) {
                  return _c(
                    "div",
                    {
                      key: fRoom.roomId,
                      staticClass: "vac-room-item",
                      class: {
                        "vac-room-selected":
                          _vm.selectedRoomId === fRoom.roomId,
                        "border-bottom-solid":
                          index != _vm.filteredRooms.length - 1,
                      },
                      attrs: {
                        "data-cy": "room-list",
                        "data-gtm": "room-chat-item-clickable",
                        id: fRoom.roomId,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openRoom(fRoom)
                        },
                      },
                    },
                    [
                      _c("room-content", {
                        attrs: {
                          "current-user-id": _vm.currentUserId,
                          room: fRoom,
                          "text-formatting": _vm.textFormatting,
                          "link-options": _vm.linkOptions,
                          "text-messages": _vm.textMessages,
                          "room-actions": _vm.roomActions,
                          "user-ids-online": _vm.userIdsOnline,
                        },
                        on: {
                          "room-action-handler": function ($event) {
                            return _vm.$emit("room-action-handler", $event)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.$scopedSlots, function (i, name) {
                              return {
                                key: name,
                                fn: function (data) {
                                  return [_vm._t(name, null, null, data)]
                                },
                              }
                            }),
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("transition", { attrs: { name: "vac-fade-message" } }, [
                  _vm.rooms.length && !_vm.loadingRooms
                    ? _c(
                        "div",
                        { attrs: { id: "infinite-loader-rooms" } },
                        [
                          _c("loader", {
                            attrs: { show: _vm.showLoader, infinite: true },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(_vm.$scopedSlots, function (idx, name) {
                                  return {
                                    key: name,
                                    fn: function (data) {
                                      return [_vm._t(name, null, null, data)]
                                    },
                                  }
                                }),
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }