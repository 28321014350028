import { Component, Prop, Vue } from 'vue-property-decorator';

import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';

import { isVideoFile } from '@/shared/media-file/media-file';

@Component({
  components: { Icon, CButton },
})
export default class CPdPortfolioSlideshow extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({ default: [] }) public cbVwPawDetailAttPortfolios;
  @Prop({ default: [] }) public countPortfolio;

  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  public isVideo(attContentType) {
    return isVideoFile(attContentType);
  }
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
