var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "custom-timepicker",
      style: !_vm.isMobile ? "min-width: 150px;" : "",
      attrs: { "data-cy": "wrap-time-picker" },
    },
    [
      !_vm.isMobile
        ? _c("v-date-picker", {
            staticClass: "cj-date-picker",
            attrs: {
              "data-cy": "date-picker",
              disabled: _vm.disabledTime,
              mode: "time",
              is24hr: _vm.isFormat24,
              "hide-time-header": "",
            },
            on: { input: _vm.handleInput },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ inputValue, inputEvents }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center rounded w-100 font-roboto font-weight-400 font-size-18 cj-color-black-primary",
                        },
                        [
                          _c("icon", {
                            staticClass:
                              "cj-fill-grey-secondary dr-icon-wrapper ml-3",
                            attrs: {
                              name: "ic_time_outlined",
                              width: "30px",
                              height: "30px",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "input",
                            _vm._g(
                              {
                                ref: "date",
                                staticClass:
                                  "cj-field-not-border rounded w-100 font-roboto font-weight-400 font-size-18 cj-color-black-primary ml-2",
                                style: {
                                  height: _vm.timeHeight
                                    ? _vm.timeHeight
                                    : "2.719rem",
                                },
                                attrs: {
                                  readonly: "",
                                  "data-cy": _vm.dataCyCustom
                                    ? `timepicker-${_vm.dataCyCustom}`
                                    : "date",
                                  "data-gtm": _vm.dataGTMCustom
                                    ? `timepicker-${_vm.dataGTMCustom}`
                                    : "date",
                                  placeholder: _vm.placeholder,
                                },
                                domProps: { value: inputValue },
                              },
                              inputEvents
                            )
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1815143565
            ),
            model: {
              value: _vm.localDate,
              callback: function ($$v) {
                _vm.localDate = $$v
              },
              expression: "localDate",
            },
          })
        : _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("icon", {
                staticClass: "cj-fill-grey-secondary dr-icon-wrapper ml-2",
                attrs: {
                  name: "ic_time_outlined",
                  width: "28px",
                  height: "28px",
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.localValue,
                    expression: "localValue",
                  },
                ],
                staticClass:
                  "cj-field-not-border rounded w-100 font-roboto font-weight-400 font-size-16 cj-color-black-primary ml-2",
                style: { height: _vm.timeHeight ? _vm.timeHeight : "2.719rem" },
                attrs: {
                  "data-cy": _vm.dataCyCustom
                    ? `timepicker-${_vm.dataCyCustom}`
                    : "date",
                  readonly: "",
                  placeholder: _vm.placeholder,
                },
                domProps: { value: _vm.localValue },
                on: {
                  click: _vm.showPopup,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.localValue = $event.target.value
                  },
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _vm.isMobile && _vm.isShowPopup
        ? _c(
            "c-popup-bottom",
            {
              attrs: {
                id: _vm.idCustomPopupTimepicker
                  ? _vm.idCustomPopupTimepicker
                  : "popup-timepicker",
              },
              model: {
                value: _vm.isShowPopup,
                callback: function ($$v) {
                  _vm.isShowPopup = $$v
                },
                expression: "isShowPopup",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between timepicker-header",
                },
                [
                  _c(
                    "p",
                    { staticClass: "font-roboto font-weight-700 font-size-16" },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cbTimepicker.selectTime"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-roboto font-size-16 font-weight-600 cj-btn-text-primary",
                      attrs: { "data-cy": "btn-popup-update-time" },
                      on: { click: _vm.updateTime },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbAuctionProject.update")
                          ),
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  attrs: {
                    "data-cy": _vm.dataCyCustom
                      ? `timepicker-${_vm.dataCyCustom}`
                      : "date",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "custom-timepicker" },
                    [
                      _c("picker", {
                        key: 1,
                        attrs: {
                          options: _vm.getHours,
                          radius: 150,
                          number: 10,
                        },
                        model: {
                          value: _vm.currentHour,
                          callback: function ($$v) {
                            _vm.currentHour = $$v
                          },
                          expression: "currentHour",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "custom-timepicker" },
                    [
                      _c("picker", {
                        key: 2,
                        attrs: {
                          options: _vm.getMinutes,
                          radius: 375,
                          number: 10,
                        },
                        model: {
                          value: _vm.currentMinute,
                          callback: function ($$v) {
                            _vm.currentMinute = $$v
                          },
                          expression: "currentMinute",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }