var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-pd-tags-or-tools-item d-flex align-items-center px-3 py-1 my-1",
      attrs: { "data-cy": "tags-tools" },
    },
    [
      _vm.isShowImg
        ? _c("div", { staticClass: "card-item-wrapper m-0 mr-1" }, [
            _c("div", { staticClass: "cb-img-wrapper cb-ratio-1x1" }, [
              _c("img", {
                attrs: {
                  src: _vm.$options.filters.LAZY(
                    `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${_vm.img}`,
                    16,
                    "ic-default-tools.png"
                  ),
                  onerror: _vm.$options.filters.LAZY_ERROR(
                    "ic-default-tools.png"
                  ),
                  alt: "img",
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow-1" }, [
        _c(
          "p",
          {
            staticClass:
              "m-0 font-roboto font-weight-500 cj-color-black-primary",
            class: _vm.isMobile ? "font-size-12" : "font-size-10",
            attrs: {
              "data-cy": _vm.isShowImg ? "detail-paw-tool" : "detail-paw-tag",
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.value) + "\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }