var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: Object.keys(_vm.room).length && _vm.showFooter,
          expression: "Object.keys(room).length && showFooter",
        },
      ],
      staticClass: "vac-room-footer",
      class: {
        "vac-app-box-shadow": _vm.shadowFooter,
      },
      attrs: { id: "room-footer", "data-cy": "vac-room-footer" },
    },
    [
      _c(
        "transition",
        { attrs: { name: "vac-slide-up" } },
        [
          _vm.isShowUpload
            ? _c("room-uploads-chat", {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.closeIsShowUpload,
                    expression: "closeIsShowUpload",
                  },
                ],
                on: {
                  launchFilePicker: _vm.launchFilePicker,
                  launchVideoImage: _vm.launchVideoImage,
                  handleLocation: _vm.showRoomLocationChat,
                  handleCamera: _vm.showRoomCameraChatChat,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("room-emojis", {
        attrs: {
          "filtered-emojis": _vm.filteredEmojis,
          "select-item": _vm.selectEmojiItem,
          "active-up-or-down": _vm.activeUpOrDownEmojis,
        },
        on: {
          "select-emoji": function ($event) {
            return _vm.selectEmoji($event)
          },
          "activate-item": function ($event) {
            _vm.activeUpOrDownEmojis = 0
          },
        },
      }),
      _vm._v(" "),
      _c("room-users-tag", {
        attrs: {
          "filtered-users-tag": _vm.filteredUsersTag,
          "select-item": _vm.selectUsersTagItem,
          "active-up-or-down": _vm.activeUpOrDownUsersTag,
        },
        on: {
          "select-user-tag": function ($event) {
            return _vm.selectUserTag($event)
          },
          "activate-item": function ($event) {
            _vm.activeUpOrDownUsersTag = 0
          },
        },
      }),
      _vm._v(" "),
      _c("room-templates-text", {
        attrs: {
          "filtered-templates-text": _vm.filteredTemplatesText,
          "select-item": _vm.selectTemplatesTextItem,
          "active-up-or-down": _vm.activeUpOrDownTemplatesText,
        },
        on: {
          "select-template-text": function ($event) {
            return _vm.selectTemplateText($event)
          },
          "activate-item": function ($event) {
            _vm.activeUpOrDownTemplatesText = 0
          },
        },
      }),
      _vm._v(" "),
      _c("room-message-reply", {
        attrs: {
          room: _vm.room,
          "message-reply": _vm.messageReply,
          "text-formatting": _vm.textFormatting,
          "link-options": _vm.linkOptions,
        },
        on: { "reset-message": _vm.resetMessage },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (i, name) {
              return {
                key: name,
                fn: function (data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c("room-files-chat", {
        attrs: { files: _vm.files },
        on: {
          "remove-file": _vm.removeFile,
          "reset-message": _vm.resetMessage,
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (i, name) {
              return {
                key: name,
                fn: function (data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vac-box-footer",
          class: { "vac-box-footer-border": !_vm.files.length },
        },
        [
          _vm.isClosed
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "pb-2 m-0 font-size-10 text-center cj-color-grey-secondary font-roboto font-weight-400",
                      attrs: { "data-cy": "vac-box-close-ticket" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("chat.msgChatEnd")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "c-button",
                    {
                      staticClass:
                        "font-size-20 text-center cj-color-white-primary font-roboto font-weight-500",
                      attrs: { fullWidth: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.askmeQuestionAgain.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("chat.askAnotherQuestion")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isShowEmotReactions && !_vm.isClosed
            ? _c("div", { staticClass: "d-flex" }, [
                _c("textarea", {
                  ref: "roomTextarea",
                  staticClass: "vac-textarea",
                  class: {
                    "vac-textarea-outline": _vm.editedMessage._id,
                  },
                  style: {
                    "min-height": `20px`,
                    "padding-left": `12px`,
                    "max-height": "160px",
                  },
                  attrs: {
                    id: "roomTextarea",
                    placeholder: _vm.$t("chat.typeMessage"),
                    "data-cy": "vac-type-message",
                  },
                  on: {
                    input: _vm.onChangeInput,
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.escapeTextarea.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        if (
                          $event.ctrlKey ||
                          $event.shiftKey ||
                          $event.altKey ||
                          $event.metaKey
                        )
                          return null
                        $event.preventDefault()
                        return _vm.selectItem.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                        )
                          return null
                        if (
                          $event.ctrlKey ||
                          $event.shiftKey ||
                          $event.altKey ||
                          $event.metaKey
                        )
                          return null
                        $event.preventDefault()
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                        )
                          return null
                        return _vm.selectItem.apply(null, arguments)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "up", 38, $event.key, [
                            "Up",
                            "ArrowUp",
                          ])
                        )
                          return null
                        if (
                          $event.ctrlKey ||
                          $event.shiftKey ||
                          $event.altKey ||
                          $event.metaKey
                        )
                          return null
                        $event.preventDefault()
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "up", 38, $event.key, [
                            "Up",
                            "ArrowUp",
                          ])
                        )
                          return null
                        return _vm.updateActiveUpOrDown(-1)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown",
                          ])
                        )
                          return null
                        if (
                          $event.ctrlKey ||
                          $event.shiftKey ||
                          $event.altKey ||
                          $event.metaKey
                        )
                          return null
                        $event.preventDefault()
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown",
                          ])
                        )
                          return null
                        return _vm.updateActiveUpOrDown(1)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.addNewLine.apply(null, arguments)
                      },
                    ],
                    paste: _vm.onPasteImage,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "vac-icon-textarea" }, [
                  _vm.editedMessage._id
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-svg-button",
                          on: { click: _vm.resetMessage },
                        },
                        [
                          _vm._t("edit-close-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: { name: "close-outline" },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "vac-svg-button",
                      attrs: { "data-cy": "vac-btn-emoji-icon" },
                      on: {
                        click: function ($event) {
                          return _vm.handleShowEmoticon()
                        },
                      },
                    },
                    [
                      _vm._t("emoji-picker-chat-icon", function () {
                        return [
                          _c("b-icon-emoji-wink", {
                            staticClass: "font-size-22 cj-color-red-primary",
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _vm.showFiles && !_vm.files.length
                      ? _c(
                          "div",
                          {
                            staticClass: "vac-svg-button btn_show_upload",
                            attrs: { "data-cy": "vac-btn-attachment" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShowUpload()
                              },
                            },
                          },
                          [
                            _vm._t("paperclip-icon", function () {
                              return [
                                _c("b-icon-paperclip", {
                                  staticClass:
                                    "cj-color-red-primary font-size-24",
                                  attrs: { rotate: "45" },
                                }),
                              ]
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.files.length && _vm.seletedFileUpload == "DOCUMENT"
                      ? _c(
                          "div",
                          {
                            staticClass: "vac-svg-button",
                            on: {
                              click: function ($event) {
                                return _vm.launchFilePicker()
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "24px", height: "24px" },
                              attrs: {
                                src: `${_vm.$env.CDN_CB}/content/cb-img/ic-document.png`,
                                alt: "file",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.files.length && _vm.seletedFileUpload == "VIDEOIMAGE"
                      ? _c(
                          "div",
                          {
                            staticClass: "vac-svg-button",
                            on: {
                              click: function ($event) {
                                return _vm.launchVideoImage()
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "24px", height: "24px" },
                              attrs: {
                                src: `${_vm.$env.CDN_CB}/content/cb-img/ic-image.png`,
                                alt: "file",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.textareaActionEnabled
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-svg-button",
                          on: { click: _vm.textareaActionHandler },
                        },
                        [
                          _vm._t("custom-action-icon", function () {
                            return [
                              _c("svg-icon", { attrs: { name: "deleted" } }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showFiles
                    ? _c("input", {
                        ref: "file",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          multiple: "",
                          accept:
                            ".pdf, .docx, .xlsx, .pptx, .doc, .xls, .ppt, .key, .numbers, .pages, .mid, .midi, .mp3, .wav",
                          "data-cy": "vac-input-document",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange($event.target.files)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showFiles
                    ? _c("input", {
                        ref: "videoImage",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          multiple: "",
                          accept:
                            ".gif, .jpeg ,.jpg, .svg, .png, .3gp, .avi, .flv, .m4v, .mkv, .mov, .mp4, .mpg, .mpeg, .wmv",
                          "data-cy": "vac-input-image-and-video",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onFileChange($event.target.files)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showSendIcon
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-svg-button",
                          class: { "vac-send-disabled": _vm.isMessageEmpty },
                          attrs: { "data-cy": "vac-btn-send-message" },
                          on: { click: _vm.sendMessage },
                        },
                        [
                          _vm._t("send-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "send",
                                  param:
                                    _vm.isMessageEmpty || _vm.isFileLoading
                                      ? "disabled"
                                      : "",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "vac-slide-up" } }, [
            _vm.isShowEmoticon
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.closeIsShowEmoticon,
                        expression: "closeIsShowEmoticon",
                      },
                    ],
                    staticClass: "pt-2",
                  },
                  [
                    _c("room-emojis-chat", {
                      attrs: {
                        cbStickers: _vm.cbStickers,
                        seletedShowEmojis: _vm.seletedShowEmojis,
                      },
                      on: {
                        "add-emoji": _vm.addEmoji,
                        "send-message-sticker": _vm.sendMessageSticker,
                        handleSeletedEmojis: _vm.handleSeletedEmojis,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.isShowEmotReactions
            ? _c("room-reactions-chat", {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.closeShowReactions,
                    expression: "closeShowReactions",
                  },
                ],
                on: { "send-message-reaction": _vm.sendMessageReaction },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("c-audio", { ref: "audioMain" }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "roomLocationChatModal",
          attrs: {
            "data-cy": "modal-room-location-chat",
            id: "modal-room-location-chat",
            size: "lg",
            "hide-footer": "",
            "hide-header": "",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
          },
        },
        [
          _c("room-location-chat", {
            attrs: { currentLocation: _vm.currentLocation },
            on: { callBack: _vm.roomLocationChatCallBack },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "roomCameraChatChatModal",
          attrs: {
            id: "modal-room-camera-chat",
            "hide-footer": "",
            "hide-header": "",
            "content-class": "vac-custom-modal-content-camera",
            "dialog-class": "vac-custom-modal-size-camera",
            "modal-class": "vac-custom-modal-b-modal",
          },
        },
        [
          _c("room-camera-chat", {
            attrs: { btnTextConfirm: _vm.$t("chat.send") },
            on: {
              callBack: _vm.roomCameraChatChatCallBack,
              submitPicture: _vm.sendTakePictureToMsg,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-modal", {
        ref: "roomNotifLocationModal",
        attrs: {
          id: "modal-confirmation-notification-location",
          "modal-class": "vac-custom-modal-b-modal",
          "hide-footer": "",
          "hide-header": "",
          centered: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t("cbgwApp.cbChat.roomNotif.enableLocAccess"),
                    subTitle: _vm.$t(
                      "cbgwApp.cbChat.roomNotif.descEnableLocAccess"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        ref: "roomNotifCameraModal",
        attrs: {
          id: "modal-confirmation-notification-camera",
          "modal-class": "vac-custom-modal-b-modal",
          "hide-footer": "",
          "hide-header": "",
          centered: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t(
                      "cbgwApp.cbChat.roomNotif.enableCameraAccess"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbChat.roomNotif.descEnableCameraAccess"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }