var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex c-detail-order-status",
      class: {
        "flex-column": _vm.type !== _vm.typeComponent.MODAL_DELIVERY,
        "flex-row": _vm.type === _vm.typeComponent.MODAL_DELIVERY,
      },
    },
    [
      _vm.type !== _vm.typeComponent.ONLY_COUNTDOWN
        ? _c(
            "div",
            {
              staticClass: "text-ellipsis-oneline",
              class: {
                "cb-badge-status":
                  _vm.type === _vm.typeComponent.CHAT_WIDGET ||
                  _vm.type === _vm.typeComponent.CHAT_BUBBLE ||
                  _vm.type === _vm.typeComponent.DETAIL_ORDER ||
                  _vm.type === _vm.typeComponent.MANAGE_ORDER,
                "font-size-10":
                  _vm.type === _vm.typeComponent.CHAT_WIDGET ||
                  _vm.type === _vm.typeComponent.CHAT_BUBBLE,
                "font-size-14": _vm.type === _vm.typeComponent.MODAL_DELIVERY,
                [_vm.getOrderStatusColorByStatus(
                  _vm.orderStatus,
                  _vm.type === _vm.typeComponent.MODAL_DELIVERY ? "text" : "bg"
                )]: _vm.getOrderStatusColorByStatus(
                  _vm.orderStatus,
                  _vm.type === _vm.typeComponent.MODAL_DELIVERY ? "text" : "bg"
                ),
              },
            },
            [
              _c(
                "span",
                {
                  attrs: {
                    "data-cy": _vm.dataCyCustom
                      ? `${_vm.dataCyCustom}-status`
                      : "order-status",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.getOrderStatusTextByStatusOrder(_vm.orderStatus))
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.getConditionTimeCountDownByStatus.timeDate
        ? _c("c-time-countdown", {
            class: { "mt-1": _vm.type !== _vm.typeComponent.MODAL_DELIVERY },
            attrs: {
              "time-date": _vm.getConditionTimeCountDownByStatus.timeDate,
              "time-milis": null,
              "time-freeze-date":
                _vm.getConditionTimeCountDownByStatus.timeFreezeDate,
              "is-freeze-countdown":
                _vm.getConditionTimeCountDownByStatus.isFreezeCountdown,
              "stop-countdown": _vm.getConditionTimeCountDownByStatus
                .isVisibleCountdownZero
                ? 0
                : null,
              "is-visible-countdown-zero":
                _vm.getConditionTimeCountDownByStatus.isVisibleCountdownZero,
              "data-cy": _vm.dataCyCustom
                ? `${_vm.dataCyCustom}-timecountdown`
                : "order-status-countdown",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ days, hours, minutes, seconds }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-start align-items-center cb-gap-1",
                        },
                        [
                          _vm.getConditionTimeCountDownByStatus
                            .isFreezeCountdown ||
                          (_vm.getConditionTimeCountDownByStatus
                            .isVisibleCountdownZero &&
                            Boolean(
                              _vm.getConditionTimeCountDownZero(
                                days,
                                hours,
                                minutes,
                                seconds
                              )
                            ))
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("icon", {
                                    class: {
                                      [_vm.getTimeBackgroundByStatus({
                                        status: _vm.orderStatus,
                                        isVisibleCountdownZero:
                                          _vm.getConditionTimeCountDownByStatus
                                            .isVisibleCountdownZero,
                                        isTimeOut: Boolean(
                                          _vm.getConditionTimeCountDownZero(
                                            days,
                                            hours,
                                            minutes,
                                            seconds
                                          )
                                        ),
                                      }).timeIconColor]:
                                        _vm.getConditionTimeCountDownByStatus
                                          .isFreezeCountdown ||
                                        (_vm.getConditionTimeCountDownByStatus
                                          .isVisibleCountdownZero &&
                                          days === "00" &&
                                          hours === "00"),
                                    },
                                    attrs: {
                                      name: "ic_time_pause",
                                      width: "13px",
                                      height: "13px",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-start align-items-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded font-weight-700 font-roboto d-flex mr-1 wrap-countdown p-custom",
                                  class: {
                                    [_vm.getTimeBackgroundByStatus({
                                      status: _vm.orderStatus,
                                      isVisibleCountdownZero:
                                        _vm.getConditionTimeCountDownByStatus
                                          .isVisibleCountdownZero,
                                    }).timeColor]:
                                      (_vm.orderStatus &&
                                        !_vm.getConditionTimeCountDownByStatus
                                          .isVisibleCountdownZero) ||
                                      (_vm.getConditionTimeCountDownByStatus
                                        .isVisibleCountdownZero &&
                                        (days !== "00" || hours !== "00")) ||
                                      (_vm.getConditionTimeCountDownByStatus
                                        .isVisibleCountdownZero &&
                                        days === "00" &&
                                        hours === "00"),
                                  },
                                  attrs: {
                                    "data-cy": _vm.dataCyCustom
                                      ? `${_vm.dataCyCustom}-timecountdown-hour`
                                      : "order-status-countdown-hour",
                                  },
                                },
                                [
                                  _vm.orderStatus ===
                                    _vm.cbCommonStatus.OVERDUE ||
                                  _vm.orderStatus === _vm.cbCommonStatus.LATE
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cj-color-white-primary font-weight-700 font-roboto font-size-12",
                                        },
                                        [_vm._v("-")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "font-size-12" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          days === "00"
                                            ? days === "00" && hours !== "00"
                                              ? hours
                                              : "00"
                                            : days
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "font-size-12",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          `cbgwApp.cbDetailOrder.${
                                            days === "00" ? "hTime" : "dTime"
                                          }`
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded font-weight-700 font-roboto d-flex mr-1 wrap-countdown p-custom",
                                  class: {
                                    [_vm.getTimeBackgroundByStatus({
                                      status: _vm.orderStatus,
                                      isVisibleCountdownZero:
                                        _vm.getConditionTimeCountDownByStatus
                                          .isVisibleCountdownZero,
                                    }).timeColor]:
                                      (_vm.orderStatus &&
                                        !_vm.getConditionTimeCountDownByStatus
                                          .isVisibleCountdownZero) ||
                                      (_vm.getConditionTimeCountDownByStatus
                                        .isVisibleCountdownZero &&
                                        (days !== "00" || hours !== "00")) ||
                                      (_vm.getConditionTimeCountDownByStatus
                                        .isVisibleCountdownZero &&
                                        days === "00" &&
                                        hours === "00"),
                                  },
                                  attrs: {
                                    "data-cy": _vm.dataCyCustom
                                      ? `${_vm.dataCyCustom}-timecountdown-minute`
                                      : "order-status-countdown-minute",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "font-size-12" }, [
                                    _vm._v(
                                      _vm._s(days === "00" ? minutes : hours)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "font-size-12",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          `cbgwApp.cbDetailOrder.${
                                            days === "00" ? "mTime" : "hTime"
                                          }`
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded font-weight-700 font-roboto d-flex wrap-countdown p-custom",
                                  class: {
                                    [_vm.getTimeBackgroundByStatus({
                                      status: _vm.orderStatus,
                                      isVisibleCountdownZero:
                                        _vm.getConditionTimeCountDownByStatus
                                          .isVisibleCountdownZero,
                                    }).timeColor]:
                                      (_vm.orderStatus &&
                                        !_vm.getConditionTimeCountDownByStatus
                                          .isVisibleCountdownZero) ||
                                      (_vm.getConditionTimeCountDownByStatus
                                        .isVisibleCountdownZero &&
                                        (days !== "00" || hours !== "00")) ||
                                      (_vm.getConditionTimeCountDownByStatus
                                        .isVisibleCountdownZero &&
                                        days === "00" &&
                                        hours === "00"),
                                  },
                                  attrs: {
                                    "data-cy": _vm.dataCyCustom
                                      ? `${_vm.dataCyCustom}-timecountdown-second`
                                      : "order-status-countdown-second",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "font-size-12" }, [
                                    _vm._v(
                                      _vm._s(days === "00" ? seconds : minutes)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "font-size-12",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          `cbgwApp.cbDetailOrder.${
                                            days === "00" ? "sTime" : "mTime"
                                          }`
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1871584405
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }