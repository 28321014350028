import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import CButton from '../button/c-button.vue';

@Component({
  components: {
    Icon,
    CButton,
  },
})
export default class CConfirmation extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: '' }) title: string;
  @Prop({ default: '' }) subTitle: string;
  @Prop({ default: '' }) cancelTitle: string;
  @Prop({ default: '' }) submitTitle: string;
  @Prop({ default: '' }) iconCustom: string;
  @Prop({ default: '' }) iconWidth: string;
  @Prop({ default: false }) isLoading?: boolean;
  @Prop({ default: false }) hideClose?: boolean;
  @Prop({ default: false }) isSubHtml?: boolean;
  @Prop({ default: null }) dataCyCustom?: string;
  @Prop({ default: null }) dataGTMCustom?: string;
  @Prop({ default: 'primary' }) typeStyleBtnSubmit: string;
  @Prop({ default: 'secondary' }) typeStyleBtnCancel: string;
  // ================= END VARIABLES ==================

  // ================= START DEFAULT FUNCTION =========
  @Emit('cancel')
  public cancel() {
    return;
  }
  @Emit('save')
  public handleSubmit() {
    return;
  }
  @Emit('close')
  public close() {
    return;
  }
  // ================= END DEFAULT FUNCTION ===========

  // ================= START FUNCTION =================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
