import Icon from '@/shared/icons/icon.vue';
import DragScrollClickFix from '@/shared/utils/drag-scroll-fix-click.component';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    Icon,
  },
})
export default class CAutocompleteNew extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: [] }) items: any[];
  @Prop({ default: null }) value: any;
  @Prop({ default: null }) itemText: string;
  @Prop({ default: null }) itemValue: string;
  @Prop({ default: null }) itemTextInput: string;
  @Prop({ default: null })
  public placeholder: string;
  @Prop({ default: false }) public noBorder: boolean;
  @Prop({ default: false }) public isShowDropdown: boolean;
  @Prop({ default: false }) public disabled: boolean;
  @Prop({ default: null }) public inputStyle: string;
  @Prop({ default: false }) public isShowListItem: boolean;
  @Prop({ default: false }) public isNoRelative: boolean;
  @Prop({ default: false }) disableTyping: boolean;
  public localValue: string = '';
  public itemsSearch: any[] = [];
  public toggleDropdown: boolean = false;
  public dragScrollClickFix = new DragScrollClickFix();
  public randomId: number = null;
  @Prop({ default: null }) public dataCyCustom;
  @Prop({ default: null }) dataGTMCustom: string;
  @Prop({ default: false }) public isHideItemList;

  public isSaveValue: string = '';
  public selectedIndex: number = -1; // Track the selected item index

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.randomId = Math.random();
  }
  public mounted() {
    this.itemsListener();
    this.valueListener();
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public handleSearchInput(e) {
    if (this.disabled || this.disableTyping || this.isHideItemList) {
      return;
    }
    this.localValue = e;
    this.handleSearch();
  }
  public handleSearch() {
    let tmpItems: any[] = [...this.items];
    this.toggleDropdown = true;
    if (this.localValue) {
      this.itemsSearch = tmpItems.filter(e =>
        this.itemText
          ? e[this.itemText].toUpperCase().includes(this.localValue.toUpperCase())
          : e.toUpperCase().includes(this.localValue.toUpperCase())
      );
      if (this.itemsSearch.length == 0) {
        this.toggleDropdown = false;
      }
    } else {
      this.itemsSearch = tmpItems;
    }
  }

  public handleSearchBlur() {
    let tmpItems: any[] = [...this.items];

    if (this.localValue) {
      this.itemsSearch = tmpItems.filter(e =>
        this.itemText
          ? e[this.itemText].toUpperCase().includes(this.localValue.toUpperCase())
          : e.toUpperCase().includes(this.localValue.toUpperCase())
      );
      if (this.itemsSearch.length == 0) {
        this.handleClear();
      }
    }
  }
  public handleSelect(item: any) {
    if (this.itemTextInput) {
      const tmpValue = item[this.itemTextInput];
      this.localValue = tmpValue;
      this.isSaveValue = tmpValue;
    } else {
      const tmpValue = this.itemText ? item[this.itemText] : item;
      this.localValue = tmpValue;
      this.isSaveValue = tmpValue;
    }
    this.$emit('input', this.itemValue ? item[this.itemValue] : item);
    this.toggleDropdown = false;
  }
  public handleClear() {
    let tmpItems: any[] = [...this.items];
    this.localValue = '';
    this.itemsSearch = tmpItems;
    this.$emit('input', null);
    this.openDropdown();
    this.$emit('closeAutocomplete');
  }

  public clickOutside() {
    this.toggleDropdown = false;
    if (this.localValue && !this.toggleDropdown) {
      this.localValue = this.isSaveValue;
    }
  }

  public openDropdown() {
    if (this.disabled || this.isHideItemList) {
      return;
    }
    this.toggleDropdown = true;
    (<any>this.$refs.inputAutocomplete)?.focus();
  }
  public handleToggleDropdown() {
    if (this.disabled || this.isHideItemList) {
      return;
    }
    this.toggleDropdown = true;
  }

  public handleKey(event) {
    console.log(event.key);
    if (event.key == 'ArrowDown') {
      this.handleKeyDown();
    } else if (event.key == 'ArrowUp') {
      this.handleKeyUp();
    } else if (event.key == 'Enter') {
      this.handleEnterKey();
    } else if (event.key == 'Backspace') {
      if (!this.localValue) {
        this.handleClear();
      }
    }
  }

  // Handling the down arrow key
  public handleKeyDown() {
    if (this.selectedIndex < this.itemsSearch.length - 1) {
      this.selectedIndex++;
      this.ensureItemVisible();
    }
  }

  // Handling the up arrow key
  public handleKeyUp() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
      this.ensureItemVisible();
    }
  }

  // Handling the enter key
  public handleEnterKey() {
    if (this.selectedIndex !== -1 && this.itemsSearch[this.selectedIndex]) {
      this.handleSelect(this.itemsSearch[this.selectedIndex]);
    }
  }

  public ensureItemVisible() {
    const listContainer = this.$el.querySelector('.cj-field-autocomplete-list');
    const highlightedItem = this.$el.querySelector('.cj-field-autocomplete-item-scroll-actived');

    if (highlightedItem && listContainer && this.toggleDropdown && !this.disabled && this.itemsSearch.length > 0) {
      const itemTop = highlightedItem.offsetTop - listContainer.offsetTop;
      listContainer.scrollTop = itemTop;
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getDisabledValue() {
    if (this.value && this.localValue && this.items?.length > 0) {
      const found = this.items.find(e => e[this.itemValue] == this.value && e[this.itemText] == this.localValue);
      if (found) {
        return true;
      }
    }
    return false;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('items')
  public itemsListener() {
    if (this.items && this.items.length > 0) {
      let tmpItems: any[] = [...this.items];
      this.itemsSearch = tmpItems;
      if (this.value) {
        this.valueListener();
      }
    } else {
      this.itemsSearch = [];
    }
  }

  @Watch('value')
  public valueListener() {
    if (this.value) {
      if (this.itemText && this.itemValue && this.items?.length > 0) {
        const found = this.items.find(e => e[this.itemValue] == this.value);
        if (found) {
          this.localValue = found[this.itemText];
          this.isSaveValue = found[this.itemText];
          if (this.itemTextInput) {
            this.localValue = found[this.itemTextInput];
            this.isSaveValue = found[this.itemTextInput];
          } else {
            this.localValue = this.itemText ? found[this.itemText] : found;
            this.isSaveValue = this.itemText ? found[this.itemText] : found;
          }
        } else {
          this.localValue = this.value;
          this.isSaveValue = this.value;
          this.handleSearch();
        }
      }
    } else {
      this.localValue = null;
    }
  }

  // Listen to changes in itemsSearch to reset selectedIndex
  @Watch('itemsSearch')
  public itemsSearchChanged() {
    this.selectedIndex = -1;
  }

  // ================= END LISTENERS ==================
}
