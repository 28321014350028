import Vue2Filters from 'vue2-filters';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import CButton from '@/components/button/c-button.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';

import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { User } from '@/shared/model/user.model';
import { CbPawOrder, ICbPawOrder } from '@/shared/model/cb-paw-order.model';
import { CbPawOrderRequirement, ICbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';
import { IChtLocation } from '@/shared/model/cht-location.model';
import { ICbVwPaw } from '@/shared/model/cb-vw-paw.model';
import { ICbPawPreOrder } from '@/shared/model/cb-paw-pre-order.model';
import { CbVwPawOrder, ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';
import { ModalStepFormOrderType } from '@/shared/model/enumerations/modal-step-form-order-type.model';
import { HANDLE_VIEW_MAP_TRACK } from '@/module/chat-container/order-paw-controller.component';

@Component({
  mixins: [Vue2Filters.mixin, StatusTextEnId],
  components: { Icon, CButton, CTextareaPreview },
})
export default class CItemChatMessageInterest extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public externalId: number;
  @Prop({ default: null }) public cbPawPreOrder: ICbPawPreOrder;
  @Prop({ default: null }) public cbVwPaw: ICbVwPaw;
  @Prop({ default: null }) public cbVwPawOrder: ICbVwPawOrder;

  public cbPawOrder: ICbPawOrder = new CbPawOrder();

  public CbSvcType = CbSvcType;
  public CbCommonStatus = CbCommonStatus;

  public isExpandPackageInclude = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.getCbVwPawListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public openDraftPawOrder(type: string) {
    const cbPawOrderRequirement: ICbPawOrderRequirement = {
      ...new CbPawOrderRequirement(),
      ...{
        requirementType: CbRequirementType.REQUIREMENT,
        title: this.cbVwPaw.title,
        status: CbCommonStatus.WAITING_PAYMENT,
        buyer: {
          ...new User(),
          ...{
            id: this.cbPawPreOrder.buyerId,
          },
        },
        partner: {
          ...new User(),
          ...{
            id: this.cbPawPreOrder.partnerId,
          },
        },
        progressProject: 0,
        pawIdHistory: this.cbVwPaw.id,
        bookStartDate: this.cbPawPreOrder.bookStartDate,
        bookEndDate: this.cbPawPreOrder.bookEndDate,
        timeZone: this.cbPawPreOrder.timeZone,
        deliveryTime: this.cbPawOrder.deliveryTime,
        deliveryTimeType: this.cbPawOrder.deliveryTimeType,
        currency: this.cbPawOrder.currency,
        amount: this.cbPawOrder.projectValueAmount,
        baseAmount: this.cbPawOrder.projectValueAmount,
        toWorkLocation: this.cbPawPreOrder.cbLocation,
        mainPhotoPawId: this.cbVwPaw.mainPhotoPawId,
      },
    };

    if (cbPawOrderRequirement?.toWorkLocation?.id) {
      cbPawOrderRequirement.toWorkLocation.id = null;
    }

    this.cbPawOrder.cbPawOrderRequirements = [cbPawOrderRequirement];

    this.$root.$emit('opc::changeValue', 'cbPawOrder', { ...this.cbPawOrder });
    this.$root.$emit('opc::changeValue', 'cbPawOrderRequirement', cbPawOrderRequirement);

    if (type === 'EDIT') {
      // isShowPkgCustom
      cbPawOrderRequirement.typeInterestPaw = 'EDIT';
      this.$root.$emit('opc::changeValue', 'modalStepFormOrders', [ModalStepFormOrderType.isShowPkgCustom]);
      (<any>this.$root).$emit('mdl::changeValue', 'isShowPkgCustom', true);
    }
    if (type === 'ACCEPT') {
      // isShowAddExtras
      cbPawOrderRequirement.typeInterestPaw = 'ACCEPT';
      this.$root.$emit('opc::changeValue', 'modalStepFormOrders', [ModalStepFormOrderType.isShowAddExtras]);
      (<any>this.$root).$emit('mdl::changeValue', 'isShowAddExtras', true);
    }
  }

  public findVwPkgs() {
    this.$root.$emit(
      'opc::changeFunction',
      'retrieveCbVwPawPkgAggFromVwPawId',
      this.cbVwPaw.id,
      this.cbPawPreOrder.pawPkgType,
      this.assignToPawOrder
    );
  }

  public async assignToPawOrder(tmpCbPawOrder: ICbPawOrder) {
    this.cbPawOrder = {
      ...tmpCbPawOrder,
      pawIdHistory: this.cbVwPaw.id,
      pawTitle: this.cbVwPaw.title,
      svcName: this.cbVwPaw.svc,
      svcCategoryName: this.cbVwPaw.svcCategory,
      svcType: this.cbVwPaw.svcType,
      pawPkgType: this.cbPawPreOrder.pawPkgType,
      orderType: CbOrderType.PAW,
      isActive: true,
      buyer: {
        ...new User(),
        ...{
          id: this.cbPawPreOrder.buyerId,
        },
      },
      partner: {
        ...new User(),
        ...{
          id: this.cbPawPreOrder.partnerId,
        },
      },
    };
    // fixing AT in description page
    if (!this.cbPawOrder.pawPkgDescription) {
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.cbPawOrder.pawPkgDescription = tmpCbPawOrder.pawPkgDescription;
    }
  }

  public handleOpenLocation() {
    if (this.cbPawPreOrder.cbLocation) {
      const chtLocation: IChtLocation = { ...this.cbPawPreOrder.cbLocation };

      let cbVwPawOrder = new CbVwPawOrder();

      if (this.cbVwPawOrder?.id) {
        cbVwPawOrder = { ...this.cbVwPawOrder };

        (<any>this.$root).$emit('opc::actionHandler', {
          action: HANDLE_VIEW_MAP_TRACK,
          cbVwPawOrder,
        });
      } else {
        cbVwPawOrder.pawTitle = this.cbVwPaw.title;

        (<any>this.$root).$emit('opc::changeValue', 'cbVwPawOrder', cbVwPawOrder);
        (<any>this.$root).$emit('opc::changeValue', 'chtLocation', chtLocation);
        (<any>this.$root).$emit('opc::changeValue', 'isShowDetailOrderLocation', true);
      }
    }
  }

  public openAllCollapse() {
    this.isExpandPackageInclude = true;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get getCbVwPaw() {
    return [this.cbPawPreOrder, this.cbVwPaw];
  }

  public get getBookDate() {
    let value = '';
    if (this.cbPawPreOrder.bookStartDate && this.cbPawPreOrder.bookEndDate) {
      const startTime = new Date(this.cbPawPreOrder.bookStartDate).toLocaleString('en-US', {
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
      });
      const endTime = new Date(this.cbPawPreOrder.bookEndDate).toLocaleString('en-US', {
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
      });
      value = `${this.$options.filters.formatDateDefault(this.cbPawPreOrder.bookStartDate)} | ${startTime} - ${endTime}`;
    }
    return value;
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================

  // ================= START LISTENERS =================
  @Watch('getCbVwPaw', { deep: true })
  public getCbVwPawListener() {
    if (this.cbPawPreOrder?.id && this.cbVwPaw?.id) {
      this.findVwPkgs();
    }
  }
  // ================= END LISTENERS ===================
}
