import dayjs from 'dayjs';
import { Inject, Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FullCalendar from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { mixins } from 'vue-class-component';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import UTC from 'dayjs/plugin/utc';
import TIMEZONE from 'dayjs/plugin/timezone';

import Icon from '@/shared/icons/icon.vue';
import CButton from '../button/c-button.vue';
import CPawSetWorkLocation from '@/components/c-paw-set-work-location/c-paw-set-work-location.vue';
import CPawBookingDate from '@/components/c-paw-booking-date/c-paw-booking-date.vue';
import CPdTitle from './components/c-pd-title/c-pd-title.vue';
import CPdStatus from './components/c-pd-status/c-pd-status.vue';
import CPdSlideshowMain from './components/c-pd-slideshow-main/c-pd-slideshow-main.vue';
import CPdDescription from './components/c-pd-description/c-pd-description.vue';
import CPdProfileUser from './components/c-pd-profile-user/c-pd-profile-user.vue';
import CPdPackageItem from './components/c-pd-package-item/c-pd-package-item.vue';
import CPdPortfolioSlideshow from './components/c-pd-portfolio-slideshow/c-pd-portfolio-slideshow.vue';
import CPdReferenceItem from './components/c-pd-reference-item/c-pd-reference-item.vue';
import CPdLicenseItem from './components/c-pd-license-item/c-pd-license-item.vue';
import CPdTagsOrToolsItem from './components/c-pd-tags-or-tools-item/c-pd-tags-or-tools-item.vue';
import CPdReviewSlideshow from './components/c-pd-review-slideshow/c-pd-review-slideshow.vue';
import CPopupBottom from '../c-popup-bottom/c-popup-bottom.vue';
import CPdPortfolioList from './components/c-pd-portfolio-list/c-pd-portfolio-list.vue';
import CPdPortfolioPreview from './components/c-pd-portfolio-preview/c-pd-portfolio-preview.vue';
import CPdReviewDetail from './components/c-pd-review-detail/c-pd-review-detail.vue';
import PLoginM from '@/module/general/mobile/login-m/login-m.vue';
import CLoader from '../c-loader/c-loader.vue';
import CProfileReport from '@/module/general/profile/components/c-profile-report/c-profile-report.vue';
import UtilFormatter from '@/shared/utils/util-formatter.component';

import PChtService from '@/services/cht.service';
import PCbVwUserScheduleService from '@/services/cb-vw-user-schedule.service';
import PCmTktService from '@/services/cm-tkt.service';
import PCbVwAvgResponseTimeService from '@/services/cb-vw-avg-response-time.service';
import PCbVwUserPartnerLevelService from '@/services/cb-vw-user-partner-level.service';
import PCbVwUserSummaryService from '@/services/cb-vw-user-summary.service';
import PCbVwPawPkgAggService from '@/services/cb-vw-paw-pkg-agg.service';
import PCbVwPawService from '@/services/cb-vw-paw.service';
import PCbTextNoAuthService from '@/services/cb-text-no-auth.service';
import PCbVwPawCrtService from '@/services/cb-vw-paw-crt.service';
import PCbVwPawDetailAttService from '@/services/cb-vw-paw-detail-att.service';
import PCbVwUserReviewService from '@/services/cb-vw-user-review.service';
import AlertService from '@/shared/alert/alert.service';
import GlobalService from '@/services/global.service';
import PCbVwPawMetaValueService from '@/services/cb-vw-paw-meta-value.service';
import PCbVWMedalService from '@/services/cb-vw-medal.service';
import PCbPawPreOrderService from '@/services/cb-paw-pre-order.service';
import JhiDataUtils from '@/shared/data/data-utils.service';
import PCmTktAttService from '@/services/cm-tkt-att.service';
import PCbVwUserService from '@/services/cb-vw-user.service';
import ContentService from '@/services/content.service';

import { CbVwPaw, ICbVwPaw } from '@/shared/model/cb-vw-paw.model';
import { CbLocation, ICbLocation } from '@/shared/model/cb-location.model';
import { CbPawPreOrder, ICbPawPreOrder } from '@/shared/model/cb-paw-pre-order.model';
import { CbVwUser, ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { CbTextNoAuth, ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import { CbVwPawDetailAtt, ICbVwPawDetailAtt } from '@/shared/model/cb-vw-paw-detail-att.model';
import { CbVwUserSchedule, ICbVwUserSchedule } from '@/shared/model/cb-vw-user-schedule.model';
import { CbUserBookmarkAgg, ICbUserBookmarkAgg } from '@/shared/model/cb-user-bookmark-agg.model';
import { CmTktSender, ICmTktSender } from '@/shared/model/cm-tkt-sender.model';
import { ICbVwAvgResponseTime, CbVwAvgResponseTime } from '@/shared/model/cb-vw-avg-response-time.model';
import { CmTktAtt, ICmTktAtt } from '@/shared/model/cm-tkt-att.model';
import { CbVwUserSummary, ICbVwUserSummary } from '@/shared/model/cb-vw-user-summary.model';
import { ICbMetaData, CbMetaData } from '@/shared/model/cb-meta-data.model';
import { ICbMetaValue, CbMetaValue } from '@/shared/model/cb-meta-value.model';
import { CmTkt, ICmTkt } from '@/shared/model/cm-tkt.model';
import { CbVwSvc, ICbVwSvc } from '@/shared/model/cb-vw-svc.model';
import { CbPkgType } from '@/shared/model/enumerations/cb-pkg-type.model';
import { ICbVwPawMetaValue } from '@/shared/model/cb-vw-paw-meta-value.model';
import { ICbVwPawCrt } from '@/shared/model/cb-vw-paw-crt.model';
import { DetailType } from '@/shared/model/enumerations/detail-type.model';
import { ICbAttachmentNoAuth } from '@/shared/model/cb-attachment-no-auth.model';
import { ICbVwUserReview } from '@/shared/model/cb-vw-user-review.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { ICbVWMedal } from '@/shared/model/cb-vw-medal.model';
import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import { ChtConversation } from '@/shared/model/cht-conversation.model';
import { ChtMessage } from '@/shared/model/cht-message.model';
import { App } from '@/shared/model/enumerations/app.model';
import { ChtContentType } from '@/shared/model/enumerations/cht-content-type.model';
import { CbBookmarkType } from '@/shared/model/enumerations/cb-bookmark-type.model';
import { ICbPawMetaValueAgg } from '@/shared/model/cb-paw-meta-value-agg.model';
import { CbInputType } from '@/shared/model/enumerations/cb-input-type.model';
import { ICbPawPortfolio } from '@/shared/model/cb-paw-portfolio.model';
import { ICbLog } from '@/shared/model/cb-log.model';
import { CbLogType } from '@/shared/model/enumerations/cb-log-type.model';
import { CmApp } from '@/shared/model/enumerations/cm-app.model';
import { CmTktChannel } from '@/shared/model/enumerations/cm-tkt-channel.model';
import { CmTktCategory } from '@/shared/model/enumerations/cm-tkt-category.model';
import { ICmAttachmentRestrict } from '@/shared/model/cm-attachment-restrict.model';
import { CbVerificationStatus } from '@/shared/model/enumerations/cb-verification-status.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { ICbLov } from '@/shared/model/cb-lov.model';
import { ICbVwUserPartnerLevel } from '@/shared/model/cb-vw-user-partner-level.model';
import { ICbVwPawPkgAgg } from '@/shared/model/cb-vw-paw-pkg-agg.model';
import { CbPkgCoreType } from '@/shared/model/enumerations/cb-pkg-core-type.model';
import { CbPawStatus } from '@/shared/model/enumerations/cb-paw-status.model';
import { SESSION_STORAGE_CB_VW_MEDAL, SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL } from '@/shared/constant/constants-session-storage';
import { DATE_FORMAT, DATE_FORMAT_GMT, DATE_ISO_STRING_FORMAT } from '@/shared/date/filters';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';

@Component({
  components: {
    Icon,
    CButton,
    FullCalendar,
    CPawBookingDate,
    CPawSetWorkLocation,
    CProfileReport,
    PLoginM,
    CPdTitle,
    CPdStatus,
    CPdSlideshowMain,
    CPdProfileUser,
    CPdDescription,
    CPdPackageItem,
    CPdPortfolioSlideshow,
    CPdReferenceItem,
    CPdLicenseItem,
    CPdTagsOrToolsItem,
    CPdReviewSlideshow,
    CPopupBottom,
    CPdPortfolioList,
    CPdPortfolioPreview,
    CPdReviewDetail,
    CLoader,
  },
  mixins: [UtilFormatter],
})
export default class CPawDetail extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  @Inject('pCbVwPawService') private pCbVwPawService: () => PCbVwPawService;
  @Inject('pCbVwMedalService') private pCbVwMedalService: () => PCbVWMedalService;

  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCmTktService') private pCmTktService: () => PCmTktService;
  @Inject('pChtService') private pChtService: () => PChtService;
  @Inject('pCbTextNoAuthService') private pCbTextNoAuthService: () => PCbTextNoAuthService;
  @Inject('pCbVwPawMetaValueService') private pCbVwPawMetaValueService: () => PCbVwPawMetaValueService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('pCmTktAttService') private pCmTktAttService: () => PCmTktAttService;
  @Inject('pCbVwPawCrtService') private pCbVwPawCrtService: () => PCbVwPawCrtService;
  @Inject('pCbVwPawDetailAttService') private pCbVwPawDetailAttService: () => PCbVwPawDetailAttService;
  @Inject('pCbVwUserReviewService') private pCbVwUserReviewService: () => PCbVwUserReviewService;
  @Inject('pCbPawPreOrderService') private pCbPawPreOrderService: () => PCbPawPreOrderService;
  @Inject('pCbVwUserScheduleService') private pCbVwUserScheduleService: () => PCbVwUserScheduleService;
  @Inject('pCbVwUserService') private pCbVwUserService: () => PCbVwUserService;
  @Inject('pCbVwAvgResponseTimeService') private pCbVwAvgResponseTimeService: () => PCbVwAvgResponseTimeService;
  @Inject('pCbVwUserPartnerLevelService') private pCbVwUserPartnerLevelService: () => PCbVwUserPartnerLevelService;
  @Inject('pCbVwUserSummaryService') private pCbVwUserSummaryService: () => PCbVwUserSummaryService;

  @Inject('pCbVwPawPkgAggService') private pCbVwPawPkgAggService: () => PCbVwPawPkgAggService;
  @Inject('contentService') public contentService: () => ContentService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwPawId: number;

  public paramId: number = null;
  public isSavingTkt = false;
  public isFetchingCbVwPaw = false;
  public CbSvcType = CbSvcType;

  public cbVwPawMetaValue = [];
  public cbPawMetaValueAggs: ICbPawMetaValueAgg[] = [];
  public cbVwPawCrts: ICbVwPawCrt[] = [];
  public cbVwPawDetailAttTools: ICbVwPawDetailAtt[] = [];
  public cbVwPawDetailAttTags: ICbVwPawDetailAtt[] = [];
  public cbVwPawDetailAttPortfolioDetails: ICbVwPawDetailAtt[] = [];
  public cbVwPawDetailPortfolios: ICbVwPawDetailAtt[] = [];
  public cbVwPawDetailAttPortfolios: ICbVwPawDetailAtt[] = [];
  public cbVwPawDetailAttPortfolioMains: ICbVwPawDetailAtt[] = [];
  public cbVwPawDetailAttDocs: ICbVwPawDetailAtt[] = [];
  public cbPawPortfolios: ICbPawPortfolio[] = [];
  public cbVwUserReviews: ICbVwUserReview[] = [];
  public cbVwUserSchedules: ICbVwUserSchedule[] = [];
  public cbVwUserScheduleFilter: ICbVwUserSchedule[] = [];
  public cbVwMedals: ICbVWMedal[] = [];
  public cbVwPawPkgAggs: ICbVwPawPkgAgg[] = [];
  public cbVwPaw: ICbVwPaw = new CbVwPaw();
  public cbVwUser: ICbVwUser = new CbVwUser();
  public cbTextNoauth: ICbTextNoAuth = new CbTextNoAuth();
  public cbVwPawDetailAttPortfolioDetail: ICbVwPawDetailAtt = new CbVwPawDetailAtt();
  public cbVwSvc: ICbVwSvc = new CbVwSvc();
  public cbPawPreOrder: ICbPawPreOrder = new CbPawPreOrder();
  public cbVwUserSchedule: ICbVwUserSchedule = new CbVwUserSchedule();
  public userBookmark: ICbUserBookmarkAgg = new CbUserBookmarkAgg();
  public cbPawDescription: ICbTextNoAuth = new CbTextNoAuth();
  public cbVwAvgResponseTime: ICbVwAvgResponseTime = new CbVwAvgResponseTime();
  public cbVwUserSummary: ICbVwUserSummary = new CbVwUserSummary();
  public cbVwUserHibernationSchedules: ICbVwUserSchedule[] = [];

  public isFetchingCbVwUser = false;
  public itemsPerPageUserReview = 5;
  public queryCountUserReview: number = null;
  public pageUserReview = 1;
  public previousPageUserReview = 1;
  public propOrderUserReview = 'id';
  public reverseUserReview = false;
  public totalItemsUserReview = 0;
  public linksUserReview = null;
  public isFetchingUserReview = false;

  public isBookmark = false;
  public detailAttType = DetailType;
  public CbBookmarkType = CbBookmarkType;

  public cbVwUserReviewRatings: any[] = [];
  public averageRatingReview: any = null;

  public tabPkg: CbPkgType = CbPkgType.REGULAR;
  public CbPkgType = CbPkgType;

  public closePopUp = false;
  public showMoreDescPkg = false;
  public showMoreNonePkg = false;
  public seletedPackage = false;

  public isSeePackage = false;
  public isSeeAllLicense = false;
  public isSeeAllReference = false;
  public isSeeAllPortfolio = false;
  public isSeeAllReview = false;
  public isShowPortfolio = false;
  public isShowDetailPackage = false;

  public currentDate = dayjs().format(DATE_FORMAT);

  public noOrderDate = '';

  public calendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    disableDragging: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    weekends: true,
    events: [],
    dayMaxEvents: 2,
    height: 400,
    eventClick: this.handleClickCalendarSchedule,
    dateClick: this.handleFullCalendarSchedule,
    dayCellClassNames: this.fcDayClassName,
    selectAllow: this.fcDisabledSelect,
    dayCellDidMount: this.fcTooltip,
    moreLinkContent: function (args) {
      return '+' + args.num;
    },
  };

  public isPremiumPkg = false;

  // INFINITE LOADING
  public itemsPerPage = 20;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'id';
  public reverse = true;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = null;
  public sortReview = 'id,desc';
  public filterByRating: number = null;

  public isRouteCPawDetailView = this.$route.name == 'PPawDetailView';
  public isShowArrowScroll = false;
  public isFetchingCbVwPawPkgAgg = false;

  public CbPkgCoreType = CbPkgCoreType;
  public cbPawStatus = CbPawStatus;

  public isloader = false;
  // ================ = END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    dayjs.extend(TIMEZONE);
    dayjs.extend(UTC);
    dayjs.extend(AdvancedFormat);
    dayjs.locale('en-us');
    this.contentService().initRetrieveCbUserBookmarkAggs(CbBookmarkType.PAW);
    this.contentService().initRetrieveCbLovs(CbLovType.LANGUAGE_LEVEL);
    this.contentService().initRetrieveCbLovs(CbLovType.LANGUAGE);

    this.$root.$off('pawDetail::changeValue');
    this.$root.$on('pawDetail::changeValue', (field, value) => {
      this[field] = value;
    });
  }

  public mounted() {
    if (this.$route.params?.id && this.$route.name !== 'PSvc') {
      this.paramId = Number(this.$route.params.id);
      this.watchGetCbVwPawId(this.$route.params.id);
    } else {
      this.watchGetCbVwPawId();
    }
  }

  updated() {
    if (!this.isSeePackage && this.isMobile) {
      this.initIntersectionObserverPawDetailPackage();
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleClickCalendarSchedule(info) {
    this.cbVwUserScheduleFilter = [];
    this.cbVwUserSchedules.filter(x => {
      const minBookStartDate = dayjs(x.minBookStartDate).format(DATE_FORMAT);
      const maxBookStartDate = dayjs(x.maxBookStartDate).format(DATE_FORMAT);
      const bookStartDate = dayjs(info.event.startStr).format(DATE_FORMAT);
      const bookEndDate = dayjs(info.event.endStr).format(DATE_FORMAT);

      if (bookStartDate === minBookStartDate && bookEndDate === maxBookStartDate) {
        this.cbVwUserScheduleFilter.push(x);
      }
    });
  }

  public handleFullCalendarSchedule(info) {
    this.cbVwUserScheduleFilter = [];
    this.cbVwUserSchedules.filter(x => {
      const minBookStartDate = dayjs(x.minBookStartDate).format(DATE_FORMAT);
      const maxBookStartDate = dayjs(x.maxBookStartDate).format(DATE_FORMAT);
      if (info.dateStr >= minBookStartDate && info.dateStr <= maxBookStartDate) {
        this.cbVwUserScheduleFilter.push(x);
      } else {
        if (info.dateStr >= this.currentDate) {
          return (this.noOrderDate = this.$t('cbGlobal.available').toString());
        } else {
          this.cbVwPaw.authorId === this.currentUser.id ? (this.noOrderDate = this.$t('cbGlobal.noHistory').toString()) : '-';
          return;
        }
      }
    });
  }
  public cancel() {
    this.$emit('cancel');
  }
  public openModalCShare() {
    (<any>this.$root).$emit('openModalCShare', 'PAW', this.cbVwPaw);
  }
  public onBack() {
    if (this.$route.name == 'PPawDetailView') {
      this.$router.go(-1);
    } else {
      this.cancel();
    }
  }

  public toProfile(idUser) {
    if (this.currentUser.id) {
      this.$router.push({ name: 'PProfilePublic', params: { id: idUser } });
      this.globalService().closeGlobalDialog(this.$root, 'modal-paw-detail');
    } else {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
    }
  }

  public handleChat() {
    this.isShowDetailPackage = false;
    this.isloader = true;

    // RESET DATA PRE ORDER
    this.cbPawPreOrder = new CbPawPreOrder();
    if (!this.authenticated && this.isMobile) {
      this.closePopUp = true;
      this.isloader = false;
      this.globalService().openGlobalDialog(this.$root, 'modal-p-login-m-pop-up');
    }
    if (!this.authenticated && !this.isMobile) {
      this.isloader = false;
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
    }
    if (this.cbVwPaw.svcType == CbSvcType.FIELD && this.authenticated) {
      this.isloader = false;
      this.globalService().openGlobalDialog(this.$root, 'modal-c-paw-set-work-location');
      // this.handleOpenBookingCalendar();
    }
    if (this.cbVwPaw.svcType == CbSvcType.INSIDE && this.authenticated) {
      this.handleOpenNewConversation();
    }
  }

  public handleSubmitFromSetBookingDate(startDate: Date, timeZone: string) {
    const newDate = dayjs(startDate, { format: DATE_FORMAT_GMT }).format(DATE_ISO_STRING_FORMAT);
    const convertToAccordanceTimezone = dayjs.tz(newDate, timeZone.split(' ')[1]).toDate();

    this.cbPawPreOrder.bookStartDate = convertToAccordanceTimezone;
    this.cbPawPreOrder.timeZone = timeZone;

    const delivery = this.cbPawPkgAggs(this.tabPkg, CbPkgCoreType.DELIVERY_TIME)
      ? this.cbPawPkgAggs(this.tabPkg, CbPkgCoreType.DELIVERY_TIME)[0]
      : null;

    if (delivery) {
      this.isloader = true;
      this.cbPawPreOrder.bookEndDate = this.cbPawPreOrder.bookStartDate;
      this.handleOpenNewConversation();
    }
  }

  public handleOpenBookingDate(e: ICbLocation) {
    if (!this.cbPawPreOrder.cbLocation) this.cbPawPreOrder.cbLocation = new CbLocation();

    Object.assign(this.cbPawPreOrder.cbLocation, e);
    this.globalService().openGlobalDialog(this.$root, 'modal-c-paw-booking-date');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-paw-set-work-location');
  }

  public handleOpenNewConversation() {
    this.cbPawPreOrder = {
      ...this.cbPawPreOrder,
      ...{
        buyerId: this.currentUser.id,
        partnerId: this.cbVwPaw.authorId,
        externalId: this.cbVwPaw.id,
        isActive: true,
        orderType: CbOrderType.PAW,
        pawPkgType: this.tabPkg,
      },
    };
    this.pCbPawPreOrderService()
      .create(this.cbPawPreOrder)
      .then(res => {
        this.cbPawPreOrder = res;
        this.newConversation();
      })
      .catch(error => {
        this.isloader = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  public newConversation() {
    this.pChtService()
      .createConversation(this.cbVwPaw.authorId)
      .then(res => {
        this.saveMessage(res.id);
        this.globalService().commitChtVwConversation(res);
      })
      .catch(err => {
        this.isloader = false;
        this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
      });
  }

  public saveMessage(conversationId: number) {
    const chtConversation = new ChtConversation();
    chtConversation.id = conversationId;
    const chtMessage = new ChtMessage();
    chtMessage.chtConversation = chtConversation;
    chtMessage.externalId = this.cbPawPreOrder.id.toString();
    chtMessage.chtContentType = ChtContentType.PAW;
    chtMessage.app = App.CB_APP;
    chtMessage.content = '';

    this.pChtService()
      .createMessage(chtMessage)
      .then(res => {
        this.isloader = false;
        this.$store.commit('roomIdToStore', res.chtConversation.id + '');
        this.$router.push({ name: 'PAppChatByRoomId', params: { roomId: res.chtConversation.id + '' } });
        this.globalService().closeGlobalDialog(this.$root, 'modal-paw-detail');
      })
      .catch(err => {
        this.isloader = false;
        this.alertService().showHttpError(this, err.response);
      });
  }

  public retrieveCbVwUserHibernationSchedules(): void {
    const param: any = {
      'partnerId.equals': this.cbVwPaw.authorId,
      'scheduleType.equals': CbCommonStatus.OFF_DUTY,
    };

    this.pCbVwUserScheduleService()
      .retrieve(param)
      .then(res => {
        if (res.data && res.data.length > 0) {
          for (const dataValue of res.data) {
            this.cbVwUserHibernationSchedules.push(dataValue);
          }
        }
      });
  }

  public retrieveCbVwUserSchedules(): void {
    const param: any = {
      'partnerId.equals': this.cbVwPaw.authorId,
      'orderStatus.in': [
        CbCommonStatus.WAITING_PAYMENT,
        CbCommonStatus.BOOKED,
        CbCommonStatus.ON_THE_WAY,
        CbCommonStatus.ON_PROGRESS,
        CbCommonStatus.LATE,
      ],
    };

    this.pCbVwUserScheduleService()
      .retrieve(param)
      .then(res => {
        this.cbVwUserSchedules = res.data;
        this.handleDateSelect(this.cbVwUserSchedules);
      });
  }
  public handleDateSelect(cbVwUserSchedules: ICbVwUserSchedule[]) {
    if (cbVwUserSchedules.length >= 1) {
      for (const cbVwUserSchedule of cbVwUserSchedules) {
        const event = {
          id: cbVwUserSchedule.id,
          start: cbVwUserSchedule.minBookStartDate,
          end: cbVwUserSchedule.maxBookStartDate,
          status: cbVwUserSchedule.orderStatus,
          title: cbVwUserSchedule.title,
          color: 'var(--cj-color-red-primary)',
        };
        this.calendarOptions.events.push(event);
      }
    }
  }

  public retrieveCbVwMedals(): void {
    const foundFilter: ICbVWMedal[] = this.sessionCbVwMedals().filter(e => e.userId == this.cbVwPaw.userId);
    if (foundFilter.length > 0) {
      this.cbVwMedals = [];
      for (const item of foundFilter) {
        const foundIndex = this.cbVwMedals.findIndex(e => e.id == item.id);
        if (foundIndex < 0) {
          this.cbVwMedals.push(item);
        }
      }
      return;
    }
    if (!this.cbVwPaw.userId) return;

    const params: any = {
      'userId.equals': this.cbVwPaw.userId,
    };
    this.pCbVwMedalService()
      .retrieve(params)
      .then(res => {
        this.cbVwMedals = res.data;

        const tmpCbVwMedals: ICbVWMedal[] = [...this.sessionCbVwMedals()];
        for (const item of this.cbVwMedals) {
          const foundIndex = tmpCbVwMedals.findIndex(e => e.id == item.id);
          if (foundIndex < 0) {
            tmpCbVwMedals.push(item);
          } else {
            Vue.set(tmpCbVwMedals, foundIndex, item);
          }
        }
        sessionStorage.setItem(SESSION_STORAGE_CB_VW_MEDAL, JSON.stringify(tmpCbVwMedals));
      })
      .catch(err => {
        this.cbVwMedals = [];
      });
  }
  public findServiceDescription(): void {
    const found = this.cbTextNoAuths.find(e => +e.id == +this.cbVwPaw.descriptionId);
    if (found) {
      this.cbTextNoauth = Object.assign({}, this.cbTextNoauth, found);
      // if (this.isRouteCPawDetailView) {
      //   (<any>this.$root).$emit('meta:::DataDescription', this.cbTextNoauth.content);
      // }
    } else {
      this.pCbTextNoAuthService()
        .find(this.cbVwPaw.descriptionId)
        .then(res => {
          this.cbTextNoauth = Object.assign({}, this.cbTextNoauth, res);
          const tmpCbTextNoAuth = [...this.cbTextNoAuths];
          tmpCbTextNoAuth.push(res);
          this.$store.commit('cbTextNoAuths', tmpCbTextNoAuth);
          // if (this.isRouteCPawDetailView) {
          //   (<any>this.$root).$emit('meta:::DataDescription', this.cbTextNoauth.content);
          // }
        })
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        });
    }
  }

  public handleSubmitReportUser(reasons: string[], description: string, files: ICmAttachmentRestrict[]) {
    this.isSavingTkt = true;
    const cmTkt: ICmTkt = new CmTkt();
    cmTkt.externalId = this.cbVwPaw.id.toString();
    cmTkt.externalClass = 'CbPaw';
    cmTkt.contactReason = 'REPORT_PAW';
    cmTkt.subContactReason = reasons.join('||');
    cmTkt.app = CmApp.CB_APP;
    cmTkt.channel = CmTktChannel.WEB_FORM;
    cmTkt.category = CmTktCategory.NON_COMPLIANCE_PAW;
    const cmTktSender: ICmTktSender = new CmTktSender();
    cmTktSender.senderStatus = this.loginType;
    cmTkt.cmTktSender = cmTktSender;
    cmTkt.description = description;
    cmTkt.title = 'Report PAW';

    this.pCmTktService()
      .create(cmTkt)
      .then(async res => {
        this.isSavingTkt = false;
        await this.saveCmTktAtts(res, files);
        this.closeModalReport();
      })
      .catch(() => {
        this.isSavingTkt = false;
        this.closeModalReport();
      });
  }

  public async saveCmTktAtts(cmTkt: ICmTkt, files: ICmAttachmentRestrict[]) {
    for (const file of files) {
      const cmTktAtt: ICmTktAtt = new CmTktAtt();
      cmTktAtt.cmAttachmentRestrict = file;
      cmTktAtt.cmTkt = cmTkt;

      await this.pCmTktAttService().create(
        cmTktAtt,
        cmTktAtt.cmAttachmentRestrict.attContentType,
        cmTktAtt.cmAttachmentRestrict.blob,
        progress => {}
      );
    }
  }

  public openModalReport() {
    if (!this.authenticated && this.isMobile) {
      this.closePopUp = true;
      this.globalService().openGlobalDialog(this.$root, 'modal-p-login-m-pop-up');
    } else if (!this.authenticated && !this.isMobile) {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
    } else {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-report');
    }
  }

  public closeModalReport() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-report');
  }

  public retrieveCbVwPawCrts(id: number): void {
    const params: any = {
      'cbPawId.equals': id,
    };
    this.pCbVwPawCrtService()
      .retrieve(params)
      .then(res => {
        this.cbVwPawCrts = this.sortCertificate(res.data);
      });
  }

  public retrieveCbVwPawMetaValue(id: number) {
    const params: any = {
      'cbPawId.equals': id,
      size: 1000,
    };
    this.pCbVwPawMetaValueService()
      .retrieve(params)
      .then(res => {
        for (const agg of res.data) {
          if (!this.cbPawMetaValueAggs) {
            this.cbPawMetaValueAggs = [];
          }

          let tmpMetadata: ICbMetaData = new CbMetaData();
          if (agg.metaData && agg.metaData.length > 0) {
            const splitField = agg.metaData.split('::');
            tmpMetadata = {
              id: splitField[0] ? +splitField[0] : null,
              name: splitField[1] ? splitField[1] : null,
              titleHeader: splitField[2] ? splitField[2] : null,
              createdBy: splitField[3] ? splitField[3] : null,
              createdDate: splitField[4] ? dayjs(splitField[4], 'DD-MM-YYYY').toDate() : null,
              lastModifiedBy: splitField[5] ? splitField[5] : null,
              lastModifiedDate: splitField[6] ? dayjs(splitField[6], 'DD-MM-YYYY').toDate() : null,
              seq: splitField[7] ? +splitField[7] : null,
              isActive: splitField[8] == 'true' ? true : false,
            };
          }
          let tmpMetavalue: ICbMetaValue = new CbMetaValue();
          if (agg.metaValue && agg.metaValue.length > 0) {
            const splitField = agg.metaValue.split('::');
            tmpMetavalue = {
              id: splitField[0] ? +splitField[0] : null,
              name: splitField[1] ? splitField[1] : null,
              inputType: splitField[2] ? <CbInputType>splitField[2] : null,
              selectLovName: splitField[3] ? splitField[3] : null,
              inputPlaceHolder: splitField[4] ? splitField[4] : null,
              createdBy: splitField[5] ? splitField[5] : null,
              createdDate: splitField[6] ? dayjs(splitField[6], 'DD-MM-YYYY').toDate() : null,
              lastModifiedBy: splitField[7] ? splitField[7] : null,
              lastModifiedDate: splitField[8] ? dayjs(splitField[8], 'DD-MM-YYYY').toDate() : null,
              seq: splitField[9] ? +splitField[9] : null,
              isActive: splitField[10] == 'true' ? true : false,
            };
          }
          this.cbPawMetaValueAggs.push({
            id: agg.id,
            otherMetaValue: agg.otherMetaValue,
            cbMetaValue: tmpMetavalue.id ? tmpMetavalue : null,
            cbMetaData: tmpMetadata,
          });
        }
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public retrieveCbVwDetailAtt(id): void {
    const params: any = {
      'cbPawId.equals': id,
    };
    this.pCbVwPawDetailAttService()
      .retrieve(params)
      .then(async res => {
        const cbVwPawDetailAtts: ICbVwPawDetailAtt[] = [...res.data];
        for (const item of cbVwPawDetailAtts) {
          if (item.detailType == DetailType.TAG) {
            this.cbVwPawDetailAttTags.push(item);
          }
          if (item.detailType == DetailType.TOOL) {
            this.cbVwPawDetailAttTools.push(item);
          }
          if (item.detailType == DetailType.PORTFOLIO && item.isPortfolioCover && item.isAttCover) {
            this.cbVwPawDetailAttPortfolioMains.push(item);
          }
          if (item.detailType == DetailType.PORTFOLIO && item.isAttCover) {
            this.cbVwPawDetailAttPortfolios.push(item);
          }
          if (item.detailType == DetailType.PORTFOLIO) {
            this.cbVwPawDetailPortfolios.push(item);
          }
          if (item.detailType == DetailType.DOC) {
            this.cbVwPawDetailAttDocs.push(item);
          }
        }
      });
  }

  public clearAllFieldPawDetail() {
    this.cbVwPawMetaValue = [];
    this.cbPawMetaValueAggs = [];
    this.cbVwPawCrts = [];
    this.cbVwPawDetailAttTools = [];
    this.cbVwPawDetailAttTags = [];
    this.cbVwPawDetailAttPortfolioDetails = [];
    this.cbVwPawDetailPortfolios = [];
    this.cbVwPawDetailAttPortfolios = [];
    this.cbVwPawDetailAttPortfolioMains = [];
    this.cbVwPawDetailAttDocs = [];
    this.cbPawPortfolios = [];
    this.cbVwUserReviews = [];
    this.cbVwUserSchedules = [];
    this.cbVwUserScheduleFilter = [];
    this.cbVwMedals = [];
    this.cbVwPawPkgAggs = [];

    this.cbVwPaw = new CbVwPaw();
    this.cbVwUser = new CbVwUser();
    this.cbTextNoauth = new CbTextNoAuth();
    this.cbVwPawDetailAttPortfolioDetail = new CbVwPawDetailAtt();
    this.cbVwSvc = new CbVwSvc();
    this.cbPawPreOrder = new CbPawPreOrder();
    this.cbVwUserSchedule = new CbVwUserSchedule();
    this.userBookmark = new CbUserBookmarkAgg();
    this.cbPawDescription = new CbTextNoAuth();
    this.cbVwAvgResponseTime = new CbVwAvgResponseTime();
    this.cbVwUserSummary = new CbVwUserSummary();
  }

  public clear(): void {
    this.page = 1;
    this.links = null;
    this.infiniteId += 1;
    this.cbVwUserReviews = [];
    this.retrieveAllCbVwUserReviews();
  }

  public reset(): void {
    this.page = 1;
    this.infiniteId += 1;
    this.cbVwUserReviews = [];
    this.retrieveAllCbVwUserReviews();
  }

  public retrieveAllCbVwUserReviews(): void {
    this.isFetchingUserReview = true;
    const params = {
      page: this.pageUserReview - 1,
      size: this.itemsPerPageUserReview,
      sort: this.sortReview,
      'ownerId.equals': this.cbVwPaw.userId,
      'externalId.equals': this.cbVwPaw.id,
      'reviewType.equals': 'PAW',
      'replyRefId.equals': 'NODATA',
    };
    if (this.filterByRating) {
      params['rating.equals'] = this.filterByRating;
    }

    this.pCbVwUserReviewService()
      .retrieve(params)
      .then(res => {
        console.log(res, 'res review');
        this.processDataUserReview(res);
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isFetchingUserReview = false;
        if (<any>this.$refs.infiniteLoading) {
          (<any>this.$refs.infiniteLoading).stateChanger.loaded();
          if (this.linksUserReview !== null && this.pageUserReview > this.linksUserReview['last']) {
            (<any>this.$refs.infiniteLoading).stateChanger.complete();
          }
        }
      });
  }

  public loadMoreUserReview($state): void {
    if (!this.isFetchingUserReview) {
      this.pageUserReview++;
      this.transitionUserReview();
    }
  }

  public processDataUserReview(res): void {
    if (res?.data?.length > 0) {
      for (const item of res.data) {
        this.cbVwUserReviews.push(item);
      }
      if (res?.headers['link']) {
        this.linksUserReview = this.parseLinks(res.headers['link']);
      }
    }
    this.totalItemsUserReview = Number(res.headers['x-total-count']);
    this.queryCountUserReview = this.totalItemsUserReview;
  }

  public transitionUserReview(): void {
    this.retrieveAllCbVwUserReviews();
  }

  public findCbVwUser(id: any) {
    this.pCbVwUserService()
      .find(id)
      .then(res => {
        this.cbVwUser = Object.assign({}, res);
        if (this.cbVwUser.id) {
          this.findCbVwUserSummaryById();
        }
      })
      .catch(err => {
        if (err.response) {
          this.alertService().showHttpError(this, err.response);
        } else {
          console.log('ERROR FIND USER ', err);
        }
      })
      .finally(() => {});
  }

  public findCbVwUserSummaryById() {
    this.pCbVwUserSummaryService()
      .find(this.cbVwUser.id)
      .then(res => {
        this.cbVwUserSummary = Object.assign({}, res);
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public finAvgREsponseTime(id: string) {
    this.pCbVwAvgResponseTimeService()
      .find(id)
      .then(res => {
        this.cbVwAvgResponseTime = res;
      })
      .catch(err => {});
  }

  public saveBookmark(valueId) {
    if (!this.authenticated && this.isMobile) {
      this.closePopUp = true;
      this.globalService().openGlobalDialog(this.$root, 'modal-p-login-m-pop-up');
    }
    if (!this.authenticated && !this.isMobile) {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
    }
    if (!this.currentUser) {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
      return;
    }

    const cbUserBookmark: ICbUserBookmarkAgg = new CbUserBookmarkAgg();
    cbUserBookmark.dataId = valueId;
    cbUserBookmark.bookmarkType = CbBookmarkType.PAW;
    if (this.$options.filters.findBookmarkByIdIsActive(this.cbUserBookmarkAggs, valueId, CbBookmarkType.PAW)) {
      cbUserBookmark.isActive = false;
    } else {
      cbUserBookmark.isActive = true;
    }

    (<any>this.$root).$emit('app::saveCbUserBookmarkAgg', cbUserBookmark);
  }

  public async retrieveCbVwUserReviewRating() {
    this.cbVwUserReviewRatings = [];
    await this.retrieveUserReviewRatings();

    const params: any = {
      'ownerId.equals': this.cbVwPaw.userId,
      'externalId.equals': this.cbVwPaw.id,
      'reviewType.equals': 'PAW',
      'replyRefId.equals': 'NODATA',
    };

    try {
      this.queryCountUserReview = await this.pCbVwUserReviewService().count(params);
    } catch (err) {
      this.alertService().showHttpError(this, err.response);
    }

    this.cbVwUserReviewRatings.sort((a, b) => b.rating - a.rating);

    if (this.cbVwUserReviewRatings?.length > 0 && this.queryCountUserReview) {
      let totalRating = this.cbVwUserReviewRatings.reduce((acc, item) => {
        return acc + +item.rating * +item.count;
      }, 0);

      if (totalRating > 0) {
        this.averageRatingReview = (+totalRating / +this.queryCountUserReview).toPrecision(2);
      }
    }
  }

  public async retrieveUserReviewRatings() {
    const ratingPromises = [];

    for (let i = 1; i < 6; i++) {
      const params: any = {
        'rating.equals': i,
        'ownerId.equals': this.cbVwPaw.userId,
        'externalId.equals': this.cbVwPaw.id,
        'reviewType.equals': 'PAW',
        'replyRefId.equals': 'NODATA',
      };

      ratingPromises.push(
        this.pCbVwUserReviewService()
          .count(params)
          .then(res => {
            return {
              rating: i,
              count: res || 0,
            };
          })
          .catch(err => {
            this.alertService().showHttpError(this, err.response);
          })
      );
    }

    this.cbVwUserReviewRatings = await Promise.all(ratingPromises);
  }

  public downloadDoc(doc: ICbAttachmentNoAuth) {
    if (doc.localUrl) {
      window.open(doc.localUrl, '_blank');
    }
  }

  public findCbVwUserPartnerLevel(userId: string) {
    const tmpPartnerLevel: ICbVwUserPartnerLevel = this.sessionCbVwUserPartnerLevel().find(e => e.userId == userId);
    if (tmpPartnerLevel) {
      return (this.cbVwPaw.userLevel = tmpPartnerLevel.userLvl.toString());
    }

    const params: any = {
      'userId.equals': userId,
    };
    this.pCbVwUserPartnerLevelService()
      .retrieve(params)
      .then(res => {
        if (res && res.data && res.data.length > 0) {
          const cbVwUserPartnerLevel: ICbVwUserPartnerLevel = Object.assign({}, res.data[0]);
          this.cbVwPaw.userLevel = cbVwUserPartnerLevel.userLvl.toString();

          if (cbVwUserPartnerLevel) {
            // PUSH TO SESSION STORAGE
            const tmpUserLevels: ICbVwUserPartnerLevel[] = [...this.sessionCbVwUserPartnerLevel()];
            const foundLevel = tmpUserLevels.findIndex(e => e.userId == this.cbVwUser.id);
            if (!foundLevel) {
              tmpUserLevels.push(cbVwUserPartnerLevel);
            } else {
              Vue.set(tmpUserLevels, foundLevel, cbVwUserPartnerLevel);
            }

            sessionStorage.setItem(SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL, JSON.stringify(tmpUserLevels));
          }
        }
      });
  }

  public sessionCbVwUserPartnerLevel(): ICbVwUserPartnerLevel[] {
    const stringCbVwUserPartnerLevels: string = sessionStorage.getItem(SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL);
    const tmpCbVwUserPartnerLevels: ICbVwUserPartnerLevel[] = JSON.parse(stringCbVwUserPartnerLevels);
    if (tmpCbVwUserPartnerLevels && tmpCbVwUserPartnerLevels.length > 0) {
      return tmpCbVwUserPartnerLevels;
    }
    return [];
  }

  public sessionCbVwMedals(): ICbVWMedal[] {
    const stringCbVwMedal: string = sessionStorage.getItem(SESSION_STORAGE_CB_VW_MEDAL);
    const tmpCbVwMedals: ICbVWMedal[] = JSON.parse(stringCbVwMedal);
    if (tmpCbVwMedals && tmpCbVwMedals.length > 0) {
      return tmpCbVwMedals;
    }
    return [];
  }

  public scrollTop() {
    const scrollTop = document.getElementById('paw-details-scroll-top');
    scrollTop.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }

  public initIntersectionObserverPawDetail() {
    const elementPawDetailId = document.getElementById('detailPawScroll');
    if (elementPawDetailId && !this.isFetchingCbVwPaw) {
      const options = {
        threshold: 0,
      };

      const observer = new IntersectionObserver(entries => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            this.isShowArrowScroll = true;
          } else {
            this.isShowArrowScroll = false;
          }
        });
      }, options);

      observer.observe(elementPawDetailId);
    }
  }

  public retrieveCbVwPkgAggs() {
    this.isFetchingCbVwPawPkgAgg = true;
    const params: any = {
      'cbPawId.equals': this.cbVwPaw.id,
    };
    this.pCbVwPawPkgAggService()
      .retrieve(params)
      .then(async res => {
        if (res?.data?.length > 0) {
          const cbVwPawPkgAggs: ICbVwPawPkgAgg[] = [...res.data];
          for (const agg of cbVwPawPkgAggs) {
            const tmpCbVwPawPkgAgg: ICbVwPawPkgAgg = Object.assign({}, agg);
            if (agg.cbTextNoAuthId) {
              let cbTextNoAuth: ICbTextNoAuth = new CbTextNoAuth();
              cbTextNoAuth = await this.findCbTextNoAuthById(agg.cbTextNoAuthId);
              tmpCbVwPawPkgAgg.value = cbTextNoAuth.content;
            }
            if (agg.pkgType == CbPkgType.PREMIUM) {
              this.isPremiumPkg = true;
            }

            this.cbVwPawPkgAggs.push(tmpCbVwPawPkgAgg);
          }
        }
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isFetchingCbVwPawPkgAgg = false;
      });
  }

  public async findCbTextNoAuthById(id: number) {
    let cbTextNoAuth: ICbTextNoAuth = new CbTextNoAuth();
    const found = this.cbTextNoAuths.find(e => +e.id == +id);
    if (found) {
      cbTextNoAuth = Object.assign({}, found);
    } else {
      await this.pCbTextNoAuthService()
        .find(id)
        .then(res => {
          cbTextNoAuth = Object.assign({}, res);
          const tmpCbTextNoAuth = [...this.cbTextNoAuths];
          tmpCbTextNoAuth.push(cbTextNoAuth);
          this.$store.commit('cbTextNoAuths', cbTextNoAuth);
        })
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        });
    }

    return cbTextNoAuth;
  }

  public initIntersectionObserverPawDetailPackage() {
    const target = document.getElementById('paw-detail-package-id');
    if (target) {
      const options = {
        threshold: 0,
      };
      const observer = new IntersectionObserver(entries => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            return (this.isSeePackage = true);
          } else {
            return (this.isSeePackage = false);
          }
        });
      }, options);

      observer.observe(target);
    }
  }

  public handleShowDetailPackage() {
    this.isShowDetailPackage = true;
  }

  public closeDetailPackage() {
    this.isShowDetailPackage = false;
  }

  public handleShowPortfolioDetail(value) {
    this.cbVwPawDetailAttPortfolioDetail = value;
    const portfolios = this.cbVwPawDetailPortfolios.filter(x => x.detailId == value.detailId);
    const portfoliosSort = portfolios.sort((a, b) => {
      return a.cbAttachmentNoAuthId - b.cbAttachmentNoAuthId;
    });
    this.cbVwPawDetailAttPortfolioDetails = [...portfoliosSort];

    this.isShowPortfolio = true;
  }

  public noLoginPortfolioDetail() {
    if (!this.authenticated && this.isMobile) {
      this.closePopUp = true;
      this.globalService().openGlobalDialog(this.$root, 'modal-p-login-m-pop-up');
    }
    if (!this.authenticated && !this.isMobile) {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
    }
    if (!this.currentUser) {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
      return;
    }
  }

  public handleScrollPackage() {
    if (this.isSeePackage) {
      this.handleChat();
    } else {
      const scrollPackage = document.getElementById('paw-detail-package-id');
      scrollPackage.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
      (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.toast.pleaseChooseAPackage').toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Warning',
        variant: 'warning',
        solid: true,
        autoHideDelay: 2000,
      });
    }
  }

  public handleSeeAllReview() {
    this.isSeeAllReview = true;
  }

  public fcDayClassName(arg) {
    const classes = [];
    const date = arg.date;
    for (const cbVwUserHibernationSchedule of this.cbVwUserHibernationSchedules) {
      // Add class for disabled dates
      const disabledStartDate = new Date(dayjs(cbVwUserHibernationSchedule.minBookStartDate).format(DATE_FORMAT));
      const disabledEndDate = new Date(dayjs(cbVwUserHibernationSchedule.maxBookStartDate).format(DATE_FORMAT));
      disabledStartDate.setDate(disabledStartDate.getDate() - 1);
      disabledEndDate.setDate(disabledEndDate.getDate() - 1);

      if (date >= disabledStartDate && date <= disabledEndDate) {
        classes.push('fc-disabled-date');
      }
    }

    return classes;
  }
  public fcDisabledSelect(selectInfo) {
    const start = new Date(selectInfo.startStr);
    const end = new Date(selectInfo.endStr);
    for (const cbVwUserHibernationSchedule of this.cbVwUserHibernationSchedules) {
      const disableStart = new Date(dayjs(cbVwUserHibernationSchedule.minBookStartDate).format(DATE_FORMAT));
      const disableEnd = new Date(dayjs(cbVwUserHibernationSchedule.maxBookStartDate).format(DATE_FORMAT));

      // Check if any part of the range overlaps with the disabled dates
      if (start < disableEnd && end > disableStart) {
        return false;
      }
      return true;
    }
    return true;
  }
  public fcTooltip(info) {
    const tooltipText = this.$t('cbGlobal.onHibernate'); // Example tooltip text
    info.el.setAttribute('fc-data-tooltip-disabled', tooltipText);
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get sortCertificate() {
    return (cbVwPawCrts: ICbVwPawCrt[]) => {
      if (cbVwPawCrts?.length > 0) {
        const cbVwPawCrtsNotdecline = cbVwPawCrts.filter(
          c => c.verificationStatus == null || (c.verificationStatus != null && c.verificationStatus != CbVerificationStatus.DECLINE)
        );

        cbVwPawCrtsNotdecline.forEach(c => {
          if (!c.isWillNotExpired && c.expiredDate != null && dayjs(c.expiredDate).isBefore(dayjs().toDate())) {
            c.isExpired = true;
          } else {
            c.isExpired = false;
          }

          if (c.certificateType == 'SKCK' && !c.name) {
            c.name = 'SKCK';
          }
        });

        return cbVwPawCrtsNotdecline.sort((a, b) => new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime());
      }

      return [];
    };
  }

  public get getCbVwPawId() {
    return this.cbVwPawId;
  }

  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get cbPawPkgAggs() {
    return (pkgType: CbPkgType, coreType: CbPkgCoreType, showMorePkg?: number, limit?: number) => {
      let tmpVwCbPawPkgAggs: ICbVwPawPkgAgg[] = [];
      if (pkgType && coreType) {
        if (coreType == CbPkgCoreType.NONE) {
          tmpVwCbPawPkgAggs = [
            ...this.cbVwPawPkgAggs.filter(e => e.pkgType == pkgType && e.coreType == CbPkgCoreType.NONE),
            ...this.cbVwPawPkgAggs.filter(e => e.pkgType == pkgType && e.coreType == CbPkgCoreType.CUSTOM),
          ];
        } else {
          tmpVwCbPawPkgAggs = this.cbVwPawPkgAggs.filter(e => e.pkgType == pkgType && e.coreType == coreType);
        }
        if (!showMorePkg && limit) {
          tmpVwCbPawPkgAggs = tmpVwCbPawPkgAggs.slice(0, limit);
        }
      }
      return tmpVwCbPawPkgAggs;
    };
  }
  public get getMetaValue() {
    return (meta: string) => {
      if (meta && meta.length > 0) {
        const metaArray = meta.split('||');
        return metaArray;
      }
    };
  }

  public get dynamicFormatDate() {
    return (date: Date, format: string) => {
      return date ? dayjs(date).format(format) : '-';
    };
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }

  public get getFilterMetadatas() {
    const tmpMetadatas: ICbMetaData[] = [];
    if (this.cbPawMetaValueAggs && this.cbPawMetaValueAggs.length > 0) {
      for (const item of this.cbPawMetaValueAggs) {
        const findIndex = tmpMetadatas.findIndex(e => e.id == item.cbMetaData.id);
        if (findIndex >= 0) {
          Vue.set(tmpMetadatas, findIndex, item.cbMetaData);
        } else {
          tmpMetadatas.push(item.cbMetaData);
        }
      }
    }
    return tmpMetadatas;
  }

  public get getFilterMetavalue() {
    return (id: number) => {
      const tmpMetavalue: any[] = [];

      const filterMetadatas = this.cbPawMetaValueAggs.filter(e => e.cbMetaData && +e.cbMetaData.id == +id);
      for (const item of filterMetadatas) {
        if (item.cbMetaValue && item.cbMetaValue.id) {
          tmpMetavalue.push(item.cbMetaValue.name);
        } else if (item.otherMetaValue) {
          tmpMetavalue.push(item.otherMetaValue);
        }
      }
      return tmpMetavalue;
    };
  }

  public get getScheduleDateDisplay() {
    return (cbVwUSerSchedule: ICbVwUserSchedule) => {
      if (cbVwUSerSchedule) {
        const distance = +dayjs(cbVwUSerSchedule.maxBookStartDate).toDate() - +dayjs(cbVwUSerSchedule.minBookStartDate).toDate();
        if (distance >= 86400000) {
          return (
            dayjs(cbVwUSerSchedule.minBookStartDate).format('D MMM YY HH:MM') +
            ' - ' +
            dayjs(cbVwUSerSchedule.maxBookStartDate).format('D MMM YY HH:MM')
          );
        } else {
          return (
            dayjs(cbVwUSerSchedule.minBookStartDate).format('HH:MM') + ' - ' + dayjs(cbVwUSerSchedule.maxBookStartDate).format('HH:MM')
          );
        }
      }
      return '';
    };
  }
  public get authenticated() {
    return this.$store.getters.authenticated;
  }
  public get loginType() {
    return this.$store.getters.loginType;
  }

  public get cbTextNoAuths(): ICbTextNoAuth[] {
    const cbTextNoAuths: ICbTextNoAuth[] = [...this.$store.getters.cbTextNoAuths];
    return cbTextNoAuths;
  }

  public get abbreviationDate() {
    let value = '';
    if (this.TIME_ZONE_ABBREVIATION) {
      const found = this.TIME_ZONE_ABBREVIATION.find(e => e.key.toLowerCase().includes('western indonesian time'));
      if (found && found.value) {
        value = found.value;
      }
    }
    return value;
  }

  public get TIME_ZONE_ABBREVIATION(): ICbLov[] {
    return this.$store.getters.TIME_ZONE_ABBREVIATION;
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  public get cbVwSvcs(): ICbVwSvc[] {
    return this.$store.getters.cbVwSvcs;
  }

  public get countPortfolio() {
    return detailId => {
      if (this.cbVwPawDetailPortfolios && this.cbVwPawDetailPortfolios.length > 0) {
        const detailIdPaw = this.cbVwPawDetailPortfolios.filter(x => x.detailId === detailId);
        return detailIdPaw;
      }
    };
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('getCbVwPawId')
  public watchGetCbVwPawId(id?: string) {
    if (this.cbVwPawId || id) {
      this.isFetchingCbVwPaw = true;
      let newId: number = null;
      if (this.cbVwPawId) {
        newId = Number(this.cbVwPawId);
      } else if (id) {
        newId = Number(id);
      }

      this.pCbVwPawService()
        .findDetail(newId)
        .then(res => {
          this.cbVwPaw = Object.assign({}, this.cbVwPaw, res);

          if (this.isRouteCPawDetailView) {
            (<any>this.$root).$emit('meta:::DataTitle', this.cbVwPaw.title);
            (<any>this.$root).$emit('meta:::DataImage', this.cbVwPaw.mainPhotoPawId);
          }
          if (this.cbVwPaw.authorId !== this.currentUser.id) {
            const cbLog: ICbLog = {
              cbLogType: CbLogType.PAW_DETAIL,
              valueHitId: newId,
            };
            (<any>this.$root).$emit('createLog', cbLog);
          }

          this.findCbVwUserPartnerLevel(res.authorId);

          this.retrieveCbVwMedals();
          this.retrieveCbVwPawMetaValue(this.cbVwPaw.id);
          this.retrieveCbVwPawCrts(this.cbVwPaw.id);
          this.retrieveCbVwDetailAtt(this.cbVwPaw.id);
          this.findCbVwUser(this.cbVwPaw.userId);
          this.finAvgREsponseTime(this.cbVwPaw.userId);
          this.retrieveCbVwPkgAggs();
          this.retrieveAllCbVwUserReviews();
          this.retrieveCbVwUserReviewRating();

          if (this.cbVwPaw.descriptionId) {
            this.findServiceDescription();
          }

          if (this.cbVwPaw.svcType == CbSvcType.FIELD) {
            this.retrieveCbVwUserSchedules();
            this.retrieveCbVwUserHibernationSchedules();
          }
        })
        .catch(error => {})
        .finally(() => {
          this.isFetchingCbVwPaw = false;
        });
    }
  }

  public get cbUserBookmarkAggs() {
    return this.$store.getters.GET_USER_BOOKMARK_PAW;
  }

  public get LANGUAGE_LEVEL() {
    return this.$store.getters.LANGUAGE_LEVEL;
  }
  public get LANGUAGE() {
    return this.$store.getters.LANGUAGE;
  }
  // ================= END LISTENERS ===================
  public get getLanguageTextEnId() {
    return (keyLanguage, levelLanguage) => {
      if (!keyLanguage && !levelLanguage) return null;

      const languageInfo = this.LANGUAGE.find(lang => lang.key === keyLanguage);
      const languageLevelInfo = this.LANGUAGE_LEVEL.find(lang => lang.key === levelLanguage);
      if (!languageInfo && !languageLevelInfo) return null;
      console.log(this.LANGUAGE_LEVEL);

      return this.currentLanguage === 'en'
        ? languageInfo.value + ' - ' + languageLevelInfo.value
        : languageInfo.value2 + ' - ' + languageLevelInfo.value2;
    };
  }
  public get getCbVwSvcsColor() {
    return idSvc => {
      if (!idSvc) return 'var(--cj-color-red-primary)';

      const colorCbVwSvcsId = this.cbVwSvcs.find(lang => lang.id === idSvc);
      if (!colorCbVwSvcsId) return 'var(--cj-color-red-primary)';

      return '#' + colorCbVwSvcsId.color;
    };
  }
}
