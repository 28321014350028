import GlobalService from '@/services/global.service';
import axios from 'axios';
import { SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN, SESSION_STORAGE_CB_JHI_ID_TOKEN } from '@/shared/constant/constants-session-storage';
import { REFRESH_TOKEN_API } from '@/constants';

import router from '@/router'; // Assuming your router is exported from a central router file
import { Store } from 'vuex';
import sensitiveField from '@/shared/model/enumerations/data-sensitive-field';

const TIMEOUT = 1000000;
let numberOfAjaxCAllPending = 0;
const onRequestSuccess = config => {
  // MAKE LAG BECAUSE SHOWING AT APP UI
  // numberOfAjaxCAllPending++;
  // globalService.setNumberOfAjaxCAllPending(numberOfAjaxCAllPending);
  //set 2 ways login
  const token =
    localStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN) ||
    sessionStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN);

  if (
    token &&
    REFRESH_TOKEN_API != config.url &&
    !config.url.includes('translation.googleapis.com') &&
    !config.url.includes('storage.googleapis.com') &&
    !config.url.includes('maps.googleapis.com/maps/api/timezone') &&
    !config.url.includes('api/authenticate/generate-token/provider')
  ) {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${token}`;
    if (config.url.includes('api/logout')) {
      config.headers.IdToken = localStorage.getItem(SESSION_STORAGE_CB_JHI_ID_TOKEN);
    }
  }

  config.timeout = TIMEOUT;
  config.url = `${SERVER_API_URL}${config.url}`;

  if (globalService.isPathNeedSignal) {
    console.log('>>>>signal');
    config.signal = globalService.currentNavigationController.signal;
  }

  return config;
};

let globalService: GlobalService; //dont set null
let isRefreshing = false;
let subscribers = [];

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}
function onRrefreshed(access_token) {
  subscribers.forEach(cb => cb(access_token));
}

const setupAxiosInterceptors = (onUnauthenticated, onServerError, globalServiceP: GlobalService) => {
  globalService = globalServiceP;

  const onResponseError = async err => {
    // numberOfAjaxCAllPending--;
    // globalService.setNumberOfAjaxCAllPending(numberOfAjaxCAllPending);

    const originalRequest = err.config;
    const url = err.response?.config?.url;

    // console.log(globalService.isHasRefreshToken());
    // console.log(err.response);
    // console.log(!originalRequest._retry);
    // console.log('api/authenticate/refresh' != url);
    // console.log((globalService.isHasRefreshToken() && err.response.status === 401 && !originalRequest._retry && 'api/authenticate/refresh' != url) );

    if (
      globalService.isHasRefreshToken() &&
      err.response &&
      err.response.status === 401 &&
      !originalRequest._retry &&
      REFRESH_TOKEN_API != url
    ) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        return await new Promise<any>((resolve, reject) => {
          //must set return to callback
          globalService
            .checkRefreshToken()
            .then(res => {
              isRefreshing = false;
              onRrefreshed(res);
              const access_token = res;

              //call at first  api, because  not call subscribeTokenRefresh
              originalRequest.headers.Authorization = `Bearer ${access_token}`;
              resolve(axios(originalRequest));
            })
            .catch(err => {
              isRefreshing = false;
              globalService.handleExpiredRefreshToken();
              reject(err);
            });
        });
      }
      //call at second api , because when refresh tokaen call, ti is not trigger
      return new Promise(resolve => {
        subscribeTokenRefresh(access_token => {
          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          resolve(axios(originalRequest));
        });
      });
    }

    isRefreshing = false;
    const status = err ? err.status : null || err ? err.response.status : null;
    if (status === 403 || status === 401) {
      return onUnauthenticated(err);
    } else if (status >= 500) {
      return onServerError(err);
    } else if (err.code && err.code === 'ERR_NETWORK') {
      err.response = {};
      err.response.status = 503;
    } else {
      err.status = 'cancelled';
    }

    sendErrorToGoogleAnalytic(err);
    return Promise.reject(err);
  };
  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess);
    axios.interceptors.response.use(res => {
      // numberOfAjaxCAllPending--;
      // globalService.setNumberOfAjaxCAllPending(numberOfAjaxCAllPending);
      return res;
    }, onResponseError);
  }
};

function maskSensitiveData(jsonObj: any, sensitiveFields: string[]) {
  if (jsonObj == undefined) return jsonObj;

  const maskedObj = JSON.parse(jsonObj);

  for (const key in maskedObj) {
    if (sensitiveFields.includes(key.toLowerCase())) {
      maskedObj[key] = '***'; // Replace sensitive data with ***
    } else if (typeof maskedObj[key] === 'object' && maskedObj[key] !== null) {
      // Recursively mask sensitive data in nested objects
      maskedObj[key] = maskSensitiveData(maskedObj[key], sensitiveFields);
    }
  }
  return maskedObj;
}

function sendErrorToGoogleAnalytic(err) {
  const vuex = window.localStorage.getItem('vuex');
  if (vuex && JSON.parse(vuex).accountStore && JSON.parse(vuex).accountStore.currentUser) {
    const currentUser = JSON.parse(vuex).accountStore.currentUser;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      errorUrl: err.config.url,
      event: 'api_error',
      errorMessage: err.message,
      errorStatus: err.response ? err.response.status : 'Unknown',
      errorResponse: err.response.data,
      payload: maskSensitiveData(err.config.data, sensitiveField),
      currentUser: currentUser,
    });
  }
}

export { onRequestSuccess, setupAxiosInterceptors };
