import { Component, Inject, Prop } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { mixins } from 'vue-class-component';
import ChatWindowController from '@/module/chat-container/chat/lib/chat-window.vue';
import Room from '@/module/chat-container/chat/lib/Room/Room.vue';
import locales from '@/module/chat-container/chat/locales';
import { defaultThemeStyles, cssThemeVars } from '@/module/chat-container/chat/themes';

import MediaPreview from '@/module/chat-container/chat/lib/MediaPreview/MediaPreview.vue';
import MediaPreviewChat from '@/module/chat-container/chat/lib/media-preview/media-preview.vue';
import CRoomMedia from '@/module/chat-container/chat/components/c-room-media/c-room-media.vue';
import vClickOutside from 'v-click-outside';
import FeedbackController from '@/module/chat-container/feedback-controller.component';
import GlobalService from '@/services/global.service';
import { FnListener, IFnListener } from '@/shared/model/fn-listener-model';
import { Debounce } from '@/shared/decorator/debounce';
const { roomsValidation, partcipantsValidation } = require('@/module/chat-container/chat/utils/data-validation');
@Component({
  components: {
    Room,
    MediaPreview,
    MediaPreviewChat,
    CRoomMedia,
    Icon,
  },
  mixins: [ChatWindowController, FeedbackController],
  directives: {
    clickOutside: vClickOutside.directive,
  },

  watch: {
    rooms: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal[0] || !newVal.find(room => room.roomId == this.room.roomId)) {
          this.showRoomsList = true;
        }

        if (!this.loadingMoreRooms && this.loadFirstRoom && newVal[0] && (!oldVal || newVal.length !== oldVal.length)) {
          if (this.roomId) {
            const room = newVal.find(r => r.roomId == this.roomId) || {};
            this.fetchRoom({ room });
          } else if (!this.isMobile || this.singleRoom) {
            this.fetchRoom({ room: this.orderedRooms[0] });
          } else {
            this.showRoomsList = true;
          }
        }
      },
    },

    loadingRooms(val) {
      if (val) this.room = {};
    },

    roomId: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log('cht============================chat-window.fetchRoom');
        if (newVal && !this.loadingRooms && this.rooms.length) {
          // const room = this.rooms.find(r => r.roomId === newVal);
        } else if (oldVal && !newVal) {
          this.room = {};
        }
      },
    },

    room(val) {
      if (!val || Object.entries(val).length === 0) return;

      roomsValidation(val);

      val.users.forEach(user => {
        partcipantsValidation(user);
      });
    },

    roomsListOpened(val) {
      this.showRoomsList = val;
    },
  },
})
export default class CLivechat extends mixins(JhiDataUtils) {
  // // ================= START SERVICES =================
  public isShowLiveChat = true;
  @Inject('globalService') private globalService: () => GlobalService;

  public callBack() {
    this.$emit('callBack');
  }

  static fnArr: IFnListener[] = [];

  @Prop({ type: String, default: '600px' })
  height;
  @Prop({ type: String, default: 'light' })
  theme;
  @Prop({ type: Object, default: () => ({}) })
  styles;
  @Prop({ type: Number, default: 900 })
  responsiveBreakpoint;
  @Prop({ type: Boolean, default: false })
  singleRoom;
  @Prop({ type: Boolean, default: true })
  roomsListOpened;
  @Prop({ type: Object, default: null })
  textMessages;
  @Prop({ type: [String, Number], default: '' })
  currentUserId;
  @Prop({ type: Array, default: () => [] })
  rooms; //list rooms
  @Prop({ type: String, default: 'desc' })
  roomsOrder;
  @Prop({ type: Boolean, default: false })
  loadingRooms;
  @Prop({ type: Boolean, default: false })
  roomsLoaded;
  @Prop({ type: [String, Number], default: null })
  roomId;
  @Prop({ type: Boolean, default: true })
  loadFirstRoom;
  @Prop({ type: Array, default: () => [] })
  messages;
  @Prop({ type: Boolean, default: false })
  messagesLoaded;
  @Prop({ type: Array, default: () => [] })
  roomActions;
  @Prop({ type: Array, default: () => [] })
  menuActions;
  @Prop({
    type: Array,
    default: () => [
      { name: 'replyMessage', title: 'Reply' },
      { name: 'editMessage', title: 'Edit Message', onlyMe: true },
      { name: 'deleteMessage', title: 'Delete Message', onlyMe: true },
      { name: 'selectMessages', title: 'Select' },
    ],
  })
  messageActions;
  @Prop({ type: Array, default: () => [] })
  messageSelectionActions;

  @Prop({
    type: Object,
    default: () => {
      return {
        send: {
          new: true,
          newAfterScrollUp: true,
        },
        receive: {
          new: true,
          newAfterScrollUp: false,
        },
      };
    },
  })
  autoScroll;

  @Prop({ type: Boolean, default: true })
  showSearch;
  @Prop({ type: Boolean, default: true })
  showAddRoom;
  @Prop({ type: Boolean, default: true })
  showSendIcon;
  @Prop({ type: Boolean, default: true })
  showFiles;
  @Prop({ type: Boolean, default: true })
  showAudio;
  @Prop({ type: Number, default: 128 })
  audioBitRate;
  @Prop({ type: Number, default: 44100 })
  audioSampleRate;
  @Prop({ type: Boolean, default: true })
  showEmojis;
  @Prop({ type: Boolean, default: true })
  showReactionEmojis;
  @Prop({ type: Boolean, default: true })
  showNewMessagesDivider;
  @Prop({ type: Boolean, default: true })
  showFooter;

  @Prop({
    type: Object,
    default: () => ({
      disabled: false,
      italic: '_',
      bold: '*',
      strike: '~',
      underline: '°',
      multilineCode: '```',
      inlineCode: '`',
    }),
  })
  textFormatting;

  @Prop({
    type: Object,
    default: () => ({ disabled: false, target: '_blank', rel: null }),
  })
  linkOptions;

  @Prop({ type: Boolean, default: false })
  roomInfoEnabled;
  @Prop({ type: Boolean, default: false })
  textareaActionEnabled;
  @Prop({ type: Boolean, default: true })
  textareaAutoFocus;
  @Prop({ type: Boolean, default: true })
  userTagsEnabled;
  @Prop({ type: Boolean, default: true })
  emojisSuggestionEnabled;
  @Prop({ type: String, default: '' })
  roomMessage;
  @Prop({ type: Number, default: 60 })
  scrollDistance;
  @Prop({ type: String, default: '*' })
  acceptedFiles;
  @Prop({ type: Array, default: null })
  templatesText;
  @Prop({ type: Boolean, default: true })
  mediaPreviewEnabled;
  @Prop({
    type: Object,
    default: () => ({ minUsers: 3, currentUser: false }),
  })
  usernameOptions;
  @Prop({ type: Array })
  userIdsOnline;

  @Prop({ type: Boolean, default: false })
  isShowChatInfoPanel;

  @Prop({ type: Boolean, default: false })
  isClosed;

  room = { roomId: null };
  loadingMoreRooms = false;
  showRoomsList = true;
  isMobile = false;
  showMediaPreview = false;
  previewFile = {};
  selectedMessage = {};
  selectedFile = {};

  public isShowAgents = true;

  public get t() {
    return {
      ...locales,
      ...this.textMessages,
    };
  }
  public get cssVars() {
    const defaultStyles = defaultThemeStyles[this.theme];
    const customStyles: any = {};

    for (const key in defaultStyles) {
      customStyles[key] = { ...defaultStyles[key], ...(this.styles[key] || {}) };
    }

    customStyles['cdn'] = {};
    customStyles['cdn']['bg_chat'] = "url('" + process.env.CDN_CB + "/content/cb-img/ic-bg-chat.png') center center";

    return cssThemeVars(customStyles);
  }

  public get CONTENT_IMAGE() {
    return this.$store.getters.CONTENT_IMAGE;
  }
  public get orderedRooms() {
    return this.rooms.slice().sort((a, b) => {
      const aVal = a.index || 0;
      const bVal = b.index || 0;

      if (this.roomsOrder === 'asc') {
        return aVal < bVal ? -1 : bVal < aVal ? 1 : 0;
      }

      return aVal > bVal ? -1 : bVal > aVal ? 1 : 0;
    });
  }

  created() {
    this.updateResponsive();

    const fn = async (event: Event) => {
      if (event.isTrusted) this.updateResponsive();
    };
    const data = { key: FnListener.KEY_RESIZE, fn: fn };
    const foundIndex = CLivechat.fnArr.findIndex(obj => obj.key === FnListener.KEY_RESIZE);
    if (foundIndex !== -1) {
      window.removeEventListener(FnListener.KEY_RESIZE, CLivechat.fnArr[foundIndex].fn, false);
      CLivechat.fnArr[foundIndex] = data;
    } else {
      CLivechat.fnArr.push(data);
    }
    window.addEventListener(FnListener.KEY_RESIZE, fn, false);
  }

  @Debounce(50)
  updateResponsive() {
    this.isMobile = window.innerWidth < this.responsiveBreakpoint;
  }
  toggleRoomsList() {
    (<any>this.$root).$emit('showFooter', 'isFillChat');
    this.showRoomsList = !this.showRoomsList;
    if (this.isMobile) this.room = { roomId: null };

    this.$emit('toggle-rooms-list', { opened: this.showRoomsList });
  }
  fetchRoom({ room }) {
    console.log('fetchRoom');
    this.room = room;
    this.fetchMessages({ reset: true });
    if (this.isMobile) this.showRoomsList = false;
  }
  fetchMoreRooms({ options }) {
    this.$emit('fetch-more-rooms', { options });
  }
  roomInfo() {
    this.$emit('room-info', this.room);
  }
  addRoom() {
    this.$emit('add-room');
  }
  fetchMessages(options) {
    console.log('fetch-messages');
    this.$emit('fetch-messages', { room: this.room, options });
  }
  sendMessage(message) {
    this.$emit('send-message', { ...message, roomId: this.room.roomId });
  }
  editMessage(message) {
    this.$emit('edit-message', { ...message, roomId: this.room.roomId });
  }
  deleteMessage(message) {
    this.$emit('delete-message', { message, roomId: this.room.roomId });
  }
  openFile({ message, file }) {
    if (this.mediaPreviewEnabled && file.action === 'preview') {
      this.previewFile = file.file;
      this.globalService().openGlobalDialog(this.$root, 'modal-cht-media-preview-live-chat');
      this.selectedMessage = message;
      this.selectedFile = file.file;
    } else {
      this.$emit('open-file', { message, file });
    }
  }
  downloadFile(value) {
    window.open(value.url, '_blank');
  }
  handleClosePreviewMedia() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-cht-media-preview-live-chat');
  }
  openUserTag({ user }) {
    this.$emit('open-user-tag', { user });
  }
  openFailedMessage({ message }) {
    this.$emit('open-failed-message', {
      message,
      roomId: this.room.roomId,
    });
  }
  menuActionHandler(ev) {
    this.$emit('menu-action-handler', {
      action: ev,
      roomId: this.room.roomId,
    });
  }
  roomActionHandler({ action, roomId }) {
    this.$emit('room-action-handler', {
      action,
      roomId,
    });
  }
  messageActionHandler(ev) {
    this.$emit('message-action-handler', {
      ...ev,
      roomId: this.room.roomId,
    });
  }
  messageSelectionActionHandler(ev) {
    this.$emit('message-selection-action-handler', {
      ...ev,
      roomId: this.room.roomId,
    });
  }
  sendMessageReaction(messageReaction) {
    this.$emit('send-message-reaction', {
      ...messageReaction,
      roomId: this.room.roomId,
    });
  }
  typingMessage(message) {
    this.$emit('typing-message', {
      message,
      roomId: this.room.roomId,
    });
  }
  textareaActionHandler(message) {
    this.$emit('textarea-action-handler', {
      message,
      roomId: this.room.roomId,
    });
  }
  askmeQuestionAgain() {
    this.$emit('ask-me-question-again');
  }
}
