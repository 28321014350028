const DataSensitiveField = [
  'password',
  'oldpassword',
  'newpassword',
  'confirmpassword',
  'cardnumber',
  'cvv',
  'credit',
  'creditcard',
  'token',
  'otp',
  'pin',
  'pincode',
  'securitycode',
];

export default DataSensitiveField;
