var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-pd-portfolio-list d-flex flex-wrap" },
    _vm._l(_vm.cbVwPawDetailAttPortfolios, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "col-6 col-md-3 col-lg-6 px-2 mb-3 cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.$emit("handleShowPortfolio", item)
            },
          },
        },
        [
          _c("div", { staticClass: "cb-img-wrapper cb-ratio-1x1 mb-1" }, [
            _c("img", {
              staticClass: "cb-img-cover border-radius-card",
              attrs: {
                src: _vm.$options.filters.LAZY(
                  `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                    _vm.isVideo(item.cbAttachmentNoAuth.attContentType)
                      ? item?.cbAttachmentNoAuth?.refThumbnailId
                      : item?.cbAttachmentNoAuth?.id
                  }`,
                  334,
                  "ic-lazy-paw.png"
                ),
                onerror: _vm.$options.filters.LAZY_ERROR("ic-lazy-paw.png"),
                alt: "img-cover-paw",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex c-pd-portfolio-count px-2 py-1" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex my-auto mr-2" },
                  [
                    _c("icon", {
                      staticClass: "cj-fill-red-primary",
                      attrs: {
                        name: "ic_picture_double",
                        height: "14px",
                        width: "14px",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto cj-color-red-primary font-weight-500 my-auto font-size-14",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.countPortfolio(item.detailId) &&
                            _vm.countPortfolio(item.detailId).length
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "mb-2 font-roboto font-weight-500 cj-color-black-primary",
              class: _vm.isMobile ? "font-size-12" : " font-size-14",
            },
            [_vm._v("\n      " + _vm._s(item.title) + "\n    ")]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }