import { render, staticRenderFns } from "./c-item-notification.vue?vue&type=template&id=e4011de6&scoped=true&"
import script from "./c-item-notification.component.ts?vue&type=script&lang=js&"
export * from "./c-item-notification.component.ts?vue&type=script&lang=js&"
import style0 from "./c-item-notification.scss?vue&type=style&index=0&id=e4011de6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4011de6",
  null
  
)

export default component.exports