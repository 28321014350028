import { Inject, Component, Vue, Emit } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';

import Icon from '@/shared/icons/icon.vue';
import CInputPin from '../c-input-pin/c-input-pin.vue';
import CButton from '@/components/button/c-button.vue';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import CAccoutDeactivateUpdate from './components/c-accout-deactivate-update/c-accout-deactivate-update.vue';

import PCbUserInfoService from '@/services/cb-user-info.service';
import PCbVwPawOrderService from '@/services/cb-vw-paw-order.service';
import PCbVwAuctionProjectService from '@/services/cb-vw-auction-project.service';
import PPayService from '@/services/pay.service';
import PCbAccountService from '@/services/cb-account.service';
import GlobalService from '@/services/global.service';
import AccountService from '@/account/account.service';

import { IUser, User } from '@/shared/model/user.model';
import { IPayVwBalanceCore, PayVwBalanceCore } from '@/shared/model/pay-vw-balance-core.model';
import { CbUserStatusType } from '@/shared/model/enumerations/cb-user-status-type.model';
import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';

@Component({
  components: {
    CButton,
    Icon,
    CConfirmation,
    CAccoutDeactivateUpdate,
    CInputPin,
  },
  mixins: [Vue2Filters.mixin],
})
export default class CAccountDeactive extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  @Inject('pCbUserInfoService') private pCbUserInfoService: () => PCbUserInfoService;
  @Inject('pCbVwPawOrderService') private pCbVwPawOrderService: () => PCbVwPawOrderService;
  @Inject('pCbVwAuctionProjectService') private pCbVwAuctionProjectService: () => PCbVwAuctionProjectService;
  @Inject('pPayService') private pPayService: () => PPayService;
  @Inject('pCbAccountService') private pCbAccountService: () => PCbAccountService;
  @Inject('accountService') private accountService: () => AccountService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================

  public cbUserInfo: ICbUserInfo = new CbUserInfo();
  public tmpCbUserInfo: ICbUserInfo = new CbUserInfo();
  public currentBalance: IPayVwBalanceCore = new PayVwBalanceCore();
  public user: IUser = new User();

  public countPawOrderBuyer = 0;
  public countPawOrderPartner = 0;
  public countAuctionActive = 0;

  public visiblePin = false;
  public visibleModalDeactive = false;

  public isFetching = false;
  public isFetchingCountActiveOrder = false;
  public isFetchingCountActiveAuction = false;
  public isSavingDeleteAccount = false;
  public isSavingDeactiveAccount = false;
  public isFetchingBalance = true;

  public pinNotMatched = false;

  public CbCurrency = CbCurrency;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    (<any>this.$root).$emit('showNavMobile', false);
    (<any>this.$root).$emit('showTabFooter', false);
    (<any>this.$root).$emit('showFloatingStore', false);

    this.$root.$off('CAccountDeactive::logout');
    this.$root.$on('CAccountDeactive::logout', () => {
      this.logout();
    });

    this.findCurrentBalance();
    this.retrieveCountPawOrder(CbLoginType.BUYER);
    this.retrieveCountPawOrder(CbLoginType.PARTNER);
    this.retrieveCountAuctionActive();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('modalDeactiveAccount')
  public modalDeactiveAccount() {
    return;
  }

  public toAccountDeactive() {
    this.$router.push({ name: 'PSettingAccountDeactiveM' });
  }

  public retrieveCbUserInfoLogin() {
    this.isFetching = true;
    this.pCbUserInfoService()
      .findByLogin()
      .then(res => {
        this.cbUserInfo = { ...res };
      })
      .finally(() => {
        this.isFetching = false;
      });
  }

  @Emit('accountDeactiveReason')
  public accountDeactiveReason() {
    return;
  }

  @Emit('accountDeactiveReasonMore')
  public accountDeactiveReasonMore() {
    return;
  }
  public showNotif() {
    this.globalService().openGlobalDialog(this.$root, 'cad-modal-notif-we-gonna-miss-you-delete');
  }

  public retrieveCountPawOrder(loginType: CbLoginType) {
    this.isFetchingCountActiveOrder = true;
    this.pCbVwPawOrderService()
      .countCbVwPawOrderActive(loginType)
      .then(res => {
        if (loginType === CbLoginType.BUYER) {
          this.countPawOrderBuyer = res;
        }

        if (loginType == CbLoginType.PARTNER) {
          this.countPawOrderPartner = res;
        }
      })
      .catch(err => {
        console.error('ERROR', err.response);
      })
      .finally(() => {
        this.isFetchingCountActiveOrder = false;
      });
  }

  public retrieveCountAuctionActive() {
    this.isFetchingCountActiveAuction = true;
    this.pCbVwAuctionProjectService()
      .countCbVwAuctionProjectActive()
      .then(res => {
        this.countAuctionActive = res;
      })
      .catch(err => {
        console.error('ERROR', err.response);
      })
      .finally(() => {
        this.isFetchingCountActiveAuction = false;
      });
  }

  public findCurrentBalance() {
    this.isFetchingBalance = true;
    this.pPayService()
      .findVwBalanceByUserId()
      .then(res => {
        this.currentBalance = res;
      })
      .catch(err => {
        console.error('ERROR', err.response);
      })
      .finally(() => {
        this.isFetchingBalance = false;
      });
  }

  public handleStartDeactiveAccount() {
    // validation
    if (this.isFetchingCountActiveAuction || this.isFetchingCountActiveOrder || this.isFetchingBalance) return;
    if (this.countPawOrderBuyer > 0 || this.countPawOrderPartner > 0 || this.countAuctionActive > 0) {
      this.globalService().openGlobalDialog(this.$root, 'cad-modal-finish-order');
      return;
    }

    this.tmpCbUserInfo = { ...this.cbUserInfo };
    this.tmpCbUserInfo.userStatus = CbUserStatusType.DEACTIVED;
    this.visibleModalDeactive = true;
  }

  public handleStartDeleteAccount() {
    // validation
    if (this.isFetchingCountActiveAuction || this.isFetchingCountActiveOrder || this.isFetchingBalance) return;

    if (this.countPawOrderBuyer > 0 || this.countPawOrderPartner > 0 || this.countAuctionActive > 0) {
      this.globalService().openGlobalDialog(this.$root, 'cad-modal-finish-order-delete');
      return;
    }

    if (this.currentBalance.balance > 0 || this.currentBalance.balanceRefund > 0 || this.currentBalance.balanceIncome > 0) {
      this.globalService().openGlobalDialog(this.$root, 'cad-modal-confirmation-deactivate-account-delete');
      return;
    }

    this.tmpCbUserInfo = { ...this.cbUserInfo };
    this.tmpCbUserInfo.userStatus = CbUserStatusType.DELETED;
    this.visibleModalDeactive = true;
  }

  public continueDeactiveOrDeleteAccount(isDelete?: boolean) {
    this.closeAllDialog();
    if (isDelete) {
      this.tmpCbUserInfo = { ...this.cbUserInfo };
      this.tmpCbUserInfo.userStatus = CbUserStatusType.DELETED;
    }
    this.visibleModalDeactive = true;
  }

  public toBalanceAccount() {
    this.$router.push({ name: 'PAccountBalance' });
  }

  public handleDeactiveSubmit(cbUserInfo: ICbUserInfo) {
    this.tmpCbUserInfo = { ...cbUserInfo };
    if (!this.currentUser.isPinExist) {
      this.globalService().openGlobalDialog(this.$root, 'modal-info-set-pin');
      return;
    }

    this.visiblePin = true;
    this.closeAllDialog();
  }

  public closeAllDialog() {
    this.globalService().closeGlobalDialog(this.$root, 'cad-modal-confirmation-deactivate-account');
    this.globalService().closeGlobalDialog(this.$root, 'cad-modal-accout-deactivate-update');
    this.globalService().closeGlobalDialog(this.$root, 'cad-modal-finish-order');
    this.globalService().closeGlobalDialog(this.$root, 'cad-modal-we-gonna-miss-you');
    this.globalService().closeGlobalDialog(this.$root, 'cad-modal-finish-order-delete');
    this.globalService().closeGlobalDialog(this.$root, 'cad-modal-confirmation-deactivate-account-delete');
    this.globalService().closeGlobalDialog(this.$root, 'cad-modal-accout-delete-update');
    this.globalService().closeGlobalDialog(this.$root, 'cad-modal-we-gonna-miss-you-delete');
  }

  public handleSubmitDeActiveAndDeleteAccount(pin: string) {
    this.tmpCbUserInfo.externalPin = pin;
    if (this.tmpCbUserInfo.userStatus == CbUserStatusType.DEACTIVED) {
      this.deActiveAccountByLogin();
    } else if (this.tmpCbUserInfo.userStatus == CbUserStatusType.DELETED) {
      this.deleteAccountByLogin();
    }
  }

  public deActiveAccountByLogin() {
    this.isSavingDeactiveAccount = true;

    this.pCbAccountService()
      .deactiveAccountByLogin(this.tmpCbUserInfo)
      .then(async res => {
        this.isSavingDeactiveAccount = false;
        this.closeAllDialog();

        this.globalService().closeGlobalDialog(this.$root, 'modal-deactive-delete');
        this.globalService().openGlobalDialog(this.$root, 'cad-modal-we-gonna-miss-you');
        this.pinNotMatched = false;
        this.visiblePin = false;
      })
      .catch(err => {
        this.pinNotMatched = true;
        this.isSavingDeactiveAccount = false;

        console.error('ERROR', err.response);
      });
  }

  public deleteAccountByLogin() {
    this.isSavingDeactiveAccount = true;

    this.pCbAccountService()
      .deleteAccountByLogin(this.tmpCbUserInfo)
      .then(async res => {
        if (res.id == 1) {
          this.isSavingDeactiveAccount = false;

          this.globalService().closeGlobalDialog(this.$root, 'modal-deactive-delete');
          this.globalService().openGlobalDialog(this.$root, 'cad-modal-we-gonna-miss-you-delete');
          this.pinNotMatched = false;
          this.visiblePin = false;
        } else {
          this.pinNotMatched = true;
          this.isSavingDeactiveAccount = false;
        }
      })
      .catch(err => {
        this.pinNotMatched = true;
        this.isSavingDeactiveAccount = false;

        console.error('ERROR', err.response);
      });
  }

  public async logout() {
    await this.accountService().logout();
    this.closeAllDialog();
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
