import { Component, Inject, Prop } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { CbTextNoAuth, ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import PCbTextNoAuthService from '@/services/cb-text-no-auth.service';
import AlertService from '@/shared/alert/alert.service';
import { CbBillboardType } from '@/shared/model/enumerations/cb-billboard-type.model';
import GlobalService from '@/services/global.service';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { CbPromoUsedStatus } from '@/shared/model/enumerations/cb-promo-used-status.model';
import PCbPromoUserAggService from '@/services/cb-promo-user-agg.service';
import { CbPromoUserAgg, ICbPromoUserAgg } from '@/shared/model/cb-promo-user-agg.model';
import { ICbVwPromo } from '@/shared/model/cb-vw-promo.model';
import { CbPromoTagType } from '@/shared/model/enumerations/cb-promo-tag-type.model';
import { AdvanceSearchFilterKey } from '@/shared/model/advance-search-model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbVwBillboard } from '@/shared/model/cb-vw-billboard.model';
@Component({
  components: { CButton, Icon },
})
export default class CBillboardDetail extends JhiDataUtils {
  // ================= START SERVICES ===================
  @Inject('pCbTextNoAuthService') private pCbTextNoAuthService: () => PCbTextNoAuthService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbPromoUserAggService') public pCbPromoUserAggService: () => PCbPromoUserAggService;
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({ default: null })
  public cbVwBillboard: CbVwBillboard;

  public cbTextNoauth: ICbTextNoAuth = new CbTextNoAuth();
  public billboardType = CbBillboardType;

  public cbPromoUsedStatus = CbPromoUsedStatus;

  public cbPromoUserAgg: ICbPromoUserAgg = new CbPromoUserAgg();
  public canCopy = false;

  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  created() {
    this.canCopy = !!navigator.clipboard;
    if (this.currentLanguage == 'id') {
      this.findServiceDescription(
        this.cbVwBillboard.descriptionLangIdId ? this.cbVwBillboard.descriptionLangIdId : this.cbVwBillboard.descriptionLangEnId
      );
    } else {
      this.findServiceDescription(
        this.cbVwBillboard.descriptionLangEnId ? this.cbVwBillboard.descriptionLangEnId : this.cbVwBillboard.descriptionLangIdId
      );
    }
  }
  // mounted() {
  // }

  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  public async copy(s) {
    await navigator.clipboard.writeText(s);
  }

  public findServiceDescription(descriptionLang): void {
    this.pCbTextNoAuthService()
      .find(descriptionLang)
      .then(res => {
        this.cbTextNoauth = res;
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public handleBillboard(item) {
    if (item.billboardType == CbBillboardType.EXISTING) {
      if (this.currentUser.id) {
        if (!item.cbVwPromo.isUserHasClaimed) {
          this.saveCardPromo(item.promoId);
        } else {
          this.toAdvancedSearch(item.cbVwPromo);
        }
      } else {
        this.close();
        this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
      }
    } else {
      window.open(item.url, '_blank');
    }
  }
  public close() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-billboard-detail');
  }

  public saveCardPromo(promoId) {
    this.cbPromoUserAgg.status = CbPromoUsedStatus.CLAIM;
    this.cbPromoUserAgg.cbPromo = { id: promoId };
    this.pCbPromoUserAggService()
      .create(this.cbPromoUserAgg)
      .then(param => {
        // Kupon terklaim!
        (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.toast.couponClaimed').toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        });
        this.$router.push({ name: 'PPromo' });
        this.close();
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }
  public toAdvancedSearch(promo: ICbVwPromo) {
    let queryObj = {};
    if (promo.cbPromoSpecifics != null) {
      for (const spec of promo.cbPromoSpecifics.split('||')) {
        const specDtl = spec.split('::');
        queryObj = Object.assign({}, this.assignQueryObj(queryObj, specDtl));
      }
    }
    this.$router.push({
      name: 'PAdvanceSearch',
      params: { key: new Date().getTime().toString() },
      query: queryObj,
    });
    this.close();
  }

  public assignQueryObj(queryObj: any, specDtl: string[]): any {
    if (specDtl[0] == CbPromoTagType.SERVICE_FIELD) {
      queryObj[AdvanceSearchFilterKey.SVCTYPE] = CbSvcType.FIELD;
    } else if (specDtl[0] == CbPromoTagType.SERVICE_INSIDE) {
      queryObj[AdvanceSearchFilterKey.SVCTYPE] = CbSvcType.INSIDE;
    } else if (specDtl[0] == CbPromoTagType.SERVICE_SPECIFIC) {
      if (queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`] == null) {
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`] = [];
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`].push(specDtl[1]);
      } else {
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`].push(specDtl[1]);
      }
    } else if (specDtl[0] == CbPromoTagType.CATEGORY_SPECIFIC) {
      if (queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`] == null) {
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`] = [];
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`].push(specDtl[1]);
      } else {
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`].push(specDtl[1]);
      }
    }
    queryObj['promoCode'] = this.cbVwBillboard.promoId;
    return queryObj;
  }

  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  public get currentUser() {
    return this.$store.getters.currentUser;
  }

  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
