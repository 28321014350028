import { Inject, Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';
import CInputOtp from '@/components/c-input-otp/c-input-otp.vue';
import PCbOidService from '@/services/cb-oid.service';
import { CbVerificationMethodType } from '@/shared/model/enumerations/cb-verification-method-type.model';
import { ICbOidActivation } from '@/shared/model/cb-oids.model';
import { CbVerificationOtp } from '@/shared/model/cb-verification-otp.model';
import GlobalService from '@/services/global.service';
import AccountService from '@/account/account.service';
import { ICbVwUserActivation } from '@/shared/model/cb-vw-user-actiovation.model';

@Component({ components: { CTimeCountdown, CInputOtp } })
export default class CVerificationEmail extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbOidService') public pCbOidService: () => PCbOidService;
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('accountService') private accountService: () => AccountService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false, type: Boolean }) public visibleFooter: boolean;
  @Prop({ default: false, type: Boolean }) public visibleHeader: boolean;
  @Prop({ default: false, type: Boolean }) public visibleCountdown: boolean;
  @Prop({ default: null }) public value: CbVerificationOtp;
  public methodeVerification: CbVerificationMethodType;
  public visibleLoading: boolean = false;
  public inputOtp: string = '';
  public invalidOtp: boolean = false;
  public showCountdown: boolean = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.visibleCountdownListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public resend() {
    this.visibleLoading = true;
    const activation: ICbOidActivation = {
      key: this.value.emailActivationKey,
      activationCode: null,
    };
    this.pCbOidService()
      .resend(activation, 'email')
      .then(res => {
        if (res.id == 1) {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.resendOtp.success').toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          });
          this.$emit('input', res.detail);

          this.visibleCountdownListener();

          this.$root.$emit('dashboard::getCbVwUserActivation', (cbVwUserActivation: ICbVwUserActivation) => {
            cbVwUserActivation.emailActivationKey = res.detail.emailActivationKey;

            this.$root.$emit('dashboard::changeValue', 'cbVwUserActivation', cbVwUserActivation);
          });
        } else {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.resendOtp.waited', { minutes: 1 }).toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Danger',
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
          });
        }
      })
      .catch(() => {
        (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.resendOtp.failed').toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Danger',
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000,
        });
      })
      .finally(() => {
        this.visibleLoading = false;
      });
  }

  public handleInputOtp() {
    if (this.inputOtp.length > 0) {
      this.inputOtp = this.inputOtp.toUpperCase();
    }
    if (this.inputOtp.length == 4) {
      this.visibleLoading = true;
      const activation: ICbOidActivation = {
        key: this.value.emailActivationKey,
        activationCode: this.inputOtp,
      };
      this.pCbOidService()
        .activate(activation, 'email')
        .then(res => {
          this.visibleLoading = false;
          if (res.id && res.id > 0) {
            this.invalidOtp = false;
            if (this.isAuthenticated) {
              this.globalService().refreshTokenAfterSetEmail();
              this.accountService().retrieveCbVwUserCurrent();
            }
            this.$emit('submit');
          } else {
            this.invalidOtp = true;
          }
        })
        .catch(err => {
          this.visibleLoading = false;
        });
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('visibleCountdown')
  public visibleCountdownListener() {
    if (this.visibleCountdown) {
      this.showCountdown = true;
    }
  }

  public get isAuthenticated() {
    return this.$store.getters.authenticated;
  }
  // ================= END LISTENERS ==================
}
