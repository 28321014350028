import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbUserLevelType } from '@/shared/model/enumerations/cb-user-level';
import { ICbVwUser } from '../../shared/model/cb-vw-user.model';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { Component } from 'vue-property-decorator';
import JhiDataUtils from '@/shared/data/data-utils.service';
import Vue2Filters from 'vue2-filters';
import { mixins } from 'vue-class-component';
import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { ICbLov } from '@/shared/model/cb-lov.model';
import { CbDeliveryTimeType } from '@/shared/model/enumerations/cb-delivery-time-type.model';
import { MaxNumberType } from '@/shared/model/enumerations/maxNumberType.model';

const validations: any = {
  cbAuctionProjectPreOffer: {
    pitch: { required, minLength: minLength(150), maxLength: maxLength(1000) },
    deliveryTime: { required },
    deliveryTimeType: { required },
    revisionCount: {},
    priceType: { required },
    currency: {},
    budget: {},
    status: {},
    acceptedBy: {},
    acceptedDate: {},
    createdBy: {},
    createdDate: {},
    lastModifiedBy: {},
    lastModifiedDate: {},
    isActive: {},
    cbAuctionProject: {},
  },
  vwAuctionProject: {
    svcType: {},
  },
  budget: { required },
};
@Component({
  validations,
  mixins: [Vue2Filters.mixin],
})
export default class CFormAuctionProjectPitchValidation extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public CbPriceType = CbPriceType;
  public CbCurrency = CbCurrency;
  public CbCommonStatus = CbCommonStatus;

  public CbSvcType = CbSvcType;

  public CbUserLevelType = CbUserLevelType;
  public CbDeliveryTimeType = CbDeliveryTimeType;
  public MaxNumberType = MaxNumberType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public initCbAuctionProjectPreOffer() {
    this.$v.cbAuctionProjectPreOffer.deliveryTimeType.$model = CbDeliveryTimeType.DAY;
    this.$v.cbAuctionProjectPreOffer.priceType.$model = CbPriceType.FIXED_PRICE;
    this.$v.cbAuctionProjectPreOffer.revisionCount.$model = 1;
    this.touchValidation();
  }

  public touchValidation() {
    if (this.$v.cbAuctionProjectPreOffer.pitch.$model) {
      this.$v.cbAuctionProjectPreOffer.pitch.$touch();
    }
    if (this.$v.cbAuctionProjectPreOffer.deliveryTime.$model) {
      this.$v.cbAuctionProjectPreOffer.deliveryTime.$touch();
    }
    if (this.$v.cbAuctionProjectPreOffer.deliveryTimeType.$model) {
      this.$v.cbAuctionProjectPreOffer.deliveryTimeType.$touch();
    }
    if (this.$v.cbAuctionProjectPreOffer.priceType.$model) {
      this.$v.cbAuctionProjectPreOffer.priceType.$touch();
    }
    if (this.$v.budget.$model) {
      this.$v.budget.$touch();
    }
  }

  public validateBtnSubmit(): boolean {
    let isValid: boolean = true;
    if (this.$v.cbAuctionProjectPreOffer.$invalid) {
      isValid = false;
    }
    if (this.getTotalBudget < MaxNumberType.MINIMAL_AMOUNT_ORDER) {
      isValid = false;
    }
    if (this.$v.budget.$invalid) {
      isValid = false;
    }
    if (this.$v.vwAuctionProject.svcType.$model == CbSvcType.INSIDE && !this.$v.cbAuctionProjectPreOffer.revisionCount.$model) {
      isValid = false;
    }

    if (!isValid) {
      this.$v.cbAuctionProjectPreOffer.$touch();
      this.$v.budget.$touch();

      this.$nextTick(() => {
        const domRect = document.querySelector('.invalid').getBoundingClientRect();
        const top = domRect.top + document.documentElement.scrollTop - 150;

        window.scrollTo({
          top: top,
          behavior: 'smooth',
        });
      });

      const message: string = this.$t('cbgwApp.cbComponent.validation.wrongFormInput').toString();
      (<any>this.$root).$bvToast.toast(message, {
        toaster: 'b-toaster-top-center',
        title: 'Info',
        variant: 'warning',
        solid: true,
        autoHideDelay: 3000,
      });
      return null;
    }

    return isValid;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get getTotalBudget(): number {
    let total: number = 0;
    if (this.$v.cbAuctionProjectPreOffer.priceType.$model == CbPriceType.FIXED_PRICE) {
      total = this.$v.budget.$model;
    } else {
      if (
        this.$v.cbAuctionProjectPreOffer.deliveryTime.$model &&
        this.$v.cbAuctionProjectPreOffer.deliveryTimeType.$model &&
        this.$v.budget.$model
      ) {
        if (this.$v.cbAuctionProjectPreOffer.deliveryTimeType.$model.toUpperCase() == CbDeliveryTimeType.DAY) {
          total = 24 * +this.$v.cbAuctionProjectPreOffer.deliveryTime.$model * this.$v.budget.$model;
        } else {
          total = +this.$v.cbAuctionProjectPreOffer.deliveryTime.$model * this.$v.budget.$model;
        }
      }
    }

    return total;
  }

  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get deliveryTimeTypes(): ICbLov[] {
    return [
      {
        key: CbDeliveryTimeType.DAY,
        value: this.$t('cbgwApp.cbComponent.deliveryTimeType.day').toString(),
      },
      {
        key: CbDeliveryTimeType.HOUR,
        value: this.$t('cbgwApp.cbComponent.deliveryTimeType.hour').toString(),
      },
    ];
  }

  public get priceTypeList() {
    const priceTypeList: ICbLov[] = [];
    for (const key in CbPriceType) {
      priceTypeList.push({
        key: key,
        value: this.$options.filters.formatLanguage(CbPriceType[key], this.currentLanguage),
      });
    }
    return priceTypeList;
  }
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  public get maxDeliveryTime() {
    if (this.$v.cbAuctionProjectPreOffer.deliveryTimeType.$model == CbDeliveryTimeType.DAY) {
      return 365;
    }

    return 999;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
