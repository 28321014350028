var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-pd-portfolio-preview mb-3",
      attrs: { "data-cy": "pd-portfolio-preview" },
    },
    [
      _c(
        "div",
        { staticClass: "card-img-wrapper cb-img-wrapper cb-ratio-4x3 mb-1" },
        [
          !_vm.isPlaying
            ? _c("img", {
                staticClass: "cb-img-contain border-radius-card",
                attrs: {
                  src: _vm.$options.filters.LAZY(
                    `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                      _vm.isVideo(_vm.item.cbAttachmentNoAuth.attContentType)
                        ? _vm.item?.cbAttachmentNoAuth?.refThumbnailId
                        : _vm.item?.cbAttachmentNoAuth?.id
                    }`,
                    720,
                    "ic-lazy-paw.png"
                  ),
                  onerror: _vm.$options.filters.LAZY_ERROR("ic-lazy-paw.png"),
                  "data-cy": "pd-portfolio-preview-image",
                  alt: `img-preview-${_vm.item?.name ?? _vm.item?.title}`,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isPlaying
            ? _c(
                "video",
                {
                  ref: "myVideoPortfolioPreview",
                  staticClass: "cb-img-contain border-radius-card",
                  attrs: {
                    poster: _vm.$options.filters.LAZY_ERROR(
                      "ic-lazy-paw.png",
                      true
                    ),
                  },
                  domProps: { muted: _vm.isMuted },
                  on: { click: _vm.handlePauseVideo },
                },
                [
                  _c("source", {
                    attrs: {
                      src: _vm.$options.filters.LAZY(
                        `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                          _vm.item.cbAttachmentNoAuth &&
                          _vm.item.cbAttachmentNoAuth.id
                        }`,
                        null,
                        "ic-lazy-paw.png"
                      ),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          (!_vm.isPlaying || _vm.isPause) &&
          _vm.isVideo(_vm.item.cbAttachmentNoAuth.attContentType)
            ? _c(
                "div",
                {
                  staticClass: "btn-play-video cursor-pointer",
                  on: { click: _vm.handlePlayVideo },
                },
                [
                  _c(
                    "div",
                    { staticClass: "icon-wrapper" },
                    [
                      _c("icon", {
                        staticClass: "cj-fill-grey-secondary",
                        attrs: {
                          name: _vm.isPause ? "ic_pause" : "ic_play",
                          width: "100%",
                          height: "100%",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowVideo
            ? _c(
                "div",
                { staticClass: "btn-icon-video-position" },
                [
                  _c("button", {
                    ref: "tooltipActivedSound",
                    staticClass: "cb-btn-icon",
                    class: _vm.isMuted
                      ? "cb-btn-ic-sound"
                      : "cb-btn-ic-sound-mute",
                    on: {
                      click: function ($event) {
                        return _vm.handleMuteVideo()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-tooltip",
                    {
                      attrs: {
                        "custom-class": "custom-tooltip-btn",
                        target: () => _vm.$refs["tooltipActivedSound"],
                        variant: "primary",
                        placement: "top",
                        triggers: "hover",
                      },
                    },
                    [
                      _vm.isMuted
                        ? _c(
                            "p",
                            { staticClass: "font-catamaran font-size-14" },
                            [_vm._v("Mute")]
                          )
                        : _c(
                            "p",
                            { staticClass: "font-catamaran font-size-14" },
                            [_vm._v("Unmute")]
                          ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-center mb-2" }, [
        _c(
          "div",
          {
            staticClass: "d-flex mr-1 cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.saveCbUserBookmarkAgg(_vm.item)
              },
            },
          },
          [
            _vm.$options.filters.findBookmarkByIdIsActive(
              _vm.GET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH,
              _vm.item.cbAttachmentNoAuthId,
              _vm.CbBookmarkType.LIKE_CB_ATTACHMENT_NO_AUTH
            )
              ? [
                  _c("icon", {
                    staticClass: "cj-fill-red-primary",
                    attrs: {
                      name: "ic_like_active",
                      width: "35px",
                      height: "35px",
                    },
                  }),
                ]
              : [
                  _c("icon", {
                    staticClass: "cj-fill-grey-secondary",
                    attrs: { name: "ic_like", width: "35px", height: "35px" },
                  }),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "font-roboto cj-color-black-primary mt-1",
            class: _vm.isMobile
              ? "font-weight-400 font-size-14"
              : "font-weight-500 font-size-18",
          },
          [_vm._v("\n      " + _vm._s(_vm.countLike) + " Likes\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }