import dayjs from 'dayjs';
import { Inject, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import { mixins } from 'vue-class-component';

import CButton from '../button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import CInputRevision from '../c-input-revision/c-input-revision.vue';
import CTextareaNew from '../c-textarea-new/c-textarea-new.vue';
import CInputNumber from '../c-input-number/c-input-number.vue';
import CAutocompleteNew from '../c-autocomplete-new/c-autocomplete-new.vue';
import CApPitchPopup from './components/c-ap-pitch-popup/c-ap-pitch-popup.vue';
import CApPitchPreview from './components/c-ap-pitch-preview/c-ap-pitch-preview.vue';
import CFormAuctionProjectPitchValidation from './c-form-auction-project-pitch-validation.component';
import CInputCurrency from '@/components/c-input-currency/c-input-currency.vue';
import CPopupBottom from '../c-popup-bottom/c-popup-bottom.vue';
import CLoader from '../c-loader/c-loader.vue';

import { CbTextNoAuth, ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import { CbAuctionProjectPreOffer, ICbAuctionProjectPreOffer } from '@/shared/model/cb-auction-project-pre-offer.model';
import { CbUserBookmarkAgg, ICbUserBookmarkAgg } from '@/shared/model/cb-user-bookmark-agg.model';
import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';
import { ICbVwAuctionProject, CbVwAuctionProject } from '@/shared/model/cb-vw-auction-project.model';
import { CbBookmarkType } from '@/shared/model/enumerations/cb-bookmark-type.model';
import { ICbAttachmentNoAuth } from '@/shared/model/cb-attachment-no-auth.model';
import { MaxNumberType } from '@/shared/model/enumerations/maxNumberType.model';

import PCbVwAuctionProjectService from '@/services/cb-vw-auction-project.service';
import PCbTextNoAuthService from '@/services/cb-text-no-auth.service';
import PCbAuctionProjectPreOfferService from '@/services/cb-auction-project-pre-offer.service';
import PCbVwAuctionProjectPreOfferService from '@/services/cb-vw-auction-project-pre-offer.service';
import ContentService from '@/services/content.service';
import JhiDataUtils from '@/shared/data/data-utils.service';
import AlertService from '@/shared/alert/alert.service';
import GlobalService from '@/services/global.service';

@Component({
  components: {
    Icon,
    CButton,
    CInputRevision,
    CInputCurrency,
    CTextareaNew,
    CInputNumber,
    CAutocompleteNew,
    CApPitchPreview,
    CApPitchPopup,
    CPopupBottom,
    CLoader,
  },
  mixins: [Vue2Filters.mixin],
})
export default class CFormAuctionProjectPitch extends mixins(JhiDataUtils, CFormAuctionProjectPitchValidation) {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbVwAuctionProjectService') private pCbVwAuctionProjectService: () => PCbVwAuctionProjectService;
  @Inject('pCbTextNoAuthService') private pCbTextNoAuthService: () => PCbTextNoAuthService;
  @Inject('pCbAuctionProjectPreOfferService') private pCbAuctionProjectPreOfferService: () => PCbAuctionProjectPreOfferService;
  @Inject('pCbVwAuctionProjectPreOfferService') private pCbVwAuctionProjectPreOfferService: () => PCbVwAuctionProjectPreOfferService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('contentService') public contentService: () => ContentService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwAuctionProjectId: number;
  @Prop({ default: true }) public closeModalId: boolean;
  @Prop({ default: false }) public isNoModalAuctionProject: boolean;

  public cbTextNoAuth: ICbTextNoAuth = new CbTextNoAuth();
  public cbAttachmentNoAuths: ICbAttachmentNoAuth[] = [];
  public cbAuctionProjectPreOffer: ICbAuctionProjectPreOffer = new CbAuctionProjectPreOffer();
  public vwAuctionProject: ICbVwAuctionProject = new CbVwAuctionProject();
  public userBookmark: ICbUserBookmarkAgg = new CbUserBookmarkAgg();
  public arrayOfAtt: Array<any> = [];

  public CbBookmarkType = CbBookmarkType;
  public CbPriceType = CbPriceType;
  public MaxNumberType = MaxNumberType;
  public isRoutePAuctionProjectDetail = this.$route.name == 'PAuctionProjectDetail';
  public budget = 0;

  public isSaving = false;
  public isActivePopupBottom = false;
  public isFetchingVwAuctionProject = false;
  public isShowBtn = false;
  public toggleOpenOffer = false;
  public visibleFormOffer = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.id) {
        vm.retrieveProjectDetail(to.params.id);
      }
    });
  }
  created() {
    this.retrieveProjectDetail();

    this.initCbAuctionProjectPreOffer();
    (<any>this.$root).$off('cap::toggleOpenOffer');
    (<any>this.$root).$on('cap::toggleOpenOffer', value => {
      this.toggleOpenOffer = value;
    });
    (<any>this.$root).$off('cap::toggleOpenOfferShow');
    (<any>this.$root).$on('cap::toggleOpenOfferShow', callBack => {
      callBack(this.toggleOpenOffer);
    });
    this.contentService().initRetrieveCbUserBookmarkAggs(CbBookmarkType.AUCTION_PROJECT);
  }

  public callBack() {
    if (this.toggleOpenOffer) {
      this.toggleOpenOffer = false;
    } else if (this.isNoModalAuctionProject) {
      this.$router.go(-1);
    } else {
      this.$emit('cancel');
    }
  }
  @Emit('cancel')
  public cancel() {
    return;
  }

  public handleToggleOpenOffer() {
    const date = new Date();
    if (
      (dayjs(this.currentUser.offDutyStartDate).isBefore(date) || dayjs(this.currentUser.offDutyStartDate).isSame(date)) &&
      (dayjs(this.currentUser.offDutyEndDate).isAfter(date) || dayjs(this.currentUser.offDutyEndDate).isSame(date))
    ) {
      this.globalService().closeGlobalDialog(this.$root, 'modal-c-auction-project');
      (<any>this.$root).$emit('appModals::changeValue', 'visibleHibernateConfirm', true);
      return;
    }
    this.toggleOpenOffer = true;
  }

  public prepareSubmit() {
    this.cbAuctionProjectPreOffer.cbAuctionProject = { id: this.vwAuctionProject.id };
    this.cbAuctionProjectPreOffer.currency = this.CbCurrency.IDR;
    this.cbAuctionProjectPreOffer.status = this.CbCommonStatus.WAITING;
    this.cbAuctionProjectPreOffer.budget = this.getTotalBudget;
    this.cbAuctionProjectPreOffer.isActive = true;
  }

  public submit() {
    if (!this.validateBtnSubmit() || this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.prepareSubmit();
    this.pCbAuctionProjectPreOfferService()
      .create(this.cbAuctionProjectPreOffer)
      .then(() => {
        this.$router.push({ name: 'PFindProject', params: { projectTab: 'offer' } });
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-auction-project');
      })
      .catch(err => {
        if (err.response.headers['x-cbcoremsapp-error'] === 'error.777') {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.formAuctionProjectPitch.msgError777').toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Warning',
            variant: 'warning',
            solid: true,
            autoHideDelay: 2000,
          });
        } else if (err.response.headers['x-cbcoremsapp-error'] === 'error.776') {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.formAuctionProjectPitch.msgError776').toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Warning',
            variant: 'warning',
            solid: true,
            autoHideDelay: 2000,
          });
        } else if (err.response.headers['x-cbcoremsapp-error'] === 'error.775') {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.formAuctionProjectPitch.msgError775').toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Warning',
            variant: 'warning',
            solid: true,
            autoHideDelay: 2000,
          });
        } else {
          this.alertService().showHttpError(this, err.response);
        }
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  public findOneCbTextAuth(id: number) {
    this.pCbTextNoAuthService()
      .find(id)
      .then(res => {
        this.cbTextNoAuth = Object.assign({}, res);
        // if (this.isRoutePAuctionProjectDetail) {
        //   (<any>this.$root).$emit('meta:::DataDescription', this.cbTextNoAuth.content);
        // }
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public openModalCShare(auctionId) {
    (<any>this.$root).$emit('openModalCShare', 'AUCTION', auctionId);
  }

  public toProfile(idUser) {
    this.$router.push({ name: 'PProfilePublic', params: { id: idUser } });
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-auction-project');
  }

  public findExistPreOffer(id: number) {
    const paramsPreOffer: any = {
      'senderId.equals': this.currentUser.id,
      'cbAuctionProjectId.equals': id,
      'isActive.equals': true,
      size: 1,
    };

    this.pCbVwAuctionProjectPreOfferService()
      .retrieve(paramsPreOffer)
      .then(res => {
        if (res.data.length > 0) {
          this.visibleFormOffer = false;
        }
      });
  }

  public saveBookmark(valueId) {
    const cbUserBookmark: ICbUserBookmarkAgg = new CbUserBookmarkAgg();
    cbUserBookmark.dataId = valueId;
    cbUserBookmark.bookmarkType = CbBookmarkType.AUCTION_PROJECT;
    if (this.$options.filters.findBookmarkByIdIsActive(this.cbUserBookmarkAggs, valueId, CbBookmarkType.AUCTION_PROJECT)) {
      cbUserBookmark.isActive = false;
    } else {
      cbUserBookmark.isActive = true;
    }

    (<any>this.$root).$emit('app::saveCbUserBookmarkAgg', cbUserBookmark, ({ isSuccess, auctionProjectTab }) => {
      if (isSuccess && (auctionProjectTab == 'wishlist' || auctionProjectTab == 'recommendation')) {
        (<any>this.$root).$emit('findProject::retrieveAllCbVwAuctionProjectWishlists');
      }
    });
  }

  public toHelpCenter() {
    this.$router.push({ name: 'PHelpCenter', params: { info: 'info' } });
    this.cancel();
  }
  public processPrepareVwAuction() {
    // if (this.isRoutePAuctionProjectDetail) {
    //   (<any>this.$root).$emit('meta:::DataTitle', this.vwAuctionProject.title);
    // }
    this.findOneCbTextAuth(this.vwAuctionProject.descriptionId);
    this.cbAttachmentNoAuths = [];
    this.visibleFormOffer = true;
    if (this.vwAuctionProject.attIdWithTypes?.length > 0) {
      const splitString = this.vwAuctionProject.attIdWithTypes.split('||');
      if (splitString && splitString.length > 0) {
        for (const item of splitString) {
          const splitField: string[] = item.split('::');
          this.cbAttachmentNoAuths.push({
            attContentType: splitField[0],
            name: splitField[1],
          });
        }
      }
    }
    if (this.vwAuctionProject.authorId == this.currentUser.id) {
      this.visibleFormOffer = false;
    }

    this.findExistPreOffer(this.vwAuctionProject.id);
  }

  public changeValue(fieldName: string, value: any) {
    this[fieldName] = value;
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  public get cbUserBookmarkAggs() {
    return this.$store.getters.GET_USER_BOOKMARK_AUCTION_PROJECT;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('cbVwAuctionProjectId')
  public retrieveProjectDetail(id?: string) {
    this.isFetchingVwAuctionProject = true;
    if (this.cbVwAuctionProjectId || id) {
      let newId: number = null;
      if (this.cbVwAuctionProjectId) {
        newId = Number(this.cbVwAuctionProjectId);
      } else if (id) {
        newId = Number(id);
      }
      this.pCbVwAuctionProjectService()
        .find(newId)
        .then(res => {
          this.vwAuctionProject = Object.assign({}, this.vwAuctionProject, res);
          this.processPrepareVwAuction();
          this.isFetchingVwAuctionProject = false;
          setTimeout(() => {
            this.isShowBtn = true;
          }, 500);
        });
    }
  }

  // ================= END LISTENERS ===================
}
