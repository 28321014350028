import { Module } from 'vuex';
import { ICbSvc } from '@/shared/model/cb-svc.model';
import { CbUserCookie, ICbUserCookie } from '@/shared/model/cb-user-cookie.model';
import { ICbVwLocation } from '@/shared/model/cb-vw-location.model';
import { ICbVwSvcCat } from '@/shared/model/cb-vw-svc-cat.model';

interface GeneralState {
  cbVwSvcs: ICbSvc[];
  cbVwSvcPopulars: ICbSvc[];
  cbVwSvcCats: ICbVwSvcCat[];
  cbVwSvcCatPopulars: ICbVwSvcCat[];
  userLevel: string;
  userCookie: ICbUserCookie;
  serverTime: Date;
  visibleModalDeactive: boolean;
  emailIdp: string;
  visibleModalIdpExist: boolean;
  numberOfAjaxCAllPending: number;
  cbVwLocations: ICbVwLocation[];
  envSecret: string;
  previousRouteName: string;
  previousRouteFullPath: string;
  previousRouteChat: string;
  visibleModalSuspend: boolean;
  isShowEyesBalance: boolean;
  isShowEyesMonthRevenue: boolean;
  isShowEyesMonthSpending: boolean;
  nextRouteName: string;
  typeBackGeneral: string;
  routeModulePaw: any;
  isPhoneTrouble: boolean;
  previousSubscriptions: [];
}

const defaultGeneralState: GeneralState = {
  cbVwSvcs: [],
  cbVwSvcPopulars: [],
  cbVwSvcCats: [],
  cbVwSvcCatPopulars: [],
  userLevel: '',
  userCookie: new CbUserCookie(),
  serverTime: new Date(),
  visibleModalDeactive: false,
  emailIdp: '',
  visibleModalIdpExist: false,
  numberOfAjaxCAllPending: 0,
  cbVwLocations: [],
  envSecret: '-----',
  previousRouteName: '',
  previousRouteFullPath: '',
  previousRouteChat: '',
  visibleModalSuspend: false,
  isShowEyesBalance: false,
  isShowEyesMonthRevenue: false,
  isShowEyesMonthSpending: false,
  nextRouteName: '',
  typeBackGeneral: '',
  routeModulePaw: {},
  isPhoneTrouble: false,
  previousSubscriptions: [],
};

export const generalStore: Module<GeneralState, any> = {
  state: { ...defaultGeneralState },
  getters: {
    cbVwSvcs: state => state.cbVwSvcs,
    cbVwSvcPopulars: state => state.cbVwSvcPopulars,
    cbVwSvcCats: state => state.cbVwSvcCats,
    cbVwSvcCatPopulars: state => state.cbVwSvcCatPopulars,
    userLevel: state => state.userLevel,
    userCookie: state => state.userCookie,
    serverTime: state => state.serverTime,
    visibleModalDeactive: state => state.visibleModalDeactive,
    emailIdp: state => state.emailIdp,
    visibleModalIdpExist: state => state.visibleModalIdpExist,
    numberOfAjaxCAllPending: state => state.numberOfAjaxCAllPending,
    cbVwLocations: state => state.cbVwLocations,
    envSecret: state => state.envSecret,
    previousRouteName: state => state.previousRouteName,
    previousRouteFullPath: state => state.previousRouteFullPath,
    previousRouteChat: state => state.previousRouteChat,
    visibleModalSuspend: state => state.visibleModalSuspend,
    getShowEyesBalance: state => state.isShowEyesBalance,
    getShowEyesMonthRevenue: state => state.isShowEyesMonthRevenue,
    getShowEyesMonthSpending: state => state.isShowEyesMonthSpending,
    nextRouteName: state => state.nextRouteName,
    typeBackGeneral: state => state.typeBackGeneral,
    routeModulePaw: state => state.routeModulePaw,
    isPhoneTrouble: state => state.isPhoneTrouble,
    previousSubscriptions: state => state.previousSubscriptions,
  },
  mutations: {
    cbVwSvcs(state, cbVwSvcs) {
      state.cbVwSvcs = cbVwSvcs;
    },
    cbVwSvcPopulars(state, cbVwSvcPopulars) {
      state.cbVwSvcPopulars = cbVwSvcPopulars;
    },
    cbVwSvcCats(state, cbVwSvcCats) {
      state.cbVwSvcCats = cbVwSvcCats;
    },
    cbVwSvcCatPopulars(state, cbVwSvcCatPopulars) {
      state.cbVwSvcCatPopulars = cbVwSvcCatPopulars;
    },
    userLevel(state, userLevel) {
      state.userLevel = userLevel;
    },
    userCookie(state, userCookie) {
      state.userCookie = userCookie;
    },
    serverTime(state, serverTime) {
      state.serverTime = serverTime;
    },
    setVisibleModalDeactive(state, visibleModalDeactive) {
      state.visibleModalDeactive = visibleModalDeactive;
    },
    setEmailIdp(state, email) {
      state.emailIdp = email;
    },
    setVisibleModalIdpExist(state, visibleModalIdpExist) {
      state.visibleModalIdpExist = visibleModalIdpExist;
    },
    numberOfAjaxCAllPending(state, count) {
      state.numberOfAjaxCAllPending = count;
    },
    cbVwLocations(state, count) {
      state.cbVwLocations = count;
    },
    envSecret(state, value) {
      state.envSecret = value;
    },
    previousRouteName(state, value) {
      state.previousRouteName = value;
    },
    previousRouteFullPath(state, value) {
      state.previousRouteFullPath = value;
    },
    previousRouteChat(state, value) {
      state.previousRouteChat = value;
    },
    visibleModalSuspend(state, value) {
      state.visibleModalSuspend = value;
    },
    setShowEyesBalance(state, value) {
      state.isShowEyesBalance = value;
    },
    setShowEyesMonthRevenue(state, value) {
      state.isShowEyesMonthRevenue = value;
    },
    setShowEyesMonthSpending(state, value) {
      state.isShowEyesMonthSpending = value;
    },
    nextRouteName(state, value) {
      state.nextRouteName = value;
    },
    typeBackGeneral(state, value) {
      state.typeBackGeneral = value;
    },
    routeModulePaw(state, value) {
      state.routeModulePaw = value;
    },
    isPhoneTrouble(state, value) {
      state.isPhoneTrouble = value;
    },
    previousSubscriptions(state, value) {
      state.previousSubscriptions = value;
    },
  },
};
