import { IPayPayload2023 } from './../shared/model/pay-payload-2023.model';
import { App } from '@/shared/model/enumerations/app.model';
import { IPayCreditCardRefund } from '@/shared/model/pay-credit-card-refund.model';
import axios, { CancelToken } from 'axios';
import { IPayVwBalanceCore } from '@/shared/model/pay-vw-balance-core.model';
import { IPayVwTransactionSummaries } from '@/shared/model/paw-vw-transaction-summaries.model';
import { IWfHistory } from '@/shared/model/wf-history.model';
import { PayMethodType } from '@/shared/model/enumerations/pay-method-type.model';
import { IPayVwWaitingForPayment } from '@/shared/model/pay-vw-waiting-for-payment.model';

const baseApiUrlPays = 'services/payment/api/pays';
const baseApiUrlPays2023 = 'services/payment/api/pays/2023';

const baseApiUrlPayWitingForPayment = 'services/payment/api/pay-vw-waiting-for-payments';

const baseApiUrlPayVwBalance = 'services/payment/api/pay-vw-balances';

const baseApiUrlCreditCardRefund = 'services/payment/api/pay-credit-card-refunds';

const baseApiUrlVwBalance = 'services/payment/api/pay-vw-balance-cores';

const baseApiUrlPayVwTransactionSummaries = 'services/payment/api/pay-vw-transaction-summaries';

const baseApiUrlPayVwBillingHistoryPayment = 'services/payment/api/pay-vw-billing-history-payments';
const baseApiUrlPayVwBillingHistoryIncome = 'services/payment/api/pay-vw-billing-history-incomes';
const baseApiUrlPayVwBillingHistoryRefund = 'services/payment/api/pay-vw-billing-history-refunds';
const baseApiUrlPayVwBillingHistoryTransaction = 'services/payment/api/pay-vw-billing-history-transactions';
const baseApiUrlPayVwBillingHistoryTopup = 'services/payment/api/pay-vw-billing-history-topups';
const baseApiUrlPayVwBillingHistoryWithdraw = 'services/payment/api/pay-vw-billing-history-withdraws';
export default class PPayService {
  public findVwBalanceByUserId(): Promise<IPayVwBalanceCore> {
    return new Promise<IPayVwBalanceCore>((resolve, reject) => {
      // http://localhost:8085/api/pay-vw-balance-cores/CB_APP/by-login
      axios
        .get(baseApiUrlVwBalance + '/' + App.CB_APP + '/by-login')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findPayVwTransactionSummaries(): Promise<IPayVwTransactionSummaries> {
    return new Promise<IPayVwTransactionSummaries>((resolve, reject) => {
      axios
        .get(baseApiUrlPayVwTransactionSummaries + '/by-login')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayOptionVA(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPays2023}/va-available-banks`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createPayment(payTypeKey: string, payload: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlPays}/cb-paw/${payTypeKey}`, payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // WF
  public pay2023(payload: IPayPayload2023, wfHistory?: IWfHistory, cancelToken?: CancelToken): Promise<any> {
    if (payload.payMethodType == PayMethodType.ORDER_PAW && !wfHistory) {
      console.log('wf needed');
      return;
    }

    payload.wfHistory = wfHistory;

    payload.app = App.CB_APP;
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlPays2023}/request`, payload, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public callBackPaymentCC2023(payload): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlPays2023}/callback-cc/CC/CC_CHARGE_BIND_EXTERNAL_ID`, payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createPaymentRefund(payload: IPayCreditCardRefund): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlCreditCardRefund}`, payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public countPayVwWaitingForPaymentByLogin(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayWitingForPayment}/count/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrievePayVwWaitingForPaymentByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayWitingForPayment}/by-login`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrievePaymentRefunds(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlCreditCardRefund}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findPayVwWaitingForPaymentByLabelPaymentId(labelPaymentId: string): Promise<IPayVwWaitingForPayment> {
    return new Promise<IPayVwWaitingForPayment>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayWitingForPayment}/${labelPaymentId}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryPayment(params, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryPayment}/by-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrievePayVwBillingHistoryPaymentByBuyerLogin(params, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryPayment}/by-buyer-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryIncome(params: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryIncome}/by-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryRefund(params: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryRefund}/by-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryTransaction(params: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryTransaction}/by-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryTopup(params: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryTopup}/by-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePayVwBillingHistoryWithdraw(params: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrlPayVwBillingHistoryWithdraw}/by-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  // =================================
}
