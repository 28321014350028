var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-verification-pin",
      attrs: { "data-cy": "verification-pin" },
    },
    [
      _vm.visibleHeader
        ? _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center w-100 position-relative",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 m-0 cj-color-black-primary font-weight-500 mt-2 text-center",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18",
                },
                [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cVerificationPin.otpCodeSend"
                          )
                        ),
                      },
                    },
                    [_vm._v(" OTP code has been send via pin to ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "cj-color-red-primary font-weight-600 mt-2",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.value.email
                              ? _vm.value.email
                              : _vm.value.detail && _vm.value.detail.email
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.invalidOtp
        ? _c("div", { staticClass: "d-flex w-100 mt-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "font-roboto px-3 py-1 d-flex wrap-border-status-error rounded-pill m-auto cj-color-status-error font-size-14 font-weight-300",
                attrs: { "data-cy": "system-message-wrong-otp" },
              },
              [
                _c("b-icon-x-circle", {
                  staticClass: "font-size-16 cj-color-status-error my-auto",
                  attrs: { "data-cy": "wrong-otp" },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 my-auto",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cVerificationPin.wrongOtp")
                      ),
                    },
                  },
                  [_vm._v("Wrong OTP Code")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("c-input-otp", {
        attrs: { visibleLoading: false, isUpperCase: true, refOtp: "Pin" },
        on: { input: _vm.handleInputOtp },
        model: {
          value: _vm.inputOtp,
          callback: function ($$v) {
            _vm.inputOtp = $$v
          },
          expression: "inputOtp",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between mb-2" }, [
        _c(
          "div",
          {
            staticClass: "d-flex ml-auto",
            class: {
              "cj-btn-text-primary": !_vm.showCountdown,
              "cj-color-black-primary": _vm.showCountdown,
            },
          },
          [
            _c(
              "p",
              {
                staticClass: "font-catamaran font-weight-400 my-auto",
                class: _vm.isMobile ? "font-size-14" : "font-size-18",
                attrs: {
                  "data-gtm": "resend-otp-clickable",
                  "data-cy": _vm.showCountdown
                    ? "resend-button-off"
                    : "resend-button-on",
                },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cbVerificationMethod.sendAgain")
                  ),
                },
                on: { click: _vm.resend },
              },
              [_vm._v("\n        Send Again\n      ")]
            ),
            _vm._v(" "),
            _vm.showCountdown
              ? _c("c-time-countdown", {
                  attrs: { timeMilis: 60 * 1000, stopCountdown: 0 },
                  on: {
                    action: function ($event) {
                      _vm.showCountdown = false
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ seconds }) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-catamaran font-weight-400 my-auto ml-1",
                                class: _vm.isMobile
                                  ? "font-size-14"
                                  : "font-size-18",
                              },
                              [
                                _vm._v(
                                  "\n          (" +
                                    _vm._s(seconds) +
                                    ")\n        "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    883841362
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }