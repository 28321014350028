import { Inject, Component, Prop, Mixins } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import CRegister from '@/components/auth/register/c-register.component';
import CPopupBottom from '@/components/c-popup-bottom/c-popup-bottom.vue';
import GlobalService from '@/services/global.service';
import { CbHelpCenterType } from '@/shared/model/enumerations/cb-help-center-type.model';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';
import ContentService from '@/services/content.service';
import { Flutter } from '@/app-flutter';
import { IKeycloakToken } from '@/shared/model/keycloak-token.model';
import CTmpAnnouncement from '@/components/c-tmp-announcement/c-tmp-announcement.vue';
import NavigationUtils from '@/shared/data/navigation-utils.service';
@Component({
  components: {
    Icon,
    CPopupBottom,
    CTmpAnnouncement,
  },
})
export default class PRegisterM extends Mixins(NavigationUtils, CRegister) {
  // ================= START SERVICES =================
  @Inject('globalService') private globalServiceM: () => GlobalService;
  @Inject('contentService') private contentServiceM: () => ContentService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public isShowPhoneCode = false;
  public localPhoneCode: string = null;
  @Prop()
  public closePopUp;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    this.$root.$off('registeM::changeValue');
    this.$root.$on('registeM::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });

    //(<any>this.$root).$emit('showTabFooter', false);
    (<any>this.$root).$emit('showNavMobile', false);

    this.contentServiceM().initRetrieveCbVeHelpCenters(CbHelpCenterType.TERM_OF_SERVICE);
    this.contentServiceM().initRetrieveCbVeHelpCenters(CbHelpCenterType.PRIVACY_POLICY);
    this.contentServiceM().initRetrieveCbLovs(CbLovType.PHONE_CODE);
  }

  mounted() {
    this.navigationPrevious();
    Flutter.onOAuth2Login({
      handlerLabel: 'onOAuth2',
      callback: (data: IKeycloakToken) => {
        console.log('onOAuth2 Register', JSON.stringify(data));
        this.globalServiceM().keepToken(data, true);
        this.globalServiceM().checkAccountAndLoginTypeData(this);
        window.location.reload();
        this.globalServiceM().closeGlobalDialog(this.$root, 'modal-c-login');
      },
    });
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public onBack() {
    if (this.closePopUp) {
      this.globalServiceM().closeGlobalDialog(this.$root, 'modal-p-register-m-pop-up');
    } else {
      this.$router.go(-1);
    }
  }
  public toLogin() {
    this.$router.push({ name: 'PLoginM' });
  }
  public loginFacebookM() {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginFacebookM();
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
