import { CbVwStItemPkg } from '@/shared/model/cb-vw-st-item-pkg.model';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
@Component({
  components: {
    Icon,
  },
})
export default class RoomEmojisChat extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: 'STICKER' }) public seletedShowEmojis;

  @Prop({ default: null }) public cbStickers: CbVwStItemPkg[];

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleSticker(item) {
    this.$emit('send-message-sticker', item);
  }
  public handleSeletedEmojis(item) {
    this.$emit('handleSeletedEmojis', item);
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
