import Vue2Filters from 'vue2-filters';
import AlertService from '@/shared/alert/alert.service';
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import CBreadcrumb from '@/components/c-breadcrumb/c-breadcrumb.vue';
import CButton from '@/components/button/c-button.vue';
import PSettingM from '../mobile/setting-m/settings-m.vue';
import CBankAccount from '@/components/c-bank-account/c-bank-account.vue';
import CInputPin from '@/components/c-input-pin/c-input-pin.vue';
import CAccountUpdateProfile from '@/components/c-account-update-profile/c-account-update-profile.vue';
import CAccountDeactive from '@/components/c-account-deactive/c-account-deactive.vue';
import CSetNewPassword from '@/components/c-set-new-password/c-set-new-password.vue';
import CConfirmation from '@/components/c-confirmation/c-confirmation.vue';
import CLinkedAccount from '@/components/c-linked-account/c-linked-account.vue';
import CSetPinSetting from '@/components/c-set-pin-setting/c-set-pin-setting.vue';
import GInitPage from '@/g-init-page.component';

import GlobalService from '@/services/global.service';
import SocketService from '@/admin/socket/socket.service';
import PNtUserNotifSettingService from '@/services/nt-user-notif-setting.serivce';
import PCbUserInfoService from '@/services/cb-user-info.service';
import PCbUserPinService from '@/services/cb-user-pin.service';

import { IUser, User } from '@/shared/model/user.model';
import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { CbVerificationOtp, ICbVerificationOtp } from '@/shared/model/cb-verification-otp.model';
import { INtUserNotifSetting, NtUserNotifSetting } from '@/shared/model/nt-user-notif-setting.model';
import { ICBreadcrumb } from '@/components/c-breadcrumb/c-breadcrumb.component';
import { CbNotifCodeUnique } from '@/shared/model/enumerations/cb-notif-code-unique.model';
import { CbVerificationMethodType } from '@/shared/model/enumerations/cb-verification-method-type.model';
import { SetPinType } from '@/shared/model/enumerations/set-pin-type.model';
import { NtNotifTypeWithSetting } from '@/shared/model/enumerations/nt-notif-type.model';

export enum TabSettingType {
  ACCOUNT = 'ACCOUNT',
  LINKED_ACCOUNT = 'LINKED_ACCOUNT',
  PASSWORD = 'PASSWORD',
  // takeaout notif
  // NOTIFICATION = 'NOTIFICATION',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  PIN = 'PIN',
}

export enum TabNotifType {
  INBOX_MESSAGES = 'INBOX_MESSAGES',
  ORDER_UPDATES = 'ORDER_UPDATES',
  SYSTEM_MESSAGES = 'SYSTEM_MESSAGES',
  FANS_FOLLOWING = 'FANS_FOLLOWING',
  NEW_REQUEST_OFFER = 'NEW_REQUEST_OFFER',
}

const validations: any = {
  ntUserNotifSettings: {
    $each: {
      id: {},
      notifType: {},
      isEmail: {},
      isWa: {},
      isOnPlatform: {},
      isActive: {},
      createdBy: {},
      createdDate: {},
      lastModifiedBy: {},
      lastModifiedDate: {},
    },
  },
};

@Component({
  validations,
  components: {
    Icon,
    CButton,
    CBreadcrumb,
    CInputPin,
    CConfirmation,
    CAccountUpdateProfile,
    CAccountDeactive,
    CSetNewPassword,
    PSettingM,
    CBankAccount,
    CLinkedAccount,
    CSetPinSetting,
  },
  mixins: [Vue2Filters.mixin, GInitPage],
})
export default class PSettings extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  @Inject('pCbUserInfoService') private pCbUserInfoService: () => PCbUserInfoService;
  @Inject('alertService') public alertService: () => AlertService;
  @Inject('pCbUserPinService') public pCbUserPinService: () => PCbUserPinService;
  @Inject('pNtUserNotifSettingService') public pNtUserNotifSettingService: () => PNtUserNotifSettingService;
  @Inject('socketService') private socketService: () => SocketService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public cbVerificationOtp: ICbVerificationOtp = new CbVerificationOtp();
  public cbUserInfo: ICbUserInfo = new CbUserInfo();
  public ntUserNotifSettings: INtUserNotifSetting[] = [];
  public localNtUserNotifSettings: INtUserNotifSetting[] = [];
  public cbSetPin: ICbVerificationOtp = {};
  public user: IUser = new User();

  public methodeVerification: CbVerificationMethodType = null;
  public TabSettingType = TabSettingType;
  public selectedTabSettingType = TabSettingType.ACCOUNT;
  public cbNotifCodeUnique = CbNotifCodeUnique;
  public setPinType = SetPinType.CHANGE_PIN;
  public notifTypes = Object.keys(NtNotifTypeWithSetting);

  public inActiveReason = '';
  public inActiveReasonMore = '';
  public selectIdSpOrder = 0;
  public valueLanguage = '';
  public passwordInputType = 'password';
  public passwordInputTypeOld = 'password';
  public confirmPassword = '';
  public valueLanguagePreferences: string = null;
  public error = null;
  public success = null;

  public isLowerCase = false;
  public isUpperCase = false;
  public isCharacter = false;
  public isAllValidate = false;
  public isOnlyLetterNumber = false;
  public isValidInput = false;
  public disableUpdate = false;
  public isFetching = false;
  public isOnline = false;
  public isFetchingNotifSetting = false;
  public isSavingNotifSetting = false;
  public isPostSetPin = false;
  public toggleCNotification = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    window.scrollTo(0, 0);
    this.$root.$off('changeTab');
    this.$root.$on('changeTab', value => {
      this.selectedTabSettingType = value;
    });

    (<any>this.$root).$off('setting::changeValue');
    (<any>this.$root).$on('setting::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });

    (<any>this.$root).$off('setting::changeFunction');
    (<any>this.$root).$on('setting::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
    if (this.$route.query) {
      this.initPage(this.$route.query);
    } else {
      this.initPage();
    }
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public initPage(query?: any) {
    if (
      !this.currentUser?.emailActivated &&
      (this.userAccount?.email == this.userAccount?.id || this.userAccount?.email == null || !this.userAccount?.email?.includes('@'))
    ) {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-verification-email-new');
      this.$router.go(-1);
      return;
    }

    if (query && query['tab']) {
      this.selectedTabSettingType = query['tab'];
    } else {
      this.selectedTabSettingType = TabSettingType.ACCOUNT;
    }
    this.retrieveCbUserInfoLogin();
    this.retrieveNotifSettings();
  }

  public changeVerificationKey(e) {
    this.cbVerificationOtp = e;
  }

  public handleSelectTab(type: TabSettingType) {
    this.selectedTabSettingType = type;
    // if (this.selectedTabSettingType == TabSettingType.NOTIFICATION) {
    //   this.retrieveNotifSettings();
    // }
  }

  public retrieveCbUserInfoLogin() {
    this.isFetching = true;
    this.pCbUserInfoService()
      .findByLogin()
      .then(res => {
        this.cbUserInfo = res;

        this.isFetching = false;

        const phoneValue = this.cbUserInfo.phone.split('');
        phoneValue[3] = '*';
        phoneValue[4] = '*';
        phoneValue[5] = '*';
        phoneValue[6] = '*';
        phoneValue[7] = '*';
        this.cbUserInfo.phone = phoneValue.join('');

        this.cbUserInfo.langKey = this.currentUser.langKey;
        this.valueLanguagePreferences = this.currentUser.langKey;
        this.cbUserInfo.isShowOnlineStatus = this.currentUser.isShowOnlineStatus == null ? true : this.currentUser.isShowOnlineStatus;
      })
      .catch(err => {
        this.isFetching = false;
      });
  }

  public openModalSetPin() {
    if (this.isPostSetPin) {
      return;
    }
    this.isPostSetPin = true;
    this.pCbUserPinService()
      .postSetPin()
      .then(res => {
        this.closeDialog();

        this.setPinType = SetPinType.SET_PIN;
        this.cbSetPin = res.detail;
        this.cbSetPin.setPinType = this.setPinType;
        this.cbVerificationOtp = this.cbSetPin;
        this.$root.$emit('openModalSetPin', this.cbSetPin);
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isPostSetPin = false;
      });
  }

  public openModalChangePin() {
    this.setPinType = SetPinType.CHANGE_PIN;
    (<any>this.$root).$emit('submitInputOldPin', true);
  }

  public saveBankAccount() {}

  public accountProfile(e) {
    this.isOnline = e.target.value == 'true';
  }

  public accountDeactiveReason(e) {
    this.inActiveReason = e.target.value;
  }

  public toOtpVerification() {
    this.closeDialog();
    this.cbSetPin.setPinType = SetPinType.RESET_PIN;
    this.cbSetPin.email = this.userAccount.email;
    this.$root.$emit('openModalSetPin', this.cbSetPin);
  }

  public accountDeactiveReasonMore(e) {
    this.inActiveReasonMore = e.target.value;
  }

  public closeDialog() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-edit-pin');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-reset-pin-init');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-change-password-init');
  }

  public accountLanguage(e) {
    this.valueLanguagePreferences = e.target.value;
  }

  public modalDeactiveAccount() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-deactive-account');
  }

  public saveDeactiveAccount() {
    const newEntity: ICbUserInfo = {
      inActiveReason: this.inActiveReason,
      inActiveReasonMore: this.inActiveReasonMore,
      inActiveDate: new Date(),
    };
    this.pCbUserInfoService()
      .partialUpdateByLogin(newEntity)
      .then(() => {
        (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.toast.success').toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        });
        this.retrieveCbUserInfoLogin();
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public saveOnlineLanguage() {
    const newEntity: ICbUserInfo = {
      isShowOnlineStatus: this.isOnline,
      langKey: this.cbUserInfo.langKey,
    };

    this.pCbUserInfoService()
      .partialUpdateByLogin(newEntity)
      .then(() => {
        (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.toast.success').toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        });
        const cbVwUser = this.currentUser;
        cbVwUser.isShowOnlineStatus = newEntity.isShowOnlineStatus;
        cbVwUser.langKey = newEntity.langKey;

        this.$store.commit('currentUser', cbVwUser);
        this.socketService().sendUserOnline();
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public async submitNotification() {
    this.isSavingNotifSetting = true;
    for (const ntUserNotifSetting of this.ntUserNotifSettings) {
      if (ntUserNotifSetting.id) {
        await this.pNtUserNotifSettingService()
          .partialUpdate(ntUserNotifSetting)
          .then(res => {})
          .catch(error => {});
      } else {
        await this.pNtUserNotifSettingService()
          .createdByLogin(ntUserNotifSetting)
          .then(res => {})
          .catch(error => {});
      }
    }
    this.toggleCNotification = false;
    const message = 'Notification updated successfully';
    (this.$root as any).$bvToast.toast(message.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Success',
      variant: 'success',
      solid: true,
      autoHideDelay: 5000,
    });
    this.retrieveNotifSettings();
    this.isSavingNotifSetting = false;
  }

  public retrieveNotifSettings() {
    this.ntUserNotifSettings = [];
    this.localNtUserNotifSettings = [];
    this.isFetchingNotifSetting = true;

    this.pNtUserNotifSettingService()
      .retirveByLogin()
      .then(res => {
        Object.values(NtNotifTypeWithSetting).forEach(notifType => {
          const ntUserNotifSettingFind = res.data.find(data => data.notifType == notifType);
          if (ntUserNotifSettingFind) {
            const idx = this.ntUserNotifSettings.findIndex(v => v.notifType == ntUserNotifSettingFind.nnotifType);
            if (idx == -1) {
              this.ntUserNotifSettings.push(ntUserNotifSettingFind);
            }
          } else {
            const ntUserNotifSetting: INtUserNotifSetting = {
              notifType: notifType,
              isEmail: true,
              isWa: true,
              isOnPlatform: true,
              isActive: true,
            };
            this.ntUserNotifSettings.push(ntUserNotifSetting);
          }
        });

        for (const notifSetting of this.ntUserNotifSettings) {
          const ntUserNotifSetting: INtUserNotifSetting = new NtUserNotifSetting();
          Object.assign(ntUserNotifSetting, notifSetting);

          ntUserNotifSetting.unixId = new Date().getTime();

          this.localNtUserNotifSettings.push(ntUserNotifSetting);
        }

        this.isFetchingNotifSetting = false;
      })
      .catch(error => {});
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): any {
    return this.$store.getters.currentUser;
  }
  public get userAccount(): User {
    return this.$store.getters.account;
  }
  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  public get breadcrumbs(): ICBreadcrumb[] {
    return [
      {
        text: this.$t('cbgwApp.cbBreadcrumb.home'),
        active: false,
        href: { name: 'PHome' },
      },
      {
        text: this.$t('cbgwApp.cbBreadcrumb.setting'),
        active: true,
      },
    ];
  }
  // ================= END COMPUTE ====================
  @Watch('isMobile')
  public isMobileListener() {
    if (this.isMobile) {
      if (this.selectedTabSettingType === TabSettingType.PIN) {
        this.$router.push({ name: 'PPinM' });
      } else if (this.selectedTabSettingType === TabSettingType.BANK_ACCOUNT) {
        this.$router.push({ name: 'PSettingAccountMBank' });
      } else if (this.selectedTabSettingType === TabSettingType.PASSWORD) {
        this.$router.push({ name: 'PSettingAccountMPassword' });
      } else if (this.selectedTabSettingType === TabSettingType.LINKED_ACCOUNT) {
        this.$router.push({ name: 'PSettingAccountMLinked' });
      } else if (this.selectedTabSettingType === TabSettingType.ACCOUNT) {
        this.$router.push({ name: 'PSettingAccountM' });
      }
    }
  }
  // ================= END COMPUTE ====================
}
