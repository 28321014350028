import { Component, Vue, Emit, Prop, Watch, Inject } from 'vue-property-decorator';

import GlobalService from '@/services/global.service';
import PCbOidService from '@/services/cb-oid.service';

import CInputOtp from '@/components/c-input-otp/c-input-otp.vue';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';

@Component({
  components: {
    CInputOtp,
    CTimeCountdown,
  },
})
export default class CVerificationPhoneNumber extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbOidService') public pCbOidService: () => PCbOidService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: '' }) public valueNewPhoneNumber: string;
  @Prop({ default: false }) public errorSubmitOtp: boolean;
  @Prop({ default: null }) public value: any;
  @Prop({ default: false, type: Boolean }) public visibleCountdown: boolean;
  @Prop({ default: false, type: Boolean }) public visibleFooter: boolean;
  @Prop({ default: false, type: Boolean }) public visibleHeader: boolean;

  public visibleLoading = false;
  public showCountdown = false;
  public invalidOtp = false;
  public timeCoundown = 3000;
  public timeMinutesOtp = 3;
  public countRequestOtp = 0;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }

  @Emit('inputOtp')
  public inputOtp(e) {
    return e;
  }

  public created() {
    this.visibleCountdownListener();
  }

  public resend() {
    this.$root.$emit('AccountUpdate::resendOtp');
    this.timeCoundown = 3000;
    this.timeMinutesOtp = 3;
    this.countRequestOtp = 0;
    this.showCountdown = true;
    this.visibleCountdownListener();
  }

  public changePhoneNumber() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-phone-verification');
    this.globalService().openGlobalDialog(this.$root, 'modal-c-new-phone-number');

    this.$emit('actionHandler');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isPhoneTrouble(): boolean {
    return this.$store.getters.isPhoneTrouble;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('visibleCountdown')
  public visibleCountdownListener() {
    if (this.visibleCountdown) {
      this.showCountdown = true;
    }
  }
  // ================= END LISTENERS ==================
}
