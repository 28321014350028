import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { Subscription } from 'rxjs';

import SocketLiveAgentService from './admin/socket/socket-live-agent.service';
import SocketService, {
  DESTINATION_BLOCKED_AND_SUSPEND,
  DESTINATION_CONVERSATION_ID,
  DESTINATION_CONVERSATION_MEMBER,
  DESTINATION_LAST_SEEN,
  DESTINATION_NOTIF_USER_LOGIN,
  DESTINATION_ORDER_TRACK_LOCATION,
  DESTINATION_PAW_ORDER_PAID,
  DESTINATION_TKT_REPLIED,
  DESTINATION_USER_ONLINE,
} from './admin/socket/socket.service';
import AccountService from '@/account/account.service';

import { CbVwPawOrder } from './shared/model/cb-vw-paw-order.model';
import { ICbVwUser } from './shared/model/cb-vw-user.model';
import { SocketTopic } from './shared/model/enumerations/socket-topic.model';
import { ISocketMessage } from './shared/model/socket-message.model';
import { RxStompState } from '@stomp/rx-stomp';

interface SubscriptionInfo {
  key: string;
  params?: any[];
  callback?: Function;
}

@Component({
  components: {},
})
export default class AppSocket extends Vue {
  @Inject('socketService') private socketService: () => SocketService;
  @Inject('socketLiveAgentService') private socketLiveAgentService: () => SocketLiveAgentService;
  @Inject('accountService') private accountService: () => AccountService;

  private subscriptionNotif?: Subscription;
  private subscriptionConversationBasedOnMember?: Subscription;
  private subscriptionConversationId?: Subscription;
  private subscribeUserOnline?: Subscription;
  private subscriptionLiveAgentConversationId?: Subscription;
  private subscribePawOrderPaid?: Subscription;
  private subscribeCbOrderTrackLocation?: Subscription;
  private subscribeBlockedAndSuspend?: Subscription;
  private subscribeTktReplied?: Subscription;
  private subscribeMsgLastSeen?: Subscription;
  private subscribeLiveAgentMsgLastSeen?: Subscription;

  public created() {
    this.initPrototypeUserModule();
    this.initPrototypeTransactionalModule();
    this.initPrototypeLiveAgentModule();
    this.initPrototypeTicketingModule();

    this.initConnectSocketAndWatch();
  }

  public initConnectSocketAndWatch() {
    this.$store.watch(
      () => this.$store.getters.authenticated,
      () => {
        if (this.$store.getters.authenticated) {
          this.initSocket();
        } else {
          this.unsubscribeAfterLogout();
        }
      }
    );

    if (this.$store.getters?.account?.id) {
      this.initSocket();
    }
  }

  public initSocket(): void {
    this.socketService().isSessionLive();
    this.unsubscribeAfterLogout();
    this.socketService().connectCustome();
    this.socketService().stomp.connected$.subscribe(state => {
      this.$root.$emit('chat::reBroadcastMessageAfterOnPause');

      if (state === RxStompState.OPEN) {
        // Re-subscribe to previous topics
        const previousSubscriptions = [...this.previousSubscriptions];
        previousSubscriptions.forEach(subscription => {
          this.reSubscribe(subscription);
        });
      }
    });

    // default subscribe
    this.$unsubscriptionNotif();
    this.$subscriptionNotif();
    this.$unsubscribeConversationBasedOnMember();
    this.$subscribeConversationBasedOnMember();
    this.$unsubscribeUserOnline();
    this.$subscribeUserOnline();
    this.$unsubscribeBlockedAndSuspend();
    this.$subscribeBlockedAndSuspend();
    this.$unsubscribeTktReplied();
    this.$subscribeTktReplied();
  }

  public unsubscribeAfterLogout(): void {
    this.$unsubscriptionNotif();
    this.$unsubscribeConversationBasedOnMember();
    this.$unsubscribeUserOnline();
    this.$unsubscribePawOrderPaid();
    this.$unSubscribeCbOrderTrackLocation();
    this.$unsubscribeBlockedAndSuspend();
    this.$unsubscribeTktReplied();
  }

  public reSubscribe(info: SubscriptionInfo): void {
    console.log('app-socket: running callback ', info);

    switch (info.key) {
      case DESTINATION_CONVERSATION_ID:
        this.$unsubscribeConversationId();
        this.$subscribeConversationId(info.params[0]);
        break;
      case DESTINATION_LAST_SEEN:
        this.$unsubscribeMsgLastSeen();
        this.$subscribeMsgLastSeen(info.params[0]);
        break;
      case DESTINATION_PAW_ORDER_PAID:
        this.$unsubscribePawOrderPaid();
        this.$subscribePawOrderPaid();
        break;
      case DESTINATION_ORDER_TRACK_LOCATION:
        this.$unSubscribeCbOrderTrackLocation();
        this.$subscribeCbOrderTrackLocation(info.params[0], info.callback);
        break;
      case DESTINATION_NOTIF_USER_LOGIN:
        this.$unsubscriptionNotif();
        this.$subscriptionNotif();
        break;
      case DESTINATION_CONVERSATION_MEMBER:
        this.$unsubscribeConversationBasedOnMember();
        this.$subscribeConversationBasedOnMember();
        break;
      case DESTINATION_USER_ONLINE:
        this.$unsubscribeUserOnline();
        this.$subscribeUserOnline();
        break;
      case DESTINATION_BLOCKED_AND_SUSPEND:
        this.$unsubscribeBlockedAndSuspend();
        this.$subscribeBlockedAndSuspend();
        break;
      case DESTINATION_TKT_REPLIED:
        this.$unsubscribeTktReplied();
        this.$subscribeTktReplied();
        break;
      default:
        console.log('app-socket: running not handle callbackSubscriptions');
        break;
    }
  }

  public initPrototypeUserModule() {
    Vue.prototype.$unsubscribeUserOnline = () => {
      if (this.subscribeUserOnline) {
        this.subscribeUserOnline.unsubscribe();
        this.subscribeUserOnline = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_USER_ONLINE);
    };

    Vue.prototype.$subscribeUserOnline = () => {
      this.subscribeUserOnline = this.socketService().subscribeUserOnline((msg: ISocketMessage) => {
        const userIdsOnline = this.$store.getters.userIdsOnline;
        const foundIdx = userIdsOnline.findIndex(x => x === msg.actionBy);

        if (foundIdx > -1 && msg.isOnline) {
          //found and online
        } else if (foundIdx > -1 && !msg.isOnline) {
          //found and online
          userIdsOnline.splice(foundIdx, 1);
        } else if (foundIdx <= -1 && msg.isOnline) {
          userIdsOnline.unshift(msg.actionBy);
        }

        this.$store.commit('userIdsOnline', userIdsOnline);
      });

      this.addPreviousSubscriptions(DESTINATION_USER_ONLINE);
    };

    Vue.prototype.$unsubscribeBlockedAndSuspend = () => {
      if (this.subscribeBlockedAndSuspend) {
        this.subscribeBlockedAndSuspend.unsubscribe;
        this.subscribeBlockedAndSuspend = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_BLOCKED_AND_SUSPEND);
    };

    Vue.prototype.$subscribeBlockedAndSuspend = () => {
      this.subscribeBlockedAndSuspend = this.socketService().subscribeBlockedAndSuspend((msg: ISocketMessage) => {
        if (msg.topic == SocketTopic.CB_SUSPEND_AND_BLOCKED) {
          setTimeout(() => {
            this.accountService().logout();
          }, 7000);
        }
      });

      this.addPreviousSubscriptions(DESTINATION_BLOCKED_AND_SUSPEND);
    };
  }

  public initPrototypeTransactionalModule() {
    Vue.prototype.$unsubscriptionNotif = () => {
      if (this.subscriptionNotif) {
        this.subscriptionNotif.unsubscribe();
        this.subscriptionNotif = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_NOTIF_USER_LOGIN);
    };

    Vue.prototype.$subscriptionNotif = () => {
      this.subscriptionNotif = this.socketService().subscribeNotif((msg: ISocketMessage) => {
        const h = this.$createElement;
        const htmlToVnode = h('p', { domProps: { innerHTML: msg.body } });
        let variant = 'info';

        if (
          msg.title.toLowerCase().includes('late order') ||
          msg.title.toLowerCase().includes('order overdue') ||
          msg.title.toLowerCase().includes('order cancelled') ||
          msg.title.toLowerCase().includes('re-upload needed for paw application') ||
          msg.title.toLowerCase().includes('order terlambat') ||
          msg.title.toLowerCase().includes('order dibatalkan') ||
          msg.title.toLowerCase().includes('unggah ulang diperlukan untuk verifikasi paw')
        ) {
          variant = 'warning';
        }

        (<any>this.$root).$bvToast.toast(msg.title ? msg.title : htmlToVnode, {
          toaster: 'b-toaster-top-center',
          title: msg.title,
          variant,
          solid: true,
          autoHideDelay: 5000,
        });
        (<any>this.$root).$emit('onNotifRefreshList', msg);
        this.$root.$emit('appnotif::retrieveNotifCount');
      });

      this.addPreviousSubscriptions(DESTINATION_NOTIF_USER_LOGIN);
    };

    Vue.prototype.$unsubscribeConversationBasedOnMember = () => {
      if (this.subscriptionConversationBasedOnMember) {
        this.subscriptionConversationBasedOnMember.unsubscribe();
        this.subscriptionConversationBasedOnMember = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_CONVERSATION_MEMBER);
    };

    Vue.prototype.$subscribeConversationBasedOnMember = () => {
      this.subscriptionConversationBasedOnMember = this.socketService().subscribeConversationBasedOnMember((msg: ISocketMessage) => {
        (<any>this.$root).$emit('onNotifConversationRefreshList', msg);
        this.$root.$emit('chat::listenConversation', msg);
        this.$root.$emit('appnotif::retrieveChtConversationCount');
      });

      this.addPreviousSubscriptions(DESTINATION_CONVERSATION_MEMBER);
    };

    Vue.prototype.$unsubscribeConversationId = () => {
      if (this.subscriptionConversationId) {
        this.subscriptionConversationId.unsubscribe();
        this.subscriptionConversationId = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_CONVERSATION_ID);
    };
    Vue.prototype.$subscribeConversationId = (conversationId: number) => {
      this.subscriptionConversationId = this.socketService().subscribeConversationId((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveOneMessage', msg.conversationId, msg.messageId);
      }, conversationId);

      this.addPreviousSubscriptions(DESTINATION_CONVERSATION_ID, [conversationId]);
    };

    Vue.prototype.$unsubscribeMsgLastSeen = () => {
      if (this.subscribeMsgLastSeen) {
        this.subscribeMsgLastSeen.unsubscribe();
        this.subscribeMsgLastSeen = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_LAST_SEEN);
    };

    Vue.prototype.$subscribeMsgLastSeen = (conversationId: number) => {
      this.subscribeMsgLastSeen = this.socketService().subscribeLastSeen((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveLastSeen', msg.conversationId, msg.messageId);
      }, conversationId);
      this.addPreviousSubscriptions(DESTINATION_LAST_SEEN, [conversationId]);
    };

    Vue.prototype.$unsubscribePawOrderPaid = () => {
      if (this.subscribePawOrderPaid) {
        this.subscribePawOrderPaid.unsubscribe();
        this.subscribePawOrderPaid = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_PAW_ORDER_PAID);
    };
    Vue.prototype.$subscribePawOrderPaid = () => {
      this.subscribePawOrderPaid = this.socketService().subscribePawOrderPaid((msg: ISocketMessage) => {
        if (msg.externalEntity && msg.externalId) {
          this.$root.$emit('opc::receiveUpdateFromSocket', msg);
          this.$root.$emit('do::receiveUpdateFromSocket', msg);
          this.$root.$emit('dashboardActiveOrder::receiveUpdateFromSocket', msg);
        }
      });
      this.addPreviousSubscriptions(DESTINATION_PAW_ORDER_PAID);
    };

    Vue.prototype.$unSubscribeCbOrderTrackLocation = () => {
      if (this.subscribeCbOrderTrackLocation) {
        this.subscribeCbOrderTrackLocation.unsubscribe();
        this.subscribeCbOrderTrackLocation = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_ORDER_TRACK_LOCATION);
    };
    Vue.prototype.$subscribeCbOrderTrackLocation = (orderId: number, callback: (msg: ISocketMessage) => void) => {
      this.subscribeCbOrderTrackLocation = this.socketService().subscribeCbOrderTrackLocation(
        (msg: ISocketMessage) => callback(msg),
        orderId
      );
      this.addPreviousSubscriptions(DESTINATION_ORDER_TRACK_LOCATION, [orderId], callback);
    };
  }

  public initPrototypeLiveAgentModule() {
    // live agent
    Vue.prototype.$socketUnsubscribeLiveAgentConversationId = () => {
      if (this.subscriptionLiveAgentConversationId) {
        this.subscriptionLiveAgentConversationId.unsubscribe();
        this.subscriptionLiveAgentConversationId = undefined;
      }
    };
    Vue.prototype.$socketSubscribeLiveAgentConversationId = (conversationId: number) => {
      this.subscriptionLiveAgentConversationId = this.socketLiveAgentService().subscribeConversationId((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveOneMessageLa', msg.conversationId, msg.messageId);
      }, conversationId);
    };

    Vue.prototype.$socketUnsubscribeLiveAgentMsgLastSeen = () => {
      if (this.subscribeLiveAgentMsgLastSeen) {
        this.subscribeLiveAgentMsgLastSeen.unsubscribe();
        this.subscribeLiveAgentMsgLastSeen = undefined;
      }
    };

    Vue.prototype.$socketSubscribeLiveAgentMsgLastSeen = (conversationId: number) => {
      this.subscribeLiveAgentMsgLastSeen = this.socketLiveAgentService().subscribeLastSeen((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveOneMessageLaLastSeen', msg.conversationId, msg);
      }, conversationId);
    };

    Vue.prototype.$socketInitSocketLiveAgent = () => {
      this.$socketUnsubscribeLiveAgentConversationId();
      this.$socketUnsubscribeLiveAgentMsgLastSeen();
      this.socketLiveAgentService().connect();
    };
  }

  public initPrototypeTicketingModule() {
    Vue.prototype.$unsubscribeTktReplied = () => {
      if (this.subscribeTktReplied) {
        this.subscribeTktReplied.unsubscribe;
        this.subscribeTktReplied = undefined;
      }
      this.removePreviousSubscriptions(DESTINATION_TKT_REPLIED);
    };
    Vue.prototype.$subscribeTktReplied = () => {
      this.subscribeTktReplied = this.socketService().subscribeTktReplied((msg: ISocketMessage) => {
        if (msg.topic == SocketTopic.CB_TKT_REPLIED) {
          console.log('msg.topic', msg.topic);
          (<any>this.$root).$emit('rt::changeFunction', 'findCmtKt', +msg.externalId);
        }
      });
      this.addPreviousSubscriptions(DESTINATION_TKT_REPLIED);
    };
  }

  public removePreviousSubscriptions(key: string) {
    const previousSubscriptions = [...this.previousSubscriptions];

    const index = previousSubscriptions.findIndex(info => info.key === key);
    if (index !== -1) {
      previousSubscriptions.splice(index, 1);
    }
    this.$store.commit('previousSubscriptions', previousSubscriptions);
  }

  public addPreviousSubscriptions(key: string, params?: any[], callback?: Function) {
    const previousSubscriptions = [...this.previousSubscriptions];
    const subscriptionInfo: SubscriptionInfo = {
      key,
      params: params,
      callback: callback,
    };
    const existingIndex = previousSubscriptions.findIndex(info => info.key === key);
    if (existingIndex !== -1) {
      previousSubscriptions[existingIndex] = subscriptionInfo;
    } else {
      previousSubscriptions.push(subscriptionInfo);
    }
    this.$store.commit('previousSubscriptions', previousSubscriptions);
  }
  public get previousSubscriptions() {
    return this.$store.getters.previousSubscriptions ? this.$store.getters.previousSubscriptions : [];
  }
}
