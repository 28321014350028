var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-item-notification d-flex w-100 py-2 px-3",
      attrs: {
        "data-gtm": "notification-item-clickable",
        "data-cy": "list-notification",
      },
      on: {
        click: function ($event) {
          return _vm.updateRead(_vm.ntUserNotif)
        },
      },
    },
    [
      _vm.tab == _vm.enumTab.MESSAGE
        ? _c(
            "div",
            { staticClass: "mr-3" },
            [
              _c("c-user-photo-online", {
                attrs: {
                  width: "3.4",
                  url: _vm.$options.filters.LAZY(
                    `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                      _vm.currentUser ? _vm.currentUser.photoId : ""
                    }`,
                    55,
                    "ic-lazy-avatar.png"
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tab == _vm.enumTab.NOTIFICATION
        ? _c("div", { staticClass: "rounded-circle mr-3" }, [
            _c("div", [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.ntUserNotif.isRead,
                    expression: "!ntUserNotif.isRead",
                  },
                ],
                staticClass: "notif-unread rounded-circle mr-1",
                attrs: { "data-cy": "notif-unread" },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "c-user-photo-notification rounded-circle position-relative",
                  staticStyle: { width: "3.375rem", height: "3.375rem" },
                },
                [
                  _c("img", {
                    staticClass: "rounded-circle box-shadow-img",
                    staticStyle: { width: "3.375rem", height: "3.375rem" },
                    attrs: {
                      src: _vm.$options.filters.LAZY(
                        _vm.ntUserNotif.iconLabel == "PARTNER_PROFILE_PICTURE"
                          ? `${
                              _vm.$env.CDN_CB
                            }/CbAttachmentNoAuth/${_vm.formatPhotoId(
                              _vm.ntUserNotif.senderId
                            )}`
                          : `${_vm.$options.filters.ICON_LABEL_URL_BY_KEY(
                              _vm.CB_ICON_LABEL_URL,
                              _vm.ntUserNotif.iconLabel
                            )}`,
                        54,
                        "ic-lazy-avatar.png"
                      ),
                      onerror:
                        _vm.$options.filters.LAZY_ERROR("ic-lazy-avatar.png"),
                      "data-cy": "notif-item-icon",
                      alt: "card",
                    },
                  }),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-block my-auto w-100" }, [
        _vm.tab == _vm.enumTab.MESSAGE
          ? _c("div", { staticClass: "d-flex w-100 justify-content-between" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary font-weight-500 font-size-16 text-wrap text-ellipsis oneline",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("sliceCharacter")(
                          _vm.cbVwUser && _vm.cbVwUser.displayName
                            ? _vm.cbVwUser.displayName
                            : _vm.cbVwUser.username,
                          16
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-grey-secondary font-weight-300 font-size-10",
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("time24")(_vm.chtVwConversation.lastMsgDate))
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.tab == _vm.enumTab.NOTIFICATION
          ? _c("div", { staticClass: "d-flex w-100 justify-content-between" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary text-left font-weight-500 font-size-16 text-wrap",
                  staticStyle: { "word-break": "break-all" },
                  attrs: { "data-cy": "title-notification-m" },
                },
                [_vm._v("\n        " + _vm._s(_vm.notifTitle) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-grey-secondary font-weight-300 font-size-10 mt-1 text-right",
                  attrs: { "data-cy": "notification-date" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("formatDateTimeDefault")(
                          _vm.ntUserNotif.createdDate
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.tab == _vm.enumTab.MESSAGE
          ? _c("div", { staticClass: "d-flex w-100 justify-content-between" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary font-weight-300 font-size-12 text-wrap text-ellipsis oneline",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.chtVwConversation.lastMsg) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-auto" }, [
                _vm.getCountMsgs > 0
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "bg-ic-circle cj-bg-status-warning cj-color-white-primary font-roboto font-weight-700 font-size-10",
                        class: _vm.getCountMsgs > 99 ? "actived-max-count" : "",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("maxCount")(
                                _vm.getCountMsgs,
                                _vm.getCountMsgs
                              )
                            )
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.tab == _vm.enumTab.NOTIFICATION
          ? _c("div", { staticClass: "w-100" }, [
              _c("p", {
                staticClass:
                  "font-roboto cj-color-black-primary font-weight-300 font-size-14 text-break-all",
                attrs: {
                  "data-gtm": "notification-link-clickable",
                  "data-cy": "desc-notification-m",
                },
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.SECURITY_VHTML_AND_NOTIF_REPLACE_BASE_URL(
                      _vm.notifContent,
                      _vm.notifReplaceBaseUrl
                    )
                  ),
                },
                on: { click: _vm.handleClick },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.ntUserNotif.isRead,
                        expression: "!ntUserNotif.isRead",
                      },
                    ],
                    staticClass: "btn-close ml-auto",
                    attrs: {
                      type: "button",
                      "data-gtm": "read-all-notification-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.updateRead(_vm.ntUserNotif)
                      },
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "cj-color-red-primary font-roboto font-weight-300 font-size-10",
                      },
                      [_vm._v("Read")]
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }