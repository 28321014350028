var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "message",
      staticClass: "vac-message-wrapper",
      attrs: { id: _vm.message._id, "data-cy": "room-message" },
    },
    [
      _vm.newMessage._id === _vm.message._id
        ? _c(
            "div",
            {
              staticClass: "vac-line-new",
              domProps: { textContent: _vm._s(_vm.$t("chat.newMessage")) },
            },
            [_vm._v("\n    " + _vm._s(_vm.textMessages.NEW_MESSAGES) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.message.system
        ? _c(
            "div",
            { staticClass: "vac-card-info vac-card-system" },
            [
              _vm._t(
                "system-message",
                function () {
                  return [
                    _c("format-message", {
                      attrs: {
                        content: _vm.message.content,
                        "sticker-url": _vm.message.stickerUrl,
                        users: _vm.roomUsers,
                        "text-formatting": _vm.textFormatting,
                        "link-options": _vm.linkOptions,
                      },
                      on: { "open-user-tag": _vm.openUserTag },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(_vm.$scopedSlots, function (i, name) {
                            return {
                              key: name,
                              fn: function (data) {
                                return [_vm._t(name, null, null, data)]
                              },
                            }
                          }),
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
                null,
                { message: _vm.message }
              ),
            ],
            2
          )
        : _c(
            "div",
            {
              staticClass: "vac-message-box",
              class: {
                "vac-offset-current":
                  _vm.message.senderId === _vm.currentUserId,
              },
              on: { click: _vm.selectMessage },
            },
            [
              _vm._t(
                "message",
                function () {
                  return [
                    _vm.message.avatar &&
                    _vm.message.senderId !== _vm.currentUserId
                      ? _c("div", {
                          staticClass: "vac-avatar",
                          style: {
                            "background-image": `url('${_vm.message.avatar}')`,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasSenderUserAvatar && !_vm.message.avatar
                      ? _c("div", { staticClass: "vac-avatar-offset" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "vac-message-container",
                        class: {
                          "vac-message-container-offset": _vm.messageOffset,
                          "w-100":
                            _vm.message.cbVwPawOrderRequirement ||
                            _vm.message.cbVwPawOrder ||
                            _vm.message.cbVwAuctionProjectPreOffer ||
                            _vm.message.cbPawPreOrder ||
                            _vm.message.cbVwPaw ||
                            _vm.message.cbTextRestrict,
                          "d-none":
                            _vm.message.chtContentType == "LIVE_AGENT_FEEDBACK",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "vac-message-card",
                            class: {
                              "vac-message-highlight": _vm.isMessageHover,
                              "vac-message-current":
                                _vm.message.senderId === _vm.currentUserId,
                              "vac-message-deleted": _vm.message.deleted,
                              "vac-message-deleted-current":
                                _vm.message.deleted &&
                                _vm.message.senderId === _vm.currentUserId,
                              "vas-clickable": _vm.messageSelectionEnabled,
                              "vac-message-selected": _vm.isMessageSelected,
                              "vac-message-sticker":
                                _vm.message.stickerUrl &&
                                !_vm.message.messageRefId,
                              "vac-message-current-stiker":
                                _vm.message.stickerUrl &&
                                _vm.message.senderId === _vm.currentUserId &&
                                !_vm.message.messageRefId,
                              "vac-message-audio": _vm.isAudio,
                              "vac-message-audio-current":
                                _vm.isAudio &&
                                _vm.message.senderId === _vm.currentUserId,
                              "vac-icon-translate-msg":
                                _vm.message.content &&
                                _vm.message.senderId !== _vm.currentUserId,
                            },
                            attrs: { "data-cy": "vac-message-card" },
                            on: {
                              mouseover: _vm.onHoverMessage,
                              mouseleave: _vm.onLeaveMessage,
                            },
                          },
                          [
                            _vm.showUsername
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vac-text-username",
                                    class: {
                                      "vac-username-reply":
                                        !_vm.message.deleted &&
                                        _vm.message.replyMessage,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.message.username)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.message.deleted && _vm.message.replyMessage
                              ? _c("message-reply", {
                                  attrs: {
                                    message: _vm.message,
                                    "room-users": _vm.roomUsers,
                                    "text-formatting": _vm.textFormatting,
                                    "link-options": _vm.linkOptions,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "searchMsgResultSelected",
                                        _vm.message.replyMessage
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(
                                        _vm.$scopedSlots,
                                        function (i, name) {
                                          return {
                                            key: name,
                                            fn: function (data) {
                                              return [
                                                _vm._t(name, null, null, data),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.message.deleted &&
                            _vm.message.chtContentType != "LIVE_AGENT_FEEDBACK"
                              ? _c("items-chat-message", {
                                  ref: _vm.CONST_MESSAGE_CARD + _vm.message.id,
                                  attrs: {
                                    id: _vm.CONST_MESSAGE_CARD + _vm.message.id,
                                    isYourSelf:
                                      _vm.message.senderId ===
                                      _vm.currentUserId,
                                    messageChtContentType:
                                      _vm.message.chtContentType,
                                    cbVwPawOrderRequirement:
                                      _vm.isShowMsgTranslate &&
                                      _vm.message.messageTranslated &&
                                      _vm.message.messageTranslated
                                        .cbVwPawOrderRequirement
                                        ? _vm.message.messageTranslated
                                            .cbVwPawOrderRequirement
                                        : _vm.message.cbVwPawOrderRequirement,
                                    cbVwPawOrder:
                                      _vm.isShowMsgTranslate &&
                                      _vm.message.messageTranslated &&
                                      _vm.message.messageTranslated.cbVwPawOrder
                                        ? _vm.message.messageTranslated
                                            .cbVwPawOrder
                                        : _vm.message.cbVwPawOrder,
                                    cbVwAuctionProjectPreOffer:
                                      _vm.isShowMsgTranslate &&
                                      _vm.message.messageTranslated &&
                                      _vm.message.messageTranslated
                                        .cbVwAuctionProjectPreOffer
                                        ? _vm.message.messageTranslated
                                            .cbVwAuctionProjectPreOffer
                                        : _vm.message
                                            .cbVwAuctionProjectPreOffer,
                                    cbPawPreOrder:
                                      _vm.isShowMsgTranslate &&
                                      _vm.message.messageTranslated &&
                                      _vm.message.messageTranslated
                                        .cbPawPreOrder
                                        ? _vm.message.messageTranslated
                                            .cbPawPreOrder
                                        : _vm.message.cbPawPreOrder,
                                    cbVwPaw:
                                      _vm.isShowMsgTranslate &&
                                      _vm.message.messageTranslated &&
                                      _vm.message.messageTranslated.paw
                                        ? _vm.message.messageTranslated.paw
                                        : _vm.message.cbVwPaw,
                                    cbTextRestrict:
                                      _vm.isShowMsgTranslate &&
                                      _vm.message.messageTranslated &&
                                      _vm.message.messageTranslated
                                        .cbTextRestrict
                                        ? _vm.message.messageTranslated
                                            .cbTextRestrict
                                        : _vm.message.cbTextRestrict,
                                    idx: _vm.index,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.message.deleted
                              ? _c(
                                  "div",
                                  [
                                    _vm._t("deleted-icon", function () {
                                      return [
                                        _c("svg-icon", {
                                          staticClass: "vac-icon-deleted",
                                          attrs: { name: "deleted" },
                                        }),
                                      ]
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.textMessages.MESSAGE_DELETED)
                                      ),
                                    ]),
                                  ],
                                  2
                                )
                              : !_vm.message.files || !_vm.message.files.length
                              ? _c("format-message", {
                                  attrs: {
                                    content: _vm.message.content,
                                    "sticker-url": _vm.message.stickerUrl,
                                    users: _vm.roomUsers,
                                    "text-formatting": _vm.textFormatting,
                                    "link-options": _vm.linkOptions,
                                  },
                                  on: { "open-user-tag": _vm.openUserTag },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(
                                        _vm.$scopedSlots,
                                        function (i, name) {
                                          return {
                                            key: name,
                                            fn: function (data) {
                                              return [
                                                _vm._t(name, null, null, data),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : !_vm.isAudio || _vm.message.files.length > 1
                              ? _c("message-files", {
                                  attrs: {
                                    "current-user-id": _vm.currentUserId,
                                    message: _vm.message,
                                    "room-users": _vm.roomUsers,
                                    "text-formatting": _vm.textFormatting,
                                    "link-options": _vm.linkOptions,
                                    "message-selection-enabled":
                                      _vm.messageSelectionEnabled,
                                    isYourSelf:
                                      _vm.message.senderId ===
                                      _vm.currentUserId,
                                  },
                                  on: {
                                    "open-file": _vm.openFile,
                                    "open-user-tag": _vm.openUserTag,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(
                                        _vm.$scopedSlots,
                                        function (i, name) {
                                          return {
                                            key: name,
                                            fn: function (data) {
                                              return [
                                                _vm._t(name, null, null, data),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : [
                                  _c("audio-player", {
                                    attrs: {
                                      "message-id": _vm.message._id,
                                      src: _vm.message.files[0].url,
                                      "message-selection-enabled":
                                        _vm.messageSelectionEnabled,
                                    },
                                    on: {
                                      "update-progress-time": function (
                                        $event
                                      ) {
                                        _vm.progressTime = $event
                                      },
                                      "hover-audio-progress": function (
                                        $event
                                      ) {
                                        _vm.hoverAudioProgress = $event
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        _vm._l(
                                          _vm.$scopedSlots,
                                          function (i, name) {
                                            return {
                                              key: name,
                                              fn: function (data) {
                                                return [
                                                  _vm._t(
                                                    name,
                                                    null,
                                                    null,
                                                    data
                                                  ),
                                                ]
                                              },
                                            }
                                          }
                                        ),
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "vac-text-timestamp" },
                              [
                                !_vm.message.deleted
                                  ? [
                                      _c("message-reactions-chat", {
                                        attrs: {
                                          reactions:
                                            _vm.message.reactionsTransient,
                                        },
                                        on: {
                                          deleteMessageReaction: function (
                                            $event
                                          ) {
                                            return _vm.$emit(
                                              "deleteMessageReaction",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.message.edited && !_vm.message.deleted
                                  ? _c(
                                      "div",
                                      { staticClass: "vac-icon-edited ml-1" },
                                      [
                                        _vm._t("pencil-icon", function () {
                                          return [
                                            _c("svg-icon", {
                                              attrs: { name: "pencil" },
                                            }),
                                          ]
                                        }),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.message.deleted &&
                                _vm.$options.filters.chatIsStar(
                                  _vm.message.stars,
                                  _vm.currentUserId
                                )
                                  ? _c("b-icon-star-fill", {
                                      staticClass: "font-size-12 ml-1",
                                      attrs: { "data-cy": "vac-data-starred" },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-1",
                                    attrs: { "data-cy": "message-time-stamp" },
                                  },
                                  [_vm._v(_vm._s(_vm.message.timestamp))]
                                ),
                                _vm._v(" "),
                                _vm.isCheckmarkVisible
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "ml-1 d-flex align-items-center",
                                      },
                                      [
                                        _vm._t(
                                          "checkmark-icon",
                                          function () {
                                            return [
                                              _c("span", {
                                                staticClass: "var-circle-msg",
                                                class: {
                                                  read: _vm.isShowAgents
                                                    ? _vm.message.seenByOther ||
                                                      _vm.message.seens
                                                    : _vm.message.distributed &&
                                                      _vm.message.seen,
                                                },
                                                attrs: {
                                                  "data-cy":
                                                    "vac-circle-read-msg",
                                                },
                                              }),
                                            ]
                                          },
                                          null,
                                          { message: _vm.message }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.isShowMsgTranslate &&
                            _vm.message.messageTranslated
                              ? [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "vac-text-translate",
                                      attrs: {
                                        "data-cy": "vac-text-translate",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.SECURITY_VHTML(
                                            _vm.message.messageTranslated
                                              .content,
                                            _vm.$env.BASE_URL
                                          )
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.message.messageTranslated
                                              .content
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c("message-actions", {
                              attrs: {
                                "current-user-id": _vm.currentUserId,
                                message: _vm.message,
                                "message-actions": _vm.messageActions,
                                "show-reaction-emojis": _vm.showReactionEmojis,
                                "message-hover": _vm.messageHover,
                                "hover-message-id": _vm.hoverMessageId,
                                "hover-audio-progress": _vm.hoverAudioProgress,
                              },
                              on: {
                                "update-message-hover": function ($event) {
                                  _vm.messageHover = $event
                                },
                                "update-options-opened": function ($event) {
                                  _vm.optionsOpened = $event
                                },
                                "update-emoji-opened": function ($event) {
                                  _vm.emojiOpened = $event
                                },
                                "message-action-handler":
                                  _vm.messageActionHandler,
                                "send-message-reaction":
                                  _vm.sendMessageReaction,
                                handleShowMsgDelete: _vm.showMessageDeleteNotif,
                                handleShowEmotReactions: function ($event) {
                                  return _vm.$emit(
                                    "handleShowEmotReactions",
                                    $event
                                  )
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  _vm._l(_vm.$scopedSlots, function (i, name) {
                                    return {
                                      key: name,
                                      fn: function (data) {
                                        return [_vm._t(name, null, null, data)]
                                      },
                                    }
                                  }),
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._t(
                      "message-failure",
                      function () {
                        return [
                          _vm.message.failure &&
                          _vm.message.senderId === _vm.currentUserId
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vac-failure-container vac-svg-button",
                                  class: {
                                    "vac-failure-container-avatar":
                                      _vm.message.avatar &&
                                      _vm.message.senderId ===
                                        _vm.currentUserId,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("open-failed-message", {
                                        message: _vm.message,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "vac-failure-text" },
                                    [_vm._v("!")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                      null,
                      { message: _vm.message }
                    ),
                    _vm._v(" "),
                    _vm.message.avatar &&
                    _vm.message.senderId === _vm.currentUserId
                      ? _c("div", {
                          staticClass: "vac-avatar vac-avatar-current",
                          style: {
                            "background-image": `url('${_vm.message.avatar}')`,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasCurrentUserAvatar && !_vm.message.avatar
                      ? _c("div", { staticClass: "vac-avatar-current-offset" })
                      : _vm._e(),
                  ]
                },
                null,
                { message: _vm.message }
              ),
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "MessageDeleteNotifModal",
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
        },
        [
          _c("message-delete-notif", {
            on: {
              callBack: _vm.messageDeleteNotifCallBack,
              messageActionHandler: _vm.messageActionHandler,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }