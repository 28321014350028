var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-pd-slideshow-main" },
    [
      _vm.cbVwPawDetailAttPortfolioMains &&
      _vm.cbVwPawDetailAttPortfolioMains.length == 0
        ? _c("div", [
            _c("div", {
              staticClass: "card-img-wrapper cb-img-wrapper cb-ratio-4x3",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.cbVwPawDetailAttPortfolioMains &&
      _vm.cbVwPawDetailAttPortfolioMains.length > 0
        ? [
            _c(
              "swiper",
              {
                staticClass: "cb-slideshow-container cb-slideshow-pagination",
                attrs: {
                  pagination: "",
                  navigation: "",
                  "slides-per-view": 1,
                  "space-between": 8,
                  loop: false,
                  threshold: 10,
                },
              },
              _vm._l(
                _vm.cbVwPawDetailAttPortfolioMains,
                function (item, index) {
                  return _c("swiper-slide", { key: index }, [
                    _c("div", { staticClass: "paw-detail-card-wrapper" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-img-wrapper cb-img-wrapper cb-ratio-4x3",
                        },
                        [
                          !_vm.isPlaying
                            ? _c("img", {
                                attrs: {
                                  "data-cy": "img-paw-detail",
                                  src: _vm.$options.filters.LAZY(
                                    `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                                      _vm.isVideo(
                                        item.cbAttachmentNoAuth.attContentType
                                      )
                                        ? item?.cbAttachmentNoAuth
                                            ?.refThumbnailId
                                        : item?.cbAttachmentNoAuth?.id
                                    }`,
                                    1092,
                                    "ic-lazy-paw.png"
                                  ),
                                  onerror:
                                    _vm.$options.filters.LAZY_ERROR(
                                      "ic-lazy-paw.png"
                                    ),
                                  alt: `img-${item?.name ?? item?.title}`,
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPlaying
                            ? _c(
                                "video",
                                {
                                  ref: "myVideoPortfolioMain",
                                  refInFor: true,
                                  staticStyle: { "object-fit": "cover" },
                                  attrs: { loop: "" },
                                  domProps: { muted: _vm.isMuted },
                                  on: { click: _vm.handlePauseVideo },
                                },
                                [
                                  _c("source", {
                                    attrs: {
                                      src: `${
                                        _vm.$env.CDN_CB
                                      }/CbAttachmentNoAuth/${
                                        item.cbAttachmentNoAuth &&
                                        item.cbAttachmentNoAuth.id
                                      }`,
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          (!_vm.isPlaying || _vm.isPause) &&
                          _vm.isVideo(item.cbAttachmentNoAuth.attContentType)
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn-play-video cursor-pointer",
                                  on: { click: _vm.handlePlayVideo },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "icon-wrapper" },
                                    [
                                      _c("icon", {
                                        staticClass: "cj-fill-grey-secondary",
                                        attrs: {
                                          name: _vm.isPause
                                            ? "ic_pause"
                                            : "ic_play",
                                          width: "100%",
                                          height: "100%",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ])
                }
              ),
              1
            ),
            _vm._v(" "),
            _vm.cbVwPaw.status == _vm.cbPawStatus.ACTIVE &&
            _vm.currentUser &&
            _vm.cbVwPaw &&
            _vm.cbVwPaw.userId != _vm.currentUser.id
              ? _c(
                  "div",
                  { staticClass: "d-flex btn-icon-position" },
                  [
                    _c("button", {
                      ref: "tooltipActivedFlag",
                      staticClass: "mx-2 cb-btn-icon cb-btn-ic-flag",
                      attrs: { "data-cy": "paw-detail-report" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("openModalReport")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("button", {
                      ref: "tooltipActivedHeart",
                      staticClass: "mx-2 cb-btn-icon cb-btn-ic-heart",
                      class: !this.$options.filters.findBookmarkByIdIsActive(
                        _vm.cbUserBookmarkAggs,
                        _vm.cbVwPaw.id,
                        _vm.cbBookmarkType.PAW
                      )
                        ? ""
                        : "actived",
                      attrs: { "data-cy": "paw-detail-like" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("saveBookmark", _vm.cbVwPaw.id)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("button", {
                      ref: "tooltipActivedShared",
                      staticClass: "mx-2 cb-btn-icon cb-btn-ic-shared",
                      attrs: { "data-cy": "paw-detail-share" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("openModalCShare")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          "custom-class": "custom-tooltip-btn",
                          target: () => _vm.$refs["tooltipActivedFlag"],
                          variant: "primary",
                          placement: "top",
                          triggers: "hover",
                        },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "font-catamaran font-size-14" },
                          [_vm._v("Report")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          "custom-class": "custom-tooltip-btn",
                          target: () => _vm.$refs["tooltipActivedHeart"],
                          variant: "primary",
                          placement: "top",
                          triggers: "hover",
                        },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "font-catamaran font-size-14" },
                          [_vm._v("Wishlist")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          "custom-class": "custom-tooltip-btn",
                          target: () => _vm.$refs["tooltipActivedShared"],
                          variant: "primary",
                          placement: "top",
                          triggers: "hover",
                        },
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "font-catamaran font-size-14" },
                          [_vm._v("Share")]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isShowVideo
              ? _c(
                  "div",
                  { staticClass: "btn-icon-video-position" },
                  [
                    _c("button", {
                      ref: "tooltipActivedSound",
                      staticClass: "cb-btn-icon",
                      class: _vm.isMuted
                        ? "cb-btn-ic-sound"
                        : "cb-btn-ic-sound-mute",
                      on: {
                        click: function ($event) {
                          return _vm.handleMuteVideo()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          "custom-class": "custom-tooltip-btn",
                          target: () => _vm.$refs["tooltipActivedSound"],
                          variant: "primary",
                          placement: "top",
                          triggers: "hover",
                        },
                      },
                      [
                        _vm.isMuted
                          ? _c(
                              "p",
                              { staticClass: "font-catamaran font-size-14" },
                              [_vm._v("Mute")]
                            )
                          : _c(
                              "p",
                              { staticClass: "font-catamaran font-size-14" },
                              [_vm._v("Unmute")]
                            ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }