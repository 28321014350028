
import vClickOutside from 'v-click-outside';

import SvgIcon from '../../../components/SvgIcon/SvgIcon.vue';
import EmojiPickerContainer from '../../../components/EmojiPickerContainer/EmojiPickerContainer.vue';

import RoomFiles from './RoomFiles/RoomFiles.vue';
import RoomMessageReply from './RoomMessageReply/RoomMessageReply.vue';
import RoomUsersTag from './RoomUsersTag/RoomUsersTag.vue';
import RoomEmojis from './RoomEmojis/RoomEmojis.vue';
import RoomTemplatesText from './RoomTemplatesText/RoomTemplatesText.vue';
import RoomUploadsChat from './room-uploads/room-uploads.vue';

import RoomFooterControllerChat from './room-footer.controller';

import filteredItems from '../../../utils/filter-items';
import Recorder from '../../../utils/recorder';

import RoomFilesChat from './room-files/room-files.vue';
import RoomEmojisChat from './room-emojis/room-emojis.vue';

const { detectMobile } = require('../../../utils/mobile-detection');
import { Component, Prop } from 'vue-property-decorator';
import ThumbnailWatermarkUtils from '@/shared/data/thumbnail-watermark-utils.service';
import { mixins } from 'vue-class-component';
import { isPdfFile, isVideoFile } from '../../../utils/media-file';
import roomLocationChat from './room-location/room-location.vue';
import roomCameraChat from './room-camera/room-camera.vue';
import { ChtLocation } from '@/shared/model/cht-location.model';
import RoomReactionsChat from './room-reactions/room-reactions.vue';
import CAudio from './../../../components/c-audio/c-audio.vue';
import { Flutter } from '@/app-flutter';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { MAX_SIZE_10MB } from '@/shared/media-file/constants';

@Component({
  components: {
    SvgIcon,
    EmojiPickerContainer,
    RoomFiles,
    RoomMessageReply,
    RoomUsersTag,
    RoomEmojis,
    RoomTemplatesText,
    RoomUploadsChat,
    RoomFilesChat,
    RoomEmojisChat,
    roomLocationChat,
    roomCameraChat,
    RoomReactionsChat,
    CAudio,
  },
  mixins: [RoomFooterControllerChat],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    roomId() {
      this.resetMessage(true, true);

      if (this.roomMessage) {
        this.message = this.roomMessage;
        setTimeout(() => this.onChangeInput());
      }
    },
    message(val) {
      this.getTextareaRef().value = val;
    },
    roomMessage: {
      immediate: true,
      handler(val) {
        if (val) this.message = this.roomMessage;
      },
    },
    editedMessage(val) {
      this.$emit('update-edited-message-id', val._id);
    },
    initReplyMessage(val) {
      this.replyMessage(val);
    },
    initEditMessage(val) {
      this.editMessage(val);
    },
  },
})
export default class RoomFooter extends mixins(ThumbnailWatermarkUtils, JhiDataUtils) {
  // props: {
  @Prop({ type: Object, required: true })
  private room;
  @Prop({ type: [String, Number], required: true })
  private roomId;
  @Prop({ type: String, default: null })
  private roomMessage;
  @Prop({ type: Object, required: true })
  private textFormatting;
  @Prop({ type: Object, required: true })
  private linkOptions;
  @Prop({ type: Object, required: true })
  private textMessages;
  @Prop({ type: Boolean, required: true })
  private showSendIcon;
  @Prop({ type: Boolean, required: true })
  private showFiles;
  @Prop({ type: Boolean, required: true })
  private showAudio;
  @Prop({ type: Boolean, required: true })
  private showEmojis;
  @Prop({ type: Boolean, required: true })
  private showFooter;
  @Prop({ type: String, required: true })
  private acceptedFiles;
  @Prop({ type: Boolean, required: true })
  private textareaActionEnabled;
  @Prop({ type: Boolean, required: true })
  private textareaAutoFocus;
  @Prop({ type: Boolean, required: true })
  private userTagsEnabled;
  @Prop({ type: Boolean, required: true })
  private emojisSuggestionEnabled;
  @Prop({ type: Array, default: null })
  private templatesText;
  @Prop({ type: Number, required: true })
  private audioBitRate;
  @Prop({ type: Number, required: true })
  private audioSampleRate;
  @Prop({ type: Object, default: null })
  private initReplyMessage;
  @Prop({ type: Object, default: null })
  private initEditMessage;
  // },
  @Prop({ type: Array, default: null })
  public cbStickers;
  @Prop({ type: Boolean, default: false })
  public isClosed;
  @Prop({ type: Boolean, default: false })
  private isShowAgents;

  private message = '';
  private editedMessage = {};
  private messageReply = null;
  private cursorRangePosition = null;
  private files = [];
  private fileDialog = false;
  private selectUsersTagItem = null;
  private selectEmojiItem = null;
  private selectTemplatesTextItem = null;
  private format = 'mp3';
  private activeUpOrDownEmojis = null;
  private activeUpOrDownUsersTag = null;
  private activeUpOrDownTemplatesText = null;
  // private emojisDB = new Database();
  private emojiOpened = false;
  private keepKeyboardOpen = false;
  private filteredEmojis = [];
  private filteredUsersTag = [];
  private selectedUsersTag = [];
  private filteredTemplatesText = [];
  private recorder = this.initRecorder();
  private isRecording = false;
  private chtLocation = null;
  public MAX_CHARAPTER = 5000;
  public isClearCharapter = false;
  private isNotShiftPressed = true;
  public acceptFiles = [
    '.pdf',
    '.docx',
    '.xlsx',
    '.pptx',
    '.doc',
    '.xls',
    '.ppt',
    '.key',
    '.numbers',
    '.pages',
    '.mid',
    '.midi',
    '.mp3',
    '.wav',
  ];
  public acceptImgsVideos = [
    '.gif',
    '.jpeg',
    '.jpg',
    '.svg',
    '.png',
    '.3gp',
    '.avi',
    '.flv',
    '.m4v',
    '.mkv',
    '.mov',
    '.mp4',
    '.mpg',
    '.mpeg',
    '.wmv',
  ];

  // computed: {
  public get isMessageEmpty() {
    return !this.files.length && !this.message.trim();
  }
  public get isFileLoading() {
    return this.files.some(e => e.loading);
  }
  public get recordedTime() {
    return new Date(this.recorder.duration * 1000).toISOString().substr(14, 5);
  }
  public get shadowFooter() {
    return (
      !!this.filteredEmojis.length ||
      !!this.filteredUsersTag.length ||
      !!this.filteredTemplatesText.length ||
      !!this.files.length ||
      !!this.messageReply
    );
  }
  // },

  public addNewLine() {
    if (Flutter.isRunOn()) {
      this.message = this.message + '\n';
      setTimeout(() => this.onChangeInput());
    }
  }
  created() {
    this.$root.$off('roomFooter::onFileChange');
    this.$root.$on('roomFooter::onFileChange', (file: File) => {
      this.onFileChange([file]).catch(e => {
        if (typeof e === 'string') {
          console.log(e);
        } else if (e instanceof Error) {
          console.log(e.message);
          console.log(e.name);
          console.log(e.stack);
        }
      });
    });

    this.$root.$off('roomFooter::onCamera');
    this.$root.$on('roomFooter::onCamera', (file: File) => {
      this.onFileChange([file]).catch(async e => {
        try {
          let fileURL = URL.createObjectURL(file);
          const typeIndex = file.name.lastIndexOf('.');
          const resizedImage = await this.resizeImage({ file, maxSize: 750 });
          fileURL = URL.createObjectURL(<any>resizedImage);
          this.files.push({
            loading: true,
            name: file.name.substring(0, typeIndex),
            size: file.size,
            type: file.type,
            extension: file.name.substring(typeIndex + 1),
            localUrl: fileURL,
            thumbnail: file.thumbnail,
          });
          const blobFile = await fetch(fileURL).then(res => res.blob());

          let loadedFile = this.files.find(file => file.localUrl === fileURL);

          if (loadedFile) {
            loadedFile.blob = blobFile;
            loadedFile.loading = false;
            delete loadedFile.loading;
          }
          this.sendMessage();
        } catch (e) {
          if (typeof e === 'string') {
            console.log(e);
          } else if (e instanceof Error) {
            console.log(e.message);
            console.log(e.name);
            console.log(e.stack);
          }
        }
      });
    });

    (<any>this.$root).$off('roomFooter::changeValue');
    (<any>this.$root).$on('roomFooter::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });
  }

  mounted() {
    const isMobile = detectMobile();
    let isComposed = true;

    this.getTextareaRef().addEventListener('keyup', e => {
      if (Flutter.isRunOn() === true) {
        this.handleShiftOnFlutter(e);
      } else if (e.key === 'Enter' && !e.shiftKey && !this.fileDialog) {
        if (isMobile) {
          this.message = this.message + '\n';
          setTimeout(() => this.onChangeInput());
        } else if (isComposed && !this.filteredEmojis.length && !this.filteredUsersTag.length && !this.filteredTemplatesText.length) {
          this.sendMessage();
        }
      }
      isComposed = !e.isComposing;

      setTimeout(() => {
        this.updateFooterLists();
      }, 60);
    });

    this.getTextareaRef().addEventListener('click', () => {
      if (isMobile) this.keepKeyboardOpen = true;
      this.updateFooterLists();
    });

    this.getTextareaRef().addEventListener('blur', () => {
      setTimeout(() => {
        this.resetFooterList();
      }, 100);

      if (isMobile) setTimeout(() => (this.keepKeyboardOpen = false));
    });
  }

  beforeDestroy() {
    this.stopRecorder();
  }

  handleShiftOnFlutter(e) {
    if (e.key === 'Enter' && this.isNotShiftPressed === true) {
      return;
    }
    if (e.key === 'Enter' && !this.fileDialog) {
      this.message = this.message + '\n';
      setTimeout(() => this.onChangeInput());
    }
  }

  // methods: {
  getTextareaRef() {
    return <any>this.$refs.roomTextarea;
  }
  focusTextarea(disableMobileFocus) {
    if (detectMobile() && disableMobileFocus) return;
    if (!this.getTextareaRef()) return;
    this.getTextareaRef().focus();

    if (this.cursorRangePosition) {
      setTimeout(() => {
        this.getTextareaRef().setSelectionRange(this.cursorRangePosition, this.cursorRangePosition);
        this.cursorRangePosition = null;
      });
    }
  }

  onChangeInput() {
    const isSafari = /AppleWebKit/.test(navigator.userAgent) || /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (!isSafari) {
      this.playAudioTyping();
    }
    if (this.getTextareaRef()?.value.length <= this.MAX_CHARAPTER) {
      this.isClearCharapter = false;
      if (this.getTextareaRef()?.value || this.getTextareaRef()?.value === '') {
        this.getTextareaRef().maxLength = this.MAX_CHARAPTER;
        this.message = this.getTextareaRef()?.value;
      }
    } else {
      if (this.isClearCharapter) {
        this.getTextareaRef().value = '';
        (<any>this.$root).$bvToast.toast(this.$t('chat.msgInputChat'), {
          toaster: 'b-toaster-top-center',
          title: 'Warning',
          variant: 'warning',
          solid: true,
          autoHideDelay: 5000,
        });
      } else {
        this.getTextareaRef().maxLength = this.MAX_CHARAPTER;
      }
    }
    this.keepKeyboardOpen = true;
    this.resizeTextarea();
    this.$emit('typing-message', this.message);
  }
  resizeTextarea() {
    const el = this.getTextareaRef();

    if (!el) return;

    const padding = window.getComputedStyle(el, null).getPropertyValue('padding-top').replace('px', '');

    el.style.height = 0;
    el.style.height = el.scrollHeight - padding * 2 + 'px';
  }
  escapeTextarea() {
    if (this.filteredEmojis.length) this.filteredEmojis = [];
    else if (this.filteredUsersTag.length) this.filteredUsersTag = [];
    else if (this.filteredTemplatesText.length) {
      this.filteredTemplatesText = [];
    } else this.resetMessage();
  }
  onPasteImage(pasteEvent) {
    const items = pasteEvent.clipboardData?.items;
    let clipboardDatapPaste = pasteEvent.clipboardData || window.clipboardData;
    let pastedData = clipboardDatapPaste.getData('Text');
    if (pastedData.length > this.MAX_CHARAPTER) {
      this.isClearCharapter = true;
    }
    if (items) {
      Array.from(items).forEach(item => {
        if (item.type.includes('image')) {
          const blob = item.getAsFile();
          this.onFileChange([blob]);
        }
      });
    }
  }
  updateActiveUpOrDown(direction) {
    if (this.filteredEmojis.length) {
      this.activeUpOrDownEmojis = direction;
    } else if (this.filteredUsersTag.length) {
      this.activeUpOrDownUsersTag = direction;
    } else if (this.filteredTemplatesText.length) {
      this.activeUpOrDownTemplatesText = direction;
    }
  }
  selectItem() {
    if (this.filteredEmojis.length) {
      this.selectEmojiItem = true;
    } else if (this.filteredUsersTag.length) {
      this.selectUsersTagItem = true;
    } else if (this.filteredTemplatesText.length) {
      this.selectTemplatesTextItem = true;
    }
  }
  selectEmoji(emoji) {
    this.selectEmojiItem = false;

    if (!emoji) return;

    const { position, endPosition } = this.getCharPosition(':');

    this.message = this.message.substr(0, position - 1) + emoji + this.message.substr(endPosition, this.message.length - 1);

    this.cursorRangePosition = position;
    this.focusTextarea(null);
  }
  selectTemplateText(template) {
    this.selectTemplatesTextItem = false;

    if (!template) return;

    const { position, endPosition } = this.getCharPosition('/');

    const space = this.message.substr(endPosition, endPosition).length ? '' : ' ';

    this.message = this.message.substr(0, position - 1) + template.text + space + this.message.substr(endPosition, this.message.length - 1);

    this.cursorRangePosition = position + template.text.length + space.length + 1;

    this.focusTextarea(null);
  }
  addEmoji(emoji) {
    this.playAudioSpacingShiftingEmoticon();
    // console.log(emoji.data);
    this.message += emoji.data;
    this.focusTextarea(true);
  }
  launchFilePicker() {
    this.isShowUpload = false;
    this.seletedFileUpload = 'DOCUMENT';
    this.$refs.file.value = '';
    this.openFileFlutter(
      {
        onFlutterNotRun: () => (this.$refs.file as HTMLElement).click(),
        allowedExtensions: '.pdf, .docx, .xlsx, .pptx, .doc, .xls, .ppt, .key, .numbers, .pages, .mid, .midi, .mp3, .wav',
        multiplePick: true,
        fileMaxSizeInMb: MAX_SIZE_10MB,
      },
      _ => {},
      (file: File) => {
        this.onFileChange([file]).catch(e => {
          if (typeof e === 'string') {
            console.log(e);
          } else if (e instanceof Error) {
            console.log(e.message);
            console.log(e.name);
            console.log(e.stack);
          }
        });
      }
    );
  }
  removeFile(index) {
    this.files.splice(index, 1);
    this.focusTextarea(null);
  }
  toggleRecorder(recording) {
    this.isRecording = recording;

    if (!this.recorder.isRecording) {
      setTimeout(() => this.recorder.start(), 200);
    } else {
      try {
        this.recorder.stop();

        const record = this.recorder.records[0];

        this.files.push({
          blob: record.blob,
          name: `audio.${this.format}`,
          size: record.blob.size,
          duration: record.duration,
          type: record.blob.type,
          audio: true,
          localUrl: URL.createObjectURL(record.blob),
        });

        this.recorder = this.initRecorder();
        this.sendMessage();
      } catch {
        setTimeout(() => this.stopRecorder(), 100);
      }
    }
  }
  stopRecorder() {
    if (this.recorder.isRecording) {
      try {
        this.recorder.stop();
        this.recorder = this.initRecorder();
      } catch {
        setTimeout(() => this.stopRecorder(), 100);
      }
    }
  }
  textareaActionHandler() {
    this.$emit('textarea-action-handler', this.message);
  }
  sendMessage() {
    this.playAudioSendingMessage();
    let message = this.message.trim();

    if (!this.files.length && !message) return;

    if (this.isFileLoading) return;

    this.selectedUsersTag.forEach(user => {
      message = message.replace(`@${user.username}`, `<usertag>${user._id}</usertag>`);
    });

    const files = this.files.length ? this.files : null;

    if (this.editedMessage._id) {
      if (this.editedMessage.content !== message || this.editedMessage.files?.length || this.files.length) {
        this.$emit('edit-message', {
          messageId: this.editedMessage._id,
          newContent: message,
          files: files,
          chtLocation: this.chtLocation,
          replyMessage: this.messageReply,
          usersTag: this.selectedUsersTag,
        });
      }
    } else {
      this.$emit('send-message', {
        content: message,
        files: files,
        chtLocation: this.chtLocation,
        replyMessage: this.messageReply,
        usersTag: this.selectedUsersTag,
      });
    }

    this.resetMessage(true);
  }
  // eslint-disable-next-line vue/no-unused-properties
  editMessage(message) {
    this.resetMessage();

    this.editedMessage = { ...message };

    let messageContent = message.content;
    const initialContent = messageContent;

    const firstTag = '<usertag>';
    const secondTag = '</usertag>';

    const usertags = [...messageContent.matchAll(new RegExp(firstTag, 'gi'))].map(a => a.index);

    usertags.forEach(index => {
      const userId = initialContent.substring(index + firstTag.length, initialContent.indexOf(secondTag, index));

      const user = this.room.users.find(user => user._id === userId);

      messageContent = messageContent.replace(`${firstTag}${userId}${secondTag}`, `@${user?.username || 'unknown'}`);

      this.selectUserTag(user, true);
    });

    this.message = messageContent;

    if (message.files) {
      this.files = [...message.files];
    }

    setTimeout(() => this.resizeTextarea());
  }
  // eslint-disable-next-line vue/no-unused-properties
  replyMessage(message) {
    this.editedMessage = {};
    this.messageReply = message;
    this.focusTextarea(null);
  }
  updateFooterLists() {
    this.updateFooterList('@');
    this.updateFooterList(':');
    this.updateFooterList('/');
  }
  updateFooterList(tagChar) {
    if (!this.getTextareaRef()) return;

    if (tagChar === ':' && !this.emojisSuggestionEnabled) {
      return;
    }

    if (tagChar === '@' && (!this.userTagsEnabled || !this.room.users)) {
      return;
    }

    if (tagChar === '/' && !this.templatesText) {
      return;
    }

    const textareaCursorPosition = this.getTextareaRef().selectionStart;

    let position = textareaCursorPosition;

    while (
      position > 0 &&
      this.message.charAt(position - 1) !== tagChar &&
      // eslint-disable-next-line no-unmodified-loop-condition
      (this.message.charAt(position - 1) !== ' ' || tagChar !== ':')
    ) {
      position--;
    }

    const beforeTag = this.message.charAt(position - 2);
    const notLetterNumber = !beforeTag.match(/^[0-9a-zA-Z]+$/);

    if (this.message.charAt(position - 1) === tagChar && (!beforeTag || beforeTag === ' ' || notLetterNumber)) {
      const query = this.message.substring(position, textareaCursorPosition);
      if (tagChar === ':') {
        // this.updateEmojis(query);
      } else if (tagChar === '@') {
        this.updateShowUsersTag(query);
      } else if (tagChar === '/') {
        this.updateShowTemplatesText(query);
      }
    } else {
      this.resetFooterList(tagChar);
    }
  }
  updateShowUsersTag(query) {
    this.filteredUsersTag = filteredItems(this.room.users, 'username', query, true).filter(user => user._id !== this.currentUserId);
  }
  selectUserTag(user, editMode = false) {
    this.selectUsersTagItem = false;

    if (!user) return;

    const { position, endPosition } = this.getCharPosition('@');

    const space = this.message.substr(endPosition, endPosition).length ? '' : ' ';

    this.message = this.message.substr(0, position) + user.username + space + this.message.substr(endPosition, this.message.length - 1);

    this.selectedUsersTag = [...this.selectedUsersTag, { ...user }];

    if (!editMode) {
      this.cursorRangePosition = position + user.username.length + space.length + 1;
    }

    this.focusTextarea(null);
  }
  updateShowTemplatesText(query) {
    this.filteredTemplatesText = filteredItems(this.templatesText, 'tag', query, true);
  }
  getCharPosition(tagChar) {
    const cursorPosition = this.getTextareaRef().selectionStart;

    let position = cursorPosition;
    while (position > 0 && this.message.charAt(position - 1) !== tagChar) {
      position--;
    }

    const endPosition = this.getTextareaRef().selectionEnd;

    return { position, endPosition };
  }

  resetFooterList(tagChar = null) {
    if (tagChar === ':') {
      this.filteredEmojis = [];
    } else if (tagChar === '@') {
      this.filteredUsersTag = [];
    } else if (tagChar === '/') {
      this.filteredTemplatesText = [];
    } else {
      this.filteredEmojis = [];
      this.filteredUsersTag = [];
      this.filteredTemplatesText = [];
    }
  }
  resetMessage(disableMobileFocus = false, initRoom = false) {
    if (!initRoom) {
      this.$emit('typing-message', null);
    }

    this.selectedUsersTag = [];
    this.resetFooterList();
    this.resetTextareaSize();
    this.message = '';
    this.editedMessage = {};
    this.messageReply = null;
    this.chtLocation = null;
    this.files = [];
    this.emojiOpened = false;
    this.preventKeyboardFromClosing();

    if (this.textareaAutoFocus || !initRoom) {
      setTimeout(() => this.focusTextarea(disableMobileFocus));
    }
  }
  resetTextareaSize() {
    if (this.getTextareaRef()) {
      this.getTextareaRef().style.height = '20px';
    }
  }
  preventKeyboardFromClosing() {
    if (this.keepKeyboardOpen) this.getTextareaRef().focus();
  }
  initRecorder() {
    this.isRecording = false;

    return new Recorder({
      bitRate: this.audioBitRate,
      sampleRate: this.audioSampleRate,
      beforeRecording: null,
      afterRecording: null,
      pauseRecording: null,
      micFailed: this.micFailed,
    });
  }
  micFailed() {
    this.isRecording = false;
    this.recorder = this.initRecorder();
  }
  // },

  checkMediaType(types, fileType) {
    if (!fileType) return;
    return types.some(t => fileType.toLowerCase().includes(t));
  }

  public async onFileChange(files) {
    this.fileDialog = true;
    this.focusTextarea(null);
    Array.from(files).forEach(async f => {
      let file = <any>f;
      let fileURL = URL.createObjectURL(file);
      const typeIndex = file.name.lastIndexOf('.');
      let size = file.size;

      // kenapa di tambahkan kondisi tidak sama dengan flutter karena di flutter udah ada kondisi tersebut agar tidak kepanggil 2 kali toastnya
      if (!Flutter.isRunOn()) {
        const fileExtension = file.name.substring(typeIndex).toLowerCase();
        if (
          this.seletedFileUpload == 'VIDEOIMAGE'
            ? !this.checkMediaType(this.acceptImgsVideos, fileExtension)
            : !this.checkMediaType(this.acceptFiles, fileExtension)
        ) {
          (<any>this.$root).$bvToast.toast(this.$t('cbgwApp.cbComponent.validation.invalidUploadFile').toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Warning',
            variant: 'warning',
            solid: true,
            autoHideDelay: 5000,
          });
        }
      }
      if (size > MAX_SIZE_10MB) {
        (<any>this.$root).$bvToast.toast(this.$t('chat.maxSize'), {
          toaster: 'b-toaster-top-center',
          title: 'Warning',
          variant: 'warning',
          solid: true,
          autoHideDelay: 5000,
        });
      } else {
        if (file.type.match(/image.*/) && file.name.substring(typeIndex + 1) != 'svg' && file.size > 350000) {
          //ignore svg or find svg to png

          await this.resizeImage({ file: file, maxSize: 750 })
            .then(resizedImage => {
              fileURL = URL.createObjectURL(<any>resizedImage);
            })
            .catch(err => {
              console.error(err);
            });
        }

        if (isVideoFile(file)) {
          const thumbnail = await this.generateVideoThumbnail(file, 500);
          file.thumbnail = thumbnail;
        }

        if (isPdfFile(file)) {
          const thumbnail = await this.generatePdfThumbnail(file, 500);
          file.thumbnail = thumbnail;
        }

        if (this.files && this.files.length > 9) {
          (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.toast.max10files').toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Warning',
            variant: 'warning',
            solid: true,
            autoHideDelay: 5000,
          });
          return;
        } else {
          this.files.push({
            loading: true,
            name: file.name.substring(0, typeIndex),
            size: file.size,
            type: file.type,
            extension: file.name.substring(typeIndex + 1),
            localUrl: fileURL,
            thumbnail: file.thumbnail,
          });

          const blobFile = await fetch(fileURL).then(res => res.blob());
          let loadedFile = this.files.find(file => file.localUrl === fileURL);

          if (loadedFile) {
            loadedFile.blob = blobFile;
            loadedFile.loading = false;
            delete loadedFile.loading;
          }
        }
      }
    });

    setTimeout(() => (this.fileDialog = false), 500);
  }

  public roomLocationChatCallBack(chtLocation: ChtLocation) {
    if (!chtLocation) {
      (<any>this.$refs.roomLocationChatModal).hide();
      return;
    }

    this.message = '';
    const blob = this.dataURItoBlob(chtLocation.snapshot);
    const blobUrl = URL.createObjectURL(blob);

    this.files.push({
      blob: blob,
      name: 'map',
      size: this.byteSize(chtLocation.snapshot),
      type: 'image/png',
      extension: 'png',
      localUrl: blobUrl,
      url: blobUrl,
    });

    this.chtLocation = chtLocation;
    this.chtLocation.snapshot = null;

    (<any>this.$refs.roomLocationChatModal).hide();
    this.sendMessage();
  }
  public sendMessageReaction(messageReaction) {
    this.$emit('send-message-reaction', messageReaction);
  }

  public playAudioTyping() {
    const myAudio = <any>(<any>this.$refs.audioMain).$refs.myAudioTyping;
    myAudio.pause();
    myAudio.currentTime = 0;
    myAudio.play();
  }

  public playAudioSendingMessage() {
    const myAudio = <any>(<any>this.$refs.audioMain).$refs.myAudioSendingMessage;
    myAudio.pause();
    myAudio.currentTime = 0;
    myAudio.play();
  }
  public playAudioSpacingShiftingEmoticon() {
    const myAudio = <any>(<any>this.$refs.audioMain).$refs.myAudioSpacingShiftingEmoticon;
    myAudio.pause();
    myAudio.currentTime = 0;
    myAudio.play();
  }

  public sendMessageSticker(messageSticker) {
    this.$emit('send-message-sticker', messageSticker, this.messageReply);
  }
  public sendTakePictureToMsg(e) {
    this.files.push({
      att: '',
      type: e.attContentType,
      name: 'cb',
      extension: e.extension,
      localUrl: e.localUrl,
      blob: e.blob,
      size: e.attSize,
      thumbnail: '',
    });

    this.sendMessage();
    (<any>this.$refs.roomCameraChatChatModal).hide();
  }

  askmeQuestionAgain() {
    this.$emit('ask-me-question-again');
  }
}
