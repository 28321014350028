var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "register-m navbar-distance" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between c-navbar-m cj-bg-white-primary px-3",
        },
        [
          _c("img", {
            staticClass: "c-logo-mobile my-auto",
            staticStyle: { width: "6rem" },
            attrs: {
              src: `${_vm.$env.CDN_CB}/content/cb-img/ic-logo-black.png`,
              alt: "Image Klikjob",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "my-auto p-3 m-n3", on: { click: _vm.onBack } },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-container" }, [
        _c("p", {
          staticClass:
            "font-roboto cj-color-black-primary font-size-24 font-weight-700",
          domProps: {
            textContent: _vm._s(
              _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.register.title")
            ),
          },
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "font-catamaran cj-color-grey-secondary font-weight-400 font-size-14 my-3",
          },
          [
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.alreadyAccount"
                  )
                ),
              },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "cj-color-red-primary font-weight-600",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.register.login")
                ),
              },
              on: { click: _vm.toLogin },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "cursor-pointer wrap-border-grey-tertiary rounded-pill d-flex justify-content-center p-2 mb-3",
            on: { click: _vm.loginGoogle },
          },
          [
            _c("div", { staticClass: "d-flex mx-auto" }, [
              _c(
                "div",
                { staticClass: "d-flex mr-3 my-auto" },
                [
                  _c("icon", {
                    attrs: { name: "ic_google", width: "1.625rem" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "my-auto" }, [
                _c("p", {
                  staticClass:
                    "font-roboto my-auto font-weight-400 font-size-14-m text-nowrap cj-color-black-primary",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.register.google")
                    ),
                  },
                }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "cursor-pointer wrap-border-grey-tertiary rounded-pill d-flex justify-content-center p-2 mb-3",
            on: { click: _vm.loginFacebook },
          },
          [
            _c("div", { staticClass: "d-flex mx-auto" }, [
              _c(
                "div",
                { staticClass: "d-flex mr-3 my-auto" },
                [
                  _c("icon", {
                    attrs: { name: "ic_facebook", width: "1.625rem" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "my-auto" }, [
                _c("p", {
                  staticClass:
                    "font-roboto my-auto font-weight-400 font-size-14-m text-nowrap cj-color-black-primary",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.facebook"
                      )
                    ),
                  },
                }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "cursor-pointer wrap-border-grey-tertiary rounded-pill d-flex justify-content-center p-2 mb-3",
            on: { click: _vm.loginLinkedin },
          },
          [
            _c("div", { staticClass: "d-flex mx-auto" }, [
              _c(
                "div",
                { staticClass: "d-flex mr-3 my-auto" },
                [
                  _c("icon", {
                    attrs: { name: "ic_linkedin", width: "1.625rem" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "my-auto" }, [
                _c("p", {
                  staticClass:
                    "font-roboto my-auto font-weight-400 font-size-14-m text-nowrap cj-color-black-primary",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.linkedin"
                      )
                    ),
                  },
                }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "cursor-pointer wrap-border-grey-tertiary rounded-pill d-flex justify-content-center p-2",
            on: { click: _vm.loginApple },
          },
          [
            _c("div", { staticClass: "d-flex mx-auto" }, [
              _c(
                "div",
                { staticClass: "d-flex mr-3 my-auto" },
                [
                  _c("icon", {
                    attrs: { name: "ic_apple", width: "1.625rem" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "my-auto" }, [
                _c("p", {
                  staticClass:
                    "font-roboto my-auto font-weight-400 font-size-14-m text-nowrap cj-color-black-primary",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.register.apple")
                    ),
                  },
                }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex w-100 my-3" }, [
          _c("p", { staticClass: "hr-text my-4" }, [
            _c("span", {
              staticClass:
                "font-catamaran font-weight-light cj-color-grey-secondary font-size-12 cj-bg-white-primary px-2",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.register.or")
                ),
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-login w-100" }, [
          _c("div", { staticClass: "d-flex mb-3" }, [
            _c(
              "div",
              {
                staticClass: "ml-auto px-3 py-1 cj-bg-red-secondary",
                staticStyle: { "border-radius": "28px" },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "ml-auto font-roboto font-weight-400 font-size-12 cj-color-black-primary",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "cj-color-grey-secondary mr-1" },
                      [_vm._v("*")]
                    ),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.required"
                          )
                        ),
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.cbUserInfo.email.$model,
                  expression: "$v.cbUserInfo.email.$model",
                },
              ],
              staticClass:
                "font-size-14 cj-field-border-default cj-field-input",
              class: `${
                _vm.$v.cbUserInfo.email.$anyDirty
                  ? _vm.$v.cbUserInfo.email.$invalid || _vm.isExistEmail
                    ? "invalid"
                    : "valid"
                  : ""
              }`,
              attrs: {
                placeholder: "Email*",
                "data-cy": "input-email-register-m",
                name: "input-email-register",
                type: "text",
                autocomplete: "off",
              },
              domProps: { value: _vm.$v.cbUserInfo.email.$model },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$v.cbUserInfo.email,
                      "$model",
                      $event.target.value
                    )
                  },
                  _vm.handleCheckEmail,
                ],
              },
            }),
            _vm._v(" "),
            _vm.isExistEmail
              ? _c("div", [
                  _c(
                    "small",
                    {
                      staticClass:
                        "form-text font-validation text-danger cj-color-status-error",
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              `cbgwApp.cbHome.cbHomeBeforeLogin.register.${_vm.valueStatusEmail}`
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _vm.valueStatusEmail != "DELETED"
                        ? _c(
                            "u",
                            {
                              staticClass: "cj-color-red-primary",
                              on: { click: _vm.toLogin },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "cbgwApp.cbHome.cbHomeBeforeLogin.register.login"
                                    )
                                  ),
                                },
                              }),
                              _vm._v("?\n            "),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$v.cbUserInfo.email.$anyDirty &&
            _vm.$v.cbUserInfo.email.$invalid
              ? _c("div", [
                  !_vm.$v.cbUserInfo.email.email
                    ? _c("small", {
                        staticClass:
                          "font-catamaran font-weight-light font-size-12 cj-color-status-error",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationMustEmail"
                            )
                          ),
                        },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "wrap-input-phone-number-m cj-field-border-default d-flex mt-3 rounded",
              class: `${
                _vm.$v.cbUserInfo.phone.$anyDirty ||
                _vm.$v.cbUserInfo.phoneCode.$anyDirty
                  ? _vm.$v.cbUserInfo.phone.$invalid ||
                    _vm.$v.cbUserInfo.phoneCode.$invalid ||
                    _vm.isExistPhone
                    ? "invalid"
                    : "valid"
                  : ""
              }`,
              attrs: { "data-cy": "border-phone-code-and-phone" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "border-phone-code p-2 d-flex",
                  attrs: { "data-cy": "click-border" },
                  on: {
                    click: function ($event) {
                      _vm.isShowPhoneCode = true
                      _vm.localPhoneCode = _vm.cbUserInfo.phoneCode
                    },
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto cj-color-black-primary font-size-14 font-weight-400 mr-3",
                      attrs: { "data-cy": "phone-code-content" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.cbUserInfo.phoneCode) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("icon", {
                    staticClass:
                      "cj-fill-black-primary ic_arrow_dropdown ml-auto",
                    attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.cbUserInfo.phone.$model,
                    expression: "$v.cbUserInfo.phone.$model",
                  },
                ],
                staticClass: "border-input-phone font-size-14 cj-field-input",
                attrs: {
                  placeholder:
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.register.phoneNumber"
                    ) + "*",
                  type: "text",
                  "data-cy": "input-phone",
                  autocomplete: "off",
                },
                domProps: { value: _vm.$v.cbUserInfo.phone.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$v.cbUserInfo.phone,
                      "$model",
                      $event.target.value
                    )
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.isExistPhone
            ? _c("div", [
                _c("small", { staticClass: "font-validation" }, [
                  _c("span", {
                    staticClass: "text-danger cj-color-status-error",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          `cbgwApp.cbCreateAccount.phone.${_vm.valueStatusPhone}`
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.valueStatusPhone != "DELETED"
                    ? _c(
                        "u",
                        {
                          staticClass: "cj-color-red-primary hand",
                          on: { click: _vm.openModalLogin },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbHome.cbHomeBeforeLogin.register.login"
                                )
                              ),
                            },
                          }),
                          _vm._v("?\n          "),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _c("p", {
                staticClass: "font-validation mt-2",
                class: `${
                  _vm.$v.cbUserInfo.phone.$anyDirty
                    ? _vm.$v.cbUserInfo.phone.$invalid
                      ? "cj-color-status-error"
                      : "cj-color-status-success d-none"
                    : "d-none"
                }`,
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(`cbgwApp.cbCreateAccount.phone.default`)
                  ),
                },
              }),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex w-100 mt-3 position-relative" }, [
            _vm.passwordInputType === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.cbUserInfo.password.$model,
                      expression: "$v.cbUserInfo.password.$model",
                    },
                  ],
                  staticClass: "font-size-14 cj-field-border-default",
                  class: {
                    invalid: _vm.invalidPassword,
                    valid:
                      _vm.$v.cbUserInfo.password.$anyDirty &&
                      !_vm.invalidPassword,
                  },
                  attrs: {
                    "data-cy": "input-password",
                    placeholder:
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.password"
                      ) + "*",
                    autocomplete: "off",
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.$v.cbUserInfo.password.$model)
                      ? _vm._i(_vm.$v.cbUserInfo.password.$model, null) > -1
                      : _vm.$v.cbUserInfo.password.$model,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.$v.cbUserInfo.password.$model,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.$v.cbUserInfo.password,
                              "$model",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.$v.cbUserInfo.password,
                              "$model",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.$v.cbUserInfo.password, "$model", $$c)
                      }
                    },
                  },
                })
              : _vm.passwordInputType === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.cbUserInfo.password.$model,
                      expression: "$v.cbUserInfo.password.$model",
                    },
                  ],
                  staticClass: "font-size-14 cj-field-border-default",
                  class: {
                    invalid: _vm.invalidPassword,
                    valid:
                      _vm.$v.cbUserInfo.password.$anyDirty &&
                      !_vm.invalidPassword,
                  },
                  attrs: {
                    "data-cy": "input-password",
                    placeholder:
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.password"
                      ) + "*",
                    autocomplete: "off",
                    type: "radio",
                  },
                  domProps: {
                    checked: _vm._q(_vm.$v.cbUserInfo.password.$model, null),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(
                        _vm.$v.cbUserInfo.password,
                        "$model",
                        null
                      )
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.cbUserInfo.password.$model,
                      expression: "$v.cbUserInfo.password.$model",
                    },
                  ],
                  staticClass: "font-size-14 cj-field-border-default",
                  class: {
                    invalid: _vm.invalidPassword,
                    valid:
                      _vm.$v.cbUserInfo.password.$anyDirty &&
                      !_vm.invalidPassword,
                  },
                  attrs: {
                    "data-cy": "input-password",
                    placeholder:
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.password"
                      ) + "*",
                    autocomplete: "off",
                    type: _vm.passwordInputType,
                  },
                  domProps: { value: _vm.$v.cbUserInfo.password.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.cbUserInfo.password,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-password position-absolute",
                staticStyle: {
                  right: "0",
                  top: "50%",
                  transform: "translateY(-50%)",
                },
                attrs: { "data-cy": "hide-password", type: "button" },
                on: { click: _vm.togglePassword },
              },
              [
                _vm.passwordInputType === "text"
                  ? _c("icon", {
                      staticClass: "cj-stroke-grey-secondary",
                      attrs: { name: "ic_eye_open", width: "1rem" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.passwordInputType === "password"
                  ? _c("icon", {
                      staticClass: "cj-stroke-grey-secondary",
                      attrs: { name: "ic_eye_close", width: "1rem" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex my-3 w-100 position-relative" }, [
            _vm.passwordInputType === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.confirmPassword.$model,
                      expression: "$v.confirmPassword.$model",
                    },
                  ],
                  staticClass: "font-size-14 cj-field-border-default",
                  class: `${
                    _vm.$v.confirmPassword.$anyDirty
                      ? _vm.confirmPassword != _vm.cbUserInfo.password ||
                        _vm.$v.confirmPassword.$invalid
                        ? "invalid"
                        : "valid"
                      : ""
                  }`,
                  attrs: {
                    "data-cy": "input-password-confirm",
                    placeholder:
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.confirmPassword"
                      ) + "*",
                    autocomplete: "off",
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.$v.confirmPassword.$model)
                      ? _vm._i(_vm.$v.confirmPassword.$model, null) > -1
                      : _vm.$v.confirmPassword.$model,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.$v.confirmPassword.$model,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.$v.confirmPassword,
                              "$model",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.$v.confirmPassword,
                              "$model",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.$v.confirmPassword, "$model", $$c)
                      }
                    },
                  },
                })
              : _vm.passwordInputType === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.confirmPassword.$model,
                      expression: "$v.confirmPassword.$model",
                    },
                  ],
                  staticClass: "font-size-14 cj-field-border-default",
                  class: `${
                    _vm.$v.confirmPassword.$anyDirty
                      ? _vm.confirmPassword != _vm.cbUserInfo.password ||
                        _vm.$v.confirmPassword.$invalid
                        ? "invalid"
                        : "valid"
                      : ""
                  }`,
                  attrs: {
                    "data-cy": "input-password-confirm",
                    placeholder:
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.confirmPassword"
                      ) + "*",
                    autocomplete: "off",
                    type: "radio",
                  },
                  domProps: {
                    checked: _vm._q(_vm.$v.confirmPassword.$model, null),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.$v.confirmPassword, "$model", null)
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.confirmPassword.$model,
                      expression: "$v.confirmPassword.$model",
                    },
                  ],
                  staticClass: "font-size-14 cj-field-border-default",
                  class: `${
                    _vm.$v.confirmPassword.$anyDirty
                      ? _vm.confirmPassword != _vm.cbUserInfo.password ||
                        _vm.$v.confirmPassword.$invalid
                        ? "invalid"
                        : "valid"
                      : ""
                  }`,
                  attrs: {
                    "data-cy": "input-password-confirm",
                    placeholder:
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.confirmPassword"
                      ) + "*",
                    autocomplete: "off",
                    type: _vm.passwordInputType,
                  },
                  domProps: { value: _vm.$v.confirmPassword.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.confirmPassword,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-password position-absolute",
                staticStyle: {
                  right: "0",
                  top: "50%",
                  transform: "translateY(-50%)",
                },
                attrs: { type: "button" },
                on: { click: _vm.togglePassword },
              },
              [
                _vm.passwordInputType === "text"
                  ? _c("icon", {
                      staticClass: "cj-stroke-grey-secondary",
                      attrs: { name: "ic_eye_open", width: "1rem" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.passwordInputType === "password"
                  ? _c("icon", {
                      staticClass: "cj-stroke-grey-secondary",
                      attrs: { name: "ic_eye_close", width: "1rem" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.confirmPassword.length > 0 &&
          _vm.confirmPassword !== _vm.cbUserInfo.password
            ? _c("div", [
                _c("small", {
                  staticClass: "font-validation cj-color-status-error",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationConfirmPassword"
                      )
                    ),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _c("span", {
              staticClass: "font-validation",
              style: "color:" + _vm.styleValidPassOne,
              attrs: { id: "span-one" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationOne"
                  )
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _c("span", {
              staticClass: "font-validation",
              style: "color:" + _vm.styleValidPassTwo,
              attrs: { id: "span-two" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationTwo"
                  )
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _c("span", {
              staticClass: "font-validation",
              style: "color:" + _vm.styleValidPassThree,
              attrs: { id: "span-three" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationThree"
                  )
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _c("span", {
              staticClass: "font-validation",
              style: "color:" + _vm.styleValidPassFour,
              attrs: { id: "span-four" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationFour"
                  )
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "my-4" }, [
            _c(
              "p",
              {
                staticClass:
                  "w-auto my-auto mr-2 font-roboto cj-color-black-primary font-weight-400 font-size-12",
              },
              [
                _c("span", {
                  staticClass: "empty",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.verificationOption"
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "cj-color-grey-secondary",
                    staticStyle: { "margin-left": "-0.2rem" },
                  },
                  [_vm._v("*")]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex mt-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "custom-control custom-checkbox cb-custom-checkbox my-auto mr-2",
                  class: {
                    "valid-checkbox":
                      _vm.cbUserInfo.phoneVerificationOpt && _vm.isDirty,
                    "invalid-checkbox":
                      !_vm.cbUserInfo.phoneVerificationOpt &&
                      !_vm.cbUserInfo.emailVerificationOpt &&
                      _vm.isDirty,
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cbUserInfo.phoneVerificationOpt,
                        expression: "cbUserInfo.phoneVerificationOpt",
                      },
                    ],
                    staticClass: "custom-control-input",
                    attrs: {
                      type: "checkbox",
                      id: `m-check-phone-number-register-buyer`,
                      "data-gtm": "whatsapp-verif-clickable",
                      "data-cy": "whatsapp-verif",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.cbUserInfo.phoneVerificationOpt
                      )
                        ? _vm._i(_vm.cbUserInfo.phoneVerificationOpt, null) > -1
                        : _vm.cbUserInfo.phoneVerificationOpt,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.cbUserInfo.phoneVerificationOpt,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.cbUserInfo,
                                  "phoneVerificationOpt",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.cbUserInfo,
                                  "phoneVerificationOpt",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.cbUserInfo,
                              "phoneVerificationOpt",
                              $$c
                            )
                          }
                        },
                        function ($event) {
                          return _vm.validationDirty($event)
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: `m-check-phone-number-register-buyer` },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "ml-2 my-auto cj-color-black-primary font-weight-400 font-size-12",
                        },
                        [_vm._v("WhatsApp")]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "custom-control custom-checkbox cb-custom-checkbox my-auto",
                  class: {
                    "valid-checkbox":
                      _vm.cbUserInfo.emailVerificationOpt && _vm.isDirty,
                    "invalid-checkbox":
                      !_vm.cbUserInfo.phoneVerificationOpt &&
                      !_vm.cbUserInfo.emailVerificationOpt &&
                      _vm.isDirty,
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cbUserInfo.emailVerificationOpt,
                        expression: "cbUserInfo.emailVerificationOpt",
                      },
                    ],
                    staticClass: "custom-control-input",
                    attrs: {
                      type: "checkbox",
                      id: `m-check-email-register-buyer`,
                      "data-cy": "email-verif",
                      "data-gtm": "email-verif-clickable",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.cbUserInfo.emailVerificationOpt
                      )
                        ? _vm._i(_vm.cbUserInfo.emailVerificationOpt, null) > -1
                        : _vm.cbUserInfo.emailVerificationOpt,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.cbUserInfo.emailVerificationOpt,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.cbUserInfo,
                                  "emailVerificationOpt",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.cbUserInfo,
                                  "emailVerificationOpt",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.cbUserInfo,
                              "emailVerificationOpt",
                              $$c
                            )
                          }
                        },
                        function ($event) {
                          return _vm.validationDirty($event)
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: `m-check-email-register-buyer` },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "ml-2 my-auto cj-color-black-primary font-weight-400 font-size-12",
                        },
                        [_vm._v("Email")]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "c-button",
                {
                  staticClass: "font-roboto mr-2 text-center my-2",
                  attrs: {
                    disabled:
                      _vm.isExistEmail ||
                      !_vm.isPasswordSame ||
                      _vm.$v.cbUserInfo.$invalid ||
                      !_vm.isLowerCase ||
                      !_vm.isUpperCase ||
                      !_vm.isCharacter ||
                      !_vm.isAllValidate ||
                      (!_vm.cbUserInfo.emailVerificationOpt &&
                        !_vm.cbUserInfo.phoneVerificationOpt) ||
                      _vm.isLoadingSubmit ||
                      _vm.isFetchingPhoneNumber ||
                      _vm.isFetchingEmail,
                    dataGTMCustom: "submit-register",
                    fullWidth: "",
                    type: "button",
                    "data-cy": "button-submit-register-m",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSubmitRegister()
                    },
                  },
                },
                [
                  _vm.isLoadingSubmit
                    ? _c("font-awesome-icon", {
                        attrs: { icon: "sync", spin: _vm.isLoadingSubmit },
                      })
                    : _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.btnTitle"
                            )
                          ),
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-catamaran text-center font-weight-300 cj-color-grey-secondary font-size-10 my-2",
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbHome.cbHomeBeforeLogin.register.descRegister"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("a", {
                    staticClass: "cj-color-red-primary font-weight-300",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbHome.cbHomeBeforeLogin.register.termOfService"
                        )
                      ),
                    },
                    on: {
                      click: function ($event) {
                        _vm.openModalTermOfService = !_vm.openModalTermOfService
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.register.and")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("a", {
                    staticClass: "cj-color-red-primary font-weight-300",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbHome.cbHomeBeforeLogin.register.privacyPolicy"
                        )
                      ),
                    },
                    on: {
                      click: function ($event) {
                        _vm.openModalPrivacyPolicy = !_vm.openModalPrivacyPolicy
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "c-popup-bottom",
        {
          attrs: { id: "popup-register-m-phone-code" },
          model: {
            value: _vm.isShowPhoneCode,
            callback: function ($$v) {
              _vm.isShowPhoneCode = $$v
            },
            expression: "isShowPhoneCode",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between mb-3 py-2 border-grey-tertiary-bottom-1",
            },
            [
              _c("p", {
                staticClass:
                  "font-roboto font-size-14 cj-color-black-primary font-weight-500",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.register.phoneCode"
                    )
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "font-roboto font-size-14 font-weight-500 cj-btn-text-primary",
                  class: {
                    disabled: !_vm.localPhoneCode,
                  },
                  attrs: {
                    type: "button",
                    "data-cy": "button-update-phone-code",
                    disabled: !_vm.localPhoneCode,
                  },
                  on: {
                    click: function ($event) {
                      _vm.$v.cbUserInfo.phoneCode.$model = _vm.localPhoneCode
                      _vm.isShowPhoneCode = false
                      _vm.handleCheckPhone()
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbCreateAccount.placeholder.update")
                      ),
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("c-autocomplete-new", {
            staticClass:
              "font-roboto font-size-14 cj-color-black-primary font-weight-400 cj-field-not-border",
            attrs: {
              dataCyCustom: "country",
              items: _vm.cbLovPhoneCodes,
              "item-text": "value",
              "item-value": "key",
              placeholder: "ID (+62)",
              isShowDropdown: true,
              isShowListItem: true,
              inputStyle: "cj-field-border-default rounded px-3",
            },
            model: {
              value: _vm.localPhoneCode,
              callback: function ($$v) {
                _vm.localPhoneCode = $$v
              },
              expression: "localPhoneCode",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-register-m-term-of-service",
            centered: "",
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.openModalTermOfService,
            callback: function ($$v) {
              _vm.openModalTermOfService = $$v
            },
            expression: "openModalTermOfService",
          },
        },
        [
          _c("c-tmp-announcement", {
            attrs: {
              title: _vm.$t("cbgwApp.cbCreateAccount.termOfService"),
              content: _vm.TERM_OF_SERVICE,
            },
            on: {
              close: function ($event) {
                _vm.openModalTermOfService = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-register-m-privacy-policy",
            centered: "",
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.openModalPrivacyPolicy,
            callback: function ($$v) {
              _vm.openModalPrivacyPolicy = $$v
            },
            expression: "openModalPrivacyPolicy",
          },
        },
        [
          _c("c-tmp-announcement", {
            attrs: {
              title: _vm.$t("cbgwApp.cbCreateAccount.termPrivacyPolicy"),
              content: _vm.PRIVACY_POLICY,
            },
            on: {
              close: function ($event) {
                _vm.openModalPrivacyPolicy = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }