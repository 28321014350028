import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';

import PCbUserBookmarkAggService from '@/services/cb-user-bookmark-agg.service';
import ContentService from '@/services/content.service';

import { isVideoFile } from '@/shared/media-file/media-file';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { CbUserBookmarkAgg, ICbUserBookmarkAgg } from '@/shared/model/cb-user-bookmark-agg.model';
import { CbBookmarkType } from '@/shared/model/enumerations/cb-bookmark-type.model';

@Component({
  components: { Icon },
})
export default class CPdPortfolioPreview extends Vue {
  // ================= START SERVICES ===================
  @Inject('pCbUserBookmarkAggService') private pCbUserBookmarkAggService: () => PCbUserBookmarkAggService;
  @Inject('contentService') public contentService: () => ContentService;
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop() public item;

  public isPlaying = false;
  public isPause = false;
  public isMuted = true;
  public isShowVideo = false;
  public CbBookmarkType = CbBookmarkType;

  public cbUserBookmarkAgg: ICbUserBookmarkAgg = new CbUserBookmarkAgg();
  public countLike = 0;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  mounted() {
    this.contentService().initRetrieveCbUserBookmarkAggs(CbBookmarkType.LIKE_CB_ATTACHMENT_NO_AUTH);
    this.retrieveCbUserBookmarkAgg(this.item.cbAttachmentNoAuthId);
    // console.log(this.cbUserBookmarkAggs, 'cbUserBookmarkAggs')
  }
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  public handlePlayVideo() {
    this.isPlaying = true;
    this.isPause = false;
    setTimeout(() => {
      (<any>this.$refs.myVideoPortfolioPreview).play();
    }, 50);
  }
  public handlePauseVideo() {
    this.isPause = true;
    setTimeout(() => {
      (<any>this.$refs.myVideoPortfolioPreview).pause();
    }, 50);
  }

  public handleMuteVideo() {
    this.isMuted = !this.isMuted;
  }

  public isVideo(attContentType: string) {
    this.isShowVideo = isVideoFile(attContentType);
    return this.isShowVideo;
  }

  public async saveCbUserBookmarkAgg(dataValue) {
    this.$emit('noLoginPortfolioDetail');
    this.cbUserBookmarkAgg.dataId = dataValue.cbAttachmentNoAuthId;
    this.cbUserBookmarkAgg.bookmarkType = CbBookmarkType.LIKE_CB_ATTACHMENT_NO_AUTH;
    if (
      this.$options.filters.findBookmarkByIdIsActive(
        this.GET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH,
        dataValue.cbAttachmentNoAuthId,
        CbBookmarkType.LIKE_CB_ATTACHMENT_NO_AUTH
      )
    ) {
      this.cbUserBookmarkAgg.isActive = false;
    } else {
      this.cbUserBookmarkAgg.isActive = true;
    }
    this.pCbUserBookmarkAggService()
      .create(this.cbUserBookmarkAgg)
      .then(async res => {
        res.cbUserInfo = null;
        this.insertBookmarkAggsOnStore(res, this.cbUserBookmarkAgg);
        this.retrieveCbUserBookmarkAgg(dataValue.cbAttachmentNoAuthId);
      })
      .catch(err => {
        console.log('ERROR', err.response);
      });
  }
  public retrieveCbUserBookmarkAgg(dataId) {
    const params = {
      'dataId.equals': dataId,
      'bookmarkType.equals': CbBookmarkType.LIKE_CB_ATTACHMENT_NO_AUTH,
      'isActive.equals': true,
    };
    this.pCbUserBookmarkAggService()
      .retrieveCount(params)
      .then(res => {
        this.countLike = res.data;
      })
      .catch(err => {});
  }

  public insertBookmarkAggsOnStore(res: ICbUserBookmarkAgg, cbUserBookmarkAgg: ICbUserBookmarkAgg) {
    const userBookmarkAggs = [...this.GET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH];
    const bookMarkIdx = userBookmarkAggs.findIndex(
      v => v.dataId == cbUserBookmarkAgg.dataId && v.bookmarkType == cbUserBookmarkAgg.bookmarkType
    );
    if (bookMarkIdx >= 0 && !cbUserBookmarkAgg.isActive) {
      Vue.delete(userBookmarkAggs, bookMarkIdx);
    } else if (bookMarkIdx < 0 && cbUserBookmarkAgg.isActive) {
      userBookmarkAggs.push(res);
    }
    this.$store.commit('SET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH', userBookmarkAggs);
  }

  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  public get GET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH() {
    return this.$store.getters.GET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
