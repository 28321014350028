import dayjs from 'dayjs';
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import { formatTimestamp, parseTimestamp } from '@/module/chat-container/utils/dates';

import CLivechat from '@/components/c-livechat/c-livechat.vue';
import CLivechatCreate from '@/components/c-livechat-create/c-livechat-create.vue';

import TranslationService from '@/locale/translation.service';
import ChtFileService from '@/services/cht-file.service';
import ChtMessageDeleteService from '@/services/cht-message-delete.service';
import ChtReactionService from '@/services/cht-reaction.service';
import ChtStarService from '@/services/cht-star.service';
import ChtMessageSeenService from '@/services/cht-message-seen.service';
import PChtService from '@/services/cht.service';
import PCmVwTktService from '@/services/cm-vw-tkt.service';
import AlertService from '@/shared/alert/alert.service';

import { ChtMessage, IChtMessage } from '@/shared/model/cht-message.model';
import { ChtReaction, IChtReaction } from '@/shared/model/cht-reaction.model';
import { ChtRoom, IChtRoom } from '@/shared/model/cht-room.model';
import { ChtStar, IChtStar } from '@/shared/model/cht-star.model';
import { ChtLiveAgentQuestion, IChtLiveAgentQuestion } from '@/shared/model/cht-live-agent-question.model';
import { ChtConversation, IChtConversation } from '@/shared/model/cht-conversation.model';
import { ChtMessageSeen, IChtMessageSeen } from '@/shared/model/cht-message-seen.model';
import { IChtVwConversation } from '@/shared/model/cht-vw-conversation.model';
import { IChtVwMessage } from '@/shared/model/cht-vw-message.model';
import { ICbVwUserCore } from '@/shared/model/cb-vw-user-core.model';
import { ChtFile } from '@/shared/model/cht-file.model';
import { ChtConversationType } from '@/shared/model/enumerations/cht-conversation-type.model';
import { CmVwTkt, ICmVwTkt } from '@/shared/model/cm-vw-tkt.model';
import { CmTktStatusResolutionType } from '@/shared/model/enumerations/cm-tkt-status-resolution-type.model';
import { CHT_APP_SELECTED, GOOGLE_TRANSLATE_FIELD_PERMITTED } from '@/constants';
import { SESSION_STORAGE_CB_JHI_ID_TOKEN } from '@/shared/constant/constants-session-storage';

@Component({
  components: {
    CLivechat,
    CLivechatCreate,
  },
})
export default class LiveChat extends Vue {
  @Inject('pChtService') private pChtService: () => PChtService;
  @Inject('chtFileService') private chtFileService: () => ChtFileService;
  @Inject('chtReactionService') private chtReactionService: () => ChtReactionService;
  @Inject('chtMessageDeleteService') private chtMessageDeleteService: () => ChtMessageDeleteService;
  @Inject('chtStarService') private chtStarService: () => ChtStarService;
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('pCmVwTktService') private pCmVwTktService: () => PCmVwTktService;
  @Inject('chtMessageSeenService') private chtMessageSeenService: () => ChtMessageSeenService;
  @Inject('alertService') private alertService: () => AlertService;

  public tkt: IChtLiveAgentQuestion = new ChtLiveAgentQuestion();
  public chtLiveAgentConversation: IChtConversation = new ChtConversation();
  public isShowliveChat = false;

  public isDevice = false;
  private theme = 'light';

  roomId = '';
  public pinnedIndex = 10000000000000;
  roomsPerPage = 15;
  rooms: IChtRoom[] = [];
  startRooms = null;
  endRooms = null;
  roomsLoaded = false;
  loadingRooms = true;
  allUsers = [];
  loadingLastMessageByRoom = 0;
  roomsLoadedCount = 0;
  selectedRoom: number = null;
  messagesPerPage = 20;
  messages = [];
  messagesLoaded = false;
  roomMessage = '';
  lastLoadedMessage = null;
  previousLastLoadedMessage = null;
  roomsListeners = [];
  listeners = [];
  typingMessageCache = '';
  disableForm = false;
  addNewRoom = null;
  addRoomUsername = '';
  inviteRoomId = null;
  invitedUsername = '';
  removeRoomId = null;
  removeUserId = '';
  removeUsers = [];
  roomActions = [
    { name: 'inviteUser', title: 'Invite User' },
    { name: 'removeUser', title: 'Remove User' },
    { name: 'deleteRoom', title: 'Delete Room' },
  ];
  menuActions = [
    // { name: 'inviteUser', title: 'Invite User' },
    // { name: 'removeUser', title: 'Remove User' },
    // { name: 'deleteRoom', title: 'Delete Room' },
    { name: 'pinnedRoom', title: 'Pin Chat' },
    // { name: 'starredRoom', title: 'Starred Message' },
    { name: 'viewProfile', title: 'View Profile' },
    { name: 'reportUser', title: 'Report User' },
  ];
  messageSelectionActions = [{ name: 'deleteMessages', title: 'Delete' }];
  styles = { container: { borderRadius: '4px' } };
  templatesText = [
    {
      tag: 'help',
      text: 'This is the help',
    },
    {
      tag: 'action',
      text: 'This is the action',
    },
    {
      tag: 'action 2',
      text: 'This is the second action',
    },
  ];
  // ,dbRequestCount: 0

  public isFetching = false;
  public queryCount: number = null;
  public pageRoom = 1;
  public previousPage = 1;
  public propOrder = 'id';
  public reverse = false;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = null;
  public chtVwConversations: IChtVwConversation[] = [];
  public cbVwUsers: ICbVwUserCore[] = [];

  //  ===============
  //  =============== room
  //  ===============

  public chtVwMessages = [];
  public queryCountVwMessage: number = null;
  public infiniteIdVwMessage = +new Date();
  public itemsPerPageVwMessage = 20;
  public previousPageVwMessage = 1;
  public propOrderVwMessage = 'createdDate';
  public reverseVwMessage = true;
  public totalItemsVwMessage = 0;
  public linksVwMessage = null;
  public messagesCollection;
  public pageVwMessage = 1;
  public isProgressUploadFile = false;
  public filesCopy = [];
  public token = localStorage.getItem(SESSION_STORAGE_CB_JHI_ID_TOKEN);
  public page;
  public isClosed = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  created() {
    this.$root.$off('chat::retrieveOneMessageLa');
    this.$root.$on('chat::retrieveOneMessageLa', (conversationId, messageId) => {
      //just twice validate
      if (conversationId != this.selectedRoom) return;
      this.listenMessageById(messageId);
    });

    this.$root.$off('chat::retrieveOneMessageLaLastSeen');
    this.$root.$on('chat::retrieveOneMessageLaLastSeen', (conversationId, msg) => {
      //just twice validate
      if (conversationId != this.selectedRoom) return;
      this.listenMessageByIdLastSeen(conversationId, msg);
    });

    (<any>this.$root).$emit('showFloatingLiveChat', false);
    (<any>this.$root).$emit('showFloatingStore', false);

    this.$root.$off('lc::changeValue');
    this.$root.$on('lc::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });

    (<any>this.$root).$off('lc::changeFunction');
    (<any>this.$root).$on('lc::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
  }

  async mounted() {
    this.isShowliveChat = true;
    this.chtLiveAgentConversation = this.$store.getters.chtLiveAgentConversation;

    // this.chtLiveAgentConversation.id = null; for testing
    await this.findCmVwTktByConversationId(this.chtLiveAgentConversation.id);
    if (!this.chtLiveAgentConversation.id) {
      this.isShowliveChat = false;
      this.isClosed = false;
    } else if (
      this.chtLiveAgentConversation.id &&
      this.chtLiveAgentConversation.createdDate &&
      dayjs(this.chtLiveAgentConversation.createdDate).diff(new Date(), 'hour') <= -8
    ) {
      //more than 8 hour
      this.isShowliveChat = false;
      this.tkt = new ChtLiveAgentQuestion();
      this.chtLiveAgentConversation = new ChtConversation();
      this.$store.commit('chtLiveAgentConversation', this.chtLiveAgentConversation);
      this.isShowliveChat = false;
      this.isClosed = false;
    } else if (this.cmVwTkt.statusResolution == CmTktStatusResolutionType.CLOSED) {
      this.isShowliveChat = false;
      this.tkt = new ChtLiveAgentQuestion();
      this.chtLiveAgentConversation = new ChtConversation();
      this.$store.commit('chtLiveAgentConversation', this.chtLiveAgentConversation);
      this.isShowliveChat = false;
      this.isClosed = false;
    } else {
      this.initSocketLiveAgent();
    }
  }

  public initSocketLiveAgent() {
    this.$socketInitSocketLiveAgent();
    this.isShowliveChat = true;
    this.fetchRooms();
  }

  public callBack(e) {
    if (this.isMobile) {
      this.$router.go(-1);
    } else {
      this.$emit('callBack', e);
    }
  }

  public toLiveChat() {
    this.$emit('submit');
  }

  public toNavigateConversation() {
    this.$router.replace({ name: 'CLivechat' });
  }

  public onBack() {
    this.$router.go(-1);
  }

  public createConversation() {
    this.tkt.app = CHT_APP_SELECTED;
    if (this.authenticated) {
      this.tkt.email = this.userLogin.email;
    }

    this.pChtService()
      .createConversationLiveAgentNoLogin(this.tkt)
      .then(res => {
        this.chtLiveAgentConversation = res;
        this.$store.commit('chtLiveAgentConversation', this.chtLiveAgentConversation);
        this.initSocketLiveAgent();
        const idToken = localStorage.getItem(SESSION_STORAGE_CB_JHI_ID_TOKEN);
        if (this.authenticated && idToken) {
          this.updateRareUserIdChtConversation(idToken, this.chtLiveAgentConversation.id);
        }
      });
  }

  public updateRareUserIdChtConversation(token: string, id: number) {
    this.pChtService()
      .partialUpdateLiveChatByToken(token, id)
      .then(res => {
        //todo

        if (res && res.data) {
          // this.chtLiveAgentConversation = this.$store.getters.chtLiveAgentConversation;
          this.chtLiveAgentConversation.realUserId = res.data.realUserId;
          this.$store.commit('chtLiveAgentConversation', this.chtLiveAgentConversation);
        }
      });
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get username(): string {
    return this.$store.getters.account?.login ?? '';
  }

  public get userLogin() {
    return this.$store.getters.account;
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  //

  //  ===============
  //  =============== end room
  //  ===============

  // computed: {
  public get loadedRooms() {
    return this.rooms.slice(0, this.roomsLoadedCount);
  }

  public get screenHeight() {
    // return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh - 80px)'
    // return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh - (80px + 6rem))';
    return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh -  126px)';
  }

  public get currentVwUser() {
    return this.$store.getters.currentUser;
  }

  public get userIdsOnline() {
    return this.$store.getters.userIdsOnline;
  }

  public get currentUserId() {
    return <string>this.chtLiveAgentConversation.liveAgentKeyTrans;
  }

  resetRooms() {
    this.loadingRooms = true;
    this.loadingLastMessageByRoom = 0;
    this.roomsLoadedCount = 0;
    this.rooms = [];
    this.roomsLoaded = true;
    this.startRooms = null;
    this.endRooms = null;
    this.roomsListeners.forEach(listener => listener());
    this.roomsListeners = [];
    this.pageRoom = 1;
    this.resetMessages();
  }

  resetMessages() {
    this.messages = [];
    this.messagesLoaded = false;
    this.lastLoadedMessage = null;
    this.previousLastLoadedMessage = null;
    this.listeners.forEach(listener => listener());
    this.listeners = [];
    this.pageVwMessage = 1;
  }

  // ===============================
  // ===============================
  // ROOM
  // ===============================
  // ===============================

  fetchRooms() {
    this.resetRooms();
    this.fetchMoreRooms({});
  }

  async fetchMoreRooms({ options = {} }) {
    if (!this.loadingRooms && (<any>options).isLoadMore) {
      this.pageRoom++;
    }
    this.retrieveAgentConversations();

    //this.listenRooms(query);
  }

  async loadRoom(query) {}

  menuActionHandler({ action, roomId, currentUserId }) {
    switch (action.name) {
      case 'inviteUser':
        return this.inviteUser(roomId);
      case 'removeUser':
        return this.removeUser(roomId);
      case 'deleteRoom':
        return this.deleteRoom(roomId);
      case 'pinnedRoom':
        return this.pinnedRoom(roomId);
    }
  }

  public listenMessageById(messageId) {
    this.isFetching = true;

    this.pChtService()
      .retrieveMessageBymessageIdAndKey(messageId, this.chtLiveAgentConversation.liveAgentKeyTrans)
      .then(
        res => {
          if (this.isProgressUploadFile && res.data.authorId == this.currentUserId) {
            return;
          }
          const room = null;
          const message = res.data;
          console.log(message);
          console.log(this.messages);

          const formattedMessage = this.formatMessage(room, message);
          const messageIndex = this.messages.findIndex(m => m._id === message.id);

          if (messageIndex === -1) {
            this.messages = this.messages.concat([formattedMessage]);
          } else {
            const messageCurrent: IChtVwMessage = this.messages.find(msg => msg._id === message.id);
            messageCurrent.reactionsTransient = formattedMessage.reactionsTransient;

            //[bugfixing] when click reaction, show blink
            if (formattedMessage.reactions != messageCurrent.reactions) {
              formattedMessage.cbVwPawOrderRequirement = messageCurrent.cbVwPawOrderRequirement;
              formattedMessage.files = messageCurrent.files;
            }

            this.messages[messageIndex] = formattedMessage;
            this.messages = [...this.messages]; //copy data with new like
          }

          this.$root.$emit('fc::changeValue', 'messagesLiveChat', this.messages);
          // this.markMessagesSeen(message);
        },
        err => {
          this.isFetching = false;
        }
      );
  }

  public listenMessageByIdLastSeen(conversationId, message) {
    const msg = this.messages.find(m => m.id === message.messageId);
    if (msg.authorId !== this.chtLiveAgentConversation.liveAgentKeyTrans) return;

    this.pChtService()
      .retrieveMessageBymessageIdAndKey(message.messageId, this.chtLiveAgentConversation.liveAgentKeyTrans)
      .then(res => {
        const msgIndex = this.messages.findIndex(m => m.id === message.messageId);

        this.messages[msgIndex].seenByOther = true;
        this.messages = [...this.messages]; //copy data with new like
      });
  }

  public sortVwMessage(): Array<any> {
    const result = [this.propOrderVwMessage + ',' + (this.reverseVwMessage ? 'desc' : 'asc')];
    if (this.propOrderVwMessage !== 'id') {
      result.push(this.propOrderVwMessage);
    }
    return result;
  }

  formatLastMessage(chtVwConversation: IChtVwConversation) {
    // alert(message.timestamp)
    // if (!message.timestamp) return;

    // let content = message.content;
    // if (message.files?.length) {
    //   const file = message.files[0];
    //   content = `${file.name}.${file.extension || file.type}`;
    // }

    const username = this.cbVwUsers.find(user => chtVwConversation.lastMsgAuthor === user.id)?.username;

    return {
      ...{
        content: chtVwConversation.lastMsg,
        senderId: chtVwConversation.lastMsgAuthor,
        timestamp: formatTimestamp(
          new Date(chtVwConversation.lastMsgDate),
          new Date(chtVwConversation.lastMsgDate),
          this.$t('chat.yesterday')
        ),
        username: username,
        distributed: true,
        iconMsg: chtVwConversation.lastMsgFileType,
        // seen: false, //message.sender_id === this.currentUserId ? message.seen : null,
        // new:true ,// message.sender_id !== this.currentUserId && (!message.seen || !message.seen[this.currentUserId]),
        lastMessage: { lastMessage: chtVwConversation.lastMsg, senderId: username },
      },
    };
  }

  public formatRoom(chtVwConversation: IChtVwConversation): IChtRoom {
    const room: IChtRoom = new ChtRoom();
    room.id = chtVwConversation.id;
    room.roomName = 'AGENT';
    room.users = [];
    room.users.push({ username: 'AGENT', _id: 'AGENT', id: 'AGENT' });

    // room.users = [{ username: 'a', _id: '33mEBwitxQAXsfUbdFDC', id: '33mEBwitxQAXsfUbdFDC' }];
    //room.lastUpdated= { "seconds": 1668252069, "nanoseconds": 247000000 };
    room.typingUsers = [];

    room.avatar = `${process.env.CDN_CB}/content/cb-img/ic-livechat-cs.png`;

    room.distributed = true;
    room.senderId = chtVwConversation.lastModifiedBy;
    room.sender_id = chtVwConversation.lastModifiedBy;
    //const roomAvatar = roomContacts.length === 1 && roomContacts[0].avatar ? roomContacts[0].avatar : require('./assets/logo.png');
    room.members = chtVwConversation.members;

    // positionScrollMsgIds
    if (chtVwConversation.positionScrollMsgIds) {
      const found = chtVwConversation.positionScrollMsgIds.split('||').find(x => x.includes(this.currentUserId));
      const positionScrollMsgId = Number(found?.split('::')[1] ?? 0);
      room.positionScrollMsgId = positionScrollMsgId;
    }

    room.roomId = chtVwConversation.id;
    if (chtVwConversation.countMsgs) {
      const found = chtVwConversation.countMsgs.split('||').find(x => x.includes(this.currentUserId));
      const count = Number(found?.split('::')[1] ?? 0);
      room.unreadCount = count > 99 ? 99 : count;
    }

    if (chtVwConversation.lastMsgDate != null)
      room.lastMessage = {
        distributed: true,
        content: chtVwConversation.lastMsg ? chtVwConversation.lastMsg : '',
        timestamp: formatTimestamp(new Date(chtVwConversation.lastMsgDate), new Date(chtVwConversation.lastMsgDate)),
        iconMsg: chtVwConversation.lastMsgFileType,
      };
    room.lastMsgDate = chtVwConversation.lastMsgDate;
    room.createdDate = chtVwConversation.createdDate;

    const pinnedDate = this.pinnedMapping(chtVwConversation);
    if (pinnedDate) {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
          // Chrome
          room.index = this.pinnedIndex + new Date(pinnedDate).getTime();
        } else {
          // Safari
          room.index = this.pinnedIndex + new Date(pinnedDate.replace(/ /g, 'T')).getTime();
        }
      } else {
        // all browser
        room.index = this.pinnedIndex + new Date(pinnedDate).getTime();
      }
      room.isPinned = true;
    } else {
      room.index = chtVwConversation.lastMsgDate
        ? new Date(chtVwConversation.lastMsgDate).getTime()
        : new Date(chtVwConversation.createdDate).getTime(); //to move to top
      room.isPinned = false;
    }

    return room;
  }

  listenRooms(roomId) {
    this.pChtService()
      .findChtVwConversation(roomId)
      .then(
        async res => {
          const chtVwConversation = res;

          const foundRoom = this.rooms.find(r => r.roomId === roomId);

          if (foundRoom) {
            const lastMessage = this.formatLastMessage(chtVwConversation);

            const roomIndex = this.rooms.findIndex(r => roomId == r.roomId);

            const pinnedDate = this.pinnedMapping(chtVwConversation);
            let newIndex = new Date(chtVwConversation.lastMsgDate).getTime();
            let isPinned = false;
            if (pinnedDate) {
              newIndex = this.pinnedIndex + new Date(pinnedDate).getTime();
              isPinned = true;
            } else {
              newIndex = new Date(chtVwConversation.lastMsgDate).getTime();
              isPinned = false;
            }

            this.rooms[roomIndex].lastMsgDate = chtVwConversation.lastMsgDate;
            this.rooms[roomIndex].createdDate = chtVwConversation.createdDate;

            this.rooms[roomIndex].isPinned = isPinned;
            this.rooms[roomIndex].lastMessage = lastMessage;
            this.rooms[roomIndex].index = newIndex; //to move to top
            this.rooms = [...this.rooms];
          } else {
            //new Room
            await this.retrieveCbVwUser([chtVwConversation]); //one conversation
            const formattedRooms = [];
            formattedRooms.push({ ...this.formatRoom(chtVwConversation) });
            this.rooms = this.rooms.concat(formattedRooms);
          }
        },
        err => {}
      );
  }

  private pinnedMapping(chtVwConversation: IChtVwConversation) {
    if (chtVwConversation.pinnedByMembers) {
      const found = chtVwConversation.pinnedByMembers.split('||').find(x => x.includes(this.currentUserId));
      const pinned = found?.split('::')[1];
      if (pinned == 'true') {
        const pinnedDate = found?.split('::')[2];
        return pinnedDate;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addRoom() {
    this.resetForms();
    this.addNewRoom = true;
  }

  async createRoom() {}

  inviteUser(roomId) {
    this.resetForms();
    this.inviteRoomId = roomId;
  }

  async addRoomUser() {}

  removeUser(roomId) {}

  async deleteRoomUser() {}

  async deleteRoom(roomId) {}

  async pinnedRoom(roomId) {
    const room = this.rooms.find(r => roomId === r.roomId);
    room.isPinned = !room.isPinned;
    if (room.isPinned) {
      room.index = this.pinnedIndex + new Date().getTime();
    } else {
      room.index = room.lastMsgDate ? new Date(room.lastMsgDate).getTime() : new Date(room.createdDate).getTime(); //to move to top
      room.isPinned = false;
    }

    this.pChtService()
      .pinned(roomId, room.isPinned)
      .then(res => {});
  }

  resetForms() {
    this.disableForm = false;
    this.addNewRoom = null;
    this.addRoomUsername = '';
    this.inviteRoomId = null;
    this.invitedUsername = '';
    this.removeRoomId = null;
    this.removeUserId = '';
  }

  async retrieveAgentConversations() {
    this.chtVwConversations = [this.chtLiveAgentConversation];

    console.log(this.chtVwConversations);

    //await this.retrieveCbVwUser(this.chtVwConversations);

    this.totalItems = 1;
    this.queryCount = this.totalItems;
    this.isFetching = false;
    if (<any>this.$refs.infiniteLoading) {
      (<any>this.$refs.infiniteLoading).stateChanger.loaded();
      if (this.links && this.page > this.links['last']) {
        (<any>this.$refs.infiniteLoading).stateChanger.complete();
      }
    }

    const formattedRooms = [];

    [...this.chtVwConversations].forEach((chtVwConversation, idx) => {
      formattedRooms.push({ ...this.formatRoom(chtVwConversation) });
    });

    this.rooms = this.rooms.concat(formattedRooms);
    //this.rooms=[{ "users": [ { "username": "a", "_id": "33mEBwitxQAXsfUbdFDC", "id": "33mEBwitxQAXsfUbdFDC" } ], "typingUsers": [], "lastUpdated": { "seconds": 1668252069, "nanoseconds": 247000000 }, "id": "YbNDBISFI1bCBQaSbTce", "roomName": "a", "roomId": "YbNDBISFI1bCBQaSbTce", "avatar": "http://localhost:9000/app/../9b596f63b94c587cfe48.png", "index": 1668252069, "lastMessage": { "content": "ss", "sender_id": "6R0MijpK6M4AIrwaaCY2", "timestamp": "12/11/2022", "id": "Dv3OZoVoXqC2d57RqQWt", "senderId": "6R0MijpK6M4AIrwaaCY2", "username": "", "distributed": true, "new": false, "lastMessage": { "senderId": "6R0MijpK6M4AIrwaaCY2" } } }];

    this.roomsLoaded = false; // this.chtVwConversations.length === 0 || this.chtVwConversations.length < this.roomsPerPage;
    this.loadingRooms = false;
    this.roomsLoadedCount = this.rooms.length; //must declare

    this.selectedRoom = this.rooms[0].roomId;
  }

  async retrieveAllChtVwConversations() {
    const paginationQuery = {
      page: this.pageRoom - 1,
      size: this.roomsPerPage,
      sort: ['lastModifiedDate,desc'],
      moreFilter: 'isLiveChat.equals=true&conversationType.notEquals=' + ChtConversationType.CATEGORY + '&',
    };
    this.pChtService()
      .retrieveConversationByLogin(paginationQuery)
      .then(
        async res => {
          this.chtVwConversations = res.data;
          await this.retrieveCbVwUser(this.chtVwConversations);
          this.totalItems = Number(res.headers['x-total-count']);
          this.queryCount = this.totalItems;
          this.isFetching = false;
          if (<any>this.$refs.infiniteLoading) {
            (<any>this.$refs.infiniteLoading).stateChanger.loaded();
            if (this.links && this.page > this.links['last']) {
              (<any>this.$refs.infiniteLoading).stateChanger.complete();
            }
          }

          const formattedRooms = [];

          [...this.chtVwConversations].forEach((chtVwConversation, idx) => {
            formattedRooms.push({ ...this.formatRoom(chtVwConversation) });
          });

          this.rooms = this.rooms.concat(formattedRooms);
          //this.rooms=[{ "users": [ { "username": "a", "_id": "33mEBwitxQAXsfUbdFDC", "id": "33mEBwitxQAXsfUbdFDC" } ], "typingUsers": [], "lastUpdated": { "seconds": 1668252069, "nanoseconds": 247000000 }, "id": "YbNDBISFI1bCBQaSbTce", "roomName": "a", "roomId": "YbNDBISFI1bCBQaSbTce", "avatar": "http://localhost:9000/app/../9b596f63b94c587cfe48.png", "index": 1668252069, "lastMessage": { "content": "ss", "sender_id": "6R0MijpK6M4AIrwaaCY2", "timestamp": "12/11/2022", "id": "Dv3OZoVoXqC2d57RqQWt", "senderId": "6R0MijpK6M4AIrwaaCY2", "username": "", "distributed": true, "new": false, "lastMessage": { "senderId": "6R0MijpK6M4AIrwaaCY2" } } }];

          this.roomsLoaded = res.data.length === 0 || res.data.length < this.roomsPerPage;
          this.loadingRooms = false;
          this.roomsLoadedCount = this.rooms.length; //must declare
        },
        err => {
          this.loadingRooms = false;
          this.roomsLoadedCount = 0;
          // this.isFetching = false;
        }
      );
  }

  async retrieveCbVwUser(chtVwConversations: IChtVwConversation[]): Promise<any> {
    let members = '';
    for (const item of chtVwConversations) {
      members = members + ' || ' + item.members;
    }
    // this.isFetchingCbVwUser = true;
    const params: any = {
      members,
    };

    return new Promise((resolve, reject) => {
      this.pChtService()
        .retrieveVwUser(params)
        .then(res => {
          res.data.forEach(user => {
            if (this.cbVwUsers.find(ex => ex.id === user.id)) {
            } else {
              this.cbVwUsers.push(user);
            }
          });
          this.$store.commit('vwUsers', this.cbVwUsers);
          resolve(res);
        })
        .catch(err => {
          console.log('ERROR RETRIEVE User', err);
          reject(err);
        });
    });
  }

  // ===============================
  // ===============================
  // END ROOM
  // ===============================
  // ===============================

  // ===============================
  // ===============================
  // START MESSAGE
  // ===============================
  // ===============================

  fetchMessages({ room, options = {} }) {
    this.$socketUnsubscribeLiveAgentConversationId();
    if (this.$socketSubscribeLiveAgentConversationId) this.$socketSubscribeLiveAgentConversationId(room.roomId);
    else this.alertService().showError(this, 'socketSubscribeLiveAgentConversationId Socket Not Active', '');

    if (this.$socketSubscribeLiveAgentMsgLastSeen) this.$socketSubscribeLiveAgentMsgLastSeen(room.roomId);

    if (!(<any>options).isLoadMore) {
      //initial at first
      this.$root.$emit('opc::findCbVwUserById', room.users);
    }

    this.$emit('show-demo-options', false);
    if ((<any>options).reset) {
      this.resetMessages();
      this.roomId = room.roomId;
    }

    if (this.previousLastLoadedMessage && !this.lastLoadedMessage) {
      this.messagesLoaded = true;
      return;
    }

    this.selectedRoom = room.roomId;

    if ((<any>options).isLoadMore) {
      this.pageVwMessage++;
    }

    const paginationQuery = {
      page: this.pageVwMessage - 1,
      size: this.messagesPerPage,
      sort: this.sortVwMessage(),
    };
    this.pChtService()
      .retrieveMessageByConversationIdAndKey(Number(this.selectedRoom), this.chtLiveAgentConversation.liveAgentKeyTrans, paginationQuery)
      .then(
        res => {
          this.isFetching = false;
          if (res.data && res.data.length < 10) {
            this.setLatestView();
          }

          if (this.selectedRoom !== room.roomId) return;
          if (res.data.length === 0 || res.data.length < this.messagesPerPage) {
            setTimeout(() => (this.messagesLoaded = true), 0);
          }
          if ((<any>options).reset) this.messages = [];
          const formattedMessages = [];
          res.data.forEach(message => {
            // message = this.addFileList(message); //move ro formateMssage
            //message = this.addReactionList(res.data[i])
            const formattedMessage = this.formatMessage(room, message);
            formattedMessages.push(formattedMessage);

            this.messages.unshift(formattedMessage); //ADD AT FIRST
            this.$root.$emit('fc::changeValue', 'messagesLiveChat', this.messages);
          });
        },
        err => {
          this.isFetching = false;
        }
      );
  }

  public messageActionHandler({ action, message, roomId, isValueNegate }) {
    switch (action) {
      case 'DELETE':
        this.confirmDeleteSelectedMessageIds([message.id]);
        return;
      case 'STAR':
        this.handleBtnStar(message, roomId, false);
        return;
      case 'STAR_DELETE':
        this.handleBtnStar(message, roomId, true);
        return;
      case 'MARK_SEEN':
        setTimeout(() => {
          this.markMessagesSeen(message);
        }, 700);
        return;
      case 'TRANSLATE':
        this.translateProcess(message);
        return;
      default:
        return;
    }
  }

  markMessagesSeen(messageP) {
    const msgId = messageP.id;
    const message = this.messages.find(m => m.id == msgId);

    if (message && !message.seenBySelf && message.authorId !== this.chtLiveAgentConversation.liveAgentKeyTrans && !message.seens) {
      const msgSeen: IChtMessageSeen = new ChtMessageSeen();
      msgSeen.chtMessage = message;
      msgSeen.chtMessage.chtConversation = { id: Number(this.roomId) };
      this.chtMessageSeenService().createForLA(msgSeen, message.authorId);
      message.seenBySelf = true;
    }
    return message;
  }

  formatMessage(room, message: IChtVwMessage) {
    message = this.addFileList(message);
    message = this.addReactionList(message);

    let seenByOther = false;
    let seenBySelf = false;

    if (message.seens) {
      const seenArr = message.seens.split('||');
      seenArr.forEach(r => {
        if (r.includes(this.currentUserId)) {
          seenBySelf = true;
        } else if (r.trim().length > 1) {
          seenByOther = true;
        }
      });
    }

    const formattedMessage = {
      ...message,
      ...{
        senderId: message.authorId,
        id: message.id,
        _id: message.id,
        externalId: message.externalId,
        chtContentType: message.chtContentType,
        content: message?.content ?? '',
        seconds: new Date(message.createdDate).getTime(),
        timestamp: parseTimestamp(new Date(message.createdDate), 'HH:mm'),
        date: parseTimestamp(new Date(message.createdDate), 'DD MMMM YYYY'),
        username: this.cbVwUsers.find(user => message.authorId === user.id)?.username,
        // avatar: senderUser ? senderUser.avatar : null,
        distributed: true,
        seen: seenByOther,
        seenBySelf,
        deleted: message.deleteForAll ? true : false,
        //lastMessage: { ...message.lastMessage, senderId: message.sender_id },
      },
    };

    // if (message.replyMessage) {
    //   formattedMessage.replyMessage = {
    //     ...message.replyMessage,
    //     ...{
    //       senderId: message.replyMessage.sender_id,
    //     },
    //   };
    // }
    if (message.messageRefId && message.messageRef) {
      message.replyMessage = this.formatMessage(room, message.messageRef);
      formattedMessage.replyMessage = {
        ...message.replyMessage,
        ...{
          senderId: message.authorId,
        },
      };
    }

    return formattedMessage;
  }

  async sendMessage({ content, roomId, files, replyMessage, chtLocation, sticker }) {
    const message: any = {
      sender_id: this.currentUserId,
      content,
      timestamp: new Date(),
    };

    if (files) {
      message.files = this.formattedFiles(files);
    }

    const chtConversation = new ChtConversation();
    chtConversation.id = roomId;
    const chtMessage: IChtMessage = new ChtMessage();
    chtMessage.chtConversation = chtConversation;
    chtMessage.content = message.content;
    chtMessage.chtLocation = chtLocation;

    if (replyMessage) {
      chtMessage.messageRefId = replyMessage._id;
    }

    this.saveMessage(chtMessage, message.files);
  }

  public isSavingMessage = false;

  public saveMessage(chtMessage: IChtMessage, files?: any): void {
    if (this.isSavingMessage) return;
    this.isSavingMessage = true;

    // if (this.selectedChtVwMessage && this.selectedChtVwMessage.id) {
    //   this.chtMessage.externalId = this.selectedChtVwMessage.id.toString();
    // }

    // if (this.chtVwMessageReply.id) {
    //   this.chtMessage.messageRefId = this.chtVwMessageReply.id;
    // }

    chtMessage.authorPhotoId = this.currentVwUser.photoId;
    chtMessage.authorDisplayName = this.currentVwUser.displayName;
    chtMessage.isLiveChat = true;

    const msgCopy = { ...chtMessage };

    let filesCopy;
    if (files) {
      filesCopy = [...files];
      this.filesCopy = filesCopy; //for next function
    }
    if (filesCopy && filesCopy.length > 0) {
      //call here to avoud publish at first
      this.isProgressUploadFile = true;
    }

    this.pChtService()
      .createMessageLiveAgentNoLogin(this.chtLiveAgentConversation.liveAgentKeyTrans, msgCopy)
      .then(async res => {
        this.isSavingMessage = false;
        const room = res.chtConversation;
        const message = res;

        // const formattedMessageRaw = this.formatMessage(room, message);
        const formattedMessage = this.formatMessage(room, message);
        // this.markMessagesSeen(formattedMessage);
        const messageIndex = this.messages.findIndex(m => m._id === message.id);
        //have attachment or not, for progress upload
        if (filesCopy && filesCopy.length > 0) {
          formattedMessage.files = filesCopy;
        }

        if (messageIndex === -1) {
          this.messages = this.messages.concat([formattedMessage]);
        } else {
          this.messages[messageIndex] = formattedMessage;
          this.messages = [...this.messages]; //copy data with new like
        }
        //have attachment or not
        if (filesCopy && filesCopy.length > 0) {
          // let progressUploads = [];
          // for (let index = 0; index < this.filesCopy.length; index++) {
          //   let progressUpload = new ProgressUpload();
          //   progressUpload.title = filesCopy[index].name;
          //   progressUpload.localUrl = filesCopy[index].localUrl;
          //   progressUpload.progress = 0;
          //   progressUpload.status = null;
          //   progressUploads.push(progressUpload);
          // }
          // this.$root.$emit('app::progressUploadDownload', progressUploads);
          // this.$root.$emit('app::showProgressUploadDownload', true);

          for (let index = 0; index < filesCopy.length; index++) {
            await this.uploadFile({ file: filesCopy[index], messageId: res.id, roomId: chtMessage.chtConversation.id });
          }
          this.isProgressUploadFile = false;
        }

        this.$root.$emit('fc::changeValue', 'messagesLiveChat', this.messages);

        // this.setLatestView();
      })
      .catch(error => {
        this.isProgressUploadFile = false;
        this.isSavingMessage = false;
        // this.closeAllDialog();
        // this.resetMessage();
        // this.alertService().showHttpError(this, error.response);
      });
  }

  async editMessage({ messageId, newContent, roomId, files }) {}

  async deleteMessage({ message, roomId }) {}

  async uploadFile({ file, messageId, roomId }) {
    const vm = this;
    return new Promise((resolve, reject) => {
      const extension = file.extension || file.type;

      const reader = new FileReader();
      reader.readAsDataURL(file.blob);

      reader.onload = async function (event) {
        //save thumbnail
        let refChtFileThumbnailId;
        if (file.thumbnail) {
          const chtMessage = new ChtMessage();
          chtMessage.id = messageId;
          chtMessage.isLiveChat = true;
          const chtFile = new ChtFile();
          chtFile.isThumbnail = true;
          chtFile.senderId = vm.currentUserId;
          chtFile.app = CHT_APP_SELECTED;
          chtFile.att = '';
          chtFile.attContentType = 'image/png';
          chtFile.chtMessage = chtMessage;
          chtFile.type = 'image/png';
          chtFile.extension = 'png';
          chtFile.url = file.localUrl;
          chtFile.name = file.name;
          await vm
            .chtFileService()
            .create4La(vm.chtLiveAgentConversation.liveAgentKeyTrans, chtFile, chtFile.type, vm.dataURItoBlob(file.thumbnail), x => {})
            .then(param => {
              refChtFileThumbnailId = param.id;
            })
            .catch(error => {});
        }
        //end thumbnail

        const chtMessage = new ChtMessage();
        chtMessage.id = messageId;
        chtMessage.isLiveChat = true;
        const chtFile = new ChtFile();
        chtFile.senderId = vm.currentUserId;
        chtFile.app = CHT_APP_SELECTED;
        chtFile.att = '';
        chtFile.attContentType = file.type;
        chtFile.chtMessage = chtMessage;
        chtFile.type = file.type;
        chtFile.extension = extension;
        chtFile.url = file.localUrl;
        chtFile.name = file.name;
        chtFile.authorPhotoId = vm.currentVwUser.photoId;
        chtFile.authorDisplayName = vm.currentVwUser.displayName;
        chtFile.conversationIdTransient = roomId;
        chtFile.refChtFileThumbnailId = refChtFileThumbnailId;

        vm.chtFileService()
          .create4La(vm.chtLiveAgentConversation.liveAgentKeyTrans, chtFile, chtFile.type, file.blob, progress => {
            vm.updateFileProgress(messageId, file.localUrl, progress);

            // vm.$root.$emit('app::getProgressUploadDownload', progressUploads => {
            //   let progressUpload = progressUploads.find(p => p.localUrl === file.localUrl);
            //   if (!progressUpload) return;
            //   progressUpload.progress = progress;
            //   vm.$root.$emit('app::progressUploadDownload', progressUploads);
            // });
          })
          .then(res => {
            const message = vm.messages.find(message => message._id === messageId);

            if (!message || !message.files) {
              resolve(true);
              return;
            }
            const f = message.files.find(e => e.localUrl === file.localUrl);

            vm.chtFileService()
              .createDownloadLink(res.refChtFileThumbnailId ? res.refChtFileThumbnailId : res.id, vm.token)
              .then(downloadLink => {
                f.localUrl = downloadLink;
                f.url = downloadLink;
                f.progress = -1; //to close progress bar
                vm.messages = [...vm.messages];
              });

            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      };
    });
  }

  async updateFileProgress(messageId, fileUrl, progress) {
    const message = this.messages.find(message => message._id === messageId);
    if (!message || !message.files) return;

    const file = message.files.find(file => file.url === fileUrl);
    file.progress = progress;

    this.messages = [...this.messages];
  }

  formattedFiles(files) {
    const formattedFiles = [];

    files.forEach(file => {
      console.log('file size ' + file.size);
      const messageFile: any = {
        name: file.name,
        size: file.size,
        type: file.type,
        extension: file.extension || file.type,
        url: file.url || file.localUrl,
        blob: file.blob,
        localUrl: file.localUrl,
        thumbnail: file.thumbnail,
      };

      if (file.audio) {
        (<any>messageFile).audio = true;
        messageFile.duration = file.duration;
      }

      formattedFiles.push(messageFile);
    });

    return formattedFiles;
  }

  openFileChatController({ file }) {
    window.open(file.file.url, '_blank');
  }

  openFile({ file }) {
    window.open(file.file.url, '_blank');
  }

  async openUserTag({ user }) {}

  messageSelectionActionHandler({ action, messages, roomId }) {
    switch (action.name) {
      case 'deleteMessages':
        messages.forEach(message => {
          this.deleteMessage({ message, roomId });
        });
    }
  }

  async sendMessageReaction({ data, category, remove, messageId, roomId }) {
    const reaction: IChtReaction = new ChtReaction();
    reaction.content = data;
    const message = new ChtMessage();
    message.id = messageId;
    message.app = CHT_APP_SELECTED;
    reaction.chtMessage = message;
    const conversation = new ChtConversation();
    conversation.id = roomId;
    reaction.chtMessage.chtConversation = conversation;
    this.pChtService().createReaction(reaction);
  }

  async deleteMessageReaction(id) {
    this.chtReactionService().delete(id);
  }

  typingMessage({ message, roomId }) {}

  private addReactionList(chtVwMessage: IChtVwMessage) {
    let reaction: IChtReaction;
    const reactionsTransient: IChtReaction[] = [];
    if (chtVwMessage.reactions) {
      const arr = chtVwMessage.reactions.split('||');
      arr.forEach(r => {
        const rArr = r.split('::');
        reaction = new ChtReaction();
        reaction.content = rArr[0];
        reaction.countTransient = Number(rArr[1]);
        reaction.firstDataTransient = rArr[2];
        reaction.id = Number(rArr[3]);

        reactionsTransient.push(reaction);
      });
      chtVwMessage.reactionsTransient = reactionsTransient;
    }

    return chtVwMessage;
  }

  private addFileList(chtVwMessage: IChtVwMessage) {
    chtVwMessage.files = chtVwMessage.chtFiles;
    return chtVwMessage;
  }

  public confirmDeleteSelectedMessageIds(selectedMessageIds) {
    let moreFilter = '';
    selectedMessageIds.forEach(x => {
      moreFilter = moreFilter + 'chtMessageId.in=' + x + '&';
    });

    const paginationQuery = {
      moreFilter: moreFilter,
    };
    this.chtMessageDeleteService()
      .deleteByIds(paginationQuery)
      .then(res => {
        res.forEach(msgId => {
          const findIndex = this.messages.findIndex(o => o.id === msgId);
          if (findIndex >= 0) {
            this.messages[findIndex].deleted = true;
          }
        });

        // this.$forceUpdate();
      })
      .catch(err => {});
  }

  public handleBtnStar(chtVwMessageP: IChtVwMessage, roomId, isDelete: boolean) {
    if (chtVwMessageP && !isDelete) {
      const start: IChtStar = new ChtStar();
      start.chtMessage = chtVwMessageP;
      const conversation: IChtConversation = new ChtConversation();
      conversation.id = roomId;
      start.chtMessage.chtConversation = conversation;
      this.chtStarService()
        .create(start)
        .then(res => {
          const findIndex = this.messages.findIndex(o => o.id === res.id);
          if (findIndex >= 0) {
            this.messages[findIndex].stars = res.stars;
            // this.$forceUpdate();
          }
        })
        .catch(err => {});
    } else {
      this.chtStarService()
        .delete(chtVwMessageP.id)
        .then(res => {
          const findIndex = this.messages.findIndex(o => o.id === chtVwMessageP.id);
          if (findIndex >= 0) {
            this.messages[findIndex].stars = null;
            // this.$forceUpdate();
          }
        })
        .catch(err => {});
    }
  }

  public addNotifMessage(userAgentId: string) {
    const messageNotif = {
      senderId: userAgentId,
      authorId: userAgentId,
      chtContentType: null,
      content: this.$store.getters.currentLanguage == 'en' ? 'Thank you for your feedback!' : 'Terimakasih telah mengisi umpan balik!',
      seconds: new Date().getTime(),
      timestamp: parseTimestamp(new Date(), 'HH:mm'),
      date: parseTimestamp(new Date(), 'DD MMMM YYYY'),
      username: userAgentId,
      distributed: true,
      deleted: false,
      app: 'CB_APP',
      id: new Date().getTime(),
      _id: new Date().getTime(),
    };

    this.messages.push(messageNotif);

    console.log(this.messages, '<<<>>>>');
  }

  public async translateProcess(message: IChtMessage) {
    //return if translated
    if (message.messageTranslated) return;

    const author = this.cbVwUsers.find(u => u.id === message.authorId);

    let source = 'en'; //source language
    const target = this.currentVwUser.langKey; //target language

    if (author.langKey === target) return;

    source = author.langKey;

    const messageTranslated = new ChtMessage();

    if (message.cbVwPawOrder) {
      messageTranslated.cbVwPawOrder = await this.translateMappingData(message.cbVwPawOrder, source, target);
    }
    if (message.cbVwAuctionProjectPreOffer) {
      messageTranslated.cbVwAuctionProjectPreOffer = await this.translateMappingData(message.cbVwAuctionProjectPreOffer, source, target);
    }
    if (message.cbPawPreOrder) {
      messageTranslated.cbPawPreOrder = await this.translateMappingData(message.cbPawPreOrder, source, target);
    }

    if (message.cbVwPaw) {
      messageTranslated.cbVwPaw = await this.translateMappingData(message.cbVwPaw, source, target);
    }

    if (message.cbTextRestrict) {
      messageTranslated.cbTextRestrict = await this.translateMappingData(message.cbTextRestrict, source, target);
    }

    if (message.content != null && message.content != '') {
      messageTranslated.content = await (await this.translateMappingData({ content: message.content }, source, target)).content;
    }

    message.messageTranslated = messageTranslated;

    //let messageIndex = this.messages.findIndex(message => message._id === message.id);
    // this.messages[messageIndex] = message;//comment becauser will make fuplicate kye error
    this.messages = [...this.messages]; //copy data with new like
  }

  private setLatestView() {}

  public get loginType() {
    return this.$store.getters.loginType;
  }

  private async translateMappingData(dataFrom, source, target) {
    const dataTranslated = { ...dataFrom };

    for (const key in dataFrom) {
      if (!GOOGLE_TRANSLATE_FIELD_PERMITTED.find(field => field === key)) {
      } else {
        console.log('key ' + key);
        let [translations] = await this.translationService().translateGoogle(dataFrom[key], source, target);
        translations = Array.isArray(translations) ? translations : [translations];
        translations.forEach((translation, i) => {
          dataTranslated[key] = translation.translatedText.replace(/"/g, "'");
          console.log(`${dataFrom[key]} => (${target}) ${translation}`);
        });
      }
    }
    return dataTranslated;
  }

  public askmeQuestionAgain() {
    this.isShowliveChat = false;
    this.tkt = new ChtLiveAgentQuestion();
    this.chtLiveAgentConversation = new ChtConversation();
    this.$store.commit('chtLiveAgentConversation', this.chtLiveAgentConversation);
    this.isShowliveChat = false;
    this.isClosed = false;
    this.roomId = '';
    this.rooms = [];
  }

  //TIKET

  // ===============================
  // ===============================
  // END MESSAGE
  // ===============================
  // ===============================

  // public playAudioReceivingMessage() {
  //   const myAudio = <any>(<any>this.$refs.audioMain).$refs.myAudioReceivingMessage;
  //   myAudio.pause();
  //   myAudio.currentTime = 0;
  //   myAudio.play();
  // }

  // TKT
  public cmVwTkt: ICmVwTkt = new CmVwTkt();

  public async findCmVwTktByConversationId(roomId) {
    await this.pCmVwTktService()
      .findTktLiveChat(roomId)
      .then(res => {
        this.cmVwTkt = res.data[0];
        this.roomId = roomId;

        if (
          this.cmVwTkt.statusResolution == CmTktStatusResolutionType.RESOLVED ||
          this.cmVwTkt.statusResolution == CmTktStatusResolutionType.CLOSED
        ) {
          this.isClosed = true;
        }
      })
      .catch(err => {
        this.cmVwTkt = new CmVwTkt();
      });
  }

  public dataURItoBlob(dataURI) {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  }

  @Watch('isMobile')
  public isMobileListener() {
    if (!this.isMobile) {
      this.$router.go(-1);
    } else {
      return (<any>this.$root).$emit('app::liveChatVisible', false);
    }
  }
}
