import Vue from 'vue';
import DOMPurify from 'dompurify';

export function initSecurityFilters() {
  Vue.filter('SECURITY_VHTML', (value, allowedLinks = [], allowedSrc = false) => {
    const allowedStyles = ['color', 'font-size', 'margin'];
    const forbiddenLinks = [`${process.env.BASE_URL}`]; // Daftar link yang tidak diizinkan (default)

    // Fungsi untuk membersihkan style inline pada elemen yang berisi "style" atribut
    function sanitizeStyle(input) {
      const styleObj = input.split(';').reduce((acc, style) => {
        const [property, value] = style.split(':');
        if (allowedStyles.includes(property.trim())) {
          acc.push(`${property.trim()}: ${value.trim()}`);
        }
        return acc;
      }, []);
      return styleObj.join('; ');
    }

    // Fungsi untuk membersihkan inline CSS
    function sanitizeInlineStyles(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const elementsWithStyle = doc.querySelectorAll('[style]');

      elementsWithStyle.forEach(element => {
        const sanitizedStyle = sanitizeStyle(element.getAttribute('style'));
        element.setAttribute('style', sanitizedStyle);
      });

      return doc.body.innerHTML;
    }

    // Fungsi untuk memblokir link yang tidak diizinkan, kecuali yang ada di allowedLinks
    function sanitizeLinks(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const links = doc.querySelectorAll('a');

      links.forEach(link => {
        const href = link.getAttribute('href').toLowerCase(); // Ubah href ke lowercase
        const isAllowed = allowedLinks.some(allowedLink => href.includes(allowedLink.toLowerCase())); // Perbandingan lowercase

        // Blokir link yang tidak ada di allowedLinks dan terdapat di forbiddenLinks
        if (!isAllowed && forbiddenLinks.some(forbiddenLink => href.includes(forbiddenLink.toLowerCase()))) {
          link.removeAttribute('href'); // Hapus atribut href untuk mencegah klik
          link.style.cursor = 'auto';
        }
      });

      return doc.body.innerHTML;
    }

    if (value) {
      // Tentukan atribut yang diizinkan
      const allowedAttributes = ['href', 'style', 'title', 'alt', 'class'];

      // Jika allowedSrc adalah true, tambahkan 'src' ke allowedAttributes
      if (allowedSrc) {
        allowedAttributes.push('src');
      }

      // Langkah 1: Sanitasi HTML dengan DOMPurify dan batasi atribut yang diizinkan
      const sanitizedHtml = DOMPurify.sanitize(value, {
        FORBID_TAGS: [
          'textarea',
          'form',
          'input',
          'button',
          'select',
          'option',
          'optgroup',
          'fieldset',
          'legend',
          'label',
          'datalist',
          'keygen',
          'output',
          'svg',
        ], // Mencegah elemen yang tidak diizinkan
        ALLOWED_ATTR: allowedAttributes, // Daftar atribut yang diizinkan
      });

      // Langkah 2: Sanitasi inline CSS di dalam HTML
      const sanitizedHtmlWithStyles = sanitizeInlineStyles(sanitizedHtml);

      // Langkah 3: Blokir link yang tidak diizinkan kecuali yang ada di allowedLinks
      const finalSanitizedHtml = sanitizeLinks(sanitizedHtmlWithStyles);

      return finalSanitizedHtml;
    }
  });

  Vue.filter('SECURITY_VHTML_AND_NOTIF_REPLACE_BASE_URL', (value, notifRepaceBaseUrl) => {
    if (value) {
      let dataValue = DOMPurify.sanitize(value);
      dataValue = dataValue.replace(/<a /g, '<span ').replace(/<\/a>/g, '</span>');
      const urlArray: string[] = notifRepaceBaseUrl.split(',');

      // Iterate over the array
      for (const url of urlArray) {
        console.log(url);
        dataValue = dataValue.replace(`href="` + url, `href="`);
        // Your logic for each URL goes here
      }
      if (dataValue) {
        return dataValue;
      }
    }
  });
}
