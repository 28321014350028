import { TabSettingType } from '@/module/general/setting/settings.component';
import { Inject, Component, Mixins, Watch } from 'vue-property-decorator';
import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { maxLength, minLength, required, sameAs } from 'vuelidate/lib/validators';
import GlobalService from '@/services/global.service';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import PCbAccountService from '@/services/cb-account.service';
import { ICbSetPassword } from '@/shared/model/cb-oids.model';
import CVerificationPassword from '../auth/c-verification-password/c-verification-password.vue';
import { CbVerificationOtp, ICbVerificationOtp } from '@/shared/model/cb-verification-otp.model';
import {
  regexMatchTextUpper,
  regexMatchTextLower,
  regexMatchChar8,
  regexMatchNumber,
  regexMatchPunctuation,
} from '@/shared/constant/constant-match-regex';
import JhiDataUtils from '@/shared/data/data-utils.service';
import FormUtils from '@/shared/utils/form-utils.service';

const validations = {
  resetPassword: {
    newPassword: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(16),
    },
    confirmPassword: {
      sameAsPassword: sameAs(vm => {
        return vm.newPassword;
      }),
    },
  },
};

@Component({
  validations,
  components: {
    CButton,
    Icon,
    CConfirmation,
    CVerificationPassword,
  },
  watch: {
    'resetPassword.newPassword': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 255;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.resetPassword.newPassword = oldVal;
        }

        if (total > 0) {
          this.removePasswordSpaces(this.resetPassword.newPassword);

          this.validatePasteMaxLengthUtils(max, total);
        }
      },
    },
    'resetPassword.confirmPassword': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 255;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.resetPassword.confirmPassword = oldVal;
        }

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
})
export default class CSetNewPassword extends Mixins(JhiDataUtils, FormUtils) {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbAccountService') private pCbAccountService: () => PCbAccountService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public isUpperCase: boolean = false;
  public passwordInputType: string = 'password';
  public passwordInputTypeOld: string = 'password';
  public isLowerCase: boolean = false;
  public isCharacter: boolean = false;
  public isAllValidate: boolean = false;
  public isInitChangePassword: boolean = false;

  success: string = null;
  error: string = null;
  doNotMatch: string = null;
  resetPassword: any = {
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
  };

  public isButtonCountinue: boolean = false;
  public visibleVerificationPassword: boolean = false;
  public invalidOtp: boolean = false;
  public visibleSuccessPassword: boolean = false;
  public cbVerificationOtp: ICbVerificationOtp = new CbVerificationOtp();

  public styleValidPassOne: string = 'var(--cj-color-grey-secondary)';
  public styleValidPassTwo: string = 'var(--cj-color-grey-secondary)';
  public styleValidPassThree: string = 'var(--cj-color-grey-secondary)';
  public styleValidPassFour: string = 'var(--cj-color-grey-secondary)';

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  created() {
    (<any>this.$root).$emit('showNavMobile', false);
  }
  // ================= START FUNCTION =================
  public togglePassword() {
    this.passwordInputType = this.passwordInputType === 'text' ? 'password' : 'text';
  }
  public togglePasswordOld() {
    this.passwordInputTypeOld = this.passwordInputTypeOld === 'text' ? 'password' : 'text';
  }

  public changePassword(): void {
    if (this.resetPassword.newPassword !== this.resetPassword.confirmPassword) {
      this.error = null;
      this.success = null;
      this.doNotMatch = 'ERROR';
    } else {
      this.doNotMatch = null;
      this.initChangePassword(false);
    }
  }

  public initChangePassword(isResend: boolean) {
    this.isInitChangePassword = true;
    this.pCbAccountService()
      .setPasswordInit()
      .then(res => {
        if (!isResend) {
          this.cbVerificationOtp = res.detail;
          this.openModalInitChangePassword();
        }
        this.cbVerificationOtp = res.detail;
        this.isInitChangePassword = false;
      })
      .catch(err => {
        this.isInitChangePassword = false;
        console.log('ERROR', err.response);
      });
  }

  public setPasswordFinish(value: ICbSetPassword) {
    value.newPassword = this.resetPassword.newPassword;
    this.pCbAccountService()
      .setPasswordFinish(value)
      .then(res => {
        this.invalidOtp = false;
        this.visibleVerificationPassword = false;
        this.visibleSuccessPassword = true;
        this.resetPassword = {
          currentPassword: null,
          newPassword: null,
          confirmPassword: null,
        };
        this.$v.$reset();
        this.styleValidPassOne = 'var(--cj-color-grey-secondary)';
        this.styleValidPassTwo = 'var(--cj-color-grey-secondary)';
        this.styleValidPassThree = 'var(--cj-color-grey-secondary)';
        this.styleValidPassFour = 'var(--cj-color-grey-secondary)';
      })
      .catch(err => {
        this.invalidOtp = true;
      });
  }

  public onBack() {
    this.$router.go(-1);
  }

  public onInputRequired(e) {
    const value = e;

    if (value === '') {
      this.styleValidPassOne = 'var(--cj-color-grey-secondary)';
      this.styleValidPassTwo = 'var(--cj-color-grey-secondary)';
      this.styleValidPassThree = 'var(--cj-color-grey-secondary)';
      this.styleValidPassFour = 'var(--cj-color-grey-secondary)';
    }
    if (regexMatchTextUpper.test(value)) {
      this.isUpperCase = true;
      this.styleValidPassTwo = 'var(--cj-color-status-success)';
    } else {
      this.isUpperCase = false;
      this.styleValidPassTwo = 'var(--cj-color-status-error)';
    }
    if (regexMatchTextLower.test(value)) {
      this.isLowerCase = true;
      this.styleValidPassOne = 'var(--cj-color-status-success)';
    } else {
      this.isLowerCase = false;
      this.styleValidPassOne = 'var(--cj-color-status-error)';
    }

    if (regexMatchChar8.test(value)) {
      this.isCharacter = true;
      this.styleValidPassThree = 'var(--cj-color-status-success)';
    } else {
      this.isCharacter = false;
      this.styleValidPassThree = 'var(--cj-color-status-error)';
    }

    if (regexMatchNumber.test(value) || regexMatchPunctuation.test(value)) {
      this.isAllValidate = true;
      this.styleValidPassFour = 'var(--cj-color-status-success)';
    } else {
      this.isAllValidate = false;
      this.styleValidPassFour = 'var(--cj-color-status-error)';
    }
  }

  public closeDialog() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-change-password-init');
  }

  public openModalInitChangePassword() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-change-password-init');
  }

  public changeFunction(functionName: string, param1?: any, param2?: any, param3?: any) {
    this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
  }

  public changeValue(fieldName: string, value: any) {
    this[fieldName] = value;
  }
  public removePasswordSpaces(e: string) {
    this.resetPassword.newPassword = e.replace(/\s/g, '');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isPasswordSame() {
    return this.resetPassword.password === this.resetPassword.confirmPassword;
  }
  public get invalidPassword() {
    if (this.$v.resetPassword.newPassword.$anyDirty) {
      if (this.$v.resetPassword.newPassword.$invalid) {
        return true;
      }
      if (
        this.styleValidPassOne == 'var(--cj-color-status-error)' ||
        this.styleValidPassTwo == 'var(--cj-color-status-error)' ||
        this.styleValidPassThree == 'var(--cj-color-status-error)' ||
        this.styleValidPassFour == 'var(--cj-color-status-error)'
      ) {
        return true;
      }
    }
    return false;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS =================== public get isMobile(): boolean {

  @Watch('isMobile')
  public isMobileListener() {
    if (!this.isMobile) {
      this.$router.push({ name: 'PSettings', query: { tab: TabSettingType.PASSWORD } });
    }
  }
}
