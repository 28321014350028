import axios from 'axios';

import { ICbAuctionProject } from '@/shared/model/cb-auction-project.model';

const baseApiUrl = 'services/cbcorems/api/cb-auction-projects';

export default class PCbAuctionProjectService {
  public find(id: number): Promise<ICbAuctionProject> {
    return new Promise<ICbAuctionProject>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public create(entity: ICbAuctionProject): Promise<ICbAuctionProject> {
    return new Promise<ICbAuctionProject>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICbAuctionProject): Promise<ICbAuctionProject> {
    return new Promise<ICbAuctionProject>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbAuctionProject): Promise<ICbAuctionProject> {
    return new Promise<ICbAuctionProject>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
