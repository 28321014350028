var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-account-update-profile d-flex flex-column" },
    [
      _c(
        "p",
        {
          staticClass:
            "cj-color-black-primary font-roboto font-weight-500 mb-4",
          class: _vm.isMobile ? "mt-4 mx-3 font-size-14" : "font-size-18",
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.titleAccount")),
            },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "ml-1 cj-btn-text-primary",
            attrs: {
              "data-gtm": "go-to-profile-button",
              "data-cy": "go-to-profile",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.myProfile")),
            },
            on: { click: _vm.toProfile },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "border-grey-tertiary-top-1 pt-4 mb-4",
          class: _vm.isMobile ? "mx-3" : "",
        },
        [
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "cj-color-black-primary font-roboto font-weight-500",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                },
                [_vm._v("Login")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-8" }, [
              _c(
                "p",
                {
                  staticClass:
                    "cj-color-black-primary font-roboto font-weight-400 text-break-all",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                  attrs: { "data-cy": "c-account-update-profile-login" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.userAccount?.login ? _vm.userAccount?.login : "-"
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c("p", {
                staticClass:
                  "cj-color-black-primary font-roboto font-weight-500",
                class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                domProps: {
                  textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.phoneNumber")),
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-8" }, [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "cj-color-black-primary font-roboto font-weight-400",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                    attrs: { "data-cy": "c-account-update-profile-phone" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.cbUserInfo.phoneCode
                            ? _vm.cbUserInfo.phoneCode
                            : ""
                        ) +
                        "\n            " +
                        _vm._s(_vm.cbUserInfo.phone) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "cj-btn-text-primary font-roboto font-weight-700",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                    attrs: { "data-cy": "btn-phone-number" },
                    on: { click: _vm.openModalSetPhone },
                  },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.cbUserInfo.phone,
                          expression: "!cbUserInfo.phone",
                        },
                      ],
                      attrs: { "data-gtm": "set-phone-number-button" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.set")),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.cbUserInfo.phone,
                          expression: "cbUserInfo.phone",
                        },
                      ],
                      attrs: {
                        "data-gtm": "set-phone-number-button",
                        "data-cy": "btn-change-phone-number",
                      },
                      domProps: {
                        textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.change")),
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-12 col-sm-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "cj-color-black-primary font-roboto font-weight-500",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                },
                [_vm._v("Email")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-8" }, [
              _c(
                "p",
                {
                  staticClass:
                    "cj-color-black-primary font-roboto font-weight-400 text-break-all",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                  attrs: { "data-cy": "c-account-update-profile-email" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.userAccount && _vm.userAccount.email
                          ? _vm.userAccount.email
                          : "-"
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "border-grey-tertiary-top-1 pt-4 mb-4 d-flex flex-column",
          class: _vm.isMobile ? "mx-3" : "",
        },
        [
          _c("div", { staticClass: "flex-grow-1" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pb-2 pb-lg-3 my-lg-auto",
                },
                [
                  _c("p", {
                    staticClass:
                      "cj-color-black-primary font-roboto font-weight-500 m-0",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbSetting.onlineStatus.title")
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 pb-3",
                },
                [
                  _c("div", { staticClass: "input-select" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.cbUserInfo.isShowOnlineStatus.$model,
                            expression:
                              "$v.cbUserInfo.isShowOnlineStatus.$model",
                          },
                        ],
                        staticClass: "cj-bg-white-primary",
                        class: {
                          "font-size-14": _vm.isMobile,
                          "font-size-18 ": !_vm.isMobile,
                          invalid:
                            _vm.$v.cbUserInfo.isShowOnlineStatus.$anyDirty &&
                            _vm.$v.cbUserInfo.isShowOnlineStatus.$invalid,
                          valid:
                            _vm.$v.cbUserInfo.isShowOnlineStatus.$anyDirty &&
                            !_vm.$v.cbUserInfo.isShowOnlineStatus.$invalid,
                        },
                        attrs: {
                          "data-cy": "show-status",
                          "data-gtm": "set-status-clickable",
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.$v.cbUserInfo.isShowOnlineStatus,
                                "$model",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            _vm.accountProfile,
                          ],
                        },
                      },
                      [
                        _c("option", { domProps: { value: true } }, [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("cbgwApp.cbSetting.onlineStatus.show")
                              ),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("option", {
                          domProps: {
                            value: false,
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbSetting.onlineStatus.hide")
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pb-2 pb-lg-3 mt-lg-2",
                },
                [
                  _c("p", {
                    staticClass:
                      "cj-color-black-primary font-roboto font-weight-500 p-0 mb-0",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbSetting.languagePreference.title")
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 pb-2",
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        "input-select": !_vm.isMobile,
                        "w-100": _vm.isMobile,
                      },
                      on: {
                        click: function ($event) {
                          _vm.isMobile
                            ? _vm.changeValue("popUpLanguage", true)
                            : null
                        },
                      },
                    },
                    [
                      _c("c-autocomplete-new", {
                        staticClass: "cj-bg-white-primary w-100 rounded mb-1",
                        class: {
                          "font-size-14": _vm.isMobile,
                          "font-size-18 ": !_vm.isMobile,
                          invalid:
                            _vm.$v.cbUserInfo.langKey.$anyDirty &&
                            _vm.$v.cbUserInfo.langKey.$invalid,
                          valid:
                            _vm.$v.cbUserInfo.langKey.$anyDirty &&
                            !_vm.$v.cbUserInfo.langKey.$invalid,
                        },
                        attrs: {
                          "data-cy": "language-data",
                          "data-gtm": "set-language-clickable",
                          isHideItemList: _vm.isMobile,
                          disableTyping: _vm.isMobile,
                          items: _vm.cbLovLanguages,
                          "item-text": `${
                            _vm.currentLanguage == "en" ? "value" : "value2"
                          }`,
                          "item-value": "key",
                          placeholder: _vm.$t(
                            "cbgwApp.cbCreateAccount.placeholder.language"
                          ),
                        },
                        model: {
                          value: _vm.$v.cbUserInfo.langKey.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.cbUserInfo.langKey, "$model", $$v)
                          },
                          expression: "$v.cbUserInfo.langKey.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "cj-color-black-primary font-catamaran font-weight-400",
                    class: _vm.isMobile ? "font-size-12" : "font-size-14",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.languagePreference.descTitle",
                          { lang: _vm.getLanguage() }
                        )
                      ),
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-2 mb-1 ml-auto col-12 col-sm-5 col-lg-4 px-0" },
            [
              _c(
                "c-button",
                {
                  staticClass: "py-1",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                  attrs: {
                    dataCyCustom: "save-language-button",
                    dataGTMCustom: "save-language",
                    disabled:
                      _vm.$v.cbUserInfo.isShowOnlineStatus.$invalid ||
                      _vm.$v.cbUserInfo.langKey.$invalid,
                    fullWidth: "",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.saveOnlineLanguage.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.update")),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "c-popup-bottom",
        {
          model: {
            value: _vm.popUpLanguage,
            callback: function ($$v) {
              _vm.popUpLanguage = $$v
            },
            expression: "popUpLanguage",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between mb-3 py-2 border-grey-tertiary-bottom-1",
              attrs: { id: "popup-language" },
            },
            [
              _c("p", {
                staticClass:
                  "font-roboto font-size-14 cj-color-black-primary font-weight-500",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbCreateAccount.language")
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "font-roboto cj-btn-text-primary font-size-14 font-weight-500",
                  attrs: { type: "button", "data-cy": "click-language" },
                  on: {
                    click: function ($event) {
                      _vm.popUpLanguage = false
                      _vm.cbUserInfo.langKey = _vm.valuePopUpLanguage
                      _vm.$v.cbUserInfo.langKey.$touch()
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbCreateAccount.placeholder.update")
                      ),
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("c-autocomplete-new", {
            staticClass:
              "font-roboto font-size-14 cj-color-black-primary font-weight-400 cj-field-not-border",
            attrs: {
              items: _vm.cbLovLanguages,
              "item-text": `${
                _vm.currentLanguage == "en" ? "value" : "value2"
              }`,
              "item-value": "key",
              placeholder: _vm.$t(
                "cbgwApp.cbCreateAccount.placeholder.language"
              ),
              isShowDropdown: true,
              isShowListItem: true,
              inputStyle: "cj-field-border-default rounded px-3",
            },
            model: {
              value: _vm.valuePopUpLanguage,
              callback: function ($$v) {
                _vm.valuePopUpLanguage = $$v
              },
              expression: "valuePopUpLanguage",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            id: "modal-c-new-phone-number",
            "data-cy": "new-phone-number-modal",
            centered: "",
            "no-close-on-backdrop": true,
            "hide-footer": "",
            "hide-header": "",
            "modal-class": "vac-custom-modal-b-modal",
          },
        },
        [
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center w-100 position-relative",
                attrs: { "data-cy": "modal-phone-number" },
              },
              [
                _c("div", { staticClass: "d-flex mb-2" }, [
                  _c("p", {
                    staticClass:
                      "font-roboto font-weight-500 cj-color-black-primary m-auto",
                    class: _vm.isMobile ? "font-size-18" : "font-size-24",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cbEditPhoneNumber.title")
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "position-absolute",
                    staticStyle: { right: "0" },
                    attrs: { type: "button" },
                    on: { click: _vm.closePhoneNumberModal },
                  },
                  [
                    _c("b-icon-x-circle-fill", {
                      staticClass: "font-size-18 cj-color-grey-secondary",
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", {
              staticClass:
                "font-roboto font-italic font-weight-500 cj-color-black-primary text-center mt-2 mb-2",
              class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.cbEditPhoneNumber.inputPhone")
                ),
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex pt-4 position-relative" }, [
              !_vm.isMobile
                ? _c(
                    "div",
                    {
                      staticClass: "rounded d-flex pr-2",
                      class: {
                        invalid: _vm.$v.phoneCode.$invalid || _vm.isExistPhone,
                        valid: !_vm.$v.phoneCode.$invalid,
                      },
                      attrs: { "data-cy": "wrap-phone-code" },
                    },
                    [
                      _c("c-autocomplete-new", {
                        staticClass:
                          "font-roboto font-weight-400 font-size-16 cj-color-black-primary my-auto cj-field-not-border",
                        attrs: {
                          "data-cy": "phone-code",
                          placeholder: "ID (+62)",
                          items: _vm.cbLovPhoneCodes,
                          isNoRelative: true,
                          "item-text": "value",
                          "item-value": "key",
                          "item-text-input": "key",
                        },
                        on: { input: _vm.handleCheckPhone },
                        model: {
                          value: _vm.$v.phoneCode.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.phoneCode, "$model", $$v)
                          },
                          expression: "$v.phoneCode.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "col-4 d-flex rounded pr-2",
                      class: {
                        invalid: _vm.$v.phoneCode.$invalid || _vm.isExistPhone,
                        valid: !_vm.$v.phoneCode.$invalid,
                      },
                      attrs: { "data-cy": "wrap-phone" },
                      on: { click: _vm.openPopupPhoneCodeM },
                    },
                    [
                      _c("input", {
                        staticClass:
                          "font-size-18 cj-field-not-border cj-field-input",
                        attrs: {
                          type: "text",
                          "data-cy": "c-account-update-profile-phone-code",
                          placeholder: "ID (+62)",
                        },
                        domProps: { value: _vm.phoneCode },
                        on: { focus: _vm.openPopupPhoneCodeM },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "my-auto d-flex" },
                        [
                          _c("icon", {
                            staticClass:
                              "my-auto cj-fill-black-primary ic_arrow_dropdown",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pl-2", staticStyle: { "min-width": "65%" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.phone.$model,
                        expression: "$v.phone.$model",
                      },
                    ],
                    staticClass:
                      "font-size-18 cj-field-border-default cj-field-input",
                    class: {
                      invalid:
                        _vm.$v.phone.$anyDirty &&
                        (_vm.$v.phone.$invalid || _vm.isExistPhone),
                      valid:
                        _vm.$v.phone.$anyDirty &&
                        !_vm.$v.phone.$invalid &&
                        !_vm.isExistPhone,
                    },
                    attrs: {
                      type: "text",
                      "data-cy": "c-account-update-profile-phone-number",
                      placeholder:
                        _vm.$t(
                          "cbgwApp.cbHome.cbHomeBeforeLogin.register.phoneNumber"
                        ) + "*",
                    },
                    domProps: { value: _vm.$v.phone.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.$v.phone, "$model", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.isExistPhone
              ? _c("div", [
                  _c("small", { staticClass: "font-validation" }, [
                    _c("span", {
                      staticClass: "text-danger cj-color-status-error",
                      attrs: { "data-cy": "validation" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            `cbgwApp.cbCreateAccount.phone.${_vm.valueStatusPhone}`
                          )
                        ),
                      },
                    }),
                  ]),
                ])
              : _c("p", {
                  staticClass: "font-validation mt-2",
                  class: `${
                    _vm.$v.phone.$anyDirty
                      ? _vm.$v.phone.$invalid
                        ? "cj-color-status-error"
                        : "cj-color-status-success d-none"
                      : "d-none"
                  }`,
                  attrs: { "data-cy": "validation" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(`cbgwApp.cbCreateAccount.phone.default`)
                    ),
                  },
                }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "c-button",
                  {
                    staticClass: "font-roboto text-center mt-4",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18 ",
                    attrs: {
                      "data-cy": "c-account-update-profile-submit",
                      fullWidth: "",
                      disabled:
                        _vm.$v.phone.$invalid ||
                        _vm.$v.phoneCode.$invalid ||
                        _vm.isExistPhone ||
                        _vm.isFetchingPhoneNumber,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbComponent.cbEditPhoneNumber.submit")
                        ),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "c-popup-bottom",
              {
                model: {
                  value: _vm.isShowPhoneCode,
                  callback: function ($$v) {
                    _vm.isShowPhoneCode = $$v
                  },
                  expression: "isShowPhoneCode",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between mb-3 py-2 border-grey-tertiary-bottom-1",
                  },
                  [
                    _c("p", {
                      staticClass:
                        "font-roboto font-size-14 cj-color-black-primary font-weight-500",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.phoneCode"
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "font-roboto cj-btn-text-primary font-size-14 font-weight-500",
                        attrs: {
                          "data-cy": "c-account-update-profile-update",
                          type: "button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.phoneCode = _vm.localPhoneCode
                            _vm.isShowPhoneCode = false
                            _vm.handleCheckPhone()
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbCreateAccount.placeholder.update"
                              )
                            ),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("c-autocomplete-new", {
                  staticClass:
                    "font-roboto font-size-14 cj-color-black-primary font-weight-400 cj-field-not-border",
                  attrs: {
                    items: _vm.cbLovPhoneCodes,
                    "item-text": "value",
                    "item-value": "key",
                    placeholder: "ID (+62)",
                    isShowDropdown: true,
                    isShowListItem: true,
                    inputStyle: "cj-field-border-default rounded px-3",
                    dataCyCustom: "c-account-update-profile-phone-code",
                  },
                  model: {
                    value: _vm.localPhoneCode,
                    callback: function ($$v) {
                      _vm.localPhoneCode = $$v
                    },
                    expression: "localPhoneCode",
                  },
                }),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          size: "md",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          id: "modal-c-set-change-phone",
          "hide-footer": "",
          "hide-header": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center w-100 position-relative",
                  },
                  [
                    _c("div", { staticClass: "d-flex mb-2" }, [
                      _c("p", {
                        staticClass:
                          "font-roboto font-weight-600 cj-color-black-primary m-auto",
                        class: _vm.isMobile ? "font-size-18" : "font-size-24",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbSetting.changePhoneNumber")
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "position-absolute",
                        staticStyle: { right: "0" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.clearModelAccountUpdateProfile()
                            cancel()
                          },
                        },
                      },
                      [
                        _c("b-icon-x-circle-fill", {
                          staticClass: "cj-color-grey-secondary",
                          class: _vm.isMobile ? "font-size-18" : "font-size-16",
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto font-italic font-weight-500 cj-color-black-primary text-center mt-2",
                  class: _vm.isMobile ? "font-size-16" : "font-size-18",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.descCbPassword")
                    ),
                  },
                }),
                _vm._v(" "),
                _vm.errorSubmitNewPhoneNumber
                  ? _c("p", {
                      staticClass:
                        "font-roboto border-radius-card cj-color-status-error font-weight-500 wrap-border-status-error px-3 py-2 mt-3",
                      class: _vm.isMobile ? "font-size-12" : "font-size-14",
                      attrs: {
                        "data-cy": "c-account-update-profile-incorrect-data",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbSetting.incorrectData")
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c("input", {
                    ref: "enterloginPassword",
                    staticClass: "cj-field-border-default mt-3",
                    attrs: {
                      type: _vm.passwordInputType,
                      "data-cy": `c-account-update-profile-input-password-${
                        _vm.passwordInputType === "text" ? "open" : "close"
                      }`,
                    },
                    on: { input: _vm.inputPassword },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "mt-3",
                      staticStyle: { "margin-left": "-2rem" },
                      attrs: {
                        type: "button",
                        "data-cy": "c-account-update-profile-toggle-password",
                      },
                      on: { click: _vm.togglePassword },
                    },
                    [
                      _vm.passwordInputType === "text"
                        ? _c("icon", {
                            staticClass: "cj-stroke-grey-secondary",
                            attrs: { name: "ic_eye_open", width: "1rem" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.passwordInputType === "password"
                        ? _c("icon", {
                            staticClass: "cj-stroke-grey-secondary",
                            attrs: { name: "ic_eye_close", width: "1rem" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form" },
                  [
                    _c(
                      "c-button",
                      {
                        staticClass: "font-roboto text-center mt-3",
                        class: _vm.isMobile ? "font-size-18" : "font-size-16",
                        attrs: {
                          fullWidth: "",
                          disabled: _vm.enableBtnNewPhone,
                          dataCyCustom: "c-account-update-profile-submit",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.submitNewPhoneNumber.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbSetting.btnContinue")
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-c-phone-verification",
          "modal-class": "vac-custom-modal-b-modal",
          size: "md",
          centered: "",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-verification-phone-number", {
                  attrs: {
                    visibleCountdown: true,
                    showCountdown: true,
                    visibleFooter: true,
                    errorSubmitOtp: _vm.errorSubmitOtp,
                    valueNewPhoneNumber: _vm.phoneCode + _vm.phoneTmp,
                  },
                  on: {
                    actionHandler: _vm.clearModelAccountUpdateProfile,
                    cancel: function ($event) {
                      _vm.isAlreadyOpenOTPVerificationPhoneNumber = false
                      _vm.clearModelAccountUpdateProfile()
                      cancel()
                    },
                    inputOtp: _vm.inputOtp,
                  },
                  model: {
                    value: _vm.phoneOtp,
                    callback: function ($$v) {
                      _vm.phoneOtp = $$v
                    },
                    expression: "phoneOtp",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          size: "md",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          id: "modal-c-success-change-phone-number",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t("cbgwApp.cbComponent.cChangePhone.success"),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.cChangePhone.descSuccess"
                    ),
                    iconCustom: "ic_check_circle",
                  },
                  on: { close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }