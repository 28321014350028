import axios from 'axios';
import * as SockJS from 'sockjs-client';
import { map } from 'rxjs';
import VueRouter from 'vue-router';
import { Store } from 'vuex';

import AccountService from '@/account/account.service';
import GlobalService from '@/services/global.service';

import { IFrame, RxStomp, RxStompState } from '@stomp/rx-stomp';

import { SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN } from '@/shared/constant/constants-session-storage';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

const SOCKET_APP = 'websocket/cb';
const API_GENERAL = 'services/btisocketkafkagw/api/btisocketkafkagw-kafka'; ///using dev.env to avoid cors

export const DESTINATION_USER_ONLINE = '/topic/cb/user_online';
export const DESTINATION_CONVERSATION_MEMBER = '/topic/cb/conversation_member'; // /id user
export const DESTINATION_CONVERSATION_ID = '/topic/cb/conversation_id'; // id conversation
export const DESTINATION_NOTIF_USER_LOGIN = '/topic/cb/notif'; //.login
export const DESTINATION_PAW_ORDER_PAID = '/topic/cb/cb_paw_order_paid';
export const DESTINATION_ORDER_TRACK_LOCATION = '/topic/cb/cb_order_track_location';
export const DESTINATION_ORDER_TRACK_SHOW_HIDE_MAP = '/topic/cb/cb_order_track_show_hide_map';
export const DESTINATION_BLOCKED_AND_SUSPEND = '/topic/cb/suspend-and-blocked';
export const DESTINATION_TKT_REPLIED = '/topic/cb/tkt-replied';
export const DESTINATION_LAST_SEEN = '/topic/cb/conversation_last_seen';

export default class SocketService {
  public globalService: GlobalService;
  private rxStomp: RxStomp;
  private authToken: string;
  public intervalId;

  constructor(private router: VueRouter, private store: Store<any>, private accountService: AccountService) {
    this.stomp = new RxStomp();
    this.setupConnectionStateMonitoring(); // Start monitoring the connection state

    // this.router.afterEach(() => this.sendUserOnline());

    this.store.watch(
      (_state, getters) => getters.authenticated,
      (value, oldValue) => {
        if (value === oldValue) return;
        // alert("authenticated changed "+ value);
        if (value) {
          console.log('SocketService: connectCustome');
          this.connectCustome();
        } else {
          this.stopConnectWithInterval();
          this.disconnect();
        }
      }
    );
  }
  private connectWithInterval() {
    const connectionCheckInterval = 3000; // 3 seconds in milliseconds

    this.intervalId = setInterval(() => {
      console.log('SocketService: connectWithInterval');
      this.connectCalledFromChatAndInterval();
    }, connectionCheckInterval);
  }

  public stopConnectWithInterval(): void {
    // Stop the interval if the user is not authorized
    if (this.intervalId) {
      clearInterval(this.intervalId);
      console.log('SocketService: Reconnect interval stopped due to unauthorized status');
    }
  }
  // Add to your SocketService class
  public setupConnectionStateMonitoring() {
    this.rxStomp.connectionState$.subscribe(state => {
      switch (state) {
        case RxStompState.CONNECTING:
          console.log('SocketService: Connecting to WebSocket...');
          this.store.commit('isSocketServiceNotActive', false);
          break;
        case RxStompState.CLOSING:
          this.store.commit('isSocketServiceNotActive', true);
          console.log('SocketService: Closing WebSocket connection...');
          break;
        case RxStompState.CLOSED:
          this.store.commit('isSocketServiceNotActive', true);
          console.log('SocketService: WebSocket connection closed.');
          // this.handleDisconnected();
          break;
        case RxStompState.OPEN:
          this.store.commit('isSocketServiceNotActive', false);
          console.log('SocketService: WebSocket connection open.');
          break;
        default:
          console.log('SocketService: WebSocket Unhandled connection state: ', state);
      }
    });
  }

  public connectCustome() {
    console.log('SocketService: try to connect');
    this.connectWithInterval();
    return this.connect();
  }

  get stomp() {
    return this.rxStomp;
  }

  set stomp(rxStomp) {
    this.rxStomp = rxStomp;
  }

  private connect(): void {
    this.updateCredentials();
    this.retrieveUserIdsOnline();
    // alert(this.rxStomp.activate())
    return this.rxStomp.activate();
  }

  public connectCalledFromChatAndInterval(): void {
    const isConnected = this.rxStomp.connected();

    if (!isConnected) {
      console.log('SocketService: RxStomp is not connected. Attempting to reconnect...');
      this.rxStomp.activate(); // Reconnect if not connected
    } else {
      console.log('SocketService: RxStomp connection is alive');
    }
  }

  private disconnect(): Promise<void> {
    return this.rxStomp.deactivate();
  }

  private buildUrl(): string {
    let url = '//' + process.env.SOCKET_URL + SOCKET_APP;
    if (this.authToken) {
      url += '?bearer=' + this.authToken;
    }

    return url;
  }

  private updateCredentials(): void {
    this.rxStomp.configure({
      webSocketFactory: () => {
        // this.getNewTokeIfExpired();
        console.log('SocketService: >>>>>>>>>>webSocketFactory ');
        const r = new SockJS(this.buildUrl(), null, { headers: { Authorization: 'Bearer ' + this.authToken } });
        return r;
      },
      reconnectDelay: 1000,
      beforeConnect: async client => {
        console.log('SocketService: Before Connect');
        const jwtAccessToken: any = jwt_decode(localStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN));
        const expires_in = jwtAccessToken.exp * 1000;

        if (expires_in && dayjs(expires_in).isAfter(dayjs(new Date()))) {
          this.authToken = localStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN);
        } else {
          await this.accountService.retrieveUserAccessToken().then(res => {
            this.authToken = res;
          });
        }

        return new Promise((resolve, reject) => {
          resolve();
        });
      },
      correlateErrors: (error: IFrame) => {
        console.error('SocketService: Broker reported error: ' + error.headers['message']);
        console.error('SocketService: Additional details: ' + error.body);
        return 'correlateErrors';
      },
      debug: (msg: string): void => {
        console.log(new Date(), msg);

        // if (msg && msg.includes('404')) {
        //   console.error('404 Not Found Error Detected');
        //   this.disconnect();
        //   this.connect();
        //   // Handle 404 error here, e.g., notify user, retry, etc.
        //  }
      },
    });

    this.rxStomp.connected$.subscribe(() => {
      this.sendUserOnline();
    });
  }

  public sendUserOnline(): void {
    if (this.store.getters.authenticated) {
      const socketSendUserOnline = this.store.getters.currentUser.isShowOnlineStatus
        ? `${API_GENERAL}/send-user-online/cb`
        : `${API_GENERAL}/send-user-online/hide`;

      axios
        .post(socketSendUserOnline)
        .then(res => {
          console.log('SocketService: send user online success');
        })
        .catch(err => {
          console.log('SocketService: error send user online success: ', err.response);
        });
    } else {
      console.log('SocketService: User not authenticated, not send user online');
    }
  }

  public isSessionLive(): void {
    axios
      .get(`${API_GENERAL}/is-session-live`)
      .then(res => {})
      .catch(err => {});
  }

  public retrieveUserIdsOnline(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(API_GENERAL + '/user-ids-online')
        .then(res => {
          this.store.commit('userIdsOnline', res.data);
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // ================== START SUBSCRIBE ==================
  public subscribeUserOnline(observer) {
    return this.rxStomp
      .watch(DESTINATION_USER_ONLINE)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeConversationBasedOnMember(observer) {
    return this.rxStomp
      .watch(DESTINATION_CONVERSATION_MEMBER + '/' + this.store.getters.account.id)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeConversationId(observer, conversationId?) {
    return this.rxStomp
      .watch(DESTINATION_CONVERSATION_ID + '/' + conversationId)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeNotif(observer) {
    return this.rxStomp
      .watch(DESTINATION_NOTIF_USER_LOGIN + '/' + this.store.getters.account.login)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribePawOrderPaid(observer) {
    return this.rxStomp
      .watch(DESTINATION_PAW_ORDER_PAID + '/' + this.store.getters.currentUser.id)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeCbOrderTrackLocation(observer, orderId: number) {
    return this.rxStomp
      .watch(DESTINATION_ORDER_TRACK_LOCATION + '/' + orderId)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeBlockedAndSuspend(observer) {
    return this.rxStomp
      .watch(DESTINATION_BLOCKED_AND_SUSPEND + '/' + this.store.getters.account.id)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeTktReplied(observer) {
    return this.rxStomp
      .watch(DESTINATION_TKT_REPLIED + '/' + this.store.getters.account.id)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeLastSeen(observer, conversationId) {
    return this.rxStomp
      .watch(DESTINATION_LAST_SEEN + '/' + conversationId)
      .pipe(
        map(imessage => {
          return JSON.parse(imessage.body);
        })
      )
      .subscribe(observer);
  }
  // ================== END SUBSCRIBE ==================

  // ================== START UTILS ==================

  // ================== END UTILS ==================
}
