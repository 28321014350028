var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vac-format-message-wrapper",
      class: { "vac-text-ellipsis": _vm.singleLine },
    },
    [
      !_vm.textFormatting.disabled
        ? _c(
            "div",
            { class: { "vac-text-ellipsis": _vm.singleLine } },
            [
              _vm._l(_vm.linkifiedMessage, function (message, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "vac-format-container",
                    attrs: { "data-cy": "vac-format-bubble" },
                  },
                  [
                    _c(
                      message.url ? "a" : "span",
                      {
                        tag: "component",
                        class: {
                          "vac-text-ellipsis": _vm.singleLine,
                          "vac-text-bold": message.bold,
                          "vac-text-italic": _vm.deleted || message.italic,
                          "vac-text-strike": message.strike,
                          "vac-text-underline": message.underline,
                          "vac-text-inline-code":
                            !_vm.singleLine && message.inline,
                          "vac-text-multiline-code":
                            !_vm.singleLine && message.multiline,
                          "vac-text-tag":
                            !_vm.singleLine && !_vm.reply && message.tag,
                        },
                        attrs: {
                          href: message.href,
                          target: message.href ? _vm.linkOptions.target : null,
                          rel: message.href ? _vm.linkOptions.rel : null,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openTag(message)
                          },
                        },
                      },
                      [
                        _vm._t(
                          "deleted-icon",
                          function () {
                            return [
                              _vm.deleted
                                ? _c("svg-icon", {
                                    staticClass: "vac-icon-deleted",
                                    attrs: { name: "deleted" },
                                  })
                                : _vm._e(),
                            ]
                          },
                          null,
                          { deleted: _vm.deleted }
                        ),
                        _vm._v(" "),
                        message.url && message.image
                          ? [
                              _c(
                                "div",
                                { staticClass: "vac-image-link-container" },
                                [
                                  _c("div", {
                                    staticClass: "vac-image-link",
                                    style: {
                                      "background-image": `url('${message.value}')`,
                                      height: message.height,
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "vac-image-link-message" },
                                [_c("span", [_vm._v(_vm._s(message.value))])]
                              ),
                            ]
                          : [
                              _c(
                                "span",
                                {
                                  attrs: { "data-cy": "vac-message-bubble" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$options.filters.SECURITY_VHTML(
                                        message.value,
                                        _vm.$env.BASE_URL
                                      )
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(message.value) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.stickerUrl
                ? [
                    _c("img", {
                      staticClass: "vac-img-sticker",
                      attrs: { src: _vm.stickerUrl, alt: "sticker" },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.SECURITY_VHTML(
                  _vm.formattedContent,
                  _vm.$env.BASE_URL
                )
              ),
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }