import { WfKeyType } from '@/shared/model/enumerations/wf-key-type.model';
// WF
export interface IWfHistory {
  id?: number;
  app?: string;
  externalId?: number;
  subExternalId?: number | null;
  workflowName?: string;
  keyName?: string;
  keyType?: keyof typeof WfKeyType;
  externalStatus?: string | null;
  subExternalStatus?: string | null;
  historyStatus?: string | null;
  cronDynamicExpiredDate?: Date | null;
  cronDynamicExpiredArr?: string | null;
  note?: string | null;
  createdBy?: string;
  createdDate?: Date;
}

export class WfHistory implements IWfHistory {
  constructor(
    public id?: number,
    public app?: string,
    public externalId?: number,
    public subExternalId?: number | null,
    public workflowName?: string,
    public keyName?: string,
    public keyType?: keyof typeof WfKeyType,
    public externalStatus?: string | null,
    public subExternalStatus?: string | null,
    public historyStatus?: string | null,
    public cronDynamicExpiredDate?: Date | null,
    public cronDynamicExpiredArr?: string | null,
    public note?: string | null,
    public createdBy?: string,
    public createdDate?: Date
  ) {}
}
