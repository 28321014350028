var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-verification-email-new" }, [
    _c(
      "div",
      {
        staticClass:
          "header-container d-flex border-grey-tertiary-bottom-1 pb-2",
      },
      [
        _c("div", { staticClass: "mx-auto px-4 text-center" }, [
          _c("p", {
            staticClass:
              "px-2 font-roboto cj-color-black-primary font-weight-700 font-size-24",
            class: { "font-size-20": _vm.isMobile },
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.emailVerification")
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "back-wrapper cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [
            _c("b-icon-x-circle", {
              staticClass: "font-size-24 cj-color-grey-secondary my-auto",
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    !_vm.isShowInputOtp
      ? _c(
          "div",
          { staticClass: "content-container text-center" },
          [
            _c("p", {
              staticClass:
                "mb-3 font-roboto text-italic cj-color-black-primary font-weight-500 font-size-18",
              class: { "font-size-14": _vm.isMobile },
              domProps: {
                textContent: _vm._s(_vm.$t("cbgwApp.cbComponent.enaterEmail")),
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.email.$model,
                  expression: "$v.email.$model",
                },
              ],
              staticClass: "my-auto cj-field-input",
              attrs: { type: "text" },
              domProps: { value: _vm.$v.email.$model },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.$v.email, "$model", $event.target.value)
                  },
                  _vm.handleCheckEmail,
                ],
              },
            }),
            _vm._v(" "),
            _vm.isExistEmail
              ? _c("div", [
                  _c("small", { staticClass: "font-validation" }, [
                    _c("span", {
                      staticClass: "text-danger cj-color-status-error",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            `cbgwApp.cbHome.cbHomeBeforeLogin.register.DELETED`
                          )
                        ),
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$v.email.$anyDirty && _vm.$v.email.$invalid
              ? _c("div", [
                  !_vm.$v.email.email
                    ? _c("small", {
                        staticClass: "font-validation cj-color-status-error",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationMustEmail"
                            )
                          ),
                        },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "c-button",
              {
                staticClass: "mt-3 font-roboto font-weight-500 font-size-20",
                class: { "font-size-16": _vm.isMobile },
                attrs: {
                  disabled:
                    _vm.$v.email.$invalid || _vm.isExistEmail || _vm.isSubmit,
                  fullWidth: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_c("span", [_vm._v("SUBMIT")])]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "content-container text-center" },
          [
            _c("p", {
              staticClass:
                "font-roboto text-italic cj-color-black-primary font-weight-500 font-size-18",
              class: { "font-size-14": _vm.isMobile },
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbComponent.cbVerificationMethod.descEmailOtp"
                  )
                ),
              },
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "mb-3 font-roboto text-italic cj-color-black-primary font-weight-500 font-size-18",
                class: { "font-size-14": _vm.isMobile },
              },
              [_vm._v("\n      " + _vm._s(_vm.email) + "\n    ")]
            ),
            _vm._v(" "),
            _vm.invalidOtp
              ? _c("div", { staticClass: "d-flex w-100 mt-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-roboto px-3 py-1 d-flex wrap-border-status-error rounded-pill m-auto cj-color-status-error font-size-14 font-weight-300",
                    },
                    [
                      _c("b-icon-x-circle", {
                        staticClass:
                          "font-size-16 cj-color-status-error my-auto",
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "ml-2 my-auto",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cbVerificationMethod.wrongOtpCode"
                            )
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("c-input-otp", {
              staticClass: "my-auto cj-field-input",
              attrs: {
                type: "text",
                visibleLoading: false,
                isUpperCase: true,
                refOtp: "MailN",
              },
              on: { input: _vm.handleInputOtp },
              model: {
                value: _vm.inputOtp,
                callback: function ($$v) {
                  _vm.inputOtp = $$v
                },
                expression: "inputOtp",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "div",
                {
                  staticClass: "mr-1 mb-2 mb-lg-0 cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.changeEmail()
                    },
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran cj-color-red-primary font-weight-400 font-size-18",
                      class: { "font-size-14": _vm.isMobile },
                    },
                    [_vm._v("Change Email")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex ml-auto",
                  class: `${
                    !_vm.showCountdown
                      ? "cj-btn-text-primary"
                      : "cj-color-black-primary"
                  }`,
                },
                [
                  _c("p", {
                    staticClass:
                      "font-catamaran cj-color-red-primary font-weight-400 font-size-18",
                    class: { "font-size-14": _vm.isMobile },
                    attrs: {
                      "data-cy": _vm.showCountdown
                        ? "resend-button-off"
                        : "resend-button-on",
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.sendAgain"
                        )
                      ),
                    },
                    on: { click: _vm.resend },
                  }),
                  _vm._v(" "),
                  _vm.showCountdown
                    ? _c("c-time-countdown", {
                        attrs: { timeMilis: 60 * 1000, stopCountdown: 0 },
                        on: {
                          action: function ($event) {
                            _vm.showCountdown = false
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ seconds }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-catamaran font-weight-400 my-auto ml-1",
                                      class: _vm.isMobile
                                        ? "font-size-14"
                                        : "font-size-18",
                                    },
                                    [
                                      _vm._v(
                                        "\n            (" +
                                          _vm._s(seconds) +
                                          ")\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          897701330
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }