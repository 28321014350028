import AccountService from '@/account/account.service';
import Component, { mixins } from 'vue-class-component';
import '@/shared/config/dayjs';
import GlobalService from '@/services/global.service';
import { Inject, Vue } from 'vue-property-decorator';
import { CbVwPaw, ICbVwPaw } from './shared/model/cb-vw-paw.model';
import { CbSvcType } from './shared/model/enumerations/cb-svc-type.model';
import { CbSvcCat, ICbSvcCat } from './shared/model/cb-svc-cat.model';
import { gsap } from 'gsap/all';
import { CbAuctionProject, ICbAuctionProject } from './shared/model/cb-auction-project.model';
import Icon from './shared/icons/icon.vue';
import CNavbarMenu from './components/c-navbar-menu/c-navbar-menu.vue';
import { CbVwSvc } from './shared/model/cb-vw-svc.model';
import PCbVwSvcService from '@/services/cb-vw-svc.service';
import PCbLogService from '@/services/cb-log.service';
import { CbLog, ICbLog } from './shared/model/cb-log.model';
import { CbLogType } from './shared/model/enumerations/cb-log-type.model';
import { CbLocation } from './shared/model/cb-location.model';
import { CbVwAuctionProjectPreOffer, ICbVwAuctionProjectPreOffer } from './shared/model/cb-vw-auction-project-pre-offer.model';
import CPawDetail from './components/c-paw-detail/c-paw-detail.vue';
import CShare from './components/c-shared/c-share.vue';
import CInputPin from './components/c-input-pin/c-input-pin.vue';
import CConfirmation from './components/c-confirmation/c-confirmation.vue';
import AlertService from '@/shared/alert/alert.service';

import CAttachmentPreview from '@/components/c-attachment-preview/c-attachment-preview.vue';

import { ICbVWMedal } from './shared/model/cb-vw-medal.model';
import CLoginIntroduction from './components/c-login-introduction/c-login-introduction.vue';
import CTabFooter from './components/c-tab-footer/c-tab-footer.vue';
import CNavbarMobile from './components/c-navbar-mobile/c-navbar-mobile.vue';
import AppRouteManagemet from './app-route-management.component';
import AppSocket from './app-socket.component';
import CLogin from './components/auth/login/c-login.vue';
import CEditPhoneNumber from './components/auth/c-edit-phone-number.vue';
import CNavbar from '@/components/navbar/c-navbar.vue';
import PCbUserBookmarkAggService from '@/services/cb-user-bookmark-agg.service';
import { ICbUserBookmarkAgg, CbUserBookmarkAgg } from '@/shared/model/cb-user-bookmark-agg.model';
import CResetPassword from './components/auth/c-reset-password.vue';
import { CbBookmarkType } from '@/shared/model/enumerations/cb-bookmark-type.model';
import JhiDataUtils from './shared/data/data-utils.service';
import AppLov from './app-lov';
import { TabSettingType } from './module/general/setting/settings.component';
import LiveChat from './module/live-chat/live-chat.vue';
import { DATE_TIME_LONG_FORMAT } from './shared/date/filters';
import dayjs from 'dayjs';
import CCardCookies from './components/c-card-cookies/c-card-cookies.vue';
import CCookiesSetting from './components/c-cookies-setting/c-cookies-setting.vue';
import PCbUserTimezoneService from './services/cb-user-timezone.service';
import { CbUserCookie, ICbUserCookie } from './shared/model/cb-user-cookie.model';
import PCbUserCookieService from './services/cb-user-cookie.service';
import CVerificationPhone from './components/auth/c-verification-phone/c-verification-phone.vue';
import CVerificationEmail from './components/auth/c-verification-email/c-verification-email.vue';
import CVerificationPin from './components/auth/c-verification-pin/c-verification-pin.vue';
import AppNotif from './app-notif';
import AppRegisterPin from './app-register-pin';
import { CShareData, ICShareData } from './shared/model/cb-share-data.model';

import CProfileReport from './module/general/profile/components/c-profile-report/c-profile-report.vue';
import { ICbVwUser } from './shared/model/cb-vw-user.model';
import CAppModals from './components/c-app-modals/c-app-modals.vue';
import AppOrderReview from './app-order-review';
import { IProgressUpload } from './shared/model/progress-upload';
import CProgressUpload from '@/components/c-progress-upload/c-progress-upload.vue';
import { Flutter } from './app-flutter';
import { CbPlatformType } from './shared/model/enumerations/cb-platform-type.model';
import { SESSION_STORAGE_IS_ALREADY_HIT } from './shared/constant/constants-session-storage';
import CVerificationEmailNew from './components/c-verification-email-new/c-verification-email-new.vue';
import MapUtils from '@/shared/utils/map-utils.service';
import CFooterBottomSheet from './components/c-footer-bottom-sheet/c-footer-bottom-sheet.vue';
import { DeviceSizeType } from './shared/model/enumerations/device-size-type.model';
import CPersonalizeYInterests from './components/c-personalize-y-Interests/c-personalize-y-Interests.vue';
import CBillboardDetail from './components/c-billboard-detail/c-billboard-detail.vue';
import { CbVwBillboard, ICbVwBillboard } from './shared/model/cb-vw-billboard.model';
import CButton from './components/button/c-button.vue';
import COrderProjectDelivery from './components/c-order/c-order-project-delivery/c-order-project-delivery.vue';
import { FnListener, IFnListener } from './shared/model/fn-listener-model';
import { Debounce } from './shared/decorator/debounce';
import CLoader from './components/c-loader/c-loader.vue';
import CMediaPreview from './components/c-media-preview/c-media-preview.vue';
import CFooter from './components/c-footer/c-footer.vue';
import CFooterCopyright from './components/c-footer-copyright/c-footer-copyright.vue';
import AppThemVars from './app-theme-vars.component';
import PCbUserInfoService from './services/cb-user-info.service';
import CLanguages from './components/c-languages/c-languages.vue';

let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

@Component({
  components: {
    LiveChat,
    Icon,
    CNavbarMenu,
    CNavbar,
    CPawDetail,
    CShare,
    CLoginIntroduction,
    CInputPin,
    CConfirmation,
    CTabFooter,
    CNavbarMobile,
    CLogin,
    CEditPhoneNumber,
    CResetPassword,
    CAttachmentPreview,
    CCardCookies,
    CCookiesSetting,
    CVerificationPhone,
    CVerificationEmail,
    CVerificationPin,
    CAppModals,
    CProfileReport,
    CProgressUpload,
    CVerificationEmailNew,
    CFooterBottomSheet,
    CPersonalizeYInterests,
    CBillboardDetail,
    CButton,
    COrderProjectDelivery,
    CLoader,
    CMediaPreview,
    CFooter,
    CFooterCopyright,
    CLanguages,
  },
  mixins: [AppRouteManagemet, AppSocket, AppLov, AppNotif, AppRegisterPin, AppThemVars],
})
export default class App extends mixins(JhiDataUtils, AppOrderReview, MapUtils) {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('alertService') public alertService: () => AlertService;
  @Inject('accountService') private accountService: () => AccountService;
  @Inject('pCbVwSvcService') private pCbVwSvcService: () => PCbVwSvcService;
  @Inject('pCbLogService') private pCbLogService: () => PCbLogService;
  @Inject('pCbUserInfoService') private pCbUserInfoService: () => PCbUserInfoService;
  @Inject('pCbUserBookmarkAggService') private pCbUserBookmarkAggService: () => PCbUserBookmarkAggService;
  @Inject('pCbUserTimezoneService') private pCbUserTimezoneService: () => PCbUserTimezoneService;
  @Inject('pCbUserCookieService') private pCbUserCookieService: () => PCbUserCookieService;

  // ================= SERVICES =================
  // ================= START VARIABLES =================
  static fnArr: IFnListener[] = [];

  public cbUserBookmarkAgg: ICbUserBookmarkAgg = new CbUserBookmarkAgg();
  public cbVwPaw: ICbVwPaw = new CbVwPaw();
  public cbSvcCat: ICbSvcCat = new CbSvcCat();
  public cbAuctionProject: ICbAuctionProject = new CbAuctionProject();
  public cbVwAuctionProjectPreOffer: ICbVwAuctionProjectPreOffer = new CbVwAuctionProjectPreOffer();
  public isShow = false;
  public visible = false;
  public cbSvcs: CbVwSvc[] = [];
  public cbSvcType = CbSvcType;
  public isFetchingSvcs = false;
  public overlay = false;
  public shareData: ICShareData = new CShareData();
  public cbVwMedals: ICbVWMedal[] = [];
  public isShowTabFooter = true;
  public isShowNavMobile = true;
  public isShowNavbar = true;
  public cbUserCookie: ICbUserCookie = new CbUserCookie();
  public cbCountCookies = 0;
  public isShowCardCookies = true;
  public isNotOpenGlobalDialogCallback = false;

  public cbVwBillboard: ICbVwBillboard = new CbVwBillboard();

  public google;
  public geocoder;

  public cbModalVerificationOtp: any;
  public auctionProjectId: number = null;
  public auctionProjectTab: string = null;

  public isSavingLog = false;

  public isBookmark = false;
  public userBookmark: ICbUserBookmarkAgg = new CbUserBookmarkAgg();
  public CbBookmarkType = CbBookmarkType;

  public hoverWidth = '';
  public hoverMinHeight = '';

  public TabSettingType = TabSettingType;
  public isDekstop = false;

  // ATTACHMENT PREVIEW
  public isShowAttachmentPreview = false;
  public attachmentPreviews: any[] = [];

  public progressUploads: IProgressUpload[] = [];

  public envSecret = '';

  public fileMediaPreview = '';

  // =================  VARIABLES =================
  // ================= START DEFAULT FUNCTION =================
  beforeCreate() {
    window['AppCall'] = {
      components: this,
      callbackGoogleMap: () => this.callbackGoogleMap(),
    };
  }

  public callbackGoogleMap() {
    this.logWebHit();
  }
  public created() {
    if (this.$store.getters.authenticated) {
      this.checkSetUsername();
    }
    this.checkDeviceSize();

    this.fnInit();

    this.logWebHit(); // first call by sadary will result undefined  ,to handle  that iissue by AppCall.callbackGoogleMap
    if (this.getUser && this.getUser.id) {
      this.saveDailyLogin();
    }
    this.checkMobile();
    this.initGlobalOnClick();
    document.addEventListener('scroll', this.scrollListener);

    this.$store.watch(
      () => this.$store.getters.authenticated,
      () => {
        if (this.$store.getters.authenticated) {
          this.checkSetUsername();
          this.initUserAuthenticated();
        }
      }
    );
    this.initUserAuthenticated();
    this.initOffOn();
  }

  public fnInit() {
    this.isDekstop = window.innerWidth < 991;
    const fn = async (event: Event) => {
      this.checkDeviceSize();
      if (event.isTrusted) this.isDekstop = window.innerWidth < 991;
      this.checkMobile();
    };
    const data = { key: FnListener.KEY_RESIZE, fn: fn };
    const foundIndex = App.fnArr.findIndex(obj => obj.key === FnListener.KEY_RESIZE);
    if (foundIndex !== -1) {
      window.removeEventListener(FnListener.KEY_RESIZE, App.fnArr[foundIndex].fn, false);
      App.fnArr[foundIndex] = data;
    } else {
      App.fnArr.push(data);
    }
    window.addEventListener(FnListener.KEY_RESIZE, fn, false);
  }

  public updated() {
    //security
    if (!Flutter.isRunOn() && process.env?.NODE_ENV == 'development' && process.env.ENV_SECRET != this.$store.getters.envSecret) {
      this.$nextTick(() => {
        //security  handle remove componet
        if (!document.getElementById('modal-first-choice')) {
          this.$router.replace({ path: '/aasas' });
        }
      });
      this.globalService().openGlobalDialog(this.$root, 'modal-first-choice');
    }
  }

  private initGlobalOnClick() {
    window.onclick = event => {
      if (event.target == <any>this.$refs.modalPawDetailField) {
        (<any>this.$refs.modalPawDetailField).classList.remove('show');
      }
      if (event.target == <any>this.$refs.modalPawDetailInside) {
        (<any>this.$refs.modalPawDetailInside).classList.remove('show');
      }
    };
  }
  private initOffOn() {
    (<any>this.$root).$off('app::liveChatVisible');
    (<any>this.$root).$on('app::liveChatVisible', value => {
      this.visible = value;
    });

    (<any>this.$root).$off('app::progressUploadDownload');
    (<any>this.$root).$on('app::progressUploadDownload', (progressUploads: IProgressUpload[]) => {
      this.progressUploads = progressUploads;
      const progress = progressUploads.filter(x => x.progress == 100);
      if (progress.length == progressUploads.length) {
        setTimeout(() => {
          this.closeProgressUploadMini();
        }, 1000);
      }
    });

    (<any>this.$root).$off('app::getProgressUploadDownload');
    (<any>this.$root).$on('app::getProgressUploadDownload', (callBack: (progress: IProgressUpload[]) => void) => {
      callBack(this.progressUploads);
    });

    (<any>this.$root).$off('app::showProgressUploadDownload');
    (<any>this.$root).$on('app::showProgressUploadDownload', isShow => {
      if (isShow) {
        (<any>this.$refs.toastProgressUpload).show();
        (<any>this.$refs.toastProgressUpload).toaster = 'b-toaster-top-center';
      } else this.closeProgressUploadMini();
    });
    (<any>this.$root).$off('openAttachment');
    (<any>this.$root).$on('openAttachment', (attachments: any[]) => {
      this.closeAllDialog();
      this.attachmentPreviews = [];
      this.attachmentPreviews = [...attachments];
      this.isShowAttachmentPreview = true;
    });

    (<any>this.$root).$off('createLog');
    (<any>this.$root).$on('createLog', (cbLog: ICbLog) => {
      this.pCbLogService().create(cbLog);
    });

    (<any>this.$root).$off('isShowNavbar');
    (<any>this.$root).$on('isShowNavbar', isShowNavbar => {
      this.isShowNavbar = isShowNavbar;
    });

    (<any>this.$root).$off('handleOpenLogin');
    (<any>this.$root).$on('handleOpenLogin', () => {
      this.closeAllDialog();
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
    });

    (<any>this.$root).$off('app::changeFunction');
    (<any>this.$root).$on('app::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });

    (<any>this.$root).$off('checkSetUsername');
    (<any>this.$root).$on('checkSetUsername', () => {
      this.checkSetUsername();
    });

    (<any>this.$root).$off('app::changeValue');
    (<any>this.$root).$on('app::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });
    (<any>this.$root).$off('app::modalPawDetail');
    (<any>this.$root).$on('app::modalPawDetail', (cbVwPaw: ICbVwPaw) => {
      this.cbVwPaw = cbVwPaw;
      this.globalService().openGlobalDialog(this.$root, 'modal-paw-detail');
    });
    (<any>this.$root).$off('app::modalPersonalizeYInterests');
    (<any>this.$root).$on('app::modalPersonalizeYInterests', () => {
      this.globalService().openGlobalDialog(this.$root, 'modal-personalize-y-interests');
    });
    (<any>this.$root).$off('app::modalBillboardDetail');
    (<any>this.$root).$on('app::modalBillboardDetail', (cbVwBillboard: ICbVwBillboard) => {
      this.cbVwBillboard = cbVwBillboard;
      this.globalService().openGlobalDialog(this.$root, 'modal-billboard-detail');
    });
    (<any>this.$root).$off('closeModalPawDetail');
    (<any>this.$root).$on('closeModalPawDetail', () => {
      (<any>this.$refs.modalPawDetailField).classList.remove('show');
      (<any>this.$refs.modalPawDetailInside).classList.remove('show');
    });
    (<any>this.$root).$off('openModalCShare');
    (<any>this.$root).$on('openModalCShare', (type: string, data: any) => {
      if (type.toUpperCase() == 'PAW') {
        if (data?.authorId === this.currentUser?.id) {
          this.shareData = {
            title: this.$t('cbgwApp.cbComponent.modals.sharedPaw.title'),
            description: this.$t('cbgwApp.cbComponent.modals.sharedPaw.description'),
            url: location.origin + '/general/page-paw-detail/' + data.id,
            externalId: data.id,
            externalType: type.toUpperCase(),
          };
        } else {
          this.shareData = {
            title: this.$t('cbgwApp.cbComponent.modals.likePaw.title'),
            description: this.$t('cbgwApp.cbComponent.modals.likePaw.description'),
            url: location.origin + '/general/page-paw-detail/' + data.id,
            externalId: data.id,
            externalType: type.toUpperCase(),
          };
        }
      } else if (type.toUpperCase() == 'AUCTION') {
        this.shareData = {
          title: this.$t('cbgwApp.cbComponent.modals.likeProject.title'),
          description: this.$t('cbgwApp.cbComponent.modals.likeProject.description'),
          url: location.origin + '/general/p-auction-project-detail/' + data,
          externalId: data,
          externalType: type.toUpperCase(),
        };
      }
      this.globalService().openGlobalDialog(this.$root, 'modal-c-share');
    });
    (<any>this.$root).$off('showOverlay');
    (<any>this.$root).$on('showOverlay', () => {
      this.overlay = true;
    });
    (<any>this.$root).$off('hideOverlay');
    (<any>this.$root).$on('hideOverlay', () => {
      this.hideOverlay();
    });
    (<any>this.$root).$off('app::saveCbUserBookmarkAgg');
    (<any>this.$root).$on('app::saveCbUserBookmarkAgg', (cbUserBookmarkAgg: ICbUserBookmarkAgg, callback?: any) => {
      this.saveCbUserBookmarkAgg(cbUserBookmarkAgg, callback);
    });

    (<any>this.$root).$off('app::cMediaPreview');
    (<any>this.$root).$on('app::cMediaPreview', (att: any) => {
      this.fileMediaPreview = att;
    });
  }
  public saveDailyLogin() {
    this.isSavingLog = true;
    const cbLog: ICbLog = {
      cbLogType: CbLogType.DAILY_LOGIN,
      valueHit: CbLogType.DAILY_LOGIN,
    };

    this.pCbLogService()
      .create(cbLog)
      .finally(() => {
        this.isSavingLog = false;
      });
  }

  public isScrolling;

  public handleScroll() {
    return document.body.scrollTop || document.documentElement.scrollTop;
  }

  public scrollListener() {
    resizeThrottler(this.handleScroll);
    window.clearTimeout(this.isScrolling);

    this.isScrolling = setTimeout(function () {}, 66);
  }

  public beforeDestroy() {
    document.removeEventListener('scroll', this.scrollListener);
  }

  public initUserAuthenticated() {
    if (this.$store.getters.authenticated) {
      this.accountService().retrieveCbVwUserCurrent();
      this.retrieveCbUserTimezones();
      Flutter.call('loginOrStartUp');
    }
  }

  public checkSetUsername() {
    this.pCbUserInfoService()
      .retrieveIsUsername()
      .then(response => {
        this.$store.commit('isSetUsername', response ? false : true);
      })
      .catch(() => {
        console.log('error');
      });
  }

  public retrieveCbUserTimezones() {
    this.pCbUserTimezoneService()
      .retrieveBylogin()
      .then(res => {
        if (res?.data?.length > 0) {
          let index = null;
          const data = {};
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].userZoneDateTime = dayjs(res.data[i].userZoneDateTime, DATE_TIME_LONG_FORMAT).toDate();
            res.data[i].timeZoneName = res.data[i].timeZoneName.replace(res.data[i].timeZoneCode, '').replace('(', '').replace(')', '');
            res.data[i].dateFormat = res.data[i].userZoneDateTime.toLocaleString('en-US', { dateStyle: 'medium' });
            res.data[i].timeFormat = `${res.data[i].userZoneDateTime.toLocaleString('en-US', {
              hour: '2-digit',
              hour12: false,
              minute: '2-digit',
            })}`;

            if (res.data[i].timeZoneCode == 'UTC') {
              index = i;
              Object.assign(data, res.data[i]);
            }
          }
          res.data.splice(index, 1);
          res.data.unshift(data);
          this.$store.commit('setCbUserTimeZones', res.data);
        }
      });
  }

  // ================= END DEFAULT FUNCTION =================
  public handleMouseLeave() {
    gsap.to(<any>this.$refs.appOverlay, {
      visibility: 'hidden',
      duration: 0,
    });
    gsap.to(<any>this.$refs.cItemAuctionProjectOfferHover, {
      x: 0,
      y: 0,
      visibility: 'hidden',
      scale: 0,
      duration: 0,
    });
    gsap.killTweensOf(<any>this.$refs.appOverlay);
    gsap.killTweensOf(<any>this.$refs.cItemAuctionProjectOfferHover);
    this.cbVwPaw = new CbVwPaw();
    this.cbSvcCat = new CbSvcCat();
  }

  // ================= START FUNCTION =================
  @Debounce(50)
  public checkMobile() {
    if (window.innerWidth < 992) {
      this.$store.commit('isMobile', true);
    } else {
      this.$store.commit('isMobile', false);
    }
  }

  @Debounce(50)
  public checkDeviceSize() {
    if (window.innerWidth < 576) {
      this.$store.commit('isDeviceSize', DeviceSizeType.SM);
    } else if (window.innerWidth >= 576 && window.innerWidth < 992) {
      this.$store.commit('isDeviceSize', DeviceSizeType.MD);
    } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      this.$store.commit('isDeviceSize', DeviceSizeType.LG);
    } else {
      this.$store.commit('isDeviceSize', DeviceSizeType.XL);
    }
  }

  public isCollapse() {
    this.isShow = true;
  }
  public createNewLivechat() {
    this.isShow = true;
    this.visible = !this.visible;
  }
  public isVisible() {
    if (this.isMobile) {
      this.$router.push({ name: 'LiveChat' });
    } else {
      this.visible = !this.visible;
    }
  }
  // ================= FUNCTION =================
  public handleCancelLoginIntroduction() {
    this.closeAllDialog();
  }

  public retrieveSvc() {
    this.isFetchingSvcs = true;
    this.pCbVwSvcService()
      .retrieve()
      .then(res => {
        this.cbSvcs = [...res.data];
        this.$store.commit('svcs', this.cbSvcs);
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isFetchingSvcs = false;
      });
  }

  public initLogWebHit(): ICbLog {
    const cbLog: ICbLog = new CbLog();
    cbLog.cbLogType = CbLogType.WEB_HIT;

    // CHECK user akses dari mana
    const isMobileApp: boolean = Flutter.isRunOn();
    if (isMobileApp) {
      cbLog.valueHit = CbPlatformType.MOBILE_APP;
    }
    if (!isMobileApp) {
      if (window.innerWidth < 991) {
        cbLog.valueHit = CbPlatformType.MOBILE_WEB;
      } else {
        cbLog.valueHit = CbPlatformType.DESKTOP_WEB;
      }
    }
    return cbLog;
  }

  public logWebHit() {
    if (sessionStorage.getItem(SESSION_STORAGE_IS_ALREADY_HIT) == 'true') {
      return;
    }

    if (!Flutter.isRunOn()) {
      this.getCurrentPosition({ maximumAge: 3000, timeout: 5000, enableHighAccuracy: true })
        .then((position: GeolocationPosition) => {
          this.google = (<any>window).google;
          if (this.google?.maps) {
            this.geocoder = new this.google.maps.Geocoder();
          } else {
            return;
          }
          let cbLog: ICbLog = this.initLogWebHit();
          const curloc = new this.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          const bounds = new this.google.maps.LatLngBounds();
          bounds.extend(curloc);
          // cari alamatnya
          this.geocoder.geocode({ location: curloc }, (results, status) => {
            if (status !== 'OK' || !results[0]) {
              throw new Error(status);
            }
            const result = results[0];
            cbLog = Object.assign({}, this.logFromGeocode(result, cbLog));

            this.pCbLogService().create(cbLog);
            // SIMPAN KE DALAM SESSION UNTUK WEB HIT
            sessionStorage.setItem(SESSION_STORAGE_IS_ALREADY_HIT, 'true');
          });
        })
        .catch(err => {
          if (this.nextRouteName != 'PBuyerHome' && this.nextRouteName != 'PFreelancerHome' && this.nextRouteName != 'PHome') {
            (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.allowLocationAccess').toString(), {
              toaster: 'b-toaster-top-center',
              title: 'Warning',
              variant: 'warning',
              solid: true,
              autoHideDelay: 2000,
            });
          }
        });
    }
  }

  public get nextRouteName() {
    return this.$store.getters.nextRouteName;
  }

  public logFromGeocode(result, cbLog: ICbLog): ICbLog {
    cbLog.cbLocation = new CbLocation();

    cbLog.cbLocation.latGoogle = result.geometry.location.lat();
    cbLog.cbLocation.longGoogle = result.geometry.location.lng();
    cbLog.cbLocation.placeId = result.place_id;

    const ac = result.address_components;

    //adehuh
    let country;
    let province;
    let city;
    let subCity;
    let zipCode;

    for (let c = 0; c < ac.length; c++) {
      if (ac[c]['short_name'].length == 2) {
        // country
        country = ac[c]['long_name'];
        if (ac[c - 1] != null) {
          province = ac[c - 1]['long_name'];
        }
        if (ac[c - 2] != null) city = ac[c - 2]['long_name'];

        if (ac[c - 3] != null) subCity = ac[c - 3]['long_name'];

        if (ac[c + 1] != null) zipCode = ac[c + 1]['long_name'];
      }
    }
    cbLog.cbLocation.country = country;
    cbLog.cbLocation.province = province;
    cbLog.cbLocation.city = city;
    cbLog.cbLocation.subCity = subCity;
    cbLog.cbLocation.zipCode = zipCode;

    return cbLog;
  }

  public hideOverlay() {
    (<any>this.$refs.navbarSection).flagDialogExploreMenu = false;
    (<any>this.$refs.navbarSection).resetDialogExplore();
    (<any>this.$refs.navbarSection).flagDialogTimeRegion = false;
    this.overlay = false;
  }

  public retrieveAllCbUserCookies() {
    this.pCbUserCookieService()
      .retrieveCountByLogin()
      .then(
        res => {
          this.cbCountCookies = res.data;
        },
        err => {
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public showCardCookies() {
    this.cbUserCookie.analyticalCookie = true;
    this.cbUserCookie.advertisingCookie = true;
    this.cbUserCookie.mandatoryCookie = true;
    if (this.$store.getters.authenticated) {
      this.pCbUserCookieService()
        .createByLogin(this.cbUserCookie)
        .then(param => {
          this.$store.commit('userCookie', param);
          this.retrieveAllCbUserCookies();
          this.globalService().closeGlobalDialog(this.$root, 'modal-c-cookie-setting');
          const message = 'Cookie Successfully Created';
          (this.$root as any).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          });
        })
        .catch(error => {
          this.alertService().showHttpError(this, error.response);
        });
    } else {
      this.pCbUserCookieService()
        .create(this.cbUserCookie)
        .then(param => {
          this.$store.commit('userCookie', param);
          this.retrieveAllCbUserCookies();
          const message = 'Cookie Successfully Created';
          this.globalService().closeGlobalDialog(this.$root, 'modal-c-cookie-setting');
          (this.$root as any).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          });
        })
        .catch(error => {
          this.alertService().showHttpError(this, error.response);
        });
    }
  }

  public handleSubmitEditVerification(e) {
    this.closeAllDialog();
    this.globalService().openGlobalDialog(this.$root, 'modal-c-success-reset-phone');
  }

  public handleCancelTokenLoader() {
    (<any>this.$root).$emit('opc::actionHandler', {
      action: 'HANDLE_MDL_PROJECT_DELIVERY_PREVIEW',
    });
  }

  public openCookiesSetting() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-cookies-setting');
  }

  public handleCloseEditPhoneNumberAndOpenOtpVerificationPhone() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-edit-phone-number');

    if (this.isNotOpenGlobalDialogCallback) {
      this.isNotOpenGlobalDialogCallback = false;
      return;
    }

    this.globalService().openGlobalDialog(this.$root, 'modal-visible-verification-phone');
  }

  public changeFunction(functionName: string, param1?: any, param2?: any, param3?: any) {
    this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
  }
  public changeValue(fieldName: string, value: any) {
    this[fieldName] = value;
  }

  public closeDialogLoginIntroduction() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-login-introduction');
  }

  public closeAllDialog() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-edit-phone-number');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-cookies-setting');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-login');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-cookie-policy');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-login-introduction');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-success-otp');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-success-reset-phone');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-register');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-auction-project');

    this.isShowAttachmentPreview = false;
  }

  public closeLanguages() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-change-language');
  }

  public openModalLogin() {
    this.closeAllDialog();
    this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
  }

  public myTicket() {
    this.$router.push({ name: 'PRequestedTicket' });
  }

  // ================= START COMPUTE =================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get getUser() {
    return this.$store.getters.account;
  }

  public get cbUserBookmarks() {
    return this.$store.getters.userBookmarks;
  }
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  public get getUserCookie() {
    return this.$store.getters.userCookie;
  }

  public get cbUserTimeZones() {
    return this.$store.getters.cbUserTimeZones;
  }

  public closeProgressUploadMini() {
    (<any>this.$refs.toastProgressUpload).hide();
  }

  public get numberOfAjaxCAllPending() {
    // dont uncoent, because make lag, fix performance route footer
    // return this.$store.getters.numberOfAjaxCAllPending;
    return null;
  }
  public get ribbonEnabled(): boolean {
    return this.$store.getters.activeProfiles?.indexOf(this.$store.getters.ribbonOnProfiles) > -1;
  }
  // ================= COMPUTE =================
  // ================= START LISTENER =================

  // ================= LISTENER =================

  public closeModalFirstChoice() {
    if (this.envSecret.trim().length <= 0 || this.envSecret != process.env.ENV_SECRET) {
      this.globalService().openGlobalDialog(this.$root, 'modal-first-choice');
      return;
    }
    this.$store.commit('envSecret', this.envSecret);
    this.globalService().closeGlobalDialog(this.$root, 'modal-first-choice');
  }

  public saveCbUserBookmarkAgg(cbUserBookmarkAgg: ICbUserBookmarkAgg, callback?: any) {
    this.pCbUserBookmarkAggService()
      .create(cbUserBookmarkAgg)
      .then(res => {
        res.cbUserInfo = null;
        this.insertCbUserBookmarkAgg(res, cbUserBookmarkAgg);
        callback({ isSuccess: true, auctionProjectTab: this.auctionProjectTab });
      })
      .catch(err => {
        callback({ isSuccess: false, auctionProjectTab: this.auctionProjectTab });
        console.log('ERROR', err.response);
      });
  }

  public insertCbUserBookmarkAgg(res: ICbUserBookmarkAgg, cbUserBookmarkAgg: ICbUserBookmarkAgg) {
    const bookmarkMapping = {
      [CbBookmarkType.AUCTION_PROJECT]: {
        getter: this.GET_USER_BOOKMARK_AUCTION_PROJECT,
        commit: 'SET_USER_BOOKMARK_AUCTION_PROJECT',
      },
      [CbBookmarkType.PAW]: {
        getter: this.GET_USER_BOOKMARK_PAW,
        commit: 'SET_USER_BOOKMARK_PAW',
      },
    };

    const bookmarkType = cbUserBookmarkAgg.bookmarkType;
    const mapping = bookmarkMapping[bookmarkType];

    if (mapping) {
      const userBookmarkAggs = mapping.getter ? [...mapping.getter] : [];
      const bookMarkIdx = userBookmarkAggs.findIndex(
        v => v.dataId == cbUserBookmarkAgg.dataId && v.bookmarkType == cbUserBookmarkAgg.bookmarkType
      );

      if (bookMarkIdx >= 0 && !cbUserBookmarkAgg.isActive) {
        Vue.delete(userBookmarkAggs, bookMarkIdx);
      } else if (bookMarkIdx < 0 && cbUserBookmarkAgg.isActive) {
        userBookmarkAggs.push(res);
      }

      this.$store.commit(mapping.commit, userBookmarkAggs);
    }
  }

  public get GET_USER_BOOKMARK_PAW() {
    return this.$store.getters.GET_USER_BOOKMARK_PAW;
  }

  public get GET_USER_BOOKMARK_AUCTION_PROJECT() {
    return this.$store.getters.GET_USER_BOOKMARK_AUCTION_PROJECT;
  }
}
