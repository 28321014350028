import { Inject, Component, Vue, Mixins } from 'vue-property-decorator';
import dayjs from 'dayjs';
import axios from 'axios';

import GInitPage from '@/g-init-page.component';

import AppWorkflow, {
  WFKEY_ACCEPT_ADDITIONAL_FREE,
  WFKEY_CHANGE_STATUS_FROM_LATE,
  WFKEY_CHANGE_STATUS_FROM_LATE_ON_TRACK,
  WFKEY_DECLINE_ADDITIONAL_CHARGE,
  WFKEY_DECLINE_ADDITIONAL_FREE,
  WFKEY_I_ACCEPT_APPOINTMENT,
  WFKEY_I_ACCEPT_NOTE_FREE,
  WFKEY_I_CANCEL_REVISION_EXTRA,
  WFKEY_I_DECLINED_REVISION_EXTRA,
  WFKEY_I_DECLINED_REVISION_EXTRA_FREE,
  WFKEY_I_DECLINE_APPOINTMENT,
  WFKEY_I_DECLINE_NOTE_CHARGE,
  WFKEY_I_DECLINE_NOTE_FREE,
  WFKEY_I_REMINDER_APPOINTMENT,
  WFKEY_I_REVISION_EXTRA_ACCEPT_FREE,
  WFKEY_I_SEND_OFFER,
  WFKEY_I_SEND_OFFER_REVISION_EXTRA,
  WFKEY_I_SEND_OFFER_REVISION_EXTRA_FREE,
  WFKEY_I_UPLOAD_FILE_DRAFT,
  WFKEY_I_UPLOAD_FILE_DRAFT_AFTER_DELIVERY,
  WFKEY_SEND_OFFER,
  WFNAME_OR_FIELD_WORKER,
  WFNAME_OR_INSIDE_WORKER,
} from '@/app-workflow';

import PCbVwPawOrderService from '@/services/cb-vw-paw-order.service';
import PCbVwPawOrderRequirementService from '@/services/cb-vw-paw-order-requirement.service';
import PCbTextRestrictService from '@/services/cb-text-restrict.service';
import PCbVwUserPartnerLevelService from '@/services/cb-vw-user-partner-level.service';
import PCbVwPawOrderRequirementActiveService from '@/services/cb-vw-paw-order-requirement-active.service';
import PCbVwAuctionProjectPreOfferService from '@/services/cb-vw-auction-project-pre-offer.service';
import PCbVwPawService from '@/services/cb-vw-paw.service';
import PCbPawOrderFileService from '@/services/cb-paw-order-file.service';
import PCbPawOrderRequirementService from '@/services/cb-paw-order-requirement.service';
import PCbVwUserSummaryService from '@/services/cb-vw-user-summary.service';
import PCbVwPawPkgAggService from '@/services/cb-vw-paw-pkg-agg.service';
import PCbTextNoAuthService from '@/services/cb-text-no-auth.service';
import PCbVwUserService from '@/services/cb-vw-user.service';
import PCbUserReviewService from '@/services/cb-user-review.service';
import PCbVwUserScheduleService from '@/services/cb-vw-user-schedule.service';
import PRptMetaTableService from '@/services/rpt-meta-table.service';
import PPayService from '@/services/pay.service';
import PCbVWMedalService from '@/services/cb-vw-medal.service';
import PCbPawOrderService from '@/services/cb-paw-order.service';
import PChtService from '@/services/cht.service';
import PCbVwUserReviewService from '@/services/cb-vw-user-review.service';
import PCbLogService from '@/services/cb-log.service';
import JhiDataUtils from '@/shared/data/data-utils.service';
import UtilsFunction from '@/shared/data/utils-function.service';
import ContentService from '@/services/content.service';
import GlobalService from '@/services/global.service';
import AlertService from '@/shared/alert/alert.service';
import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { CbVwPawOrderRequirement, ICbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbVwUser, ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { CbAttachmentRestrict, ICbAttachmentRestrict } from '@/shared/model/cb-attachment-restrict.model';
import { CbVwPaw, ICbVwPaw } from '@/shared/model/cb-vw-paw.model';
import { CbPawOrder, ICbPawOrder } from '@/shared/model/cb-paw-order.model';
import { ICbPawOrderRequirement, CbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';
import { ICbPawOrderFile, CbPawOrderFile } from '@/shared/model/cb-paw-order-file.model';
import { CbPawOrderMilestone, ICbPawOrderMilestone } from '@/shared/model/cb-paw-order-milestone.model';
import { ChtMessage, IChtMessage } from '@/shared/model/cht-message.model';
import { CbTextRestrict, ICbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { IProgressUpload, ProgressUpload } from '@/shared/model/progress-upload';
import { IPayDetailVA, PayDetailVA } from '@/shared/model/pay-detail-va.model';
import { IPayPayload2023, PayPayload2023 } from '@/shared/model/pay-payload-2023.model';
import { ChtConversation, IChtConversation } from '@/shared/model/cht-conversation.model';
import { CbUserReview, ICbUserReview } from '@/shared/model/cb-user-review.model';
import { ChtLocation, IChtLocation } from '@/shared/model/cht-location.model';
import { CbVwPawOrder, ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbVwUserSummary, ICbVwUserSummary } from '@/shared/model/cb-vw-user-summary.model';
import { CbVwAuctionProjectPreOffer, ICbVwAuctionProjectPreOffer } from '@/shared/model/cb-vw-auction-project-pre-offer.model';
import { ICbPawOrderExtra } from '@/shared/model/cb-paw-order-extra.model';
import { ISocketMessage } from '@/shared/model/socket-message.model';
import { ICbPawOrderInclude } from '@/shared/model/cb-paw-order-include.model';
import { ICbTextAuth } from '@/shared/model/cb-text-auth.model';
import { ICbVwUserSchedule } from '@/shared/model/cb-vw-user-schedule.model';
import { ICbPawPreOrder } from '@/shared/model/cb-paw-pre-order.model';
import { ICbVwUserPartnerLevel } from '@/shared/model/cb-vw-user-partner-level.model';
import { ICbVwPawPkgAgg } from '@/shared/model/cb-vw-paw-pkg-agg.model';
import { ICBreadcrumb } from '@/components/c-breadcrumb/c-breadcrumb.component';
import { ICbVWMedal } from '@/shared/model/cb-vw-medal.model';
import { ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import { ICbLov } from '@/shared/model/cb-lov.model';
import { ICbLog } from '@/shared/model/cb-log.model';
import { IWfHistory } from '@/shared/model/wf-history.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';
import { CbPawOrderFileType } from '@/shared/model/enumerations/cb-paw-order-file-type.model';
import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import { ChtContentType } from '@/shared/model/enumerations/cht-content-type.model';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { CbUserLevelType } from '@/shared/model/enumerations/cb-user-level';
import { CbLocation } from '@/shared/model/cb-location.model';
import { CbPkgType } from '@/shared/model/enumerations/cb-pkg-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbPkgCoreType } from '@/shared/model/enumerations/cb-pkg-core-type.model';
import { CbLogType } from '@/shared/model/enumerations/cb-log-type.model';
import { PayMethodType } from '@/shared/model/enumerations/pay-method-type.model';
import { User } from '@/shared/model/user.model';
import { App } from '@/shared/model/enumerations/app.model';
import { ModalStepFormOrderType } from '@/shared/model/enumerations/modal-step-form-order-type.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';

import { SESSION_STORAGE_CB_VW_MEDAL, SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL } from '@/shared/constant/constants-session-storage';
import { FnListener } from '@/shared/model/fn-listener-model';

export const HANDLE_MDL_PAYMENT_ORDER_OFFER_ACTIVE = 'HANDLE_MDL_PAYMENT_ORDER_OFFER_ACTIVE';
export const HANDLE_MDL_PAYMENT_ORDER_OFFER = 'HANDLE_MDL_PAYMENT_ORDER_OFFER';
export const HANDLE_MDL_PAYMENT_ORDER_OFFER_REVISION = 'HANDLE_MDL_PAYMENT_ORDER_OFFER_REVISION';
export const HANDLE_MDL_PAW_OFFER_OPEN = 'HANDLE_MDL_PAW_OFFER_OPEN';
export const HANDLE_MDL_PAW_DETAIL_OPEN = 'HANDLE_MDL_PAW_DETAIL_OPEN';
export const HANDLE_ASK_COMMITTEE_REPORT_OPEN = 'HANDLE_ASK_COMMITTEE_REPORT_OPEN';
export const HANDLE_ASK_COMMITTEE_DISPUTED_OPEN = 'HANDLE_ASK_COMMITTEE_DISPUTED_OPEN';
export const HANDLE_OFFER_SEND = 'HANDLE_OFFER_SEND';
export const HANDLE_VIEW_PROFILE = 'HANDLE_VIEW_PROFILE';
export const HANDLE_CREATE_CONVERSATION = 'HANDLE_CREATE_CONVERSATION';
export const HANDLE_BUYER_COMPLETED_ORDER_OPEN = 'HANDLE_BUYER_COMPLETED_ORDER_OPEN';
export const HANDLE_BUYER_COMPLETED_ORDER_SEND = 'HANDLE_BUYER_COMPLETED_ORDER_SEND';
export const HANDLE_BUYER_CANCEL_ORDER_OPEN = 'HANDLE_BUYER_CANCEL_ORDER_OPEN';
export const HANDLE_BUYER_CANCEL_ORDER_SEND = 'HANDLE_BUYER_CANCEL_ORDER_SEND';
export const HANDLE_BUYER_REVIEW_OPEN = 'HANDLE_BUYER_REVIEW_OPEN';
export const HANDLE_VIEW_DETAIL_ORDER = 'HANDLE_VIEW_DETAIL_ORDER';
export const HANDLE_DOWNLOAD_INVOICE = 'HANDLE_DOWNLOAD_INVOICE';

// inside
export const HANDLE_MDL_MOM_OPEN = 'HANDLE_MDL_MOM_OPEN';
export const HANDLE_MDL_MOM_SEND = 'HANDLE_MDL_MOM_SEND';
export const HANDLE_MDL_MOM_ACCEPT_AND_PAY = 'HANDLE_MDL_MOM_ACCEPT_AND_PAY';
export const HANDLE_MDL_MOM_ACCEPT = 'HANDLE_MDL_MOM_ACCEPT';
export const HANDLE_MDL_MOM_PREVIEW = 'HANDLE_MDL_MOM_PREVIEW';
export const HANDLE_MDL_MOM_DECLINE = 'HANDLE_MDL_MOM_DECLINE';
export const HANDLE_MDL_MOM_CONFIRMATION_DECLINE = 'HANDLE_MDL_MOM_CONFIRMATION_DECLINE';
export const HANDLE_MDL_MOM_CONFIRMATION_ACCEPT = 'HANDLE_MDL_MOM_CONFIRMATION_ACCEPT';
export const HANDLE_MDL_MOM_CONFIRMATION_ACCEPT_DIRECTLY = 'HANDLE_MDL_MOM_CONFIRMATION_ACCEPT_DIRECTLY';
export const HANDLE_MDL_PROJECT_DELIVERY_OPEN = 'HANDLE_MDL_PROJECT_DELIVERY_OPEN';
export const HANDLE_MDL_PROJECT_DELIVERY_CONFIRMATION_OPEN = 'HANDLE_MDL_PROJECT_DELIVERY_CONFIRMATION_OPEN';
export const HANDLE_MDL_PROJECT_DELIVERY_SEND = 'HANDLE_MDL_PROJECT_DELIVERY_SEND';
export const HANDLE_MDL_PROJECT_DELIVERY_PREVIEW = 'HANDLE_MDL_PROJECT_DELIVERY_PREVIEW';
export const HANDLE_MDL_PROJECT_DELIVERY_PREVIEW_LAST = 'HANDLE_MDL_PROJECT_DELIVERY_PREVIEW_LAST';
export const HANDLE_MDL_PROJECT_DELIVERY_ASK_REVISION = 'HANDLE_MDL_PROJECT_DELIVERY_ASK_REVISION';
export const HANDLE_MDL_PROJECT_DELIVERY_SEND_EXTRA = 'HANDLE_MDL_PROJECT_DELIVERY_SEND_EXTRA';
export const HANDLE_MDL_PROJECT_DELIVERY_ACCEPT_EXTRA_FREE = 'HANDLE_MDL_PROJECT_DELIVERY_ACCEPT_EXTRA_FREE';
export const HANDLE_MDL_PROJECT_DELIVERY_REJECT_EXTRA = 'HANDLE_MDL_PROJECT_DELIVERY_REJECT_EXTRA';
export const HANDLE_MDL_MILESTONE_FILE = 'HANDLE_MDL_MILESTONE_FILE';
export const HANDLE_MDL_MILESTONE_OPEN = 'HANDLE_MDL_MILESTONE_OPEN';
export const HANDLE_MDL_MILESTONE_SEND = 'HANDLE_MDL_MILESTONE_SEND';
export const HANDLE_MDL_APPOINTMENT_OPEN = 'HANDLE_MDL_APPOINTMENT_OPEN';
export const HANDLE_MDL_APPOINTMENT_SEND = 'HANDLE_MDL_APPOINTMENT_SEND';
export const HANDLE_MDL_APPOINTMENT_UPDATE = 'HANDLE_MDL_APPOINTMENT_UPDATE';
export const HANDLE_MDL_APPOINTMENT_PREVIEW = 'HANDLE_MDL_APPOINTMENT_PREVIEW';
export const HANDLE_MDL_APPOINTMENT_SET_REMINDER = 'HANDLE_MDL_APPOINTMENT_SET_REMINDER';
export const HANDLE_UPLOAD_PROJECT_FILE = 'HANDLE_UPLOAD_PROJECT_FILE';
export const HANDLE_BUYER_EXTEND_ORDER_OPEN = 'HANDLE_BUYER_EXTEND_ORDER_OPEN';
export const HANDLE_BUYER_EXTEND_ORDER_SEND = 'HANDLE_BUYER_EXTEND_ORDER_SEND';

// field
export const HANDLE_REFRESH_ORDER = 'HANDLE_REFRESH_ORDER';
export const HANDLE_SEND_ON_THE_WAY = 'HANDLE_SEND_ON_THE_WAY';
export const HANDLE_CLOCK_IN_OPEN = 'HANDLE_CLOCK_IN_OPEN';
export const HANDLE_DETAIL_ORDER_CLOCK_IN_OPEN = 'HANDLE_DETAIL_ORDER_CLOCK_IN_OPEN';
export const HANDLE_CLOCK_IN_SEND = 'HANDLE_CLOCK_IN_SEND';
export const HANDLE_CLOCK_OUT_OPEN = 'HANDLE_CLOCK_OUT_OPEN';
export const HANDLE_CLOCK_OUT_NOT_FINISHED = 'HANDLE_CLOCK_OUT_NOT_FINISHED';
export const HANDLE_CLOCK_OUT_SEND = 'HANDLE_CLOCK_OUT_SEND';
export const HANDLE_BUYER_TRACK_ORDER = 'HANDLE_BUYER_TRACK_ORDER';
export const HANDLE_VIEW_MAP_TRACK = 'HANDLE_VIEW_MAP_TRACK';
export const HANDLE_BUYER_DETAIL_ORDER_TRACK_ORDER = 'HANDLE_BUYER_DETAIL_ORDER_TRACK_ORDER';
export const HANDLE_DETAIL_ORDER_MAP_OPEN = 'HANDLE_DETAIL_ORDER_MAP_OPEN';

export const HANDLE_VA_DETAIL_OPEN = 'HANDLE_VA_DETAIL_OPEN';
export const HANDLE_CC_DETAIL_OPEN = 'HANDLE_CC_DETAIL_OPEN';

export enum ViewChatMobile {
  CONVERSATION_LIST = 'CONVERSATION_LIST',
  CONVERSATION_SEARCH = 'CONVERSATION_SEARCH',
  CONVERSATION_SETTING = 'CONVERSATION_SETTING',
  ACTIVITY = 'ACTIVITY',
  MESSAGE = 'MESSAGE',
}

export interface ILoadingComponent {
  index?: number | null;
  visible?: boolean | null;
}

export class LoadingComponent implements ILoadingComponent {
  constructor(public index?: number | null, public visible?: boolean | null) {
    this.visible = this.visible ?? false;
  }
}

// WF
@Component({
  components: {},
  mixins: [GInitPage],
})
@Component({})
export default class OrderPawController extends Mixins(JhiDataUtils, AppWorkflow, UtilsFunction, StatusTextEnId) {
  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  @Inject('pCbVwPawOrderService') private pCbVwPawOrderService: () => PCbVwPawOrderService;
  @Inject('pCbVwPawOrderRequirementService') private pCbVwPawOrderRequirementService: () => PCbVwPawOrderRequirementService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('pCbTextRestrictService') private pCbTextRestrictService: () => PCbTextRestrictService;
  @Inject('pCbVwPawService') private pCbVwPawService: () => PCbVwPawService;
  @Inject('pCbVwAuctionProjectPreOfferService') private pCbVwAuctionProjectPreOfferService: () => PCbVwAuctionProjectPreOfferService;
  @Inject('pCbPawOrderFileService') private pCbPawOrderFileService: () => PCbPawOrderFileService;
  @Inject('pCbPawOrderRequirementService') private pCbPawOrderRequirementService: () => PCbPawOrderRequirementService;
  @Inject('pCbUserReviewService') private pCbUserReviewService: () => PCbUserReviewService;
  @Inject('pCbVwUserScheduleService') private pCbVwUserScheduleService: () => PCbVwUserScheduleService;
  @Inject('pPayService') private pPayService: () => PPayService;
  @Inject('pCbVwUserService') private pCbVwUserService: () => PCbVwUserService;
  @Inject('pCbVwUserPartnerLevelService') private pCbVwUserPartnerLevelService: () => PCbVwUserPartnerLevelService;
  @Inject('pCbVwPawOrderRequirementActiveService')
  private pCbVwPawOrderRequirementActiveService: () => PCbVwPawOrderRequirementActiveService;
  @Inject('contentService') private contentService: () => ContentService;
  @Inject('pCbVwUserSummaryService') private pCbVwUserSummaryService: () => PCbVwUserSummaryService;
  @Inject('pCbVwPawPkgAggService') private pCbVwPawPkgAggService: () => PCbVwPawPkgAggService;
  @Inject('pCbTextNoAuthService') private pCbTextNoAuthService: () => PCbTextNoAuthService;
  @Inject('pCbPawOrderService') private pCbPawOrderService: () => PCbPawOrderService;
  @Inject('pCbVwMedalService') private pCbVwMedalService: () => PCbVWMedalService;
  @Inject('pRptMetaTableService') private pRptMetaTableService: () => PRptMetaTableService;
  @Inject('pChtService') private pChtService: () => PChtService;
  @Inject('pCbVwUserReviewService') private pCbVwUserReviewService: () => PCbVwUserReviewService;
  @Inject('pCbLogService') private pCbLogService: () => PCbLogService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================

  public cbVwPawOrderRequirements: ICbVwPawOrderRequirement[] = [];
  public cbVwPawOrderRequirementRevision: ICbVwPawOrderRequirement = new CbVwPawOrderRequirement();
  public cbVwAuctionProjectPreOffers: ICbVwAuctionProjectPreOffer[] = [];
  public cbVwAuctionProjectPreOffer: ICbVwAuctionProjectPreOffer = new CbVwAuctionProjectPreOffer();
  public cbVwPawOrders: ICbVwPawOrder[] = [];
  public cbVwUser: ICbVwUser = new CbVwUser();
  public cbVwUserSchedules: ICbVwUserSchedule[] = [];
  public cbVwUserHibernationSchedules: ICbVwUserSchedule[] = [];
  public cbVwPaws: CbVwPaw[] = [];
  public cbVwPaw: ICbVwPaw = new CbVwPaw();
  public cbVwMedals: ICbVWMedal[] = [];

  public cbPawOrder: ICbPawOrder = new CbPawOrder();
  public cbPawOrderRequirement: ICbPawOrderRequirement = new CbPawOrderRequirement();
  public cbPawOrderRequirements: ICbPawOrderRequirement[] = [];
  public cbPawPreOrders: ICbPawPreOrder[] = [];
  public cbPawOrderFile: ICbPawOrderFile = new CbPawOrderFile();
  public cbPawOrderFiles: ICbPawOrderFile[] = [];
  public cbPawOrderIncludes: ICbPawOrderInclude[] = [];
  public cbPawOrderExtras: ICbPawOrderExtra[] = [];
  public cbPawOrderMilestones: ICbPawOrderMilestone[] = [];
  public cbPawOrderMilestone: ICbPawOrderMilestone = new CbPawOrderMilestone();
  public cbUserReview: ICbUserReview = new CbUserReview();
  public cbAttachmentRestrictSelfie: ICbAttachmentRestrict = new CbAttachmentRestrict();
  public cbAttachmentRestrictLocationPhoto: ICbAttachmentRestrict = new CbAttachmentRestrict();
  public currentCbVwUserSchedules: ICbVwUserSchedule[] = [];
  public chtConversation: IChtConversation = new ChtConversation();

  public isFetchingVwPawOrdersProgressOnly = false;
  public isFetchingVwPawOrderRequirements = false;
  public isFetchingCbVwUserSchedules = false;
  public isFetchingVwPawOrder = false;
  public isFetchingCbLocWatermark = false;
  public isSavingSurvey = false;
  public isSavingPawOrderRequirement = false;
  public isFetchingCbVwPaw = false;
  public isFetchingCbVwUser = false;
  public isSavingPawOrder = false;
  public isShowChatInfoPanel = false;

  public CbLoginType = CbLoginType;

  public loadingComponent: ILoadingComponent = new LoadingComponent();
  public: IProgressUpload[] = [];
  public cbVwUserSummary: ICbVwUserSummary = new CbVwUserSummary();

  public locationBreadCrumbs: ICBreadcrumb[] = [
    {
      text: 'Home',
      active: false,
      href: { name: 'PHome' },
    },
    {
      text: 'Chat',
      active: false,
      isVariable: true,
    },
    {
      text: 'Maps',
      active: true,
    },
  ];

  public cancelToken: any;
  public cancelTokenRetrieveProgressOnly: any;
  public cancelTokenProjectDeliveryPreview: any;

  public chtLocation: IChtLocation = new ChtLocation();

  // NEW PAYMENT
  public paymentPayload: IPayPayload2023 = new PayPayload2023();
  public xenditUrl = '';
  public payDetailVA: IPayDetailVA = new PayDetailVA();

  public cbVwPawPkgAggs: ICbVwPawPkgAgg[] = [];

  public otherUserId: string;

  // OBJECT SELECTED for modal
  public cbTextRestrict: ICbTextRestrict = new CbTextRestrict();
  public cbVwPawOrderRequirement: ICbVwPawOrderRequirement = new CbVwPawOrderRequirement();
  public cbVwPawOrder: ICbVwPawOrder = new CbVwPawOrder(); //order selected
  public modalStepFormOrders: ModalStepFormOrderType[] = [];

  // MODAL/COMPoNENT SHOW
  public isShowDetailOrderLocation = false;
  public TypeComponent = TypeComponent;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  beforeDestroy() {
    this.resetAll();
  }

  public getValidationUserHibernation(type = 'CALL', callback?: any) {
    const date = new Date();

    if (
      (dayjs(this.currentUser.offDutyStartDate).isBefore(date) || dayjs(this.currentUser.offDutyStartDate).isSame(date)) &&
      (dayjs(this.currentUser.offDutyEndDate).isAfter(date) || dayjs(this.currentUser.offDutyEndDate).isSame(date))
    ) {
      if (type === 'CALL') {
        (<any>this.$root).$emit('appModals::changeValue', 'visibleHibernateConfirm', true);
      } else {
        if (callback) callback({ isResult: true });
        return true;
      }
    }

    if (
      this.currentUser.loginType == CbLoginType.PARTNER &&
      this.currentUser.userLvl !== CbUserLevelType.ROOKIE &&
      this.currentCbVwUserSchedules
    ) {
      for (const item of this.currentCbVwUserSchedules) {
        if (item.scheduleType == 'OFF_DUTY') {
          if (
            dayjs().toDate().getTime() >= dayjs(item.minBookStartDate).toDate().getTime() &&
            dayjs().toDate().getTime() <= dayjs(item.maxBookStartDate).toDate().getTime()
          ) {
            if (type === 'CALL') {
              (<any>this.$root).$bvToast.toast(this.$t('cbgwApp.cbVwUser.youAreOffDuty').toString(), {
                toaster: 'b-toaster-top-center',
                title: 'Warning',
                variant: 'warning',
                solid: true,
                autoHideDelay: 5000,
              });
            } else {
              if (callback) callback({ isResult: true });
              return true;
            }
          }
        }
      }
    }

    if (callback) callback({ isResult: false });
    return false;
  }

  public openPawOffer() {
    if (this.getValidationUserHibernation('VALIDATION')) {
      this.getValidationUserHibernation('CALL');
      return;
    }

    if (this.currentUser.userLvl == CbUserLevelType.ROOKIE) {
      (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.levelUpToVerified').toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Warning',
        variant: 'warning',
        solid: true,
        autoHideDelay: 5000,
      });
    }

    // show open paw offer
    if (this.currentUser.loginType == CbLoginType.PARTNER && this.currentUser.userLvl !== CbUserLevelType.ROOKIE) {
      const modalStepFormOrders: ModalStepFormOrderType[] = [ModalStepFormOrderType.isShowSelectVwPaw];
      this.$root.$emit('opc::changeValue', 'modalStepFormOrders', modalStepFormOrders);
      (<any>this.$root).$emit('mdl::changeValue', 'isShowSelectVwPaw', true);
    }
  }

  public sendPawOffer(cbPawOrder: ICbPawOrder, callback: any) {
    let wfGetWfHistoryByDirectKey: IWfHistory = null;
    if (this.currentUser.defaultSvcType == CbSvcType.FIELD) {
      wfGetWfHistoryByDirectKey = this.wfGetWfHistoryByDirectKey(WFNAME_OR_FIELD_WORKER, WFKEY_SEND_OFFER);
    } else if (this.currentUser.defaultSvcType == CbSvcType.INSIDE) {
      wfGetWfHistoryByDirectKey = this.wfGetWfHistoryByDirectKey(WFNAME_OR_INSIDE_WORKER, WFKEY_I_SEND_OFFER);
    }

    this.pCbPawOrderService()
      .create(cbPawOrder, wfGetWfHistoryByDirectKey)
      .then(res => {
        callback({ isSavingPawOrder: false, data: res });

        const requirement = res.cbPawOrderRequirements.find(e => e.requirementType === CbRequirementType.REQUIREMENT);

        if (this.isMobile) {
          this.isShowChatInfoPanel = false;
        }
        this.saveChatMessageRequirement(requirement.id, ChtContentType.ORDER_REQUIREMENT);
        this.modalStepFormOrders = [];

        this.refreshOrder({ orderId: res.id, reqId: requirement.id, isChatSkip: false, typeModal: TypeComponent.CHAT_MODAL });
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        callback({ isSavingPawOrder: false });
      });
  }

  // MUST USED THIS  each create requirement
  public createRequirement({
    cbVwPawOrder,
    req,
    callback,
    type,
    WFKEY,
    isCreateMessageChatSkip,
    isRefreshOrderSkip = false,
  }: {
    cbVwPawOrder: ICbVwPawOrder;
    req: ICbPawOrderRequirement;
    callback: any;
    type?: TypeComponent;
    WFKEY?: string;
    isCreateMessageChatSkip?: boolean;
    isRefreshOrderSkip?: boolean;
  }) {
    callback({ isSavingLoader: true });

    this.wfGetWfHistoryByOR(cbVwPawOrder.workflowName, cbVwPawOrder.id, req, WFKEY)
      .then(wfHistory => {
        console.log('order-paw-controller - wf history: ', wfHistory);

        this.pCbPawOrderRequirementService()
          .create(req, wfHistory)
          .then(res => {
            callback({ isSavingLoader: false, data: res });

            if (!isCreateMessageChatSkip) {
              if (this.isMobile) {
                this.isShowChatInfoPanel = false;
              }

              this.createConversation({ openChat: false, reqParam: res, type });
            }

            if (!isRefreshOrderSkip) {
              const isChatRefreshSkip = true;
              this.refreshOrder({
                orderId: cbVwPawOrder.id,
                reqId: res.id,
                isChatSkip: isChatRefreshSkip,
                typeModal: type,
                isWithoutLoading: false,
              });
            }
          })
          .catch(err => {
            callback({ isSavingLoader: false, data: null });
            this.alertService().showHttpError(this, err.response);
            console.error('error ', err.response);
          });
      })
      .catch(err => {
        callback({ isSavingLoader: false, data: null });
        console.error('error ', err.response);
      });
  }

  public broadcastUpdateOrder(id?: number) {
    this.cbVwPawOrders = [...this.cbVwPawOrders];
  }

  private updateDescription(descriptionId, value: ICbTextRestrict, type: TypeComponent) {
    if (type === TypeComponent.DETAIL_ORDER) {
      if (this.cbVwPawOrder.cbPawPkgDescriptionId == descriptionId) {
        this.cbVwPawOrder.pawPkgDescription = value.content;
      }

      if (this.cbVwPawOrder.cbVwPawOrderRequirements) {
        this.cbVwPawOrder.cbVwPawOrderRequirements.forEach(r => {
          if (r.descriptionId == descriptionId) {
            r.description = value;
            this.cbVwPawOrder.cbVwTextRestrict = value; // fix bug not show summary when offering
          }
        });
      }
    } else {
      this.cbVwPawOrders.forEach(o => {
        if (type === TypeComponent.CHAT_WIDGET) {
          if (o.cbPawPkgDescriptionId == descriptionId) {
            o.pawPkgDescription = value.content;
          }
        }

        if (o.cbVwPawOrderRequirements) {
          o.cbVwPawOrderRequirements.forEach(r => {
            if (r.descriptionId == descriptionId) {
              r.description = value;
              o.cbVwTextRestrict = value; // fix bug not show summary when offering
            }
          });
        }
      });

      this.broadcastUpdateOrder();
    }
  }

  public retrieveCbVwPawOrdersProgressOnly({
    ids,
    isOnlyFirstLoading = false,
    isWithoutLoadingRequirement = false,
  }: {
    ids?: number[];
    isWithoutLoadingRequirement?: boolean;
    isOnlyFirstLoading?: boolean;
  }) {
    if (this.cancelTokenRetrieveProgressOnly) this.cancelTokenRetrieveProgressOnly.cancel('Request Order Cancelled');
    this.cancelTokenRetrieveProgressOnly = axios.CancelToken.source();

    console.log(isOnlyFirstLoading, 'isOnlyFirstLoading');

    if (isOnlyFirstLoading) this.isFetchingVwPawOrdersProgressOnly = true;
    let isInitCall = false;

    if (!ids) {
      isInitCall = true;
    }
    const params: any = {
      size: 999999,
    };
    if (ids) {
      params['id.in'] = ids;
    }

    this.pCbVwPawOrderService()
      .retrieveOnProgress(
        this.currentUser.id,
        this.otherUserId,
        params,
        this.cancelTokenRetrieveProgressOnly ? this.cancelTokenRetrieveProgressOnly.token : null
      )
      .then(async res => {
        if (!res.data || (res.data && res.data.length <= 0)) {
          // status CANCELLED, for refresh on panel,, after count down
          if (this.cbVwPawOrders && this.cbVwPawOrders.length > 0) {
            const idToDelete = ids[0];
            // Use the filter method to create a new array without the item with the specified ID
            this.cbVwPawOrders = this.cbVwPawOrders.filter(item => item.id !== idToDelete);
          }

          return;
        }

        res.data.forEach((cbVwPawOrder: ICbVwPawOrder) => {
          this.processToWorkLocation(cbVwPawOrder);

          if (cbVwPawOrder.orderStatus === CbCommonStatus.WAITING_PAYMENT) {
            this.processItemChatWidgetOffers(cbVwPawOrder);
          }

          if (cbVwPawOrder.orderStatus === CbCommonStatus.LATE) {
            this.retrieveCbPawOrderTrack({ cbVwPawOrder });
          }
        });

        // this.cbVwPawOrders = res.data;
        // PENDING sorting by status
        if (this.cbVwPawOrders && this.cbVwPawOrders.length > 0) {
          res.data.forEach(d => {
            const idx = this.cbVwPawOrders.findIndex(o => o.id == d.id);
            if (idx >= 0) {
              this.cbVwPawOrders[idx] = d;
            } else {
              this.cbVwPawOrders = [d, ...this.cbVwPawOrders];
            }
          });

          this.cbVwPawOrders = [...this.cbVwPawOrders];
        } else {
          this.cbVwPawOrders = [...res.data, ...this.cbVwPawOrders];
        }

        // fix bug text restrict not found because of this.cbVwPawOrder not process first
        res.data.forEach((cbVwPawOrder: ICbVwPawOrder) => {
          if (cbVwPawOrder.orderStatus === CbCommonStatus.WAITING_PAYMENT) {
            this.retrieveCbTextRestrict(cbVwPawOrder.cbPawPkgDescriptionId, TypeComponent.CHAT_WIDGET);
          }
        });

        this.processOrderData({ cbVwPawOrders: this.cbVwPawOrders, ids, isInitCall, isWithoutLoading: isWithoutLoadingRequirement });

        this.cbVwPawOrders.sort((a, b) => {
          return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
        });
      })
      .finally(() => {
        this.isFetchingVwPawOrdersProgressOnly = false;
      });
  }

  public findCbVwPawOrder({ id, isWithoutLoading = false }: { id: string | number; isWithoutLoading?: boolean }) {
    if (!isWithoutLoading) this.isFetchingVwPawOrder = true;
    let idsFind = null;

    if (typeof id === 'string') {
      idsFind = Number(id.replace(/[^0-9]/gi, ''));
    } else if (typeof id === 'number') {
      idsFind = id;
    }

    this.pCbVwPawOrderService()
      .find(idsFind, this.cancelToken ? this.cancelToken.token : null)
      .then(res => {
        this.cbVwPawOrder = res;
        this.processToWorkLocation(res);
        this.retrieveCbTextRestrict(res.cbPawPkgDescriptionId, TypeComponent.DETAIL_ORDER);
        this.retrieveTotalDeliverTimeBeforeOverdue({ cbVwPawOrder: res, type: TypeComponent.DETAIL_ORDER });
        this.processOrderData({ cbVwPawOrders: [res], ids: [idsFind], type: TypeComponent.DETAIL_ORDER, isWithoutLoading });
        this.findCbVwUser([res.partnerId, res.buyerId]);
        this.retrieveCbPawOrderTrack({ cbVwPawOrder: res });
        this.retrieveCbVwUserReviewCount(res.id);
      })
      .catch(err => {
        if (
          err?.response?.status === 404 ||
          err?.response?.status === 403 ||
          err?.response?.status === 500 ||
          err?.response?.status === 504
        ) {
          this.$router.replace('/forbidden');
        }

        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isFetchingVwPawOrder = false;
      });
  }

  public processOrderData({
    cbVwPawOrders,
    ids,
    isInitCall,
    type,
    isWithoutLoading = false,
  }: {
    cbVwPawOrders: ICbVwPawOrder[];
    ids: number[];
    isInitCall?: boolean;
    type?: TypeComponent;
    isWithoutLoading?: boolean;
  }) {
    if (!isWithoutLoading) this.isFetchingVwPawOrderRequirements = true;

    let pawOrderIds: number[];
    const reqAmountIds: string[] = [];

    if (isInitCall) {
      // the first call
      pawOrderIds = cbVwPawOrders.map(e => +e.id);
    } else {
      pawOrderIds = ids;
    }

    const params: any = {
      'cbPawOrderId.in': pawOrderIds.filter(this.globalService().onlyUniqueIds),
      size: 9999999,
    };

    this.pCbVwPawOrderRequirementService()
      .retrieve(params, this.cancelToken ? this.cancelToken.token : null)
      .then(res => {
        if (res.data && res.data.length > 0) {
          cbVwPawOrders.forEach(cbVwPawOrder => {
            (<ICbVwPawOrderRequirement[]>res.data).forEach(requirement => {
              if (cbVwPawOrder.id === requirement.cbPawOrderId) {
                if (!cbVwPawOrder.cbVwPawOrderRequirements) cbVwPawOrder.cbVwPawOrderRequirements = [];
                cbVwPawOrder.cbVwPawOrderRequirements.push(requirement);

                this.retrieveCbTextRestrict(requirement.descriptionId, type);

                if (requirement.descriptionId && this.cbTextRestricts.length > 0) {
                  cbVwPawOrder.cbVwTextRestrict = this.cbTextRestricts.find(text => text.id === requirement.descriptionId);
                }

                if (requirement.timeZone) {
                  cbVwPawOrder.timeZone = requirement.timeZone;
                }

                if (requirement.requirementType === CbRequirementType.DELIVERY) {
                  if (!cbVwPawOrder.cbVwPawOrderProjectDeliverys) cbVwPawOrder.cbVwPawOrderProjectDeliverys = [];
                  cbVwPawOrder.cbVwPawOrderProjectDeliverys.push(requirement);
                  cbVwPawOrder.cbVwPawOrderProjectDeliverys = this.findUniqueArrayInCbPawOrderRequirements(
                    cbVwPawOrder.cbVwPawOrderProjectDeliverys
                  );
                }

                if (requirement.requirementType === CbRequirementType.REQUIREMENT) {
                  if (!cbVwPawOrder.cbVwPawOrderRequirement) cbVwPawOrder.cbVwPawOrderRequirement = {};
                  cbVwPawOrder.cbVwPawOrderRequirement = requirement;
                }

                if (
                  requirement.requirementType === CbRequirementType.MOM ||
                  (requirement.requirementType === CbRequirementType.REQUIREMENT && cbVwPawOrder.workflowName === 'OR_INSIDE_WORKER')
                ) {
                  if (!cbVwPawOrder.cbVwPawOrderMOMs) cbVwPawOrder.cbVwPawOrderMOMs = [];
                  cbVwPawOrder.cbVwPawOrderMOMs.push(requirement);
                  cbVwPawOrder.cbVwPawOrderMOMs = this.findUniqueArrayInCbPawOrderRequirements(cbVwPawOrder.cbVwPawOrderMOMs);
                }

                if (requirement.requirementType === CbRequirementType.APPOINTMENT) {
                  if (!cbVwPawOrder.cbVwPawOrderAppoinments) cbVwPawOrder.cbVwPawOrderAppoinments = [];
                  cbVwPawOrder.cbVwPawOrderAppoinments.push(requirement);
                  cbVwPawOrder.cbVwPawOrderAppoinments = this.findUniqueArrayInCbPawOrderRequirements(cbVwPawOrder.cbVwPawOrderAppoinments);
                }

                if (requirement.requirementType === CbRequirementType.MILESTONE) {
                  if (!cbVwPawOrder.cbVwPawOrderMilestoneRequirements) cbVwPawOrder.cbVwPawOrderMilestoneRequirements = [];
                  cbVwPawOrder.cbVwPawOrderMilestoneRequirements.push(requirement);
                  cbVwPawOrder.cbVwPawOrderMilestoneRequirements = this.findUniqueArrayInCbPawOrderRequirements(
                    cbVwPawOrder.cbVwPawOrderMilestoneRequirements
                  );
                }

                if (requirement.requirementType === CbRequirementType.CLOCK_IN) {
                  if (!cbVwPawOrder.cbVwPawOrderFileClockIns) cbVwPawOrder.cbVwPawOrderFileClockIns = [];
                  cbVwPawOrder.cbVwPawOrderFileClockIns = requirement.cbPawOrderFiles;
                  cbVwPawOrder.cbVwPawOrderFileClockIns.forEach(file => (file.createdDate = requirement.createdDate));
                }

                if (requirement.requirementType === CbRequirementType.CLOCK_OUT) {
                  if (!cbVwPawOrder.cbVwPawOrderFileClockOuts) cbVwPawOrder.cbVwPawOrderFileClockOuts = [];
                  cbVwPawOrder.cbVwPawOrderFileClockOuts = requirement.cbPawOrderFiles;
                  cbVwPawOrder.cbVwPawOrderFileClockOuts.forEach(file => (file.createdDate = requirement.createdDate));
                }

                if (requirement.requirementType === CbRequirementType.DISPUTE) {
                  cbVwPawOrder.isAlreadyOrderDisputed = true;
                }

                if (
                  requirement.amount &&
                  (requirement.paymentStatus === CbCommonStatus.COMPLETED || requirement.status === CbCommonStatus.PAID)
                ) {
                  reqAmountIds.push(requirement.labelPaymentId);
                }
              }
            });

            // sorted list
            if (cbVwPawOrder.cbVwPawOrderMOMs?.length > 0) {
              cbVwPawOrder.cbVwPawOrderMOMs.sort((a, b) => <any>dayjs(b.createdDate).diff(<any>dayjs(a.createdDate)));
            }

            if (cbVwPawOrder.cbVwPawOrderAppoinments?.length > 0) {
              cbVwPawOrder.cbVwPawOrderAppoinments.sort((a, b) => <any>dayjs(b.createdDate) - <any>dayjs(a.createdDate));
            }

            if (cbVwPawOrder.cbVwPawOrderProjectDeliverys?.length > 0) {
              cbVwPawOrder.cbVwPawOrderProjectDeliverys.sort((a, b) => a.seq - b.seq);
            }
          });

          if (type === TypeComponent.DETAIL_ORDER) {
            if (reqAmountIds.length > 0) this.retrieveCbVwBillingHistory(this.cbVwPawOrder, reqAmountIds);
            (<any>this.$root).$emit('do::setCbVwPawOrder', this.cbVwPawOrder);
          } else {
            this.broadcastUpdateOrder();
          }
        }

        this.isFetchingVwPawOrderRequirements = false;
      })
      .catch(err => {
        this.isFetchingVwPawOrderRequirements = false;
        console.error('error', err.response);
        this.alertService().showHttpError(this, err.response);
      });
  }

  public retrieveCbVwUserReviewCount(orderId: number) {
    const params: any = {
      'externalOrderId.equals': orderId,
    };

    this.pCbVwUserReviewService()
      .count(params)
      .then(res => {
        if (res === 1) {
          this.cbVwPawOrder.isAlreadyOrderReview = true;
        }
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public retrieveTotalDeliverTimeBeforeOverdue({ cbVwPawOrder, type }: { cbVwPawOrder: ICbVwPawOrder; type?: TypeComponent }) {
    this.pCbVwPawOrderService()
      .retrieveTotalDeliveryTimeBeforeOverdue(cbVwPawOrder.id)
      .then(res => {
        cbVwPawOrder.deliveryTimeTotalBeforeOverdue = res.data;

        if (type === TypeComponent.DETAIL_ORDER) {
          (<any>this.$root).$emit('do::setCbVwPawOrder', cbVwPawOrder);
        }
      })
      .catch(err => {
        console.error('error', err.response);
      });
  }

  public retrieveCbVwBillingHistory(cbVwPawOrder: ICbVwPawOrder, ids: string[]) {
    if (ids.length === 0) return;
    const tmpInvoiceIds: string[] = ids.filter(this.globalService().onlyUniqueIds);

    const params: any = {
      size: 99999,
      'externalId.in': tmpInvoiceIds,
    };

    this.pPayService()
      .retrievePayVwBillingHistoryPayment(params)
      .then(res => {
        if (res?.data?.length > 0) {
          if (!cbVwPawOrder.cbVwBillingHistoryPayments) cbVwPawOrder.cbVwBillingHistoryPayments = [];

          for (const billing of res.data) {
            const foundIndex = cbVwPawOrder.cbVwBillingHistoryPayments?.findIndex(e => e.id == billing.id);

            if (tmpInvoiceIds.includes(billing.externalId)) {
              if (foundIndex < 0) {
                cbVwPawOrder.cbVwBillingHistoryPayments.push(billing);
              } else {
                Vue.set(cbVwPawOrder.cbVwBillingHistoryPayments, foundIndex, billing);
              }
            }
          }

          if (cbVwPawOrder.cbVwBillingHistoryPayments.length > 0) {
            cbVwPawOrder.cbVwBillingHistoryPayments?.sort((a, b) => <any>dayjs(a.createdDate) - <any>dayjs(b.createdDate));
            (<any>this.$root).$emit('do::setCbVwPawOrder', cbVwPawOrder);
          }
        }
      })
      .catch(err => {
        console.error('error', err.response);
      });
  }

  public createConversation({
    openChat = false,
    reqParam,
    type = TypeComponent.CHAT_MODAL,
  }: {
    openChat: boolean;
    reqParam?: ICbPawOrderRequirement;
    type?: TypeComponent;
  }) {
    if (!this.cbVwPawOrder?.id) return;

    // create conversation if no exist
    const userId: string = this.cbVwPawOrder.buyerId !== this.currentUser.id ? this.cbVwPawOrder.buyerId : this.cbVwPawOrder.partnerId;

    this.pChtService()
      .createConversation(userId)
      .then(res => {
        this.globalService().commitChtVwConversation(res);
        this.chtConversation = res;

        if (openChat) {
          this.$store.commit('roomIdToStore', res.id + '');
          this.$router.push({ name: 'PAppChatByRoomId', params: { roomId: res.id + '' } });
        } else {
          this.saveChatMessageRequirement(reqParam.id, this.getChtContentTypeByReqType(reqParam.requirementType), type); // save to chat
        }
      })
      .catch(err => {
        console.error('error', err.response);
      });
  }

  public retrieveCbPawOrderTrack({ cbVwPawOrder }: { cbVwPawOrder: ICbVwPawOrder }) {
    this.pWfVwWorkflowService()
      .retrieveHistoryByExternalId(cbVwPawOrder.workflowName, cbVwPawOrder.id)
      .then(res => {
        if (res?.length > 0) {
          if (!cbVwPawOrder.cbVwPawOrderTracks) cbVwPawOrder.cbVwPawOrderTracks = [];
          if (!cbVwPawOrder.wfHistoriesKeyName) cbVwPawOrder.wfHistoriesKeyName = [];
          const cbVwPawOrderTracks = res;

          cbVwPawOrderTracks.forEach(track => {
            const content = this.getContentTracks(track.workflowName, track.keyName);

            if (content.en !== '' && content.id !== '') {
              const orderTrack = {
                id: track.id,
                descriptionEn: content.en,
                descriptionIn: content.id,
                createdDate: track.createdDate,
                cbPawOrderTrackCode: track.keyName,
              };

              cbVwPawOrder.cbVwPawOrderTracks.unshift(orderTrack);
            }

            cbVwPawOrder.wfHistoriesKeyName.unshift(track.keyName);
          });

          cbVwPawOrder.cbVwPawOrderTracks.sort((a, b) => <any>dayjs(b.createdDate).diff(<any>dayjs(a.createdDate)));
          (<any>this.$root).$emit('do::setCbVwPawOrder', cbVwPawOrder);
        }
      })
      .catch(err => {
        console.error('error', err.response);
      });
  }

  public refreshChatMessagePawPreOrdersAfterPayment(cbPawPreOrderIds: number[]) {
    // get from chat-external-id
    if (cbPawPreOrderIds?.length > 0) {
      (<any>this.$root).$emit('chtex::getVariable', 'cbPawPreOrders4Chat', (cbPawPreOrders4Chat: ICbPawPreOrder[]) => {
        const cbPawOrderUniqueIds = cbPawPreOrderIds.filter(this.globalService().onlyUniqueIds);

        if (cbPawPreOrders4Chat.length > 0) {
          cbPawPreOrders4Chat.forEach((pawPreOrder: ICbPawPreOrder) => {
            if (cbPawOrderUniqueIds.includes(pawPreOrder.id) && pawPreOrder.status === null) {
              this.$root.$emit('chtex::refreshDataMessageByExternalId', pawPreOrder?.id);
            }
          });
        }
      });
    }
  }

  public refreshOrder({
    orderId,
    reqId,
    isChatSkip,
    typeModal,
    isWidgetSkip = false,
    isWithoutLoading = false,
  }: {
    orderId: string | number;
    reqId?: number;
    isChatSkip: boolean;
    typeModal: TypeComponent;
    isWidgetSkip?: boolean;
    isWithoutLoading?: boolean;
  }) {
    if (!orderId) {
      console.log('order-paw-controller - refreshOrder: ' + orderId + ',' + reqId);
      return;
    }

    if (typeModal === TypeComponent.CHAT_MODAL || typeModal === TypeComponent.DETAIL_ORDER_LOCATION) {
      if (!isWidgetSkip)
        this.retrieveCbVwPawOrdersProgressOnly({
          ids: [Number(orderId)],
          isOnlyFirstLoading: false,
          isWithoutLoadingRequirement: isWithoutLoading,
        });

      if (!isChatSkip && reqId) {
        console.log('order-paw-controller - refreshOrder: id refreshOrder', reqId);
        this.$root.$emit('chtex::refreshDataMessageByExternalId', reqId);
      } else if (!isChatSkip && !reqId) {
        this.cbVwPawOrders.forEach(o => {
          if (o.id === Number(orderId) && o.cbVwPawOrderRequirements)
            o.cbVwPawOrderRequirements.forEach(r => {
              this.$root.$emit('chtex::refreshDataMessageByExternalId', r.id);
            });
        });
      }
    } else if (typeModal === TypeComponent.DETAIL_ORDER_MODAL) {
      this.findCbVwPawOrder({ id: orderId, isWithoutLoading });
    }
  }

  public processToWorkLocation(cbVwPawOrder: ICbVwPawOrder) {
    cbVwPawOrder.toWorkLocation = cbVwPawOrder.cbLocationRequirement;
  }

  public processItemChatWidgetOffers(cbVwPawOrder: ICbVwPawOrder) {
    if (cbVwPawOrder.svcType === CbSvcType.INSIDE && cbVwPawOrder.orderStatus === CbCommonStatus.WAITING_PAYMENT) {
      cbVwPawOrder.itemChatWidgetOffers = [
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.packageInclude',
          key: 'package-included',
        },
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.summary',
          key: 'summary',
        },
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.extra',
          key: 'extra',
        },
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.milestone',
          key: 'milestone',
        },
      ];
    } else if (cbVwPawOrder.svcType === CbSvcType.FIELD && cbVwPawOrder.orderStatus === CbCommonStatus.WAITING_PAYMENT) {
      cbVwPawOrder.itemChatWidgetOffers = [
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.packageInclude',
          key: 'package-included',
        },
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.summary',
          key: 'summary',
        },
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.extra',
          key: 'extra',
        },
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.bookDate',
          key: 'book-date',
        },
        {
          isShow: false,
          title: 'cbgwApp.cbDetailOrder.workLocation',
          key: 'work-location',
        },
      ];
    }
  }

  private getChtContentTypeByReqType(requirementType: CbRequirementType) {
    //ChtContentType.PROJECT_AUCTION
    // ChtContentType.PAW
    switch (requirementType) {
      case CbRequirementType.MOM:
        return ChtContentType.ORDER_MOM;
      case CbRequirementType.REQUIREMENT:
        return ChtContentType.ORDER_REQUIREMENT;
      case CbRequirementType.REVISION:
        return ChtContentType.ORDER_PROJECT_DELIVERY;
      case CbRequirementType.REVISION_EXTRA:
        return ChtContentType.ORDER_PROJECT_DELIVERY;
      case CbRequirementType.DELIVERY:
        return ChtContentType.ORDER_PROJECT_DELIVERY;
      case CbRequirementType.ON_MY_WAY:
        return ChtContentType.ORDER_ON_MY_WAY;
      case CbRequirementType.CLOCK_IN:
        return ChtContentType.ORDER_CLOCK_IN;
      case CbRequirementType.CLOCK_OUT:
        return ChtContentType.ORDER_CLOCK_OUT;
      case CbRequirementType.MILESTONE:
        return ChtContentType.ORDER_MILESTONE;
      case CbRequirementType.APPOINTMENT:
        return ChtContentType.ORDER_APPOINTMENT;
      default:
        console.log('not define type');
        return;
    }
  }

  private async actionHandler(
    {
      action,
      typeModal,
      cbVwPawOrder,
      cbVwPawOrderRequirement,
      cbTextRestrict,
      cbAttachmentRestrictLocationPhoto,
      cbPawOrderParam,
      cbPawOrderFiles,
      cbPawOrderFile,
      reqParam,
      offerExpiredLabel,
      amount,
      actionId,
      payDetailVA,
      xenditCCUrl,
      invoiceParam,
      userId,
      publicUserType,
    },
    callback?: any
  ) {
    let req: ICbPawOrderRequirement;
    let reqParamPartial: ICbPawOrderRequirement;
    let cbVwPawOrderRequirementVariable: ICbVwPawOrderRequirement;
    let reqCbPawOrderFile: ICbPawOrderFile;
    let callbackLocal = {};
    const isCreateMessageChatSkip = true;
    let isChatSkip = true;
    let isSkipValidationWfHistory: boolean;
    let wfKeyType: string;

    switch (action) {
      case HANDLE_OFFER_SEND:
        this.sendPawOffer(cbPawOrderParam, callback);
        return;
      case HANDLE_MDL_PAYMENT_ORDER_OFFER:
      case HANDLE_MDL_PAYMENT_ORDER_OFFER_ACTIVE:
      case HANDLE_MDL_MOM_ACCEPT_AND_PAY:
      case HANDLE_MDL_PAYMENT_ORDER_OFFER_REVISION:
        this.isSavingPawOrderRequirement = true;

        if (cbVwPawOrderRequirement?.amount > 0) {
          // clear data not use in payload payment
          const cbVwPawOrderTmp: ICbVwPawOrder = {
            ...cbVwPawOrder,
            cbPawOrderFiles: [],
            cbVwPawOrderIncludes: [],
            cbVwPawOrderExtras: [],
            cbVwPawOrderRequirements: [],
            cbVwPawOrderRequirement: {},
            cbVwPawOrderProjectDeliverys: [],
            cbVwPawOrderProjectDeliveryPreviews: [],
            cbVwPawOrderProjectDeliverySelected: {},
            cbVwPawOrderMilestoneRequirements: [],
            cbVwPawOrderMilestones: [],
            cbVwPawOrderMOMs: [],
            cbVwPawOrderAppoinments: [],
            cbVwPawOrderFileClockIns: [],
            cbVwPawOrderFileClockOuts: [],
            cbVwBillingHistoryPayments: [],
            cbVwPawOrderTracks: [],
            cbVwTextRestrict: {},
            itemChatWidgetOffers: [],
          };

          const payload: IPayPayload2023 = {
            title: 'Payment',
            subTitle: `${cbVwPawOrder.labelOrderId} | ${cbVwPawOrderRequirement.pawTitle}`,
            orderId: cbVwPawOrderRequirement.id,
            orderIdParent: cbVwPawOrderRequirement.cbPawOrderId,
            cbVwPawOrder: cbVwPawOrderTmp,
            cbVwPawOrderRequirement: cbVwPawOrderRequirement,
            payMethodType: PayMethodType.ORDER_PAW_DETAIL,
            app: App.CB_APP,
          };

          this.cbVwPawOrder = cbVwPawOrder;
          this.cbVwPawOrderRequirement = cbVwPawOrderRequirement;

          this.checkPaymentVa(payload, typeModal, callback);
        } else {
          const partial: ICbPawOrderRequirement = {
            id: cbVwPawOrderRequirement.id,
            requirementType: <CbRequirementType>cbVwPawOrderRequirement.requirementType,
            status: CbCommonStatus.PAID,
            cbPawOrder: {
              id: cbVwPawOrderRequirement.cbPawOrderId,
            },
          };
          this.cbPawOrderRequirementPartialUpdate(partial, typeModal, callback);
        }
        return;

      case HANDLE_MDL_PAW_OFFER_OPEN:
        this.openPawOffer();
        return;

      case HANDLE_MDL_PAW_DETAIL_OPEN:
        if (cbVwPawOrder.orderType === CbOrderType.AUCTION_PROJECT) {
          (<any>this.$root).$emit('app::changeValue', 'auctionProjectId', cbVwPawOrder.pawIdHistory);
          this.globalService().openGlobalDialog(this.$root, 'modal-c-auction-project');
        } else if (cbVwPawOrder.orderType === CbOrderType.PAW) {
          this.pCbVwPawService()
            .find(cbVwPawOrder.pawIdHistory)
            .then(res => {
              this.handleActionModal({ type: TypeComponent.PAW_DETAIL_MODAL, data: res });
            })
            .catch(err => {
              this.alertService().showHttpError(this, err.response);
            });
        }
        return;
      case HANDLE_ASK_COMMITTEE_REPORT_OPEN:
      case HANDLE_ASK_COMMITTEE_DISPUTED_OPEN:
        this.$router.push({
          name: 'PAskCommitee',
          query: { orderId: cbVwPawOrder.id.toString(), type: action === HANDLE_ASK_COMMITTEE_DISPUTED_OPEN ? 'DISPUTED' : 'REPORT' },
        });
        return;

      case HANDLE_MDL_MOM_SEND:
        this.isSavingPawOrderRequirement = true;

        if (reqParam?.offerExpired) {
          const newExpired: Date = new Date();
          let minutes = 0;
          if (reqParam.offerExpired === 15) {
            minutes = reqParam.offerExpired;
          } else {
            minutes = reqParam.offerExpired * 60;
          }
          newExpired.setMinutes(newExpired.getMinutes() + minutes);
          reqParam.offerExpiredDate = newExpired;
          reqParam.offerExpired = null;
        }

        if (reqParam?.deliveryTime && reqParam?.amount) {
          reqParam.deliveryTime = Number(reqParam.deliveryTime);
          reqParam.status = CbCommonStatus.WAITING_PAYMENT;
        } else {
          reqParam.status = CbCommonStatus.REQUESTED;
        }

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });

            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        this.createRequirement({
          cbVwPawOrder,
          req: reqParam,
          callback: callbackLocal,
          isCreateMessageChatSkip: false,
          isRefreshOrderSkip: true,
          type: typeModal,
        });
        return;
      case HANDLE_MDL_APPOINTMENT_SEND:
        this.isSavingPawOrderRequirement = true;

        reqParam = {
          ...reqParam,
          ...{
            requirementType: CbRequirementType.APPOINTMENT,
            status: CbCommonStatus.APPOINTMENT,
            buyerIdFrmServer: cbVwPawOrder.buyerId,
            partnerIdFrmServer: cbVwPawOrder.partnerId,
            buyer: {
              id: cbVwPawOrder.buyerId,
            },
            partner: {
              id: cbVwPawOrder.partnerId,
            },
            cbPawOrder: {
              id: cbVwPawOrder.id,
            },
          },
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });

            if (callback) callback({ isSavingLoader: isSavingLoader, data: data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          } else {
            callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        this.createRequirement({
          cbVwPawOrder,
          req: reqParam,
          callback: callbackLocal,
          isCreateMessageChatSkip: false,
          isRefreshOrderSkip: true,
          type: typeModal,
        });
        return;
      case HANDLE_MDL_MILESTONE_SEND:
        this.isSavingPawOrderRequirement = true;

        // clear file base64
        if (Array.isArray(cbPawOrderFiles)) {
          cbPawOrderFiles?.forEach(cbPawOrderFile => {
            if (cbPawOrderFile.cbAttachmentRestrict) {
              cbPawOrderFile.cbAttachmentRestrict.thumbnail = null;
              cbPawOrderFile.cbAttachmentRestrict.downloadLinkFile = null;
              cbPawOrderFile.cbAttachmentRestrict.localFileUrl = null;
            }

            if (cbPawOrderFile.cbAtt) {
              cbPawOrderFile.cbAtt.thumbnail = null;
              cbPawOrderFile.cbAtt.thumbnailLocalUrl = null;
              cbPawOrderFile.cbAtt.localUrl = null;
              cbPawOrderFile.cbAtt.localFileUrl = null;
            }
          });
        }

        reqParam = {
          ...cbVwPawOrderRequirement,
          id: cbVwPawOrderRequirement.id,
          progressProject: 50,
          status: CbCommonStatus.COMPLETED,
          requirementType: <CbRequirementType>cbVwPawOrderRequirement.requirementType,
          cbPawOrder: {
            id: cbVwPawOrderRequirement.cbPawOrderId,
          },
          ...{
            buyerIdFrmServer: cbVwPawOrder.buyerId,
            partnerIdFrmServer: cbVwPawOrder.partnerId,
            buyer: {
              id: cbVwPawOrder.buyerId,
            },
            partner: {
              id: cbVwPawOrder.partnerId,
            },
            cbPawOrder: {
              id: cbVwPawOrder.id,
            },
          },
          cbPawOrderFiles,
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            if (this.isMobile) this.isShowChatInfoPanel = false;
            if (callback) callback({ isSavingLoader, data });

            this.isSavingPawOrderRequirement = isSavingLoader;
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            this.createConversation({ openChat: false, reqParam: data, type: typeModal });

            if (typeModal === TypeComponent.CHAT_MODAL) {
              this.refreshOrder({ orderId: cbVwPawOrder.id, isChatSkip, typeModal, isWithoutLoading: false });
            }
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        this.createRequirement({
          cbVwPawOrder,
          req: reqParam,
          callback: callbackLocal,
          type: typeModal,
          isCreateMessageChatSkip,
          isRefreshOrderSkip: true,
        });
        return;

      case HANDLE_MDL_MOM_PREVIEW:
        let cbVwPawOrderRequirementMOM: ICbVwPawOrderRequirement;

        if (cbVwPawOrderRequirement) {
          // from chat
          cbVwPawOrderRequirementMOM = cbVwPawOrderRequirement;
          cbVwPawOrderRequirementMOM.description = cbTextRestrict;
        } else {
          // from chat panel or detail order
          cbVwPawOrderRequirementMOM = cbVwPawOrder.cbVwPawOrderRequirements?.find(
            (req: ICbVwPawOrderRequirement) =>
              [CbRequirementType.REQUIREMENT, CbRequirementType.MOM].includes(<CbRequirementType>req.requirementType) && req.id === actionId
          );
        }

        this.cbVwPawOrderRequirement = cbVwPawOrderRequirementMOM;
        this.cbVwPawOrder = cbVwPawOrder;
        this.modalStepFormOrders = [ModalStepFormOrderType.isShowMomPreview];

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowMomPreview' });
        return;
      case HANDLE_MDL_MOM_OPEN:
        let newCbPawOrderRequirementMOM: ICbPawOrderRequirement = new CbPawOrderRequirement();

        newCbPawOrderRequirementMOM = {
          ...{
            currency: cbVwPawOrder.currency,
            buyer: {
              id: cbVwPawOrder.buyerId,
              firstName: cbVwPawOrder.buyerDisplayName,
            },

            partner: {
              id: cbVwPawOrder.partnerId,
              firstName: cbVwPawOrder.partnerDisplayName,
            },
            cbPawOrder: {
              id: cbVwPawOrder.id,
              svcType: cbVwPawOrder.svcType,
              svcName: cbVwPawOrder.svcName,
              svcCategoryName: cbVwPawOrder.svcCategoryName,
              labelOrderId: cbVwPawOrder.labelOrderId,
              pawTitle: cbVwPawOrder.pawTitle,
              orderType: cbVwPawOrder.orderType,
              currency: cbVwPawOrder.currency,
            },
            requirementType: CbRequirementType.MOM,
          },
        };

        this.modalStepFormOrders = [ModalStepFormOrderType.isShowMomEdit];
        this.cbVwPawOrder = cbVwPawOrder;
        this.cbPawOrderRequirement = newCbPawOrderRequirementMOM;

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowMomEdit' });
        return;

      case HANDLE_MDL_MOM_CONFIRMATION_DECLINE:
      case HANDLE_MDL_MOM_CONFIRMATION_ACCEPT:
      case HANDLE_MDL_MOM_CONFIRMATION_ACCEPT_DIRECTLY:
        if (action === HANDLE_MDL_MOM_CONFIRMATION_DECLINE) {
          this.addModalStepFormOrders(ModalStepFormOrderType.isShowConfirmationDeclinedMom);
        } else if (action === HANDLE_MDL_MOM_CONFIRMATION_ACCEPT) {
          this.addModalStepFormOrders(ModalStepFormOrderType.isShowConfirmationAcceptMom);
        } else if (action === HANDLE_MDL_MOM_CONFIRMATION_ACCEPT_DIRECTLY) {
          this.modalStepFormOrders = [ModalStepFormOrderType.isShowConfirmationAcceptMom];
          cbVwPawOrderRequirement.acceptDirectlyMom = true;
        }

        this.cbVwPawOrder = cbVwPawOrder;
        this.cbVwPawOrderRequirement = cbVwPawOrderRequirement;

        this.handleActionModal({
          type: typeModal,
          isShowModal:
            action === HANDLE_MDL_MOM_CONFIRMATION_ACCEPT || action === HANDLE_MDL_MOM_CONFIRMATION_ACCEPT_DIRECTLY
              ? 'isShowConfirmationAcceptMom'
              : 'isShowConfirmationDeclinedMom',
        });
        break;
      case HANDLE_MDL_MOM_DECLINE:
        this.isSavingPawOrderRequirement = true;

        req = {
          id: cbVwPawOrderRequirement.id,
          status: CbCommonStatus.DECLINED,
          isWfRunNeeded: true,
        };

        if (cbVwPawOrder.workflowName === 'OR_INSIDE_WORKER') {
          wfKeyType = cbVwPawOrderRequirement.amount > 0 ? WFKEY_I_DECLINE_NOTE_CHARGE : WFKEY_I_DECLINE_NOTE_FREE;
        } else if (cbVwPawOrder.workflowName === 'OR_FIELD_WORKER') {
          wfKeyType = cbVwPawOrderRequirement.amount > 0 ? WFKEY_DECLINE_ADDITIONAL_CHARGE : WFKEY_DECLINE_ADDITIONAL_FREE;
        }

        this.wfGetWfHistoryByOR(cbVwPawOrder.workflowName, cbVwPawOrder.id, req, wfKeyType)
          .then(wfHistory => {
            req.wfHistory = wfHistory;
            this.cbPawOrderRequirementPartialUpdate(req, typeModal);
          })
          .catch(err => {
            console.error('error', err.response);
            this.isSavingPawOrderRequirement = false;
          })
          .finally(() => {
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
          });
        this.modalStepFormOrders = [];
        return;
      case HANDLE_MDL_MOM_ACCEPT:
      case HANDLE_MDL_PROJECT_DELIVERY_ACCEPT_EXTRA_FREE:
        this.isSavingPawOrderRequirement = true;

        reqParamPartial = {
          id: cbVwPawOrderRequirement.id,
          requirementType: <CbRequirementType>cbVwPawOrderRequirement.requirementType,
          status: CbCommonStatus.ACCEPTED,
          isWfRunNeeded: true,
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            if (callback) callback({ isSavingLoader, data });
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        if (action === HANDLE_MDL_MOM_ACCEPT) {
          if (cbVwPawOrder.workflowName === 'OR_FIELD_WORKER') wfKeyType = WFKEY_ACCEPT_ADDITIONAL_FREE;
          else wfKeyType = WFKEY_I_ACCEPT_NOTE_FREE;
        } else if (action === HANDLE_MDL_PROJECT_DELIVERY_ACCEPT_EXTRA_FREE) {
          wfKeyType = WFKEY_I_REVISION_EXTRA_ACCEPT_FREE;
        }

        //still not close the  popup
        this.wfGetWfHistoryByOR(cbVwPawOrder.workflowName, cbVwPawOrder.id, reqParamPartial, wfKeyType)
          .then(wfHistory => {
            reqParamPartial.wfHistory = wfHistory;
            this.cbPawOrderRequirementPartialUpdate(reqParamPartial, typeModal, callbackLocal);
          })
          .catch(err => {
            if (callback) callback({ isSavingLoader: false, data: null });
            this.isSavingPawOrderRequirement = false;

            console.error('error', err.response);
          });
        return;

      case HANDLE_MDL_PROJECT_DELIVERY_OPEN:
        const newCbPawOrderRequirementUploadDelivery: ICbPawOrderRequirement = new CbPawOrderRequirement();

        newCbPawOrderRequirementUploadDelivery.cbPawOrder = new CbPawOrder();
        newCbPawOrderRequirementUploadDelivery.cbPawOrder.id = cbVwPawOrder.id;
        newCbPawOrderRequirementUploadDelivery.deliveryGroupByDate = new Date();
        newCbPawOrderRequirementUploadDelivery.requirementType = CbRequirementType.DELIVERY;
        newCbPawOrderRequirementUploadDelivery.buyer = new User();
        newCbPawOrderRequirementUploadDelivery.partner = new User();
        newCbPawOrderRequirementUploadDelivery.buyer.id = cbVwPawOrder.buyerId;
        newCbPawOrderRequirementUploadDelivery.partner.id = cbVwPawOrder.partnerId;
        newCbPawOrderRequirementUploadDelivery.currency = cbVwPawOrder.currency;
        newCbPawOrderRequirementUploadDelivery.status = CbCommonStatus.FEEDBACK_NEEDED;

        this.cbPawOrderRequirement = newCbPawOrderRequirementUploadDelivery;
        this.cbVwPawOrder = cbVwPawOrder;
        this.cbPawOrderFiles = [];
        this.modalStepFormOrders = [ModalStepFormOrderType.isShowUploadProjectDelivery];

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowUploadProjectDelivery' });
        return;
      case HANDLE_MDL_PROJECT_DELIVERY_CONFIRMATION_OPEN:
        this.cbPawOrderFiles = cbPawOrderFiles;

        this.addModalStepFormOrders(ModalStepFormOrderType.isShowConfirmationSendProjectDelivery);
        this.handleActionModal({ type: typeModal, isShowModal: 'isShowConfirmationSendProjectDelivery' });
        return;
      case HANDLE_MDL_PROJECT_DELIVERY_SEND:
        this.isSavingPawOrderRequirement = true;

        // using confirmation so save to this
        cbVwPawOrder = this.cbVwPawOrder;
        req = this.cbPawOrderRequirement;

        // clear file base64
        if (Array.isArray(this.cbPawOrderFiles)) {
          this.cbPawOrderFiles?.forEach(cbPawOrderFile => {
            if (cbPawOrderFile.cbAttachmentRestrict) {
              cbPawOrderFile.cbAttachmentRestrict.thumbnail = null;
              cbPawOrderFile.cbAttachmentRestrict.localFileUrl = null;
            }

            if (cbPawOrderFile.cbAtt) {
              cbPawOrderFile.cbAtt.thumbnail = null;
              cbPawOrderFile.cbAtt.thumbnailLocalUrl = null;
              cbPawOrderFile.cbAtt.localUrl = null;
              cbPawOrderFile.cbAtt.localFileUrl = null;
            }
          });

          req.cbPawOrderFiles = this.cbPawOrderFiles;
        } else {
          req.cbPawOrderFiles = [];
        }

        // 2
        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            if (this.isMobile) this.isShowChatInfoPanel = false;
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;

            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            this.createConversation({ openChat: false, reqParam: data, type: typeModal });
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        // 1
        this.createRequirement({
          cbVwPawOrder,
          req,
          callback: callbackLocal,
          type: typeModal,
          isCreateMessageChatSkip,
          isRefreshOrderSkip: true,
        });
        return;
      case HANDLE_MDL_PROJECT_DELIVERY_PREVIEW:
      case HANDLE_MDL_PROJECT_DELIVERY_PREVIEW_LAST:
        this.isSavingPawOrderRequirement = true;

        if (this.cancelTokenProjectDeliveryPreview) this.cancelTokenProjectDeliveryPreview.cancel('Request Order Cancelled');
        this.cancelTokenProjectDeliveryPreview = axios.CancelToken.source();

        if (!cbVwPawOrder && !actionId) return;

        cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews = [];
        cbVwPawOrder.cbVwPawOrderProjectDeliverySelected = new CbVwPawOrderRequirement();

        this.globalService().openGlobalDialog(this.$root, 'modal-c-loader');

        const paramCbPawOrderReqDelivery: any = {
          'cbPawOrderId.equals': cbVwPawOrder?.id,
          'id.equals': actionId,
          size: 1,
        };
        await this.pCbVwPawOrderRequirementService()
          .retrieve(
            paramCbPawOrderReqDelivery,
            this.cancelTokenProjectDeliveryPreview ? this.cancelTokenProjectDeliveryPreview.token : null
          )
          .then(resRequirements => {
            if (resRequirements && resRequirements.data.length > 0) {
              resRequirements.data.forEach(req => {
                if (req.cbPawOrderId === cbVwPawOrder?.id) {
                  if (!cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews) cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews = [];
                  cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews.push(req);
                }
              });
            }
          });

        const paramCbPawOrderRequirements: any = {
          'cbPawOrderId.equals': cbVwPawOrder?.id,
          'refRequirementId.equals': actionId,
          size: 99999999,
        };
        await this.pCbVwPawOrderRequirementService()
          .retrieve(
            paramCbPawOrderRequirements,
            this.cancelTokenProjectDeliveryPreview ? this.cancelTokenProjectDeliveryPreview.token : null
          )
          .then(resRequirements => {
            if (resRequirements && resRequirements.data.length > 0) {
              resRequirements.data.forEach((req: ICbVwPawOrderRequirement) => {
                if (req.refRequirementId === actionId || req.id === actionId) {
                  if (!cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews) cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews = [];
                  cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews.push(req);
                  this.retrieveCbTextRestrict(req.descriptionId, typeModal);
                }
              });
            }
          })
          .finally(() => {
            this.globalService().closeGlobalDialog(this.$root, 'modal-c-loader');
            this.isSavingPawOrderRequirement = false;
          });

        const tmpRequirementDeliverys = cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews?.filter(
          (req: ICbVwPawOrderRequirement) => req.requirementType === CbRequirementType.DELIVERY
        );
        if (action === HANDLE_MDL_PROJECT_DELIVERY_PREVIEW_LAST) {
          tmpRequirementDeliverys?.sort((a, b) => <any>dayjs(b.createdDate) - <any>dayjs(a.createdDate));
        }

        cbVwPawOrder.cbVwPawOrderProjectDeliverySelected = cbVwPawOrder.cbVwPawOrderProjectDeliveryPreviews?.find(
          (req: ICbVwPawOrderRequirement) => {
            if (action === HANDLE_MDL_PROJECT_DELIVERY_PREVIEW) {
              return req.requirementType === CbRequirementType.DELIVERY && req.id === actionId;
            } else if (action === HANDLE_MDL_PROJECT_DELIVERY_PREVIEW_LAST) {
              return tmpRequirementDeliverys[0].id === req.id;
            }
          }
        );

        this.cbVwPawOrder = cbVwPawOrder;
        this.modalStepFormOrders = [ModalStepFormOrderType.isShowProjectDelivery];

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowProjectDelivery' });
        return;
      case HANDLE_MDL_PROJECT_DELIVERY_ASK_REVISION: // REVISION EXTRA too
        this.isSavingPawOrderRequirement = true;
        const newDate = dayjs().toDate();

        let requirementType: CbRequirementType = CbRequirementType.REVISION;
        let status: CbCommonStatus = CbCommonStatus.ASK_FOR_REVISION;

        if (cbVwPawOrderRequirement.revisionUsed >= cbVwPawOrderRequirement.revisionTotal) {
          requirementType = CbRequirementType.REVISION_EXTRA;
          status = CbCommonStatus.REVISION_REQUESTED;
        }

        req = {
          title: requirementType + ' ' + newDate,
          requirementType,
          currency: CbCurrency.IDR,
          status,
          statusDate: newDate,
          buyerIdFrmServer: cbVwPawOrderRequirement.buyerId,
          partnerIdFrmServer: cbVwPawOrderRequirement.partnerId,
          requirementId: cbVwPawOrderRequirement.id,
          deliveryGroupByDate: newDate,
          cbTextRestrict: cbTextRestrict,
          buyer: {
            id: cbVwPawOrderRequirement.buyerId,
          },
          partner: {
            id: cbVwPawOrderRequirement.partnerId,
          },
          cbPawOrder: {
            id: cbVwPawOrderRequirement.cbPawOrderId,
          },
        };

        // 2
        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            cbPawOrderFiles?.forEach(item => {
              item.deliveryGroupByDate = data.deliveryGroupByDate;
              item.requirementId = data.id; // req.id
              item.cbPawOrder = { id: cbVwPawOrder.id };
            });
            if (cbPawOrderFiles.length > 0) {
              this.uploadCbPawOrderFiles(cbPawOrderFiles, cbVwPawOrder, () => {
                if (callback) callback({ isSavingLoader, data });
                this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
                this.isSavingPawOrderRequirement = isSavingLoader;
              }); //in have refresh order and save chat
            } else {
              this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            }
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        // 1
        this.createRequirement({ cbVwPawOrder, req, callback: callbackLocal, isCreateMessageChatSkip: true });
        return;
      case HANDLE_MDL_PROJECT_DELIVERY_REJECT_EXTRA:
        this.isSavingPawOrderRequirement = true;

        // don't use const req, its error
        const reqUp: ICbPawOrderRequirement = {
          id: cbVwPawOrderRequirement.id,
          requirementType: <CbRequirementType>cbVwPawOrderRequirement.requirementType,
          status: CbCommonStatus.DECLINED,
          isWfRunNeeded: true,
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            this.modalStepFormOrders = [];
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        // STATUS_PARTNER_WIN = disputed from revision requested and partner win back to revision requested
        if (cbVwPawOrder.keyName === 'ASK_FOR_REVISION_1' || cbVwPawOrder.keyName === 'STATUS_PARTNER_WIN') {
          wfKeyType = WFKEY_I_CANCEL_REVISION_EXTRA;
        } else if (cbVwPawOrder.keyName === 'WAITING_FOR_PAYMENT_4') {
          wfKeyType = WFKEY_I_DECLINED_REVISION_EXTRA;
        } else if (cbVwPawOrder.keyName === 'REVISION_REQUESTED_CHARGE') {
          wfKeyType = WFKEY_I_DECLINED_REVISION_EXTRA;
        } else if (cbVwPawOrder.keyName === 'REVISION_REQUESTED_EXTRA_FREE') {
          wfKeyType = WFKEY_I_DECLINED_REVISION_EXTRA_FREE;
        }

        // still not close the  popup
        this.wfGetWfHistoryByOR(cbVwPawOrder.workflowName, cbVwPawOrder.id, reqUp, wfKeyType)
          .then(wfHistory => {
            reqUp.wfHistory = wfHistory;
            this.cbPawOrderRequirementPartialUpdate(reqUp, typeModal, callbackLocal);
          })
          .catch(err => {
            if (callback) callback({ isSavingLoader: false, data: null });
            this.isSavingPawOrderRequirement = false;

            console.error('error', err.response);
          });

        return;
      case HANDLE_MDL_PROJECT_DELIVERY_SEND_EXTRA:
        this.isSavingPawOrderRequirement = true;

        let newCbPawOrderRequirementAcceptExtra: ICbPawOrderRequirement = new CbPawOrderRequirement();
        Object.assign(newCbPawOrderRequirementAcceptExtra, cbVwPawOrderRequirement);

        const newExpired: Date = dayjs()
          .set('minutes', dayjs().minute() + Number(offerExpiredLabel))
          .toDate();

        newCbPawOrderRequirementAcceptExtra.offerExpiredDate = newExpired;

        newCbPawOrderRequirementAcceptExtra = {
          id: newCbPawOrderRequirementAcceptExtra.id,
          amount,
          cbTextRestrict: newCbPawOrderRequirementAcceptExtra.cbTextRestrict,
          currency: newCbPawOrderRequirementAcceptExtra.currency,
          status: CbCommonStatus.ASK_FOR_REVISION_EXTRA,
          requirementType: newCbPawOrderRequirementAcceptExtra.requirementType,
          offerExpiredDate: newCbPawOrderRequirementAcceptExtra.offerExpiredDate,
          isWfRunNeeded: true,
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            this.modalStepFormOrders = [];
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        if (amount > 0) {
          wfKeyType = WFKEY_I_SEND_OFFER_REVISION_EXTRA;
        } else {
          wfKeyType = WFKEY_I_SEND_OFFER_REVISION_EXTRA_FREE;
        }

        this.wfGetWfHistoryByOR(cbVwPawOrder.workflowName, cbVwPawOrder.id, newCbPawOrderRequirementAcceptExtra, wfKeyType)
          .then(wfHistory => {
            newCbPawOrderRequirementAcceptExtra.wfHistory = wfHistory;
            this.cbPawOrderRequirementPartialUpdate(newCbPawOrderRequirementAcceptExtra, typeModal, callbackLocal);
          })
          .catch(err => {
            if (callback) callback({ isSavingLoader: false, data: null });
            this.isSavingPawOrderRequirement = false;

            console.error('error', err.response);
          });
        return;

      case HANDLE_UPLOAD_PROJECT_FILE:
        if (cbVwPawOrder.orderStatus === CbCommonStatus.ON_PROGRESS) {
          isSkipValidationWfHistory = false;
          wfKeyType = WFKEY_I_UPLOAD_FILE_DRAFT;
        } else {
          isSkipValidationWfHistory = true;
          wfKeyType = WFKEY_I_UPLOAD_FILE_DRAFT_AFTER_DELIVERY;
        }

        this.wfGetWfHistoryByExternalId(cbVwPawOrder.workflowName, cbVwPawOrder.id, wfKeyType, isSkipValidationWfHistory)
          .then(wfHistory => {
            if (cbPawOrderFile !== null) {
              reqCbPawOrderFile = cbPawOrderFile;

              // clear base64
              if (reqCbPawOrderFile.cbAttachmentRestrict) {
                reqCbPawOrderFile.cbAttachmentRestrict.thumbnail = null;
                reqCbPawOrderFile.cbAttachmentRestrict.localFileUrl = null;
              }

              if (reqCbPawOrderFile.cbAtt) {
                reqCbPawOrderFile.cbAtt.thumbnail = null;
                reqCbPawOrderFile.cbAtt.thumbnailLocalUrl = null;
                reqCbPawOrderFile.cbAtt.localUrl = null;
                reqCbPawOrderFile.cbAtt.localFileUrl = null;
              }

              reqCbPawOrderFile.wfHistory = wfHistory;

              this.pCbPawOrderFileService()
                .create(reqCbPawOrderFile, false, progress => {
                  console.log('callback not use');
                })
                .then(res => {
                  if (callback) callback({ cbPawOrderFile, error: null });
                })
                .catch(error => {
                  if (callback) callback({ cbPawOrderFile, error });
                  this.alertService().showHttpError(this, error.response);
                  console.error('error paw order files', error.response);
                });
            }
          })
          .catch(error => {
            if (callback) callback({ cbPawOrderFile, error });
            this.alertService().showHttpError(this, error.response);
            console.error('error workflow', error.response);
          });
        return;

      case HANDLE_MDL_MILESTONE_OPEN:
        this.cbVwPawOrder = cbVwPawOrder;
        this.cbPawOrderFiles = [];
        this.modalStepFormOrders = [ModalStepFormOrderType.isShowMilestone];

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowMilestone' });
        return;
      case HANDLE_MDL_MILESTONE_FILE:
        reqParamPartial = new CbPawOrderRequirement();

        if (cbVwPawOrderRequirement) {
          // from chat
          cbVwPawOrderRequirementVariable = cbVwPawOrderRequirement;
        } else {
          // from panel
          cbVwPawOrderRequirementVariable = cbVwPawOrder.cbVwPawOrderRequirements.find((req: ICbVwPawOrderRequirement) => {
            if (actionId) {
              return req.requirementType === CbRequirementType.MILESTONE && req.id === actionId;
            } else {
              return req.requirementType === CbRequirementType.MILESTONE;
            }
          });
        }

        if (this.modalStepFormOrders?.length > 0) {
          this.addModalStepFormOrders(ModalStepFormOrderType.isShowMilestoneFile);
        } else {
          this.modalStepFormOrders = [ModalStepFormOrderType.isShowMilestoneFile];
        }

        // for update Milestone
        if (reqParam) {
          const cbPawOrderMilestone: ICbPawOrderMilestone = reqParam;
          reqParamPartial = {
            title: cbPawOrderMilestone.name,
            labelPaymentId: '',
            requirementType: CbRequirementType.MILESTONE,
            currency: CbCurrency.IDR,
            status: CbCommonStatus.MILESTONE,
            statusDate: new Date(),
            requirementId: cbPawOrderMilestone.id,
          };

          this.cbPawOrderMilestone = cbPawOrderMilestone;
        }

        if (cbVwPawOrderRequirementVariable) this.cbVwPawOrderRequirement = cbVwPawOrderRequirementVariable;
        else this.cbVwPawOrderRequirement = reqParamPartial;
        this.cbVwPawOrder = cbVwPawOrder;

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowMilestoneFile' });
        return;

      case HANDLE_MDL_APPOINTMENT_OPEN:
        cbVwPawOrderRequirementVariable = {
          ...new CbVwPawOrderRequirement(),
          ...{
            requirementType: CbRequirementType.APPOINTMENT,
            status: CbCommonStatus.APPOINTMENT,
            buyerIdFrmServer: cbVwPawOrder.buyerId,
            partnerIdFrmServer: cbVwPawOrder.partnerId,
            buyer: {
              id: cbVwPawOrder.buyerId,
            },
            partner: {
              id: cbVwPawOrder.partnerId,
            },
            cbPawOrder: {
              id: cbVwPawOrder.id,
            },
            cbTextRestrict: new CbTextRestrict(),
          },
        };

        // reset old data
        this.cbTextRestrict = new CbTextRestrict();
        this.cbVwPawOrderRequirement = new CbVwPawOrderRequirement();

        this.cbVwPawOrderRequirement = cbVwPawOrderRequirementVariable;
        this.modalStepFormOrders = [ModalStepFormOrderType.isShowAppointmentLocation];
        this.cbVwPawOrder = cbVwPawOrder;

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowAppointmentLocation' });
        return;
      case HANDLE_MDL_APPOINTMENT_PREVIEW:
        let cbVwPawOrderRequirementAppointment: ICbVwPawOrderRequirement;

        if (cbVwPawOrderRequirement) {
          // from chat
          cbVwPawOrderRequirementAppointment = cbVwPawOrderRequirement;
        } else {
          // from panel
          cbVwPawOrderRequirementAppointment = cbVwPawOrder.cbVwPawOrderRequirements.find(
            (paw: ICbVwPawOrderRequirement) => paw.requirementType === CbRequirementType.APPOINTMENT && paw.id === actionId
          );
        }

        const cbTextRestrictAppointment = this.cbTextRestricts.find(e => e.id === cbVwPawOrderRequirementAppointment.descriptionId);
        if (cbTextRestrictAppointment) cbVwPawOrderRequirementAppointment.cbTextRestrict = cbTextRestrictAppointment;

        this.cbPawOrderRequirement = new CbPawOrderRequirement();
        this.cbVwPawOrderRequirement = cbVwPawOrderRequirementAppointment;
        this.cbVwPawOrder = cbVwPawOrder;
        this.modalStepFormOrders = [ModalStepFormOrderType.isShowAppointmentSummary];

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowAppointmentSummary' });
        return;
      case HANDLE_MDL_APPOINTMENT_UPDATE:
        this.isSavingPawOrderRequirement = true;

        reqParam = {
          ...reqParam,
          ...{
            isWfRunNeeded: true,
          },
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;

            if (reqParam.status === CbCommonStatus.ACCEPTED) {
              this.cbVwPawOrder = cbVwPawOrder;
              this.cbVwPawOrderRequirement = data;
              this.handleActionModal({ type: typeModal, isShowModal: 'isShowAppointmentReminder' });
            }
          } else {
            if (callback) callback({ isSavingLoader: isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        //still not close the  popup
        this.wfGetWfHistoryByOR(
          cbVwPawOrder.workflowName,
          cbVwPawOrder.id,
          reqParam,
          reqParam.status == CbCommonStatus.ACCEPTED ? WFKEY_I_ACCEPT_APPOINTMENT : WFKEY_I_DECLINE_APPOINTMENT
        )
          .then(wfHistory => {
            reqParam.wfHistory = wfHistory;
            this.cbPawOrderRequirementPartialUpdate(reqParam, typeModal, callbackLocal);
          })
          .catch(err => {
            console.error('error', err.response);
            this.isSavingPawOrderRequirement = false;
          });
        return;
      case HANDLE_MDL_APPOINTMENT_SET_REMINDER:
        this.isSavingPawOrderRequirement = true;

        const reminderValue = cbVwPawOrderRequirement.reminderValue;
        let reminderDate: Date = null;

        if (reminderValue === 'H_1' || reminderValue === 'H_3' || reminderValue === 'H_6') {
          reminderDate = dayjs(cbVwPawOrderRequirement.bookStartDate)
            .subtract(Number(reminderValue.split('H_')[1]), 'hour')
            .toDate();
        } else if (reminderValue === 'D_1' || reminderValue === 'D_2') {
          reminderDate = dayjs(cbVwPawOrderRequirement.bookStartDate)
            .subtract(Number(reminderValue.split('D_')[1]), 'hour')
            .toDate();
        }

        reqParam = {
          id: cbVwPawOrderRequirement.id,
          requirementType: <CbRequirementType>cbVwPawOrderRequirement.requirementType,
          cbPawOrder: {
            id: cbVwPawOrderRequirement.cbPawOrderId,
          },
          reminderValue: cbVwPawOrderRequirement.reminderValue,
          reminderDate,
          ...{
            isWfRunNeeded: true,
          },
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            this.modalStepFormOrders = [];
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });

            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        // still not close the  popup
        this.wfGetWfHistoryByOR(cbVwPawOrder.workflowName, cbVwPawOrder.id, reqParam, WFKEY_I_REMINDER_APPOINTMENT)
          .then(wfHistory => {
            reqParam.wfHistory = wfHistory;
            this.cbPawOrderRequirementPartialUpdate(reqParam, typeModal, callbackLocal);
          })
          .catch(err => {
            if (callback) callback({ isSavingLoader: false, data: null });
            this.isSavingPawOrderRequirement = false;

            console.error('error', err.response);
          });
        return;
      case HANDLE_SEND_ON_THE_WAY:
        this.cbVwPawOrder = cbVwPawOrder;

        req = {
          ...new CbPawOrderRequirement(),
          ...{
            requirementType: CbRequirementType.ON_MY_WAY,
            cbPawOrder: {
              ...new CbPawOrder(),
              ...{
                id: cbVwPawOrder.id,
                pawTitle: cbVwPawOrder.pawTitle,
                svcType: cbVwPawOrder.svcType,
                svcName: cbVwPawOrder.svcName,
                svcCategoryName: cbVwPawOrder.svcCategoryName,
              },
            },
            partner: {
              ...new User(),
              ...{
                id: cbVwPawOrder.partnerId,
              },
            },
            buyer: {
              ...new User(),
              ...{
                id: cbVwPawOrder.buyerId,
              },
            },
            status: CbCommonStatus.ON_THE_WAY,
          },
        };

        if (cbVwPawOrder.bookStartDate) {
          req.bookStartDate = dayjs(cbVwPawOrder.bookStartDate).toDate();
        }
        if (cbVwPawOrder.timeZone) {
          req.timeZone = cbVwPawOrder.timeZone;
        }
        if (cbVwPawOrder.bookEndDate) {
          req.bookEndDate = dayjs(cbVwPawOrder.bookEndDate).toDate();
        }
        if (cbVwPawOrder.toWorkLocation) {
          req.toWorkLocation = {
            ...new CbLocation(),
            ...{
              placeId: (<ICbVwPawOrder>cbVwPawOrder).toWorkLocation.placeId,
              address: (<ICbVwPawOrder>cbVwPawOrder).toWorkLocation.address,
            },
          };
        }

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            if (this.isMobile) this.isShowChatInfoPanel = false;

            if (callback) callback({ isSavingLoader, data });
            this.createConversation({ openChat: false, reqParam: data, type: typeModal });

            if (typeModal === TypeComponent.CHAT_MODAL) {
              this.refreshOrder({ orderId: cbVwPawOrder.id, isChatSkip: true, typeModal, isWithoutLoading: false });
            }
          } else {
            if (callback) callback({ isSavingLoader, data });
          }
        };

        this.createRequirement({
          cbVwPawOrder,
          req,
          callback: callbackLocal,
          isCreateMessageChatSkip,
          isRefreshOrderSkip: true,
          type: typeModal,
        });
        return;
      case HANDLE_REFRESH_ORDER:
        isChatSkip = false;

        this.refreshOrder({ orderId: cbVwPawOrder.id, reqId: cbVwPawOrderRequirement.id, isChatSkip, typeModal });
        return;

      case HANDLE_VIEW_MAP_TRACK:
      case HANDLE_BUYER_TRACK_ORDER:
      case HANDLE_CLOCK_IN_OPEN:
      case HANDLE_DETAIL_ORDER_CLOCK_IN_OPEN:
      case HANDLE_BUYER_DETAIL_ORDER_TRACK_ORDER:
      case HANDLE_DETAIL_ORDER_MAP_OPEN:
        this.cbVwPawOrder = cbVwPawOrder;
        this.isShowDetailOrderLocation = true;
        (<any>this.$root).$emit('hideFooter');

        if (
          action === HANDLE_DETAIL_ORDER_MAP_OPEN ||
          action === HANDLE_DETAIL_ORDER_CLOCK_IN_OPEN ||
          action === HANDLE_BUYER_DETAIL_ORDER_TRACK_ORDER
        ) {
          this.$router.push({ name: 'PDetailOrderLocation', params: { cbVwPawOrderId: cbVwPawOrder.id.toString() } });
        }

        return;
      case HANDLE_CLOCK_IN_SEND:
        this.cbVwPawOrder = cbVwPawOrder;

        req = {
          ...new CbPawOrderRequirement(),
          ...{
            requirementType: CbRequirementType.CLOCK_IN,
            cbPawOrder: {
              id: cbVwPawOrder.id,
              pawTitle: cbVwPawOrder.pawTitle,
              svcType: cbVwPawOrder.svcType,
              svcName: cbVwPawOrder.svcName,
              svcCategoryName: cbVwPawOrder.svcCategoryName,
            },
            partner: {
              id: cbVwPawOrder.partnerId,
            },
            buyer: {
              id: cbVwPawOrder.buyerId,
            },
            status: CbCommonStatus.CLOCK_IN,
          },
        };

        req.cbPawOrderFiles = this.clearFileBase64(cbPawOrderFiles);

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            if (this.isMobile) this.isShowChatInfoPanel = false;
            if (callback) callback({ isSavingLoader, data });

            this.createConversation({ openChat: false, reqParam: data, type: typeModal });

            if (typeModal === TypeComponent.CHAT_MODAL || typeModal === TypeComponent.DETAIL_ORDER_LOCATION) {
              this.refreshOrder({ orderId: cbVwPawOrder.id, isChatSkip: true, typeModal, isWithoutLoading: false });
            }
          } else {
            if (callback) callback({ isSavingLoader, data });
          }
        };

        this.createRequirement({
          cbVwPawOrder,
          req,
          callback: callbackLocal,
          isCreateMessageChatSkip,
          isRefreshOrderSkip: true,
          type: typeModal,
        });
        return;
      case HANDLE_CLOCK_OUT_OPEN:
        req = {
          requirementType: CbRequirementType.CLOCK_OUT,
          cbPawOrder: {
            id: cbVwPawOrder.id,
            pawTitle: cbVwPawOrder.pawTitle,
            svcType: cbVwPawOrder.svcType,
            svcName: cbVwPawOrder.svcName,
            svcCategoryName: cbVwPawOrder.svcCategoryName,
          },
          partner: {
            id: cbVwPawOrder.partnerId,
          },
          buyer: {
            id: cbVwPawOrder.buyerId,
          },
          status: CbCommonStatus.SERVICE_FINISHED,
        };

        this.cbPawOrderRequirement = req;
        this.cbVwPawOrder = cbVwPawOrder;

        this.handleActionModal({ type: typeModal, isShowModal: 'isShowUploadFieldClockOut' });
        return;
      case HANDLE_CLOCK_OUT_NOT_FINISHED:
        (<any>this.$root).$bvToast.toast(this.$t('cbGlobal.popUpClockOutWarning'), {
          toaster: 'b-toaster-top-center',
          variant: 'warning',
          solid: true,
          autoHideDelay: 5000,
        });
        return;
      case HANDLE_CLOCK_OUT_SEND:
        this.isSavingPawOrderRequirement = true;
        req = this.cbPawOrderRequirement;
        req.cbPawOrderFiles = this.clearFileBase64(cbPawOrderFiles);

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            if (this.isMobile) this.isShowChatInfoPanel = false;

            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });

            this.createConversation({ openChat: false, reqParam: data, type: typeModal });

            if (typeModal === TypeComponent.CHAT_MODAL || typeModal === TypeComponent.DETAIL_ORDER_LOCATION) {
              this.refreshOrder({ orderId: cbVwPawOrder.id, isChatSkip: true, typeModal, isWithoutLoading: false });
            }
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        this.createRequirement({
          cbVwPawOrder,
          req,
          callback: callbackLocal,
          isCreateMessageChatSkip,
          isRefreshOrderSkip: true,
          type: typeModal,
        });
        return;

      case HANDLE_BUYER_COMPLETED_ORDER_OPEN:
        this.cbVwPawOrder = cbVwPawOrder;
        this.cbVwPawOrderRequirement = cbVwPawOrderRequirement;

        if (cbVwPawOrder.svcType == CbSvcType.FIELD) {
          this.handleActionModal({ type: typeModal, isShowModal: 'isShowConfirmFieldCompleted' });
        } else if (cbVwPawOrder.svcType == CbSvcType.INSIDE) {
          this.handleActionModal({ type: typeModal, isShowModal: 'isShowConfirmationInsideCompleted' });
        }
        return;
      case HANDLE_BUYER_COMPLETED_ORDER_SEND:
        this.isSavingPawOrderRequirement = true;

        req = {
          requirementType: CbRequirementType.COMPLETED,
          status: CbCommonStatus.COMPLETED,
          buyerIdFrmServer: cbVwPawOrder.buyerId,
          partnerIdFrmServer: cbVwPawOrder.partnerId,
          requirementId: cbVwPawOrderRequirement.id,
          buyer: {
            id: cbVwPawOrder.buyerId,
          },
          partner: {
            id: cbVwPawOrder.partnerId,
          },
          cbPawOrder: {
            id: cbVwPawOrder.id,
            pawTitle: cbVwPawOrder.pawTitle,
            svcType: cbVwPawOrder.svcType,
            svcName: cbVwPawOrder.svcName,
            svcCategoryName: cbVwPawOrder.svcCategoryName,
          },
          progressProject: 100,
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            const res = data;
            let messages: any[] = [];
            const findIndex = this.cbVwPawOrders.findIndex(e => e.id == res.cbPawOrder.id);

            this.pCbVwPawOrderService()
              .find(+res.cbPawOrder.id, this.cancelToken ? this.cancelToken.token : null)
              .then(resPawOrder => {
                const tmpCbVwPawOrder: ICbVwPawOrder = { ...resPawOrder };
                this.cbVwPawOrder = { ...tmpCbVwPawOrder };
              })
              .catch(error => {
                console.error('error', error.response);
              })
              .finally(() => {
                if (findIndex >= 0) {
                  // delete cbVwPawOrder in cbVwPawOrders
                  Vue.delete(this.cbVwPawOrders, findIndex);
                }

                // update chat message
                this.$root.$emit('chat::getVariable', 'messages', value => {
                  messages = value;
                });
                const findIndexMessage = messages.findIndex(e => e?.cbVwPawOrderRequirement?.id == cbVwPawOrderRequirement.id);
                const tmpMessage = { ...messages[findIndexMessage] };
                if (tmpMessage?.cbVwPawOrderRequirement?.status) {
                  tmpMessage.cbVwPawOrderRequirement.status = CbCommonStatus.COMPLETED;
                  Vue.set(messages, findIndexMessage, tmpMessage);
                  this.$root.$emit('chat::changeValue', 'messages', messages);
                }

                if (callback) callback({ isSavingLoader, data });
                this.isSavingPawOrderRequirement = isSavingLoader;

                this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });

                this.$root.$emit('app::changeValue', 'cbVwPawOrder', this.cbVwPawOrder);
                this.$root.$emit('appModals::changeValue', 'visibleReview', true);
              });
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        this.createRequirement({ cbVwPawOrder, req, callback: callbackLocal, isCreateMessageChatSkip: true });
        return;

      case HANDLE_BUYER_CANCEL_ORDER_OPEN:
        this.cbVwPawOrder = cbVwPawOrder;

        if (
          (cbVwPawOrder.orderStatus === CbCommonStatus.WAITING_PAYMENT || cbVwPawOrder.orderStatus === CbCommonStatus.BOOKED) &&
          dayjs(cbVwPawOrder.bookStartDate).isBefore(dayjs()) &&
          dayjs(cbVwPawOrder.bookStartDate).diff(dayjs(), 'hour') > 24
        ) {
          // ? tampilkan modal cancel order
          this.handleActionModal({ type: typeModal, isShowModal: 'isShowConfirmCancelWithRefund' });
        }

        if (
          cbVwPawOrder.orderStatus === CbCommonStatus.ON_THE_WAY ||
          (dayjs(cbVwPawOrder.bookStartDate).isBefore(dayjs()) && dayjs(cbVwPawOrder.bookStartDate).diff(dayjs(), 'hour') < 24) ||
          dayjs(cbVwPawOrder.bookStartDate).isAfter(dayjs())
        ) {
          // ? tampilkan modal cancel order duit ke freelancer
          this.handleActionModal({ type: typeModal, isShowModal: 'isShowConfirmCancelNoRefund' });
        }

        if (cbVwPawOrder.orderStatus === CbCommonStatus.OVERDUE) {
          this.handleActionModal({ type: typeModal, isShowModal: 'isShowConfirmCancelWithRefund' });
        }

        if (cbVwPawOrder.orderStatus === CbCommonStatus.LATE || cbVwPawOrder.orderStatus === CbCommonStatus.LATE_ON_TRACK) {
          this.handleActionModal({ type: typeModal, isShowModal: 'isShowConfirmCancelNoRefund' });
        }
        return;
      case HANDLE_BUYER_CANCEL_ORDER_SEND:
        this.isSavingPawOrderRequirement = true;

        req = {
          requirementType: CbRequirementType.CANCELLED,
          status: CbCommonStatus.CANCELLED,
          buyerIdFrmServer: this.cbVwPawOrder.buyerId,
          partnerIdFrmServer: this.cbVwPawOrder.partnerId,
          buyer: {
            id: this.cbVwPawOrder.buyerId,
          },
          partner: {
            id: this.cbVwPawOrder.partnerId,
          },
          cbPawOrder: {
            id: this.cbVwPawOrder.id,
          },
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            const foundIndex = this.cbVwPawOrders.findIndex(e => e.id == this.cbVwPawOrder.id);
            Vue.delete(this.cbVwPawOrders, foundIndex);
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            this.isSavingPawOrderRequirement = isSavingLoader;
          } else {
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        if (cbVwPawOrder.workflowName === 'OR_INSIDE_WORKER') {
          wfKeyType = '';
        } else if (cbVwPawOrder.workflowName === 'OR_FIELD_WORKER') {
          if (
            (cbVwPawOrder?.wfHistoriesKeyName?.includes('LATE_1') && !cbVwPawOrder?.wfHistoriesKeyName?.includes('LATE_ON_TRACK_1')) ||
            cbVwPawOrder.keyName === 'LATE_1'
          ) {
            wfKeyType = WFKEY_CHANGE_STATUS_FROM_LATE;
          } else if (cbVwPawOrder?.wfHistoriesKeyName?.includes('LATE_ON_TRACK_1') || cbVwPawOrder.keyName === 'LATE_ON_TRACK_1') {
            wfKeyType = WFKEY_CHANGE_STATUS_FROM_LATE_ON_TRACK;
          }
        }

        this.createRequirement({
          cbVwPawOrder,
          req,
          callback: callbackLocal,
          isCreateMessageChatSkip: true,
          type: typeModal,
          WFKEY: wfKeyType,
        });
        return;
      case HANDLE_BUYER_EXTEND_ORDER_OPEN:
        this.cbVwPawOrder = cbVwPawOrder;
        this.handleActionModal({ type: typeModal, isShowModal: 'isShowExtendTime' });
        return;
      case HANDLE_BUYER_EXTEND_ORDER_SEND:
        this.isSavingPawOrderRequirement = true;

        reqParam = {
          ...reqParam,
          cbPawOrder: {
            id: cbVwPawOrder.id,
            pawTitle: cbVwPawOrder.pawTitle,
            svcType: cbVwPawOrder.svcType,
            svcName: cbVwPawOrder.svcName,
            svcCategoryName: cbVwPawOrder.svcCategoryName,
          },
          partner: {
            id: cbVwPawOrder.partnerId,
          },
          buyer: {
            id: cbVwPawOrder.buyerId,
          },
          status: CbCommonStatus.EXTENDED_DELIVERY_TIME,
          requirementType: CbRequirementType.EXTENDED_DELIVERY_TIME,
        };

        callbackLocal = ({ isSavingLoader, data }) => {
          if (data) {
            this.modalStepFormOrders = [];
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });

            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          } else {
            if (callback) callback({ isSavingLoader, data });
            this.isSavingPawOrderRequirement = isSavingLoader;
          }
        };

        this.createRequirement({
          cbVwPawOrder,
          req: reqParam,
          callback: callbackLocal,
          isCreateMessageChatSkip: true,
          isRefreshOrderSkip: false,
        });
        return;
      case HANDLE_VIEW_PROFILE:
        this.$router.push({ name: 'PProfilePublic', params: { id: userId }, query: { publicUserType } });
        return;
      case HANDLE_VIEW_DETAIL_ORDER:
        this.$router.push({ name: 'PDetailOrder', params: { cbVwPawOrderId: cbVwPawOrder.id.toString() } });
        return;
      case HANDLE_CREATE_CONVERSATION:
        this.createConversation({ openChat: true });
        return;

      case HANDLE_BUYER_REVIEW_OPEN:
        this.$root.$emit('app::changeValue', 'cbVwPawOrder', cbVwPawOrder);
        this.$root.$emit('appModals::changeValue', 'visibleReview', true);
        return;

      case HANDLE_DOWNLOAD_INVOICE:
        // invoiceType: string, labelPaymentId: any
        let invoiceType = '';

        if (invoiceParam.externalId) {
          if (invoiceParam.externalId.includes('CBAO')) {
            invoiceType = 'PAYMENT_RECEIPT_ORDER_PAW';
          } else {
            invoiceType = 'PAYMENT_RECEIPT_PG';
          }
        }

        this.pRptMetaTableService()
          .download(invoiceType, invoiceParam.externalId, this.currentLanguage.toUpperCase())
          .catch(error => {
            console.log('FAILED to Download PDF');
            console.error('error', error.response);
          })
          .finally(() => {});
        break;
      case HANDLE_VA_DETAIL_OPEN:
        this.payDetailVA = payDetailVA;
        this.handleActionModal({ type: typeModal, isShowModal: 'visibleVA' });
        return;
      case HANDLE_CC_DETAIL_OPEN:
        this.initXenditCreditIframeCallback(xenditCCUrl, typeModal);
        return;
    }
  }

  public handleActionModal({ type, isShowModal, data }: { type?: string; isShowModal?: string; data?: any }) {
    switch (type) {
      case TypeComponent.CHAT_MODAL:
        if (isShowModal === 'closeAll') {
          (<any>this.$root).$emit('mdl::closeAll');
          return;
        }

        (<any>this.$root).$emit('mdl::changeValue', isShowModal, true);
        return;
      case TypeComponent.DETAIL_ORDER_MODAL:
        if (isShowModal === 'closeAll') {
          (<any>this.$root).$emit('domdl::closeAll');
          return;
        }

        (<any>this.$root).$emit('domdl::changeValue', isShowModal, true);
        return;
      case TypeComponent.PAW_DETAIL_MODAL:
        (<any>this.$root).$emit('app::modalPawDetail', data);
        return;
    }
  }

  public addModalStepFormOrders(step: ModalStepFormOrderType) {
    // FOR MODALS BACK
    if (this.modalStepFormOrders && this.modalStepFormOrders.length > 0) {
      const tmpModalStepFormOrders: ModalStepFormOrderType[] = [...this.modalStepFormOrders];

      tmpModalStepFormOrders.push(step);
      if (tmpModalStepFormOrders.length > 0) {
        this.modalStepFormOrders = tmpModalStepFormOrders;
      }
    }
  }

  public created() {
    (<any>this.$root).$off('opc::invokeOrderByBuyerAndPartner');
    (<any>this.$root).$on('opc::invokeOrderByBuyerAndPartner', (users: any[]) => {
      this.resetAll();
      this.loadingComponent = new LoadingComponent();
      if (users?.length > 0) {
        const find = users.find(e => e.id !== this.currentUser.id);
        if (find?.id) {
          this.otherUserId = find.id;
        }
      }

      if (this.otherUserId) {
        this.findCbVwUser([this.otherUserId]);
        this.retrieveCbVwPawOrdersProgressOnly({ isOnlyFirstLoading: true });
      }
    });

    (<any>this.$root).$off('opc::actionHandler');
    (<any>this.$root).$on('opc::actionHandler', (obj: any, callback?: any) => {
      this.actionHandler(obj, callback);
    });

    (<any>this.$root).$off('opc::changeFunction');
    (<any>this.$root).$on('opc::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      switch (functionName) {
        case 'openPawOffer':
          this.openPawOffer();
          return;
        case 'sendPawOffer':
          this.sendPawOffer(param1, param2);
          return;
        case 'createRequirement':
          this.createRequirement({ cbVwPawOrder: param1, req: param2, callback: param3 });
          return;
        case 'cancelRequest':
          this.cancelRequest();
          return;
        case 'checkPaymentVa':
          this.checkPaymentVa(param1);
          return;
        case 'cbPawOrderRequirementPartialUpdate':
          this.cbPawOrderRequirementPartialUpdate(param1);
          return;
        case 'retrieveBgWatermark':
          this.retrieveBgWatermark();
          return;
        case 'saveChatMessageRequirement':
          this.saveChatMessageRequirement(param1, param2);
          return;
        case 'refreshCbVwPawOrder':
          return;
        case 'sendClockOut':
          this.sendClockOut({ cbVwPawOrder: param1, req: param2, cbAttachmentRestrictLocationPhoto: param3 });
          return;
        case 'initXenditCreditIframeCallback':
          this.initXenditCreditIframeCallback(param1);
          return;
        case 'showModalVA':
          this.showModalVA(param1);
          return;
        case 'findCbTextNoAuths':
          this.findCbTextNoAuths(param1, param2);
          return;
        default:
          this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
          return;
      }
    });

    (<any>this.$root).$off('opc::changeValue');
    (<any>this.$root).$on('opc::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });

    (<any>this.$root).$off('opc::getVariable');
    (<any>this.$root).$on('opc::getVariable', (fieldName: string, callback: any) => {
      callback(this[fieldName]);
    });
    (<any>this.$root).$off('opc::findCbVwUserById');
    (<any>this.$root).$on('opc::findCbVwUserById', (users: any[]) => {
      this.resetAll();
      this.findCbVwUser(users);
    });

    (<any>this.$root).$off('opc::cbPawOrderRequirementPartialUpdate');
    (<any>this.$root).$on('opc::cbPawOrderRequirementPartialUpdate', (partial: ICbPawOrderRequirement) => {
      this.cbPawOrderRequirementPartialUpdate(partial);
    });

    (<any>this.$root).$off('opc::sendOnMyWay');
    (<any>this.$root).$on(
      'opc::sendOnMyWay',
      (cbPawOrderRequirement: ICbPawOrderRequirement, cbVwPawOrder: ICbPawOrder, event4wf, callback?: any) => {
        this.sendOnMyWay(cbPawOrderRequirement, cbVwPawOrder, event4wf, callback);
      }
    );

    (<any>this.$root).$off('opc::openClock');
    (<any>this.$root).$on('opc::openClock', (cbVwPawOrder: ICbVwPawOrder, type: string) => {
      this.openClock(cbVwPawOrder, type);
    });

    (<any>this.$root).$off('opc::receiveUpdateFromSocket');
    (<any>this.$root).$on('opc::receiveUpdateFromSocket', (msg: ISocketMessage) => {
      if (msg.externalEntity == 'CbVwPawOrder') {
        let orderId = null;
        let reqId = null;
        let refReqIdOrOfferingReqId = null; // from parent project delivery or requirement type/ offering
        let refReqIdOrOfferingReqId2 = null; // from parent project delivery or requirement type/ offering
        const isChatSkip = false;

        console.log('order-paw-controller - message: socket "CB_PAW_ORDER_PAID" CbVwPawOrder', msg);
        console.log('order-paw-controller - message socket: msg.externalId', msg.externalId);

        if (msg.externalId.includes('::')) {
          orderId = +msg.externalId.split('::')[0];
          reqId = +msg.externalId.split('::')[1];

          if (msg.externalId.split('::')[2] && msg.externalId.split('::')[2] !== '') {
            refReqIdOrOfferingReqId = +msg.externalId.split('::')[2];
          }
          if (msg.externalId.split('::')[3] && msg.externalId.split('::')[3] !== '') {
            refReqIdOrOfferingReqId2 = +msg.externalId.split('::')[3];
          }
        }

        if (refReqIdOrOfferingReqId) {
          console.log('order-paw-controller - message socket: refReqIdOrOfferingReqId', refReqIdOrOfferingReqId);
          this.refreshOrder({
            orderId,
            reqId: refReqIdOrOfferingReqId,
            isChatSkip,
            typeModal: TypeComponent.CHAT_MODAL,
            isWidgetSkip: true,
          });
        }

        if (refReqIdOrOfferingReqId2) {
          console.log('order-paw-controller - message socket: refReqIdOrOfferingReqId2', refReqIdOrOfferingReqId2);
          this.refreshOrder({
            orderId,
            reqId: refReqIdOrOfferingReqId2,
            isChatSkip,
            typeModal: TypeComponent.CHAT_MODAL,
            isWidgetSkip: true,
          });
        }

        // for refresh active order
        this.refreshOrder({ orderId, reqId, isChatSkip, typeModal: TypeComponent.CHAT_MODAL, isWidgetSkip: false });
      }

      if (msg.externalEntity == 'CbPawPreOrder') {
        if (msg.externalId) {
          console.log('order-paw-controller - message socket: CbPawPreOrder, msg.externalId ', msg.externalId);
          this.refreshChatMessagePawPreOrdersAfterPayment([Number(msg.externalId)]);
        }
      }

      if (msg.externalEntity == 'CbVwAuctionProjectPreOffer') {
        this.retrieveCbVwAuctionProjectPreOffer([Number(msg.externalId)]);
      }

      // history requirement from cancel-workflow project delivery send and clock out send
      if (msg.externalEntity == 'WfHistory') {
        console.log('order-paw-controller - message socket: "CB_PAW_ORDER_PAID" WfHistory ', msg);
        console.log('order-paw-controller - message socket: msg.externalId socket ', msg.externalId);
        if (msg.externalId) {
          this.$root.$emit('chtex::refreshDataMessageByExternalId', msg.externalId);
        }
      }

      this.setLogReceiveUpdateFromSocket(msg.externalEntity, msg.externalId);
    });

    (<any>this.$root).$off('op::isShowChatInfoPanel');
    (<any>this.$root).$on('op::isShowChatInfoPanel', value => {
      this.isShowChatInfoPanel = value;
    });
    (<any>this.$root).$off('op::isShowChatInfoPanelShow');
    (<any>this.$root).$on('op::isShowChatInfoPanelShow', (callBack: (value: boolean) => void) => {
      callBack(this.isShowChatInfoPanel);
    });

    if (this.currentUser?.loginType == CbLoginType.PARTNER) {
      this.retrieveCbVwUserSchedulesCurrent();
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public setLogReceiveUpdateFromSocket(entity, externalId) {
    const cbLog: ICbLog = {
      cbLogType: CbLogType.LOG,
      valueHit: `${entity}::push/socket/${externalId}`,
    };

    this.pCbLogService()
      .create(cbLog)
      .catch(err => console.error('error', err.response));
  }

  public findCbVwUser(users: any[]) {
    this.loadingComponent = new LoadingComponent();
    this.isFetchingCbVwUser = true;

    let userId = '';

    if (users?.length > 0) {
      const find = users.find(user => {
        if (user?.id) {
          return user.id !== this.currentUser.id;
        } else {
          return user !== this.currentUser.id;
        }
      });

      if (find?.id) userId = find.id;
      else userId = find;
    }

    if (userId) {
      this.findConversationCbVwUser(userId);
    }
  }

  public findConversationCbVwUser(id) {
    this.pCbVwUserService()
      .find(id, this.cancelToken ? this.cancelToken.token : null)
      .then(res => {
        this.cbVwUser = { ...res };
        this.cbVwPawOrder.cbVwUser = { ...res };

        this.findCbVwUserSummary();

        if (this.currentUser.id === this.cbVwPawOrder.buyerId) {
          this.retrieveCbVwMedalsByUserId(this.cbVwUser.id);
          this.processAssignUserLvl(this.cbVwUser.id);
        }

        if (this.cbVwUser.defaultSvcType == CbSvcType.FIELD) {
          this.retrieveCbVwUserSchedules();
          this.retrieveCbVwUserHibernationSchedules();
        }
      })
      .catch(error => {
        this.isFetchingCbVwUser = false;
        console.error('error ', error.response);
      });
  }

  public findCbVwUserSummary() {
    this.pCbVwUserSummaryService()
      .find(this.cbVwUser.id)
      .then(res => {
        this.isFetchingCbVwUser = false;
        this.cbVwUserSummary = { ...res };
      })
      .catch(error => {
        this.isFetchingCbVwUser = false;
        console.error('error ', error.response);
      });
  }

  public processAssignUserLvl(userId: string) {
    const tmpPartnerLevel: ICbVwUserPartnerLevel = this.sessionCbVwUserPartnerLevel().find(e => e.userId === userId);
    if (tmpPartnerLevel) {
      this.cbVwUser.userLvl = tmpPartnerLevel.userLvl.toString();

      if (this.cbVwPawOrder) {
        this.cbVwPawOrder.cbVwUser.userLvl = tmpPartnerLevel.userLvl.toString();
        (<any>this.$root).$emit('do::setCbVwPawOrder', this.cbVwPawOrder);
      }

      return this.cbVwUser.userLvl;
    }

    const params: any = {
      'userId.equals': userId,
    };
    this.pCbVwUserPartnerLevelService()
      .retrieve(params)
      .then(res => {
        if (res?.data?.length > 0) {
          const cbVwUserPartnerLevel: ICbVwUserPartnerLevel = Object.assign({}, res.data[0]);
          this.cbVwUser.userLvl = cbVwUserPartnerLevel.userLvl?.toString();

          if (this.cbVwPawOrder) {
            this.cbVwPawOrder.cbVwUser.userLvl = cbVwUserPartnerLevel.userLvl?.toString();
            (<any>this.$root).$emit('do::setCbVwPawOrder', this.cbVwPawOrder);
          }

          if (cbVwUserPartnerLevel) {
            // PUSH TO SESSION STORAGE
            const tmpUserLevels: ICbVwUserPartnerLevel[] = [...this.sessionCbVwUserPartnerLevel()];
            const foundLevel = tmpUserLevels.findIndex(e => e.userId == this.cbVwUser.id);
            if (!foundLevel) {
              tmpUserLevels.push(cbVwUserPartnerLevel);
            } else {
              Vue.set(tmpUserLevels, foundLevel, cbVwUserPartnerLevel);
            }

            sessionStorage.setItem(SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL, JSON.stringify(tmpUserLevels));
          }
        }
      });
  }

  public sessionCbVwUserPartnerLevel(): ICbVwUserPartnerLevel[] {
    const stringCbVwUserPartnerLevels: string = sessionStorage.getItem(SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL);
    const tmpCbVwUserPartnerLevels: ICbVwUserPartnerLevel[] = JSON.parse(stringCbVwUserPartnerLevels);
    if (tmpCbVwUserPartnerLevels && tmpCbVwUserPartnerLevels.length > 0) {
      return tmpCbVwUserPartnerLevels;
    }
    return [];
  }

  public showInfoPanel() {
    this.isShowChatInfoPanel = true;
  }

  public closeShowInfoPanel() {
    this.isShowChatInfoPanel = false;
  }

  public closeShowDetailOrderLocation() {
    this.isShowDetailOrderLocation = false;
  }

  public retrieveCbVwMedalsByUserId(userId: string) {
    const foundFilter: ICbVWMedal[] = this.getSessionCbVwMedals.filter(e => e.userId == userId);
    let cbVwMedalsSession: ICbVWMedal[] = [];

    if (foundFilter.length > 0) {
      for (const item of foundFilter) {
        const foundIndex = cbVwMedalsSession.findIndex(e => e.id == item.id);
        if (foundIndex < 0) {
          cbVwMedalsSession.push(item);
        }
      }
      this.cbVwMedals = cbVwMedalsSession;
      return;
    }

    if (!userId) return;

    const params: any = {
      'userId.equals': userId,
    };

    this.pCbVwMedalService()
      .retrieve(params)
      .then(res => {
        cbVwMedalsSession = [...res.data];
        this.cbVwMedals = [...res.data];

        this.setSessionStorageCbVwMedals(cbVwMedalsSession);
      })
      .catch(() => {
        cbVwMedalsSession = [];
      });
  }

  public get getSessionCbVwMedals(): ICbVWMedal[] {
    const stringCbVwMedal: string = sessionStorage.getItem(SESSION_STORAGE_CB_VW_MEDAL);
    const tmpCbVwMedals: ICbVWMedal[] = JSON.parse(stringCbVwMedal);
    if (tmpCbVwMedals && tmpCbVwMedals.length > 0) {
      return tmpCbVwMedals;
    }
    return [];
  }

  public setSessionStorageCbVwMedals(cbVwMedals: ICbVWMedal[]) {
    const tmpCbVwMedals: ICbVWMedal[] = [...this.getSessionCbVwMedals];

    for (const item of cbVwMedals) {
      const foundIndex = tmpCbVwMedals.findIndex(e => e.id == item.id);

      if (foundIndex < 0) {
        tmpCbVwMedals.push(item);
      } else {
        Vue.set(tmpCbVwMedals, foundIndex, item);
      }
    }
    sessionStorage.setItem(SESSION_STORAGE_CB_VW_MEDAL, JSON.stringify(tmpCbVwMedals));
  }

  public async retrieveCbVwPawOrderRequirements(pawOrderIds: number[]) {
    this.isFetchingVwPawOrderRequirements = true;

    const params: any = {
      'cbPawOrderId.in': pawOrderIds.filter(this.globalService().onlyUniqueIds),
      size: 9999999,
    };
    await this.pCbVwPawOrderRequirementService()
      .retrieve(params, this.cancelToken ? this.cancelToken.token : null)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          const cbTextRestrictIds: number[] = [];
          const cbAttachmentRestrictIds: number[] = [];
          for (const item of <ICbVwPawOrderRequirement[]>res.data) {
            const foundIndex = this.cbVwPawOrderRequirements.findIndex(e => +e.id == item.id);
            if (foundIndex < 0) {
              this.cbVwPawOrderRequirements.push(item);
            } else {
              Vue.set(this.cbVwPawOrderRequirements, foundIndex, item);
            }
            if (item?.descriptionId != null && item?.requirementType == CbRequirementType.REQUIREMENT) {
              cbTextRestrictIds.push(item.descriptionId);
            }
          }
          if (cbTextRestrictIds?.length > 0) {
            await this.retrieveCbTextRestricts(cbTextRestrictIds);
          }
        }
      })
      .finally(() => {
        this.isFetchingVwPawOrderRequirements = false;
      });
  }

  public retrieveCbVwUserSchedules() {
    this.isFetchingCbVwUserSchedules = true;
    const param: any = {
      'partnerId.equals': this.cbVwUser.id,
      'orderStatus.in': [
        CbCommonStatus.WAITING_PAYMENT,
        CbCommonStatus.BOOKED,
        CbCommonStatus.ON_THE_WAY,
        CbCommonStatus.ON_PROGRESS,
        CbCommonStatus.LATE,
      ],
    };

    this.pCbVwUserScheduleService()
      .retrieve(param, this.cancelToken ? this.cancelToken.token : null)
      .then(res => {
        this.cbVwUserSchedules = res.data;
      })
      .catch(error => {
        console.error('error ', error.response);
      })
      .finally(() => {
        this.isFetchingCbVwUserSchedules = false;
      });
  }

  public retrieveCbVwUserHibernationSchedules(): void {
    this.isFetchingCbVwUserSchedules = true;
    const param: any = {
      'partnerId.equals': this.cbVwUser.id,
      'scheduleType.equals': CbCommonStatus.OFF_DUTY,
    };

    this.pCbVwUserScheduleService()
      .retrieve(param)
      .then(res => {
        this.cbVwUserHibernationSchedules = res.data;
      })
      .catch(error => {
        console.error('error ', error.response);
      })
      .finally(() => {
        this.isFetchingCbVwUserSchedules = false;
      });
  }

  public retrieveCbVwUserSchedulesCurrent() {
    this.isFetchingCbVwUserSchedules = true;
    const param: any = {
      'partnerId.equals': this.currentUser.id,
      'orderStatus.in': [
        CbCommonStatus.WAITING_PAYMENT,
        CbCommonStatus.BOOKED,
        CbCommonStatus.ON_THE_WAY,
        CbCommonStatus.ON_PROGRESS,
        CbCommonStatus.LATE,
      ],
    };

    this.pCbVwUserScheduleService()
      .retrieve(param, this.cancelToken ? this.cancelToken.token : null)
      .then(res => {
        this.currentCbVwUserSchedules = [...res.data];
      })
      .catch(error => {
        console.error('error ', error.response);
      })
      .finally(() => {
        this.isFetchingCbVwUserSchedules = false;
      });
  }

  private async retrieveCbTextRestrict(textId, type?: TypeComponent) {
    if (!textId) return;

    const cbTextRestrictsStore = [...this.cbTextRestricts];
    let foundIdx = -1;

    foundIdx = cbTextRestrictsStore.findIndex(x => x.id == textId);
    if (foundIdx < 0) {
      this.findCbTextRestrictById(textId).then((res: ICbTextRestrict) => {
        const findIndex = cbTextRestrictsStore.findIndex(e => +e.id === +res.id);
        if (findIndex < 0) {
          cbTextRestrictsStore.push(res);
        } else {
          Vue.set(cbTextRestrictsStore, findIndex, res);
        }

        this.$store.commit('cbTextRestricts', cbTextRestrictsStore);
        this.updateDescription(textId, res, type);
      });
    } else {
      this.updateDescription(textId, cbTextRestrictsStore[foundIdx], type);
    }
  }

  public async retrieveCbTextRestricts(ids: number[]) {
    return new Promise(async resolve => {
      for (const id of ids) {
        this.retrieveCbTextRestrict(id);
      }
      resolve(true);
    });
  }

  public findCbTextRestrictById(id: number) {
    return new Promise(resolve => {
      this.pCbTextRestrictService()
        .find(id, this.cancelToken ? this.cancelToken.token : null)
        .then(res => {
          resolve(res);
        });
    });
  }
  public async findCbTextNoAuths(id: number, cb?: any) {
    const cbTextNoAuthsStore = [...this.cbTextNoAuths];
    const found = this.cbTextNoAuths.find(e => e.id == id);
    if (found) {
      cb(found);
      return;
    }
    if (!id || id == 0) {
      return;
    }
    this.pCbTextNoAuthService()
      .find(id, this.cancelToken ? this.cancelToken.token : null)
      .then(res => {
        const foundIndex = cbTextNoAuthsStore.findIndex(e => +e.id == +res.id);
        cb(res);

        if (foundIndex < 0) {
          cbTextNoAuthsStore.push(res);
        } else {
          Vue.set(cbTextNoAuthsStore, foundIndex, res);
        }
        this.$store.commit('cbTextNoAuths', cbTextNoAuthsStore);
      });
  }

  public openAddExtras(cbPawOrder: ICbPawOrder) {
    this.cbPawOrder = Object.assign({}, this.cbPawOrder, cbPawOrder);
    const findRequirement = this.cbPawOrder.cbPawOrderRequirements.find(e => e.requirementType == CbRequirementType.REQUIREMENT);
    if (findRequirement) {
      this.cbPawOrderRequirement = Object.assign({}, this.cbPawOrderRequirement, findRequirement);
    }

    this.cbPawOrderRequirement.title = cbPawOrder.pawTitle;
    this.cbPawOrderExtras = this.cbPawOrder.cbPawOrderExtras;
    this.cbPawOrderMilestones = this.cbPawOrder.cbPawOrderMilestones;
    (<any>this.$root).$emit('mdl::changeValue', 'isShowAddExtras', true);
  }

  public retrieveCbVwPawPkgAggFromVwPawId(id: number, pkgType: CbPkgType, cb?: (cbPawOrder: ICbPawOrder) => void) {
    if (!id || !pkgType) {
      cb(new CbPawOrder());
    }

    const filterPkgs: ICbVwPawPkgAgg[] = this.cbVwPawPkgAggs.filter(e => e.cbPawId == id && e.pkgType == pkgType);
    if (filterPkgs?.length > 0) {
      cb(this.assignPkgsToPawOrder(filterPkgs));
    } else {
      const params: any = {
        'cbPawId.equals': id,
        'pkgType.equals': pkgType,
      };
      this.pCbVwPawPkgAggService()
        .retrieve(params)
        .then(res => {
          if (res?.data?.length > 0) {
            const tmpCbVwPawPkgAgg: ICbVwPawPkgAgg[] = [...res.data];

            cb(this.assignPkgsToPawOrder(tmpCbVwPawPkgAgg));
            this.cbVwPawPkgAggs = [...this.cbVwPawPkgAggs, ...tmpCbVwPawPkgAgg];
          }
        })
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        });
    }
  }

  public assignPkgsToPawOrder(pkgs: ICbVwPawPkgAgg[]) {
    const cbPawOrder: ICbPawOrder = new CbPawOrder();

    for (const pkg of pkgs) {
      if (pkg.coreType === CbPkgCoreType.PRICE) {
        cbPawOrder.currency = <CbCurrency>pkg.valueIdfier;
        cbPawOrder.projectValueAmount = +pkg.value;
      }
      if (pkg.coreType === CbPkgCoreType.DELIVERY_TIME) {
        cbPawOrder.deliveryTimeType = pkg.valueIdfier;
        cbPawOrder.deliveryTime = +pkg.value;
      }
      if (pkg.coreType === CbPkgCoreType.DESCRIPTION) {
        this.findCbTextNoAuths(pkg.cbTextNoAuthId, (cbTextNoAuth: ICbTextAuth) => {
          cbPawOrder.pawPkgDescription = cbTextNoAuth?.content;
        });
      }
      if (pkg.coreType === CbPkgCoreType.REVISION) {
        cbPawOrder.revision = +pkg.value;
      }
      if (pkg.coreType === CbPkgCoreType.NONE || pkg.coreType === CbPkgCoreType.CUSTOM) {
        const foundExist = cbPawOrder.cbPawOrderIncludes.find(e => e.value.trim().toLowerCase() == pkg.value.trim().toLowerCase());
        if (!foundExist) {
          cbPawOrder.cbPawOrderIncludes.push({ value: pkg.value });
        }
      }
    }

    return cbPawOrder;
  }

  public async retrieveCbVwAuctionProjectPreOffer(ids: number[]) {
    if (ids?.length > 0) {
      const params: any = {
        'id.in': ids.filter(this.globalService().onlyUniqueIds),
      };
      await this.pCbVwAuctionProjectPreOfferService()
        .retrieve(params, this.cancelToken ? this.cancelToken.token : null)
        .then(res => {
          if (res.data && res.data.length > 0) {
            for (const item of res.data) {
              const findIndex = this.cbVwAuctionProjectPreOffers.findIndex(e => Number(e.id) == Number(item.id));
              if (findIndex < 0) {
                this.cbVwAuctionProjectPreOffers.push(item);
              } else {
                Vue.set(this.cbVwAuctionProjectPreOffers, findIndex, item);
              }
            }
          }
        })
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        });
    }
  }

  public async uploadCbPawOrderFiles(cbPawOrderFiles: ICbPawOrderFile[], cbVwPawOrder: ICbVwPawOrder, callback?: any): Promise<void> {
    if (this.loadingComponent.index) this.loadingComponent.visible = true;
    this.cbVwPawOrder = cbVwPawOrder; // for callbackRejectPawOrderFile

    let progressUploadsInit: IProgressUpload[] = [];
    let progressUploadFindByEmit = new ProgressUpload();
    let progressUploadsFindByEmit: IProgressUpload[] = [];

    if (cbPawOrderFiles?.length > 0) {
      // start setting upload
      this.$root.$emit('app::getProgressUploadDownload', getProgressUploads => {
        if (getProgressUploads.length > 0) {
          progressUploadsInit = [...progressUploadsInit, ...getProgressUploads];
        }
      });

      for (const cbPawOrderFile of cbPawOrderFiles) {
        const progressUpload = new ProgressUpload();
        progressUpload.title = cbPawOrderFile.fileName;
        progressUpload.localUrl = cbPawOrderFile.cbAttachmentRestrict.localUrl;
        progressUpload.progress = 0;
        progressUpload.status = null;
        progressUploadsInit.push(progressUpload);
      }

      // end setting upload
      this.$root.$emit('app::progressUploadDownload', progressUploadsInit);
      this.$root.$emit('app::showProgressUploadDownload', true);

      for (const cbPawOrderFile of cbPawOrderFiles) {
        //thumbnail
        let base64DataThumbnail;
        let refChtFileThumbnailId;

        if (cbPawOrderFile.cbAttachmentRestrict.thumbnail) {
          base64DataThumbnail = cbPawOrderFile.cbAttachmentRestrict.thumbnail.substring(
            cbPawOrderFile.cbAttachmentRestrict.thumbnail.indexOf('base64,') + 'base64,'.length
          );

          const cbAttachmentRestrict = new CbAttachmentRestrict();
          cbAttachmentRestrict.isThumbnail = true;
          cbAttachmentRestrict.att = '';
          cbAttachmentRestrict.attContentType = 'image/png';
          cbAttachmentRestrict.extension = 'png';
          cbAttachmentRestrict.name = cbPawOrderFile.cbAttachmentRestrict.name;
          cbAttachmentRestrict.isActive = true;
          cbAttachmentRestrict.attSize = base64DataThumbnail.length;
          cbAttachmentRestrict.blob = this.dataURItoBlob(cbPawOrderFile.cbAttachmentRestrict.thumbnail);
          cbAttachmentRestrict.localUrl = this.base64ToBlobString(base64DataThumbnail, 'image/png');

          await this.pCbPawOrderFileService()
            .createUploadAttachmnetRestrict(cbAttachmentRestrict, progress => {
              console.log('uploading thumbnail ...', progress);
            })
            .then(async param => {
              refChtFileThumbnailId = param.id;
              cbPawOrderFile.cbAttachmentRestrict.refThumbnailId = refChtFileThumbnailId;
            })
            .catch(error => {
              if (callback) callback({ error });
              this.alertService().showHttpError(this, error.response);
            });
        }

        await this.pCbPawOrderFileService()
          .create(
            cbPawOrderFile,
            true,
            progress => {
              // start setting upload
              this.$root.$emit('app::getProgressUploadDownload', (progressUploads: IProgressUpload[]) => {
                progressUploadFindByEmit = progressUploads.find(
                  (progressUpload: IProgressUpload) => progressUpload.localUrl === cbPawOrderFile.cbAttachmentRestrict.localUrl
                );
                if (!progressUploadFindByEmit) return;
                progressUploadFindByEmit.progress = progress;
                progressUploadFindByEmit.status = CbCommonStatus.COMPLETED;
                this.$root.$emit('app::progressUploadDownload', progressUploads);
                // end setting upload
              });
            },
            this.callbackRejectPawOrderFile
          )
          .catch(error => {
            if (callback) callback({ error });
            this.alertService().showHttpError(this, error.response);
          });

        this.$root.$emit('app::getProgressUploadDownload', (progressUploads: IProgressUpload[]) => {
          progressUploadsFindByEmit = progressUploads;
        });

        if (progressUploadsFindByEmit?.every(upload => upload.progress === 100)) {
          if (callback) callback({ error: null });

          // clear after upload
          progressUploadsFindByEmit = [];
          this.$root.$emit('app::progressUploadDownload', progressUploadsFindByEmit);
        }
      }
    }
    if (this.loadingComponent.index) {
      this.loadingComponent = new LoadingComponent();
    }
  }

  // NEED_SETTING_PROGRESS
  public callbackRejectPawOrderFile(cbPawOrderFile: ICbPawOrderFile) {
    if (!cbPawOrderFile.id) {
      return;
    }
    this.pCbPawOrderFileService()
      .delete(cbPawOrderFile.id)
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        const isChatSkip = false;

        this.refreshOrder({
          orderId: this.cbVwPawOrder.id,
          reqId: cbPawOrderFile.requirementId,
          isChatSkip,
          typeModal: TypeComponent.CHAT_MODAL,
        });
        (<any>this.$root).$emit('mdl::closeAll');
      });

    if (this.loadingComponent.index) {
      this.loadingComponent = new LoadingComponent();
    }
  }

  public saveCbUserReview(): void {
    this.pCbUserReviewService()
      .create(this.cbUserReview)
      .then(() => {
        this.cbUserReview = new CbUserReview();
        this.$root.$emit('mdl::isShowSurvey', true);
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public cbPawOrderRequirementPartialUpdate(partial: ICbPawOrderRequirement, typeModal?: TypeComponent, callback?: any) {
    this.isSavingPawOrderRequirement = true;
    if (callback) callback({ isSavingLoader: true });

    this.pCbPawOrderRequirementService()
      .partialUpdate(partial)
      .then(res => {
        if (callback) callback({ isSavingLoader: false, data: res });
      })
      .catch(err => {
        if (callback) callback({ isSavingLoader: false, data: null });
        this.alertService().showHttpError(this, err.response);
        console.log('error', err.response);
      })
      .finally(() => {
        this.isSavingPawOrderRequirement = false;
      });
  }

  // WF
  public sendOnMyWay(cbPawOrderRequirement: ICbPawOrderRequirement, cbVwPawOrder: ICbPawOrder, event4wf, callback?: any) {
    callback(true);
    this.pCbPawOrderRequirementService()
      .create(cbPawOrderRequirement, this.wfGetWfHistoryByElementStatic(cbVwPawOrder.workflowName, event4wf))
      .then(res => {
        const chtMessage: IChtMessage = new ChtMessage();
        const chtConversation = new ChtConversation();
        // get variableValue from chat controller
        this.$root.$emit('chat::getVariable', 'roomId', value => {
          chtConversation.id = value;
        });
        chtMessage.chtConversation = chtConversation;
        chtMessage.externalId = res.id.toString();
        chtMessage.chtContentType = ChtContentType.ORDER_ON_MY_WAY;
        chtMessage.content = '';
        (<any>this.$root).$emit('chat::saveMessage', chtMessage);
        (<any>this.$root).$emit('mdl::closeAll');
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        callback(false);
      });
  }

  public openClock(cbVwPawOrder: ICbVwPawOrder, type: string) {
    this.cbAttachmentRestrictSelfie = new CbAttachmentRestrict();
    this.cbAttachmentRestrictLocationPhoto = new CbAttachmentRestrict();

    this.cbPawOrderRequirement = {
      ...new CbPawOrderRequirement(),
      ...{
        requirementType: type == CbRequirementType.CLOCK_IN ? CbRequirementType.CLOCK_IN : CbRequirementType.SERVICE_FINISHED,
        cbPawOrder: {
          ...new CbPawOrder(),
          ...{
            id: cbVwPawOrder.id,
            pawTitle: cbVwPawOrder.pawTitle,
            svcType: cbVwPawOrder.svcType,
            svcName: cbVwPawOrder.svcName,
            svcCategoryName: cbVwPawOrder.svcCategoryName,
          },
        },
        partner: {
          ...new User(),
          ...{
            id: cbVwPawOrder.partnerId,
          },
        },
        buyer: {
          ...new User(),
          ...{
            id: cbVwPawOrder.buyerId,
          },
        },
        status: type == CbRequirementType.CLOCK_IN ? CbCommonStatus.CLOCK_IN : CbCommonStatus.SERVICE_FINISHED,
      },
    };

    if (type == CbRequirementType.CLOCK_IN) {
      (<any>this.$root).$emit('mdl::changeValue', 'isShowUploadFieldClockIn', true);
    } else if (type == CbCommonStatus.SERVICE_FINISHED) {
      (<any>this.$root).$emit('mdl::changeValue', 'isShowUploadFieldClockOut', true);
    }
  }

  // NEED_SETTING_PROGRESS
  public sendClockOut({
    cbVwPawOrder,
    req,
    cbAttachmentRestrictLocationPhoto,
    typeModal,
    callback,
  }: {
    cbVwPawOrder: ICbVwPawOrder;
    req: ICbPawOrderRequirement;
    cbAttachmentRestrictLocationPhoto: ICbAttachmentRestrict;
    typeModal?: TypeComponent;
    callback?: any;
  }) {
    this.isSavingPawOrderRequirement = true;
    if (callback) callback({ isSavingLoader: true, data: null });

    this.wfGetWfHistoryByOR(cbVwPawOrder.workflowName, cbVwPawOrder.id, req)
      .then(wfHistory => {
        this.pCbPawOrderRequirementService()
          .create(req, wfHistory)
          .then(async res => {
            cbAttachmentRestrictLocationPhoto.restrictToUserId = req.buyer.id;
            const pawOrderFileLocationPhoto: ICbPawOrderFile = {
              cbAttachmentRestrict: cbAttachmentRestrictLocationPhoto,
              requirementId: res.id,
              fileName: 'location photo ' + res.id,
              pawOrderFileType: CbPawOrderFileType.PROJECT_CLOCK_OUT,
            };

            await this.pCbPawOrderFileService()
              .create(
                pawOrderFileLocationPhoto,
                true,
                progress => {
                  console.log('uploading ...', progress);
                },
                this.callbackRejectPawOrderFile
              )
              .catch(err => {
                this.alertService().showHttpError(this, err.response);
              });

            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            if (callback) callback({ isSavingLoader: false, data: res });

            if (this.isMobile) this.isShowChatInfoPanel = false;
            this.createConversation({ openChat: false, reqParam: res, type: typeModal });

            this.refreshOrder({ orderId: cbVwPawOrder.id, reqId: res.id, isChatSkip: false, isWithoutLoading: false, typeModal });
          })
          .catch(err => {
            this.isSavingPawOrderRequirement = false;
            if (callback) callback({ isSavingLoader: false, data: null });
            this.alertService().showHttpError(this, err.response);
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
          });
      })
      .catch(err => {
        if (callback) callback({ isSavingLoader: false, data: null });
        console.error('error', err.response);
      });
  }

  // USED
  public async saveChatMessageRequirement(cbVwPawOrderRequirementId: number, chtContentType: ChtContentType, type?: TypeComponent) {
    let roomId: number = null;
    this.$root.$emit('chat::getVariable', 'roomId', value => {
      roomId = value;
    });

    const chtMessage: IChtMessage = new ChtMessage();
    const chtConversation = new ChtConversation();

    if (this.chtConversation.id) {
      chtMessage.chtConversation = this.chtConversation;
    } else {
      chtConversation.id = roomId;
      chtMessage.chtConversation = chtConversation;
    }
    chtMessage.externalId = cbVwPawOrderRequirementId + '';
    chtMessage.chtContentType = chtContentType;
    chtMessage.content = '';

    if (type === TypeComponent.DETAIL_ORDER_MODAL || type === TypeComponent.DETAIL_ORDER_LOCATION) {
      this.createMessage(chtMessage);
    } else {
      (<any>this.$root).$emit('chat::saveMessage', chtMessage);
    }
  }

  public createMessage(chtMessage: IChtMessage) {
    chtMessage.authorPhotoId = this.currentUser.photoId;
    chtMessage.authorDisplayName = this.currentUser.displayName;

    this.pChtService()
      .createMessage(chtMessage)
      .then(() => {
        if (this.isMobile) {
          this.$store.commit('roomIdToStore', chtMessage.chtConversation.id + '');
          this.$router.push({
            name: 'PAppChatByRoomId',
            params: { roomId: chtMessage.chtConversation.id + '' },
            query: { type: 'isShowChat' },
          });
        } else {
          this.$store.commit('roomIdToStore', chtMessage.chtConversation.id + '');
          this.$router.push({ name: 'PAppChatByRoomId', params: { roomId: chtMessage.chtConversation.id + '' } });
        }
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public openAskExtraRevision(e) {
    this.cbPawOrderExtras = [];
    this.modalStepFormOrders.push(ModalStepFormOrderType.isShowAddExtraRevision);

    if (this.cbVwPawOrder.orderType == CbOrderType.PAW) {
      this.pCbVwPawService()
        .find(this.cbVwPawOrder.pawIdHistory, this.cancelToken ? this.cancelToken.token : null)
        .then(res => {
          this.cbVwPaw = Object.assign({}, this.cbVwPaw, res);
          this.$root.$emit('opc::changeValue', 'cbPawOrderRequirement', e);

          (<any>this.$root).$emit('mdl::changeValue', 'isShowAddExtraRevision', true);
        })
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        });
    }
    if (this.cbVwPawOrder.orderType == CbOrderType.AUCTION_PROJECT) {
      this.$root.$emit('opc::changeValue', 'cbPawOrderRequirement', e);
      (<any>this.$root).$emit('mdl::changeValue', 'isShowAddExtraRevision', true);
    }
  }

  public retrieveBgWatermark() {
    this.contentService().initRetrieveCbLovs(CbLovType.WATERMARK);
  }
  public get cbLovWatermarks() {
    return this.$store.getters[CbLovType.WATERMARK];
  }

  public cancelRequest() {
    if (this.cancelToken) {
      this.cancelToken.cancel('Request Order Cancelled');
    }
  }

  public initXenditCreditIframeCallback(xenditUrl: string, typeModal?: TypeComponent) {
    this.xenditUrl = xenditUrl;
    const fn = async (event: Event) => {
      await this.paymentCCListener(event, typeModal);
    };
    const data = { key: FnListener.KEY_XENDIT_CREDIT_IFRAME_CALLBACK, fn: fn };
    const foundIndex = FnListener.fnArr.findIndex(obj => obj.key === FnListener.KEY_XENDIT_CREDIT_IFRAME_CALLBACK);
    if (foundIndex !== -1) {
      window.removeEventListener(FnListener.KEY_XENDIT_CREDIT_IFRAME_CALLBACK, FnListener.fnArr[foundIndex].fn, false);
      FnListener.fnArr[foundIndex] = data;
    } else {
      FnListener.fnArr.push(data);
    }
    window.addEventListener(FnListener.KEY_XENDIT_CREDIT_IFRAME_CALLBACK, fn, false);

    this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
    this.handleActionModal({ type: typeModal, isShowModal: 'visibleXenditCC' });
  }

  public async paymentCCListener(event: any, typeModal?: TypeComponent) {
    if (event.origin == 'https://redirect.xendit.co') {
      const data = JSON.parse(event.data);

      await this.pPayService()
        .callBackPaymentCC2023(data)
        .then(res => {
          console.log('RESPONSE callback', res);

          if (res.id != -1 && res.message) {
            this.handleActionModal({ type: typeModal, isShowModal: 'closeAll' });
            this.refreshOrder({ orderId: this.cbVwPawOrder.id, reqId: this.cbVwPawOrderRequirement.id, isChatSkip: false, typeModal });
          }

          if (res.id == -1 && res.message) {
            (<any>this.$root).$bvToast.toast(res.message, {
              toaster: 'b-toaster-top-center',
              title: 'Error',
              variant: 'error',
              solid: true,
              autoHideDelay: 5000,
            });
          }
        })
        .catch(error => {
          this.alertService().showHttpError(this, error.response);
        });
    }
  }

  public showModalVA(payDetailVA: IPayDetailVA, typeModal?: string) {
    this.payDetailVA = payDetailVA;
    this.handleActionModal({ type: typeModal, isShowModal: 'visibleVA' });
  }

  public checkPaymentVa(payPayload2023: PayPayload2023, typeModal?: string, callback?: any) {
    if (callback) callback({ isSavingLoader: true, data: null });

    this.paymentPayload = Object.assign({}, payPayload2023);
    this.pPayService()
      .pay2023(this.paymentPayload)
      .then(res => {
        if (callback) callback({ isSavingLoader: false, data: res });

        if (res?.reqType == 'VA_ACTIVE' && res?.detail) {
          this.showModalVA(res.detail, typeModal);
        } else {
          this.handleActionModal({ type: typeModal, isShowModal: 'visibleFormPayment2023' });
        }
      })
      .catch(err => {
        if (callback) callback({ isSavingLoader: false, data: null });
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isSavingPawOrderRequirement = false;
      });
  }

  public retrieveCbVwPawOrderRquirementActiveByLogin() {
    this.pCbVwPawOrderRequirementActiveService()
      .retrieveByLogin()
      .then(res => {
        this.$root.$emit('chat::changeValue', 'cbVwPawOrderRquirementActives', res.data);
      })
      .catch(err => {});
  }

  public clearFileBase64(file: ICbPawOrderFile[]) {
    // clear file base64
    if (Array.isArray(file)) {
      file?.forEach(cbPawOrderFile => {
        if (cbPawOrderFile.cbAttachmentRestrict) {
          cbPawOrderFile.cbAttachmentRestrict.thumbnail = null;
          cbPawOrderFile.cbAttachmentRestrict.downloadLinkFile = null;
          cbPawOrderFile.cbAttachmentRestrict.localFileUrl = null;
        }

        if (cbPawOrderFile.cbAtt) {
          cbPawOrderFile.cbAtt.thumbnail = null;
          cbPawOrderFile.cbAtt.thumbnailLocalUrl = null;
          cbPawOrderFile.cbAtt.localUrl = null;
          cbPawOrderFile.cbAtt.localFileUrl = null;
        }
      });
    }

    return file;
  }

  public resetAll() {
    this.cbVwPawOrders = [];
    this.cbVwPawOrderRequirements = [];
    this.cbPawOrderFiles = [];
    this.cbPawOrderIncludes = [];
    this.cbPawOrderExtras = [];
    this.cbPawOrderMilestones = [];
    this.cbPawOrderRequirements = [];
    this.cbVwAuctionProjectPreOffers = [];
    this.cbPawPreOrders = [];
    this.cbVwPaws = [];
    this.cbVwPawOrder = new CbVwPawOrder();
    this.cbPawOrderRequirement = new CbPawOrderRequirement();
    this.cbVwPaw = new CbVwPaw();
    this.cbPawOrder = new CbPawOrder();
    this.cbVwUser = new CbVwUser();

    this.isFetchingVwPawOrdersProgressOnly = false;
    this.isFetchingVwPawOrderRequirements = false;
    this.isFetchingCbVwPaw = false;
    this.isSavingPawOrder = false;
    this.isSavingPawOrderRequirement = false;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  public get loginType() {
    return this.$store.getters.loginType;
  }

  public get cbTextRestricts(): ICbTextRestrict[] {
    return [...this.$store.getters.cbTextRestricts];
  }

  public get cbTextNoAuths(): ICbTextNoAuth[] {
    return [...this.$store.getters.cbTextNoAuths];
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  public get cbLovLanguages(): ICbLov[] {
    return this.$store.getters[CbLovType.LANGUAGE];
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
  // ================= START RESET VARIABLES ================
  // ================= END RESET VARIABLES ==================
}
