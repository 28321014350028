import { Inject, Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';
import CInputOtp from '@/components/c-input-otp/c-input-otp.vue';
import PCbOidService from '@/services/cb-oid.service';
import { ICbSetPassword } from '@/shared/model/cb-oids.model';
import { CbVerificationOtp } from '@/shared/model/cb-verification-otp.model';

@Component({ components: { CTimeCountdown, CInputOtp } })
export default class CVerificationPassword extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbOidService') public pCbOidService: () => PCbOidService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false, type: Boolean }) public visibleFooter: boolean;
  @Prop({ default: false, type: Boolean }) public visibleHeader: boolean;
  @Prop({ default: false, type: Boolean }) public visibleCountdown: boolean;
  @Prop({ default: null }) public value: CbVerificationOtp;
  @Prop({ default: false }) public invalidOtp: boolean;
  public visibleLoading: boolean = false;
  public inputOtp: string = '';
  public showCountdown: boolean = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.visibleCountdownListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public handleInputOtp(value) {
    if (value.length > 0) {
      value = value.toUpperCase();
    }
    if (value.length == 4) {
      this.visibleLoading = true;
      const activation: ICbSetPassword = {
        newPassword: '',
        activationCode: value,
      };

      this.$emit('changeFunction', 'setPasswordFinish', activation);
    }
  }

  public resend() {
    if (!this.showCountdown) {
      this.$emit('changeFunction', 'initChangePassword', true);
      this.visibleCountdownListener();
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('visibleCountdown')
  public visibleCountdownListener() {
    if (this.visibleCountdown) {
      this.showCountdown = true;
    }
  }
  // ================= END LISTENERS ==================
}
