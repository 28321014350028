var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-room-search-format-msg vac-text-ellipsis" },
    [
      !_vm.textFormatting.disabled
        ? _c(
            "div",
            { staticClass: "vac-text-ellipsis" },
            _vm._l(_vm.linkifiedMessage, function (message, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "vac-format-container",
                  attrs: { "data-cy": "vac-format-container" },
                },
                [
                  _c(
                    message.url ? "a" : "span",
                    {
                      tag: "component",
                      class: {
                        "vac-text-ellipsis": _vm.singleLine,
                        "vac-text-bold": message.bold,
                        "vac-text-italic": _vm.deleted || message.italic,
                        "vac-text-strike": message.strike,
                        "vac-text-underline": message.underline,
                        "vac-text-inline-code":
                          !_vm.singleLine && message.inline,
                        "vac-text-multiline-code":
                          !_vm.singleLine && message.multiline,
                        "vac-text-tag":
                          !_vm.singleLine && !_vm.reply && message.tag,
                      },
                      attrs: {
                        href: message.href,
                        target: message.href ? _vm.linkOptions.target : null,
                        rel: message.href ? _vm.linkOptions.rel : null,
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          attrs: { "data-cy": "vac-format-message" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$options.filters.SECURITY_VHTML(
                                _vm.textBoldSearch(
                                  message.value,
                                  _vm.searchInputed
                                ),
                                _vm.$env.BASE_URL
                              )
                            ),
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(message.value) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.SECURITY_VHTML(
                  _vm.textBoldSearch(_vm.formattedContent, _vm.searchInputed),
                  _vm.$env.BASE_URL
                )
              ),
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }